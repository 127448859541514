import {ComponentContainer} from "@rx/core"
import { OnCallOverrideListComponent } from './list/on-call-override-list.component'

export const ON_CALL_OVERRIDE_SHARED_COMPONENT_CONTAINER: ComponentContainer[] = [
	{
    component: OnCallOverrideListComponent,
    accessItem: 'list',
    applicationModuleId: 4,
	keyName: 'onCallOverrideId',
	childModuleName :  'oncalloverride'  ,
	pageName:'on-call-override',
	rootModuleId:2
	},
];

