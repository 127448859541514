import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { PmdPatient } from './pmd-patient'; @table('PmdUsers')
export class PmdUser {
    constructor(pmdUser?: PmdUser )  {
        let properties = [ "altPhoneNumber", "applicationTimeZoneId", "createdByUserId", "createdDate", "email", "firstName", "languageId", "lastName", "modifiedByUserId", "modifiedDate", "officeID", "password", "phoneNumber", "pmdUserID", "practiceID", "providerId", "regGuid", "salt", "statusId", "userProfilePhotoUrl", "pmdPatients", "currentPassword", "userPassword", "isChangePassword", "confirmPassword", "dateData", "oldPassword", "timeZoneName", "fileExtension", "fileType", "profilePicture", "subDomain",];
        for (let property of properties)
            if (pmdUser && (pmdUser[property] || pmdUser[property] == 0))
                this[property] = pmdUser[property];
    }
 
    @maxLength(12)
	@property('AltPhoneNumber')
	altPhoneNumber : string = undefined ;
 
    @range(1,2147483647)
	@property('ApplicationTimeZoneId')
	applicationTimeZoneId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedByUserId')
	createdByUserId : number =   undefined;
 
    @required()
	@property('CreatedDate')
	createdDate : Date =   undefined;
 
    @required()
    @maxLength(100)
	@property('Email')
	email : string = undefined ;
 
    @maxLength(50)
	@property('FirstName')
	firstName : string = undefined ;
 
	@property('LanguageId')
	languageId : number =   undefined;
 
    @maxLength(50)
	@property('LastName')
	lastName : string = undefined ;
 
	@property('ModifiedByUserId')
	modifiedByUserId : number =   undefined;
 
	@property('ModifiedDate')
	modifiedDate : Date =   undefined;
 
	@property('OfficeID')
	officeID : number =   undefined;
 
    @required()
    @maxLength(132)
	@property('Password')
	password : any =   undefined;
 
    @maxLength(12)
	@property('PhoneNumber')
	phoneNumber : string = undefined ;
 
	@property('PmdUserID')
	pmdUserID : number =   0 ;
 
    @range(1,2147483647)
	@property('PracticeID')
	practiceID : number =   undefined;
 
	@property('ProviderId')
	providerId : number =   undefined;
 
    @required()
	@property('RegGuid')
	regGuid : string = undefined ;
 
    @required()
    @maxLength(140)
	@property('Salt')
	salt : any =   undefined;
 
	@property('StatusId')
	statusId : number =   undefined;
 
    @maxLength(500)
	@property('UserProfilePhotoUrl')
	userProfilePhotoUrl : string = undefined ;
	@nested(PmdPatient)
	pmdPatients: PmdPatient[];



	currentPassword : string =   undefined;
	userPassword : string =   undefined;
	isChangePassword : boolean = false ;
	confirmPassword : string =   undefined;
	dateData : Date =   undefined;
	oldPassword : string =   undefined;
	timeZoneName : string =   undefined;
	fileExtension : string =   undefined;
	fileType : string =   undefined;
	profilePicture : string =   undefined;
	subDomain : string =   undefined;

}
