export const TEXT_ADDITIONAL_TIMES = [
	{
        "textAdditionalTimesId": 2120,
        "textAdditionalTimesName": "1"
    },
	{
        "textAdditionalTimesId": 2121,
        "textAdditionalTimesName": "2"
    },
	{
        "textAdditionalTimesId": 2122,
        "textAdditionalTimesName": "3"
    },
	{
        "textAdditionalTimesId": 2123,
        "textAdditionalTimesName": "4"
    },
	{
        "textAdditionalTimesId": 2124,
        "textAdditionalTimesName": "5"
    },
]