import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('CallCenters')
export class CallCenter {
    constructor(callCenter?: CallCenter )  {
        let properties = [ "accountNumber", "callCenterId", "companyName", "contactName", "emailDomainName", "ipAddress", "phoneNumber",];
        for (let property of properties)
            if (callCenter && (callCenter[property] || callCenter[property] == 0))
                this[property] = callCenter[property];
    }
 
    @range(1,2147483647)
@required()
@numeric()
	@property('AccountNumber')
	accountNumber : number =   undefined;
 
	@property('CallCenterId')
	callCenterId : number =   0 ;
 
    @required()
    @maxLength(100)
@required()
	@property('CompanyName')
	companyName : string = undefined ;
 
    @required()
    @maxLength(50)
@required()
	@property('ContactName')
	contactName : string = undefined ;
 
    @required()
    @maxLength(100)
@required()
@pattern('^[a-zA-Z0-9]{2,}[\.][a-zA-Z0-9]{2,}$')
	@property('EmailDomainName')
	emailDomainName : string = undefined ;
 
    @required()
    @maxLength(50)
@pattern('^0*([1-9]?[0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.0*([1-9]?[0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.0*([1-9]?[0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.0*([1-9]?[0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$')
	@property('IpAddress')
	ipAddress : string = undefined ;
 
    @required()
    @maxLength(12)
@required()
	@property('PhoneNumber')
	phoneNumber : string = undefined ;



}
