import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick } from '@rx/view';

import { vOnCallRoster } from 'src/app/database-models';
import { OnCallRostersService } from '../on-call-rosters.service';
import { OnCallRosterDomain } from '../domain/on-call-roster.domain';

import { CallTypesEnum } from '../../../../const/call-type';
import { ApplicationBroadcaster } from '@rx/core';
import { user } from '@rx/security';


@Component({
    selector: 'app-on-call-roster-list',
    templateUrl: './on-call-roster-list.component.html',
})
export class OnCallRosterListComponent extends OnCallRosterDomain implements OnInit, OnDestroy {
    showComponent: boolean = false;
    onCallRoster: any;
    listSubscription: Subscription;
    @Input() practiceId: number;
    calltypeEnum: any;
    isOnCallRosterDetails: boolean = false;
    constructor(
        private onCallRostersService: OnCallRostersService,
        private dialog: RxDialog,
        private applicationBroadcaster: ApplicationBroadcaster,
        private router: Router,
    ) {
        super();
        this.applicationBroadcaster.isPracticeSelectedSubscriber.subscribe(value => {
            this.practiceId = user.data["practiceId"];
            this.showComponent = false;
            this.ngOnInit();
        });
        this.practiceId = user.data["practiceId"];
    }

    ngOnInit(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        if (this.practiceId != undefined && this.practiceId != null && this.practiceId != 0) {
            this.listSubscription = this.onCallRostersService.get(this.practiceId).subscribe(onCallRosters => {
                if (onCallRosters == "")
                    this.isOnCallRosterDetails = false
                else {
                    this.calltypeEnum = CallTypesEnum;
                    this.onCallRoster = onCallRosters[0];
                    this.onCallRoster.preference.where(x=>x.CallTypeId == CallTypesEnum.Appointment)[0].NotificationType = this.onCallRoster.preference.where(x=>x.CallTypeId == CallTypesEnum.PatientToDoctor)[0].NotificationType
                    this.onCallRoster.preference.where(x=>x.CallTypeId == CallTypesEnum.HospitalAdmission)[0].NotificationType = this.onCallRoster.preference.where(x=>x.CallTypeId == CallTypesEnum.PatientToDoctor)[0].NotificationType
                    this.isOnCallRosterDetails = true;
                }
                // this.onCallRoster['preference'].forEach(element => {
                //     if(element.CallTypeId == 
                // });

                this.showComponent = true;
            });
        }
    }



    ngOnDestroy(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        super.destroy();
    }
}
