import { Component, OnChanges, SimpleChanges, OnInit } from '@angular/core';

import { RxToast, RxPopup } from "@rx/view";
import { RxStorage } from '@rx';
import { ApplicationConfiguration, ApplicationBroadcaster } from '@rx/core';
import { user } from '@rx/security';
import { TopBarService } from './top-bar.service';
import { UserAuthorizationService } from '../../../domain/authorization';
import { DEFAULT_USERPROFILE_PHOTO_URL } from '../../../const/default.const';
import { Router } from '@angular/router';
import { FormState } from '../../../domain/form-state';
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
})
export class TopBarComponent implements OnInit {
  profilepic: any;
  userData: any;
  showComponent: boolean;
  userId: number;

  constructor(private toast: RxToast, private userAuthorizationService: UserAuthorizationService,
    private storage: RxStorage,
    private usersService: TopBarService,
    private applicationBroadcaster: ApplicationBroadcaster,
    private router: Router,

  ) {
    this.applicationBroadcaster.updateEmailIdSubscriber.subscribe(t => {
      this.userData.email = t.email;
      if (t.profilepic)
        this.profilepic = t.profilepic;
      else
        this.profilepic = DEFAULT_USERPROFILE_PHOTO_URL;
    })

  }

  ngOnInit(): void {
    this.userId = user.data['userId']
    this.usersService.getBy([this.userId]).subscribe(users => {
      this.userData = users;
      this.profilepic = user.data['ProfilePicture'];
      if (this.profilepic == null || this.profilepic == '') {
        this.profilepic = DEFAULT_USERPROFILE_PHOTO_URL;
      }
      this.showComponent = true;
    })
  }

  gotoDashboard() {
    FormState.remove(0);
    localStorage.removeItem('callId');
    this.router.navigate(['/dashboard']);
  }

  toggleSideBar(): void {
    document.body.classList.toggle("show-sidebar");
  }

  toggleTermSection(): void {
    document.body.classList.toggle("show-termSection");
  }

  logOut(): void {
    // this.isOpenUserProfile = !this.isOpenUserProfile;
    this.userAuthorizationService.postLogOut().subscribe(t => {

      this.toast.show("User logout succesfully", { status: 'success' });
      this.storage.local.clearAll();
      this.storage.session.clearAll();
      this.deleteAllCookies();
      //this.isLoggedIn = false;

      setTimeout(() => {
        window.location.href = '/'
      },
        500);
      ;
    }, error => {
      this.storage.local.clearAll();
      window.location.href = '/';
      this.toast.show("User logout succesfully", { status: 'success' });
    })
  }




  deleteAllCookies(): void {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}
