import { 
	vProvider,
	CallDb,
	vHospital,
	vPrimaryConcern,
} from 'src/app/database-models';
import { maxLength, property, nested } from '@rx';

export class CallDbLookupGroup {
	providers : vProvider[];
	hospitals : vHospital[];
	primamryConcerns : vPrimaryConcern[];
	callDb : CallDb;
	callDbArray: CallDbListModel;
}


export class CallDbViewModel{
	constructor(callDb?: CallDb )  {
        let properties = [ "ProviderName", "NotificationPref", "Phonetic", "WithinTime", "OnAht", "OnAhtDivert", "IsPrimaryProvider","IsRoundingProvider","NotPrefId","OnGlobalOverride","OnLocalOverride","ProviderId"];
        for (let property of properties)
            if (callDb && callDb[property])
                this[property] = callDb[property];
	}
	
	@maxLength(50)
	@property('ProviderName')
	ProviderName : string = undefined;
 
	@maxLength(20)
	@property('NotificationPref')
	NotificationPref : string =   undefined;
 
    @maxLength(50)
	@property('Phonetic')
	Phonetic : string = undefined ;
 
	@property('WithinTime')
	WithinTime : number =   undefined;
 
	@property('OnAht')
	OnAht : any =   undefined;
 
	@property('OnAhtDivert')
	OnAhtDivert : any =   undefined;
 
	@property('OnOverride')
	OnOverride : any =   undefined;

	@property('IsRoundingProvider')
	IsRoundingProvider : boolean = undefined;
	@property('IsPrimaryProvider')
	IsPrimaryProvider : boolean = undefined;
	@property('NotPrefId')
	NotPrefId : any = undefined;
	@property('OnGlobalOverride')
	OnGlobalOverride : boolean = undefined;
	@property('OnLocalOverride')
	OnLocalOverride : boolean = undefined;
	@property('ProviderId')
	ProviderId : number = undefined;
}

export class CallDbListModel{
	constructor(callDbList?: CallDbListModel )  {
        let properties = [ "CallDbArray","isEdit"];
        for (let property of properties)
            if (callDbList && callDbList[property])
                this[property] = callDbList[property];
	}
	@nested(CallDb)
	CallDbArray : Array<CallDb> =   undefined;

	@property('isEdit')
	isEdit : boolean = undefined
}