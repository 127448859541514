import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";
import {RxViewModule } from '@rx/view';
import {RxFormsModule } from '@rx/forms';
import {RxTableModule } from "@rx/table";
import {DynamicComponentContainer } from '@rx/core';

import { PracticeListComponent } from './list/practice-list.component'
import { PracticeViewComponent } from './view/practice-view.component'
import {PracticesService } from "./practices.service";
import {PRACTICES_SHARED_COMPONENT_CONTAINER } from './practices-shared-component.container';
import { PracticeSearchComponent } from './search/practice-search.component';
import { ProvidersSharedComponentModule } from '../../agent-provider/providers/providers-shared-component.module';
import { AlertsService } from '../../agent-alert/alerts/alerts.service';
@NgModule({
    imports: [
        RxViewModule, RxFormsModule,
        CommonModule, FormsModule, ReactiveFormsModule, RxTableModule,ProvidersSharedComponentModule
    ],
    declarations: [ PracticeListComponent,  PracticeViewComponent,PracticeSearchComponent ],
    providers: [PracticesService,AlertsService ],
    exports: [RouterModule, PracticeListComponent,  PracticeViewComponent, PracticeSearchComponent]
})
export class PracticesSharedComponentModule { }
//DynamicComponentContainer.register(PRACTICES_SHARED_COMPONENT_CONTAINER );