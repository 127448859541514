import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vAgentQuestionMappings')
export class vAgentQuestionMapping {
    constructor(vAgentQuestionMapping?: vAgentQuestionMapping )  {
        let properties = [ "agentQueId", "agentQueMappingId", "agentQueMasterId", "callTypeId", "msgIndex", "practiceId", "question", "questionCode", "required", "toBeAsked", "usedInMsg",];
        for (let property of properties)
            if (vAgentQuestionMapping && (vAgentQuestionMapping[property] || vAgentQuestionMapping[property] == 0))
                this[property] = vAgentQuestionMapping[property];
    }
 
    @range(1,2147483647)
	@property('AgentQueId')
	agentQueId : number =   undefined;
 
    @range(1,2147483647)
	@property('AgentQueMappingId')
	agentQueMappingId : number =   undefined;
 
    @range(1,2147483647)
	@property('AgentQueMasterId')
	agentQueMasterId : number =   undefined;
 
    @range(1,2147483647)
	@property('CallTypeId')
	callTypeId : number =   undefined;
 
    @range(1,2147483647)
	@property('MsgIndex')
	msgIndex : number =   undefined;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @required()
    @maxLength(200)
	@property('Question')
	question : string = undefined ;
 
    @required()
    @maxLength(200)
	@property('QuestionCode')
	questionCode : string = undefined ;
 
	@property('Required')
	required : boolean = false ;
 
	@property('ToBeAsked')
	toBeAsked : boolean = false ;
 
	@property('UsedInMsg')
	usedInMsg : boolean = false ;



}
