import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Hospital } from './hospital';import { HospitalRoundingRoster } from './hospital-rounding-roster'; @table('RoundingHospitalMapping')
export class RoundingHospitalMapping {
    constructor(roundingHospitalMapping?: RoundingHospitalMapping )  {
        let properties = [ "roundingHospitalMappingId", "hospitalId", "hospitalRoundingRosterId",];
        for (let property of properties)
            if (roundingHospitalMapping && (roundingHospitalMapping[property] || roundingHospitalMapping[property] == 0))
                this[property] = roundingHospitalMapping[property];
    }
 
	@property('RoundingHospitalMappingId')
	roundingHospitalMappingId : number =   0 ;
 
    @range(0,2147483647)
	@property('HospitalId')
	hospitalId : number =   undefined;
	hospital : Hospital  ;
 
    @range(0,2147483647)
	@property('HospitalRoundingRosterId')
	hospitalRoundingRosterId : number =   undefined;
	hospitalRoundingRoster : HospitalRoundingRoster  ;



}
