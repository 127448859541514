import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { PageAccess, CanActivatePage } from "src/app/domain/authorization";
import { LoginComponent } from "../login/login/login.component";
import { ResetPasswordComponent } from "../login/reset-password/reset-password.component";
import { UnAuthorizedComponent } from "../unauthorized/unauthorized.component";





const APP_LAZY_ROUTES: Routes = [
  {
    path: '', redirectTo: 'login', pathMatch: 'full', data: { anonymous: true }
  },
  {
    path: 'login', component: LoginComponent, data: { anonymous: true }
  },
  {
    path: 'unauthorized', component: UnAuthorizedComponent,
  },
  {
    path: 'dashboard', loadChildren: () => import('src/app/components/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [PageAccess], data: { applicationModuleId: 5104 }
  },
  {
    path: 'users',
    loadChildren: () => import('src/app/components/manage-user-agent/users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent, canActivate: [CanActivatePage], data: { anonymous: true }
  },
  {
    path: 'providers',
    loadChildren: () => import('src/app/components/agent-provider/providers/providers.module').then(m => m.ProvidersModule),
  },
  {
    path: 'application-issues',
    loadChildren: () => import('src/app/components/application-issue/application-issues/application-issues.module').then(m => m.ApplicationIssuesModule),
  },
  {
    path: 'on-call-override',
    loadChildren: () => import('src/app/components/agent-on-call-override/on-call-override/on-call-override.module').then(m => m.OnCallOverrideModule),
  },
  {
    path: 'alerts',
    loadChildren: () => import('src/app/components/agent-alert/alerts/alerts.module').then(m => m.AlertsModule),
  },
  {
    path: 'call-db',
    loadChildren: () => import('src/app/components/call-manager/call-db/call-db.module').then(m => m.CallDbModule),
  },
  {
    path: 'call-outbound',
    loadChildren: () => import('src/app/components/call-manager/call-outbound/call-outbound.module').then(m => m.CallOutboundModule),
  },
  {
    path: 'call-record-history',
    loadChildren: () => import('src/app/components/call-manager/call-record-history/call-record-history.module').then(m => m.CallRecordHistoryModule),
  },
  {
    path: 'on-call-rosters',
    loadChildren: () => import('src/app/components/agent-roster/on-call-rosters/on-call-rosters.module').then(m => m.OnCallRostersModule),
  },
  {
    path: 'telehealth-dashboard',
    loadChildren: () => import('src/app/components/telehealth-dashboard/telehealth-dashboard.module').then(m => m.TelehealthDashboardModule),
    canActivate: [PageAccess], data: { applicationModuleId: 5104 }
  },
  {
    path: 'schedule-televisit',
    loadChildren: () => import('src/app/components/schedule-televisit/schedule-televisit.module').then(m => m.ScheduleTelevisitModule),
  },
  {
    path: 'aht-divert',
    loadChildren: () => import('src/app/components/agent-aht-divert/aht-divert/aht-divert.module').then(m => m.AhtDivertModule),
    
  },
  //route-paths
];


export const APP_LAZY_ROUTING = RouterModule.forRoot(APP_LAZY_ROUTES, { preloadingStrategy: PreloadAllModules, useHash: true, relativeLinkResolution: 'legacy' });
