import { NgModule } from '@angular/core';
import {RouterModule } from '@angular/router';


import { AHT_DIVERT_ROUTING } from './aht-divert.routing';
import { AhtDivertService } from './aht-divert.service';

@NgModule({
    imports: [AHT_DIVERT_ROUTING],
    exports: [RouterModule],
    providers: [AhtDivertService]
})
export class AhtDivertModule { }