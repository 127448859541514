import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxFormsModule } from "@rx/forms";
import { RxViewModule } from "@rx/view";
import { RxTableModule } from "@rx/table";
import { TELEHEALTH_DASHBOARD_ADD_ROUTING } from './telehealth-dashboard.routing';
import { TelehealthDashboardComponent } from './telehealth-dashboard.component';
import { PracticesSharedComponentModule } from '../agent-office/practices/practices-shared-component.module';
import { ScheduleTelevisitSharedComponentModule } from '../schedule-televisit/schedule-televisit-shared-component.module';
import { ScheduleTelevisitAddComponent } from '../schedule-televisit/add/schedule-televisit-add.component';
import { ScheduleTelevisitEditComponent } from '../schedule-televisit/edit/schedule-televisit-edit.component';
import { TelehealthDashboardService } from './telehealth-dashboard';
import { ScheduleTelevisitViewComponent } from '../schedule-televisit/view/schedule-televisit-view.component';

@NgModule({
  imports: [
    TELEHEALTH_DASHBOARD_ADD_ROUTING,
    CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,
      PracticesSharedComponentModule,ScheduleTelevisitSharedComponentModule
  ],
  declarations: [TelehealthDashboardComponent],
  exports: [RouterModule],
  providers: [TelehealthDashboardService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents:[ScheduleTelevisitAddComponent,ScheduleTelevisitEditComponent,ScheduleTelevisitViewComponent]
  
})
export class TelehealthDashboardModule { }
