import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick } from '@rx/view';

import { TechnicalTerm } from 'src/app/database-models';
import { TechnicalTermsService } from '../technical-terms.service';
import { TechnicalTermDomain } from '../domain/technical-term.domain';
import { ApplicationBroadcaster } from '@rx/core';
import { PracticesService } from 'src/app/components/agent-office/practices/practices.service';
import { user } from '@rx/security/user';


@Component({
    selector: 'app-technical-term-list',
    templateUrl: './technical-term-list.component.html',
})
export class TechnicalTermListComponent extends TechnicalTermDomain implements OnInit, OnDestroy {
    showComponent: boolean = false;
    technicalTerms: TechnicalTerm[];
    listSubscription: Subscription;
    technicalTermName: string = null;
    @Input() isCalldbScreen: boolean = false;
    isShow: boolean;
    practiceId : number;
    respondTimeUrgent : any;
    constructor(
        private technicalTermsService: TechnicalTermsService,
        private dialog: RxDialog,
        private router: Router,
        private applicationBroadcaster: ApplicationBroadcaster,
        private practicesService: PracticesService,
    ) { 
        super(); 
        this.applicationBroadcaster.openTechnicalSectionSubscriber.subscribe(value => {
            this.isShow = value
        })
        this.practiceId = user.data["practiceId"];
    }

    ngOnInit(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        this.listSubscription = this.technicalTermsService.get().subscribe(technicalTerms => {
            
            this.technicalTerms = technicalTerms;
            this.showComponent = true;
        });

        this.practicesService.getBy([this.practiceId]).subscribe(practices => {

            this.respondTimeUrgent = practices['data'].respondTimeUrgent;
            if(!this.respondTimeUrgent){
                this.respondTimeUrgent = "-";
            }
        });
    }

    public handleClick(inputElement) {
        
        inputElement.select();
        document.execCommand('copy');
        // inputElement.setSelectionRange(0, 0);
    }
    
    removetermSectionClass(){
        // document.body.classList.remove("show-termSection");
        this.isShow = !this.isShow;
    }

    ngOnDestroy(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        super.destroy();
    }

    showPopup() {
        window.open("#/alerts/search", '_blank', 'location=yes,scrollbars=yes,status=yes')
      }
}