import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick } from '@rx/view';
import { user } from '@rx/security';
import { vOnCallOverride } from 'src/app/database-models';
import { OnCallOverrideService } from '../on-call-override.service';
import { OnCallOverrideDomain } from '../domain/on-call-override.domain';
import { ApplicationConfiguration } from '@rx/core';


@Component({
    selector: 'app-on-call-override-list',
    templateUrl: './on-call-override-list.component.html',
})
export class OnCallOverrideListComponent extends OnCallOverrideDomain implements OnInit, OnDestroy {
    showComponent: boolean = false;
    onCallOverride: vOnCallOverride[];
    listSubscription: Subscription;
    @Input() practiceId: number;
    isFilter: boolean = false;
    totalCount: number = 0;
    constructor(
        private onCallOverrideService: OnCallOverrideService,
        private dialog: RxDialog,
        private router: Router,
    ) {
        super();
        if (user && user.data) {
            this.practiceId = user.data["practiceId"];
        }
    }

    ngOnInit(): void {
        
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        this.listSubscription = this.onCallOverrideService.get(this.practiceId).subscribe(onCallOverride => {
            
            this.onCallOverride = onCallOverride;
            this.showComponent = true;
        });
    }

    deleteOnCallOverride(onCallOverride): void {
        this.dialog.confirmation([onCallOverride.firstName], "delete").then(dialogClick => {
            if (dialogClick == DialogClick.PrimaryOk) {
                this.listSubscription = this.onCallOverrideService.delete(this.practiceId, onCallOverride.onCallOverrideId).subscribe(t => {
                    this.ngOnInit();
                }, error => {
                    for (var key in error)
                        this.dialog.alert(ApplicationConfiguration.get("toastValidationMessages.dependency"), error[key]);
                });
            }
        });
    }

    ngOnDestroy(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        super.destroy();
    }
}
