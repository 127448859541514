import { LookupAction } from '@rx/http'

export class ManageUserAgentLookups {
    static roleTypes: LookupAction = {
        controllerName: "manageuseragent",
        actionName: "roleTypes",
		cacheOption: {
            isVersionBase: true,
            tableName:''
        }
    };

}
