import { Component, OnInit, OnDestroy,  Input,ComponentFactoryResolver} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import {RxToast, RxDialog, DialogClick,RxPopup } from '@rx/view';

import { ScheduleTelevisitService } from '../schedule-televisit.service';
import { ScheduleTelevisitDomain } from '../domain/schedule-televisit.domain';
import { user } from '@rx/security';
import { ScheduleTelevisit } from 'src/app/database-models/schedule-televisit';


@Component({
    templateUrl: './schedule-televisit-list.component.html',
})
export class ScheduleTelevisitListComponent extends ScheduleTelevisitDomain implements OnInit, OnDestroy {
    showComponent: boolean = false;
    scheduleTelevisit: ScheduleTelevisit[];
    listSubscription: Subscription;
   
    constructor(
        private scheduleTelevisitService: ScheduleTelevisitService,    
        private dialog: RxDialog,
        private router: Router,
       // private practicesService: PracticesService,
       // private providersService: ProvidersService,
        private popup: RxPopup,
    ) {
        super();
        // this.practiceName = user.data["practiceName"];
        // this.practiceId = user.data["practiceId"];
        // this.userId = user.data["userId"];
    
    }

    ngOnInit(): void {

        
    }
    
  
    ngOnDestroy(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        super.destroy();
    }
}
