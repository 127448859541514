import { ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import { PageAccess } from 'src/app/domain/authorization';

const AHT_DIVERT_ROUTES: Routes = [
	// {
    // path: '', 
	// loadChildren: './list/aht-divert-list.module#AhtDivertListModule',
	// canActivate: [PageAccess],
    // data: { rootModuleId: 33,pageName:'aht-divert', applicationModuleId: 2046, accessItem: 'list',  keyName: 'ahtDivertId', compositeKeys:[] }
	// },
	{
		path: '',
		loadChildren: () => import('./list/aht-divert-list.module').then(m => m.AhtDivertListModule),
		canActivate: [PageAccess],
		data: { rootModuleId: 33,pageName:'aht-divert', applicationModuleId: 2046, accessItem: 'list',  keyName: 'ahtDivertId', compositeKeys:[] }
	  },
];

export const AHT_DIVERT_ROUTING: ModuleWithProviders<any> = RouterModule.forChild(AHT_DIVERT_ROUTES);
