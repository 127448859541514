import { LookupAction } from '@rx/http'

export class CallManagerLookups {
    static hospitals: LookupAction = {
        controllerName: "callmanager",
        actionName: "hospitals",
		cacheOption: {
            isVersionBase: true,
            tableName:''
        }
    };

    static primaryConcerns: LookupAction = {
        controllerName: "callmanager",
        actionName: "primaryConcerns",
		cacheOption: {
            isVersionBase: true,
            tableName:''
        }
    };

    static providers: LookupAction = {
        controllerName: "callmanager",
        actionName: "providers",
		cacheOption: {
            isVersionBase: true,
            tableName:''
        }
    };

}
