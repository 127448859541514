import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { vCallDb, vAgentCallHistory } from 'src/app/database-models';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { CallDbSearchComponent } from '../search/call-db-search.component';
import { ApplicationBroadcaster, ApplicationConfiguration } from '@rx/core';
import { PageQueryViewModel } from 'src/app/view-models/page-query-view-model';


@Component({
  selector: 'app-call-db-callhistory',
  templateUrl: './call-db-callhistory.component.html',
})
export class CallDbCallhistoryComponent extends CallDbDomain implements OnInit, OnDestroy {
  showComponent: boolean = false;
  // @Input() calldblist: any;
  callDb: any;
  callhistorySubscription: Subscription;

  orderByColumn: string;
  totalCount: number = 0;
  sortOrder: string;
  searchText: string = '';

  pageIndex: number;
  tableConfiguration: any;
  pageQueryModel = new PageQueryViewModel();
  rowCount: number;
  sortableConfig: { [key: string]: any };

  @Input() practiceId: number;
  isPopupClose: boolean = false;
  searchQuery: any = undefined;

  callHistory: any;

  //gridSearchObject: { [key: string]: any } = {};


  constructor(
    private callDbService: CallDbService,
    private dialog: RxDialog,
    private router: Router,
    private applicationBroadcaster: ApplicationBroadcaster,
    private popup: RxPopup,
  ) {
    super();
    this.tableConfiguration = ApplicationConfiguration.get('control.rxTable');
    this.applicationBroadcaster.outboundSubscriber.subscribe(value => {
      this.ngOnInit();
    });
  }

  ngOnInit(): void {

    this.sortableConfig = {
      sortColumnName: 'CallID',
      sortOrder: false
    };

    if (this.callhistorySubscription)
      this.callhistorySubscription.unsubscribe();

    let queryParam = "";
    var query: any = {};
    this.pageQueryModel.sortColumnName = "CallID";
    this.tableConfiguration.pageSize = 5;
    this.getData();


    //if (this.pageQueryModel["searchObject"]) {
    //  this.searchData(this.pageQueryModel);
    //}
    //else {
    //  query.pageIndex = 1;
    //  query.rowCount = 5;
    //  query.orderByColumn = this.pageQueryModel.sortColumnName;
    //  query.sortOrder = this.pageQueryModel.sortOrder;
      
    //  queryParam = JSON.stringify(query);
    //}

  }

  getData() {
    let date = this.formatDate(new Date());
    this.callhistorySubscription = this.callDbService.get(0, [null, null, date, 7]).subscribe(callDb => {

      if (callDb.length != 0) {
        this.totalCount = callDb[0].footerCount;
        this.callHistory = JSON.parse(callDb[0].callDbList) == null ? [] : JSON.parse(callDb[0].callDbList);
        this.showComponent = true;

        this.bindGrid(this.searchText);
      }
      else
        this.callDb = [];
        this.showComponent = true;
    });
  }

  showPopup(callDetil: any) {
    this.popup.show(CallDbSearchComponent, { isGridCall: true, callDetails: callDetil }).then(t => {
      this.isPopupClose = true
      this.ngOnInit()
    })
  }

  ngOnDestroy(): void {
    if (this.callhistorySubscription)
      this.callhistorySubscription.unsubscribe();
    super.destroy();
  }


  pageChanging(pageQueryModel: any) {
    
    if (this.searchQuery != undefined) {
      pageQueryModel.searchObject = this.searchQuery
    }
    else {
      pageQueryModel.searchObject = {
        CStartTimeDate: ""
        , CallID: ""
        , CallName: ""
        , Comments: ""
        , PatientName: ""
        , PracticeName: ""
        , TextMessage: ""
        , callTypeName: ""
        , notificationPref: ""
      }
    }
    this.pageQueryModel = pageQueryModel;
    this.bindGrid(this.searchText);
  }

  hasValue(obj, key, value) {
    return obj.hasOwnProperty(key) && obj[key] === value;
  }

  
  bindGrid(searchString: string) {
    this.callDb = [];
    var gridObj = [];
    
    if (searchString != '') {
      gridObj = this.filterBySearch(searchString);
    }
    else {
      gridObj = this.callHistory;
    }

    if (this.pageQueryModel == undefined) {
      this.callDb = gridObj.take(5);
    }
    else {
      var skipRow = this.pageQueryModel.pageSize * (this.pageQueryModel.pageNumber - 1);
      this.callDb = gridObj.skip(skipRow).take(this.pageQueryModel.pageSize);
    }

    this.totalCount = gridObj.length;
  }

  filterBySearch(searchString: string) {
    var filteredObj = [];

    for (var i = 0; i < this.callHistory.length; i++) {
      var callId = this.callHistory[i].CallID;

      var valueMatched = this.checkIfMatch(this.callHistory[i], searchString)

      if (valueMatched == true) {
        filteredObj.push(this.callHistory.filter(x => x.CallID == callId)[0]);
      }
    }

    return filteredObj;
  }

  checkIfMatch(row: any, searchString: string) {

    if (String(row.CallID).toLowerCase().includes(String(searchString).toLowerCase()))
      return true;

    if (String(row.callTypeName).toLowerCase().includes(String(searchString).toLowerCase()))
      return true;

    if (String(row.PracticeName).toLowerCase().includes(String(searchString).toLowerCase()))
      return true;

    if (String(row.CallName).toLowerCase().includes(String(searchString).toLowerCase()))
      return true;

    if (String(row.PatientName).toLowerCase().includes(String(searchString).toLowerCase()))
      return true;

  }

}
