import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";
import {RxViewModule } from '@rx/view';
import {RxFormsModule } from '@rx/forms';
import {RxTableModule } from "@rx/table";
import {DynamicComponentContainer, RxCoreModule } from '@rx/core';

import { CallDbPatientComponent } from './patient/call-db-patient.component'
import { CallDbNewbornComponent } from './newborn/call-db-newborn.component'
import { CallDbDoctorComponent } from './doctor/call-db-doctor.component'
import { CallDbAdmissionComponent } from './admission/call-db-admission.component'
import { CallDbAppointmentComponent } from './appointment/call-db-appointment.component'
import { CallDbAnsserviceComponent } from './ansservice/call-db-ansservice.component'
import { CallDbTriageComponent } from './triage/call-db-triage.component'
import {CallDbService } from "./call-db.service";
import {CALL_DB_SHARED_COMPONENT_CONTAINER } from './call-db-shared-component.container';
import { CallDbListComponent } from './list/call-db-list.component';
import { CallDbViewComponent } from './view/call-db-view.component';
import { CallDbSearchComponent } from './search/call-db-search.component';
import { CallDbRxrefillComponent } from './rxrefill/call-db-rxrefill.component';
import { CallOutboundService } from '../call-outbound/call-outbound.service';
import { CallDbCallhistoryComponent } from './callhistory/call-db-callhistory.component';

@NgModule({
    imports: [
        RxViewModule, RxFormsModule,
        CommonModule, FormsModule, ReactiveFormsModule, RxTableModule,RxCoreModule
    ],
    declarations: [ CallDbPatientComponent,CallDbRxrefillComponent,  CallDbListComponent,CallDbViewComponent,CallDbNewbornComponent,CallDbCallhistoryComponent,  CallDbDoctorComponent,  CallDbAdmissionComponent,  CallDbAppointmentComponent,  CallDbAnsserviceComponent,  CallDbTriageComponent, CallDbListComponent,CallDbSearchComponent],
    providers: [CallDbService,CallOutboundService ],
    exports: [RouterModule,CallDbListComponent,CallDbViewComponent, CallDbPatientComponent,CallDbRxrefillComponent,  CallDbNewbornComponent, CallDbCallhistoryComponent,CallDbDoctorComponent,  CallDbAdmissionComponent,  CallDbAppointmentComponent,  CallDbAnsserviceComponent,  CallDbTriageComponent, CallDbListComponent,CallDbSearchComponent],
    // entryComponents:[ CallDbListComponent,CallDbViewComponent,CallDbNewbornComponent,  CallDbDoctorComponent,  CallDbAdmissionComponent,  CallDbAppointmentComponent,  CallDbAnsserviceComponent,  CallDbTriageComponent, CallDbListComponent]
    
})
export class CallDbSharedComponentModule { }
DynamicComponentContainer.register(CALL_DB_SHARED_COMPONENT_CONTAINER );