import {
    Component,
    AfterContentInit,
    Input,
    Output, HostListener,
    EventEmitter,
    ViewChild,
    OnInit, ComponentRef, ComponentFactoryResolver, EmbeddedViewRef,
    ElementRef, ViewContainerRef, forwardRef, ViewChildren, QueryList, OnDestroy, OnChanges, SimpleChange

} from "@angular/core";

import { Subscription } from "rxjs/Rx";
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from "@angular/forms"
import { ApplicationConfiguration } from '@rx/core';
import { Observable } from "rxjs/Observable";
export class DateDisabled {
    constructor(public date: Date, public title: string) { }
}

export class DateCollection {
    weeks: Week[];
    monthName: string;
    year: number;
    month: number;
    nextMonth: Date;
    previousMonth: Date;
}

export class Week {
    days: Day[];
}

export class Day {
    constructor(public date: Date,
        public selected: boolean,
        public isPreviousMonthDay: boolean,
        public year: number,
        public month: number,
        public day: number,
        public monthDate: number,
        public isDisabled: boolean,
        public isHighlighted: boolean,
        public dateDisabled: DateDisabled
    ) { }

    data: any;
    isCurrentDate: boolean = false;
}
export const days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const daysShort: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const daysMin: string[] = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
export const months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const monthsShort: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


@Component({
    selector: "app-calendar",
    styleUrls: ['./app-calender.component.css'],
    templateUrl: './app-calendar.component.html',
})
export class AppCalendarComponent implements AfterContentInit, OnInit, OnDestroy, OnChanges {
    dayShortNames: Array<string>;
    monthsShort: string[];
    dateCollection: DateCollection;
    currentMonth: number;
    @Input() rosterName: string;
    @Input() dataSource: Observable<any[]>
    source: any[] = [];
    @Input() value: Date;
    @Input() resetSource: boolean;
    @Output() selectAllDate: EventEmitter<Date[]> = new EventEmitter<Date[]>();
    @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() dateUnSelected: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() monthChanged: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() idSelected: EventEmitter<number> = new EventEmitter<number>();

    subscription: Subscription;
    constructor() {
        this.monthsShort = monthsShort;
    }

    ngOnChanges(change: any) {
        if (change.resetSource && change.resetSource.currentValue) {
            this.resetSource = false;
        }
    }

    ngOnInit(): void {
        this.subscription = this.dataSource.subscribe(t => {
            this.source = t;
            this.value = (this.value) ? this.value : new Date();
            this.make(this.value);
        })
        this.dayShortNames = new Array<string>();
        var dayCount = 0;
        daysMin.forEach(t => {
            this.dayShortNames.push(daysShort[dayCount].toUpperCase());
            if (dayCount == 6)
                dayCount = 0;
            else
                dayCount++;
        })
    }

    ngAfterContentInit(): void {
        if (this.source) {
            this.value = (this.value) ? this.value : new Date();
            this.currentMonth = this.value.getMonth();
            this.make(this.value);
        }
    }

    make(date: Date): void {
        this.dateCollection = new DateCollection();
        var year = date.getFullYear(),
            month = date.getMonth();
        this.dateCollection.nextMonth = this.utcDate(year, month + 1, 28);
        this.dateCollection.previousMonth = this.utcDate(year, month - 1, 28);
        var prevMonth = this.utcDate(year, month - 1, 28),
            day = this.daysInMonth(prevMonth.getFullYear(), prevMonth.getMonth());
        prevMonth.setDate(day);
        prevMonth.setDate(day - (prevMonth.getDay() - 0 + 7) % 7);
        var nextMonth = new Date(prevMonth.toString());
        nextMonth.setDate(nextMonth.getDate() + 42);
        var nextMonthValueOf = nextMonth.valueOf();
        this.dateCollection.year = year;
        this.dateCollection.monthName = months[month];
        this.dateCollection.month = month;

        var dayCount = 0;
        this.dateCollection.weeks = new Array<Week>();
        var days: Day[];
        while (prevMonth.valueOf() < nextMonthValueOf) {
            if (dayCount === 0) {
                var week = new Week();
                week.days = new Array<Day>();
            }
            var isWeekDayDisable = false;
            var isHighlightWeekDay = false
            let dateDisabled: DateDisabled = new DateDisabled(undefined, "");
            var dayObject = new Day(
                this.utcDate(prevMonth.getFullYear(), prevMonth.getMonth(), prevMonth.getDate()),
                false,
                prevMonth.getMonth() !== month,
                prevMonth.getFullYear(),
                prevMonth.getMonth(),
                prevMonth.getDay(),
                prevMonth.getDate(),
                isWeekDayDisable,
                isHighlightWeekDay,
                dateDisabled);
            var findObject = this.source.filter(t => t.roasterDate.getDate() == dayObject.monthDate && t.roasterDate.getMonth() == dayObject.month && t.roasterDate.getFullYear() == dayObject.year)[0]
            if (findObject) {
                dayObject.data = findObject;
            } else {
                dayObject.data = {
                    info: [{ id: 0, data: "" }],
                    isHighlight: false
                };
            }
            var todayDate = new Date();
            dayObject.isCurrentDate = (dayObject.monthDate == todayDate.getDate() && dayObject.month == todayDate.getMonth() && dayObject.year == todayDate.getFullYear())
            week.days.push(dayObject);
            prevMonth.setDate(prevMonth.getDate() + 1);
            if (dayCount == 6) {
                dayCount = 0;
                this.dateCollection.weeks.push(week);
            } else {
                dayCount++
            }
        }
    }

    checkBoxChange(id: number): void {
        this.idSelected.emit(id)
    }


    nextMonth(date: Date) {
        this.value = date;
        this.monthChanged.emit(this.value)
        //this.make(date);
    }

    previousMonth(date: Date) {
        this.value = date;
        this.monthChanged.emit(this.value)
        //this.make(date);
    }

    show(date: Date) {
        this.make(date);
    }

    select(day: Day) {
        if (!day.isDisabled) {
            if (day.selected) {
                day.selected = false;
                this.dateUnSelected.emit(day.date)
            }
            else {
                this.deSelect();
                day.selected = true;
                this.dateSelected.emit(day.date)
            }
        }
    }

    print(): void {
        let printContents, popupWin;
        let rosterName = this.rosterName;
        printContents = document.getElementById('print-section').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
            <link rel="stylesheet" href="assets/css/bootstrap/bootstrap.min.css" media="screen,print">
            <link rel="stylesheet" href="assets/css/font-awesome.min.css" media="screen,print">
            <link rel="stylesheet" href="assets/css/style.css">
              <title>${rosterName} Roster</title>
              <style>
                    body{
                        color:black;
                    }
                    .early-aht {
                        background-color: #f9deae !important;
                        color: #333;
                    }
                    .current-date {
                        background-color: #a3e1c6 !important;
                        color: #333;
                    }
              </style>
            </head>
        <body onload="window.print();window.close()">${printContents}</body>
          </html>`
        );
        popupWin.document.close();
    }

    private deSelect() {
        for (let week of this.dateCollection.weeks) {
            //var selectedDay = week.days.filter(t => t.selected);
            //if (selectedDay.length > 0)
            //    selectedDay[0].selected = false;
        }
    }

    isLeapYear(year: number): boolean {
        return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
    }

    daysInMonth(year, month): number {
        return [31, (this.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    }

    utcDate(year: number, month: number, days: number): Date {
        //return new Date(Date.UTC.apply(Date, [year, month, days]));
        return new Date(year, month, days);
    }

    makeCurrentMonthDateArray(val) {
        let dates: Date[] = [];
        for (let week of this.dateCollection.weeks) {
            for (let day of week.days) {
                if (!day.isPreviousMonthDay) {
                    if (val) {
                        day.selected = true;
                        dates.push(day.date);
                    }
                    else {
                        day.selected = false;
                    }
                }
            }
        }
        this.selectAllDate.emit(dates)
    }

    ngOnDestroy() {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}

