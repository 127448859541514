export const RELATIONSHIPS = [
	{
        "relationshipId": 2139,
        "relationshipName": "Mother"
    },
	{
        "relationshipId": 2140,
        "relationshipName": "Father"
    },
	{
        "relationshipId": 2141,
        "relationshipName": "Guardian"
    },
	{
        "relationshipId": 2142,
        "relationshipName": "Grandmother"
    },
	{
        "relationshipId": 2143,
        "relationshipName": "Grandfather"
    },
	{
        "relationshipId": 2144,
        "relationshipName": "Self"
    },
	{
        "relationshipId": 2145,
        "relationshipName": "Other"
    },
]