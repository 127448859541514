import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('TechnicalTerms')
export class TechnicalTerm {
    constructor(technicalTerm?: TechnicalTerm )  {
        let properties = [ "definition", "pronunciation", "technicalTermId", "technicalTermName",];
        for (let property of properties)
            if (technicalTerm && (technicalTerm[property] || technicalTerm[property] == 0))
                this[property] = technicalTerm[property];
    }
 
    @maxLength(10)
	@property('Definition')
	definition : string = undefined ;
 
    @maxLength(150)
	@property('Pronunciation')
	pronunciation : string = undefined ;
 
	@property('TechnicalTermId')
	technicalTermId : number =   0 ;
 
    @required()
    @maxLength(100)
	@property('TechnicalTermName')
	technicalTermName : string = undefined ;



}
