import { ReflectiveInjector, Inject, Injectable } from "@angular/core";
import { Response } from "@angular/http";

import { ResponseResult } from "@rx/http";

import { RxSpinner, RxToast } from "@rx/view";
import { ApplicationConfiguration, ApplicationBroadcaster } from "@rx/core";
import '@rx/linq';
import { RxStorage } from "@rx/storage"
import { requestCollection, Uri } from './request-uri';



@Injectable()
export class ApplicationResponse implements ResponseResult {
  dataOperationMessage: {};
  storage: RxStorage;
  timeOut: any;
  responseTimeOut: any;
  constructor(@Inject(RxToast) private rxToast: RxToast, @Inject(RxSpinner) private spinner: RxSpinner, @Inject(ApplicationBroadcaster) private applicationBroadcaster: ApplicationBroadcaster) {
    this.applicationBroadcaster.configurationSubscriber.subscribe(t => {
      this.dataOperationMessage = ApplicationConfiguration.get('dataOperation');
    })
    let injector: any = ReflectiveInjector.resolveAndCreate([RxStorage]);
    this.storage = injector.get(RxStorage);
  }

  check(response: Response, requestMethod: string, showToast: boolean): boolean {
      this.storage.local.save("lastAccessTime", response.headers.get("x-session"));
    requestCollection.splice(0, 1);
    if (requestCollection.length == 0) {
      this.spinner.hide();
    }
    if (this.timeOut)
      window.clearTimeout(this.timeOut);
    if (requestCollection.length > 0) {
      this.timeOut = setTimeout(() => {
        this.spinner.hide();
        var length = requestCollection.length;
        for (var i = 0; i < length; i++) {
          requestCollection.splice(0, 1);
        }
        window.clearTimeout(this.timeOut);
      }, 5000)
    }
    if (showToast == undefined && this.dataOperationMessage && this.dataOperationMessage[requestMethod.toLowerCase()] && (response.status == 200 || response.status == 204 || response.status == 201))
      this.rxToast.show(this.dataOperationMessage[requestMethod.toLowerCase()]);
    else if (response.status == 406 || response.status == 417) {
      this.storage.session.clearAll();
      this.storage.local.clearAll();
      window.location.href = '/';
    }
    return true;
  }

  error(message: string) {
    requestCollection.splice(0, 1);
    if (requestCollection.length == 0)
      this.spinner.hide();
    this.rxToast.show("Error Occured.", { status: 'error' });
  }
}
