import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { user } from '@rx/security';

@Injectable()
export class ApplicationIssueDomain {
    constructor() { }
    setDefaultValue(applicationIssueFormGroup: FormGroup) {
        if (applicationIssueFormGroup.value["applicationIssueId"] == 0 ||applicationIssueFormGroup.value["applicationIssueId"] == null)  {
            applicationIssueFormGroup.controls['createdById'].setValue(user.data["userId"]);
            applicationIssueFormGroup.controls['createdDateTime'].setValue(new Date());
            applicationIssueFormGroup.controls['applicationName'].setValue("Remedy");
            applicationIssueFormGroup.controls['applicationIssueId'].setValue(0);
            
          }
        
        else{
            applicationIssueFormGroup.controls['modifiedById'].setValue(user.data["userId"]);
            applicationIssueFormGroup.controls['modifiedDateTime'].setValue(new Date());
            applicationIssueFormGroup.controls['applicationName'].setValue("Remedy");
        }       
    }

    destroy(): void {
        
    }
}
