export const TimeSource  = [
    { value: "00:00 AM",active:false }, { value: "00:15 AM",active:false }, { value: "00:30 AM",active:false }, { value: "00:45 AM",active:false },
    { value: "01:00 AM" ,active:false}, { value: "01:15 AM",active:false }, { value: "01:30 AM" ,active:false}, { value: "01:45 AM",active:false },
    { value: "02:00 AM",active:false }, { value: "02:15 AM" ,active:false}, { value: "02:30 AM" ,active:false}, { value: "02:45 AM",active:false },
    { value: "03:00 AM",active:false }, { value: "03:15 AM" ,active:false}, { value: "03:30 AM" ,active:false}, { value: "03:45 AM",active:false },
    { value: "04:00 AM",active:false }, { value: "04:15 AM",active:false }, { value: "04:30 AM",active:false }, { value: "04:45 AM",active:false },
    { value: "05:00 AM",active:false }, { value: "05:15 AM",active:false }, { value: "05:30 AM",active:false }, { value: "05:45 AM",active:false },
    { value: "06:00 AM",active:false }, { value: "06:15 AM" ,active:false}, { value: "06:30 AM",active:false }, { value: "06:45 AM",active:false },
    { value: "07:00 AM" ,active:false}, { value: "07:15 AM" ,active:false}, { value: "07:30 AM" ,active:false}, { value: "07:45 AM",active:false },
    { value: "08:00 AM" ,active:false}, { value: "08:15 AM",active:false }, { value: "08:30 AM" ,active:false}, { value: "08:45 AM",active:false },
    { value: "09:00 AM" ,active:false}, { value: "09:15 AM",active:false }, { value: "09:30 AM",active:false }, { value: "09:45 AM" ,active:false},
    { value: "10:00 AM",active:false }, { value: "10:15 AM",active:false }, { value: "10:30 AM",active:false }, { value: "10:45 AM",active:false },
    { value: "11:00 AM",active:false }, { value: "11:15 AM" ,active:false}, { value: "11:30 AM",active:false }, { value: "11:45 AM",active:false },
    { value: "12:00 PM",active:false}, { value: "12:15 PM" ,active:false}, { value: "12:30 PM",active:false }, { value: "12:45 PM",active:false },
    { value: "01:00 PM" ,active:false}, { value: "01:15 PM",active:false }, { value: "01:30 PM" ,active:false}, { value: "01:45 PM",active:false },
    { value: "02:00 PM" ,active:false}, { value: "02:15 PM",active:false }, { value: "02:30 PM" ,active:false}, { value: "02:45 PM" ,active:false},
    { value: "03:00 PM",active:false }, { value: "03:15 PM",active:false }, { value: "03:30 PM",active:false }, { value: "03:45 PM",active:false },
    { value: "04:00 PM" ,active:false}, { value: "04:15 PM" ,active:false}, { value: "04:30 PM" ,active:false}, { value: "04:45 PM",active:false },
    { value: "05:00 PM" ,active:false}, { value: "05:15 PM",active:false }, { value: "05:30 PM",active:false }, { value: "05:45 PM",active:false },
    { value: "06:00 PM",active:false }, { value: "06:15 PM",active:false }, { value: "06:30 PM",active:false }, { value: "06:45 PM",active:false },
    { value: "07:00 PM",active:false }, { value: "07:15 PM",active:false }, { value: "07:30 PM",active:false }, { value: "07:45 PM",active:false },
    { value: "08:00 PM",active:false }, { value: "08:15 PM" ,active:false}, { value: "08:30 PM",active:false }, { value: "08:45 PM",active:false },
    { value: "09:00 PM",active:false }, { value: "09:15 PM" ,active:false}, { value: "09:30 PM" ,active:false}, { value: "09:45 PM",active:false },
    { value: "10:00 PM",active:false }, { value: "10:15 PM",active:false }, { value: "10:30 PM",active:false }, { value: "10:45 PM",active:false },
    { value: "11:00 PM" ,active:false}, { value: "11:15 PM" ,active:false}, { value: "11:30 PM" ,active:false}, { value: "11:45 PM",active:false },{ value: "11:59 PM" ,active:false},]