import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('AhtStartTimes')
export class AhtStartTime {
    constructor(ahtStartTime?: AhtStartTime )  {
        let properties = [ "aHTHourId", "ahtProviderId", "createdDateTime", "createdeById", "dayOfWeekId", "modifiedById", "modifiedDateTime", "openTimeFrom", "openTimeTo",];
        for (let property of properties)
            if (ahtStartTime && (ahtStartTime[property] || ahtStartTime[property] == 0))
                this[property] = ahtStartTime[property];
    }
 
    @range(1,2147483647)
	@property('AHTHourId')
	aHTHourId : number =   undefined;
 
    @range(1,2147483647)
	@property('AhtProviderId')
	ahtProviderId : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @range(1,2147483647)
	@property('CreatedeById')
	createdeById : number =   undefined;
 
    @range(1,2147483647)
	@property('DayOfWeekId')
	dayOfWeekId : number =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @required()
	@property('OpenTimeFrom')
	openTimeFrom : Date =   undefined;
 
    @required()
	@property('OpenTimeTo')
	openTimeTo : Date =   undefined;



}
