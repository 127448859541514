import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('ApplicationIssues')
export class ApplicationIssue {
    constructor(applicationIssue?: ApplicationIssue )  {
        let properties = [ "applicationIssueId", "applicationName", "createdById", "createdDateTime", "description", "developerNote", "modifiedById", "modifiedDateTime", "pageName", "priorityId", "statusId", "title", "typeId",];
        for (let property of properties)
            if (applicationIssue && (applicationIssue[property] || applicationIssue[property] == 0))
                this[property] = applicationIssue[property];
    }
 
	@property('ApplicationIssueId')
	applicationIssueId : number =   0 ;
 
    @required()
    @maxLength(100)
	@property('ApplicationName')
	applicationName : string = undefined ;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @maxLength(500)
	@property('Description')
	description : string = undefined ;
 
    @maxLength(500)
	@property('DeveloperNote')
	developerNote : string = undefined ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @maxLength(50)
	@property('PageName')
	pageName : string = undefined ;
 
@required()
	@property('PriorityId')
	priorityId : number =   undefined;
 
@required()
	@property('StatusId')
	statusId : number =   undefined;
 
    @maxLength(50)
@required()
	@property('Title')
	title : string = undefined ;
 
@required()
	@property('TypeId')
	typeId : number =   undefined;



}
