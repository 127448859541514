import { Component, OnInit, OnDestroy ,Input,ComponentFactoryResolver} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { RxMessageComponent } from '@rx/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { ComponentCanDeactivate } from "@rx/core";
import {RxToast, RxDialog, DialogClick } from '@rx/view';
import { RxValidation } from '@rx/forms';
import {  ApplicationIssue, } from 'src/app/database-models';

import { } from 'src/app/lookups';
import { ApplicationIssuesService } from '../application-issues.service';
import { ApplicationIssueDomain } from '../domain/application-issue.domain';
import { ApplicationIssueLookupGroup } from '../domain/application-issue.models';
import { user } from '@rx/security';
import { ISSUE_TYPES, ISSUE_PRIORITIES, ISSUE_STATUS } from '../../../../database-collections';



@Component({
    templateUrl: './application-issue-add.component.html',
    entryComponents : [RxMessageComponent]
})
export class ApplicationIssueAddComponent extends ApplicationIssueDomain implements OnInit, OnDestroy, ComponentCanDeactivate  {
    issuePriorities: any;
    issueStatus: any;
    practiceName: any;
    issueTypes :any;
    showGrid: boolean = true;
    showComponent:boolean = false;
    applicationIssueFormGroup: FormGroup;
    addSubscription: Subscription;
    applicationIssueLookupGroup: ApplicationIssueLookupGroup;;
    constructor(
        private validation: RxValidation,
        private router: Router,
        private toast: RxToast,
        private applicationIssuesService: ApplicationIssuesService,    
    ) {
        super();
        this.practiceName = user.data['practiceName']
    }

    ngOnInit(): void {
                this.issueTypes = ISSUE_TYPES;
                this.issuePriorities = ISSUE_PRIORITIES;
                this.issueStatus= ISSUE_STATUS;
				this.applicationIssueLookupGroup = new ApplicationIssueLookupGroup();

                this.applicationIssueLookupGroup.applicationIssue = new ApplicationIssue();
                this.applicationIssueFormGroup = this.validation.getFormGroup(this.applicationIssueLookupGroup.applicationIssue);
                this.setDefaultValue(this.applicationIssueFormGroup);
                this.showComponent = true;
    }


    addApplicationIssue(): void {
         
        this.showGrid = false;
        this.addSubscription =  this.applicationIssuesService.post(this.applicationIssueFormGroup.value).subscribe(t => {
            this.showGrid = true;
            this.applicationIssueFormGroup.reset();
            this.setDefaultValue(this.applicationIssueFormGroup);
        },
            error => {
                this.showGrid = true;
        })
    }

   
	canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        return !this.applicationIssueFormGroup.dirty;
    }

    ngOnDestroy(): void {
        if (this.addSubscription)
            this.addSubscription.unsubscribe();
        super.destroy();
    }

}
