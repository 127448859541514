import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vPageMyDoctor')
export class vPageMyDoctor {
    constructor(vPageMyDoctor?: vPageMyDoctor )  {
        let properties = [ "addressId", "createdById", "createdDateTime", "dayOfWeekId", "dayWeekName", "fromTime", "lunchFrom", "lunchTo", "modifiedById", "modifiedDateTime", "officeClosed", "officeOpen", "officePhone", "officePhoneExt", "pageMyDoctorId", "practiceId", "respondTimeRoutine", "serviceTypeId", "serviceTypeName", "toTime",];
        for (let property of properties)
            if (vPageMyDoctor && (vPageMyDoctor[property] || vPageMyDoctor[property] == 0))
                this[property] = vPageMyDoctor[property];
    }
 
	@property('AddressId')
	addressId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @range(1,2147483647)
	@property('DayOfWeekId')
	dayOfWeekId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('dayWeekName')
	dayWeekName : string = undefined ;
 
    @maxLength(30)
	@property('FromTime')
	fromTime : string = undefined ;
 
    @maxLength(30)
	@property('LunchFrom')
	lunchFrom : string = undefined ;
 
    @maxLength(30)
	@property('LunchTo')
	lunchTo : string = undefined ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @maxLength(30)
	@property('OfficeClosed')
	officeClosed : string = undefined ;
 
    @maxLength(30)
	@property('OfficeOpen')
	officeOpen : string = undefined ;
 
    @maxLength(50)
	@property('OfficePhone')
	officePhone : string = undefined ;
 
    @maxLength(10)
	@property('OfficePhoneExt')
	officePhoneExt : string = undefined ;
 
    @range(1,2147483647)
	@property('PageMyDoctorId')
	pageMyDoctorId : number =   undefined;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
	@property('RespondTimeRoutine')
	respondTimeRoutine : number =   undefined;
 
    @range(1,2147483647)
	@property('ServiceTypeId')
	serviceTypeId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('serviceTypeName')
	serviceTypeName : string = undefined ;
 
    @maxLength(30)
	@property('ToTime')
	toTime : string = undefined ;



}
