import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";
import {RxViewModule } from '@rx/view';
import {RxFormsModule } from '@rx/forms';
import {RxTableModule } from "@rx/table";
import {DynamicComponentContainer } from '@rx/core';

import { ProviderViewComponent } from './view/provider-view.component'
import {ProvidersService } from "./providers.service";
import {PROVIDERS_SHARED_COMPONENT_CONTAINER } from './providers-shared-component.container';
@NgModule({
    imports: [
        RxViewModule, RxFormsModule,
        CommonModule, FormsModule, ReactiveFormsModule, RxTableModule
    ],
    declarations: [ ProviderViewComponent, ],
    providers: [ProvidersService ],
    exports: [RouterModule, ProviderViewComponent, ]
})
export class ProvidersSharedComponentModule { }
DynamicComponentContainer.register(PROVIDERS_SHARED_COMPONENT_CONTAINER );