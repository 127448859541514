import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { RxMessageComponent } from '@rx/forms';
import { ActivatedRoute, Router } from '@angular/router';

import "tinymce";
import "tinymce/themes/modern";
import "tinymce/plugins/table";
import "tinymce/plugins/link";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/image";
import "tinymce/plugins/code";
import "tinymce/plugins/paste";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/charmap";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/preview";
import "tinymce/plugins/lists";


declare var tinymce: any;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
})
export class EditorComponent implements OnInit, OnDestroy {
  @Input() elementId: String;
  @Input() height: string;
  @Input() value: any;
  @Input() defaultSettings: any;

  @Output() onEditorContentChange = new EventEmitter();

  editor;

  ngOnInit(): void {
    // console.log(this.value);
  }

  ngAfterViewInit() {
    tinymce.init({      
      selector: '#' + this.elementId,
      height: this.height,
      plugins: ['textcolor link image code searchreplace paste visualchars charmap table fullscreen preview lists'],
      toolbar1: 'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | formatselect | fontselect | fontsizeselect |        paste pastetext searchreplace | outdent indent | undo redo | link unlink anchor image | bullist numlist |      css | forecolor backcolor | table | hr removeformat visualchars | subscript superscript | charmap preview ',
      menubar: false,
      skin_url: '/assets/tinymce/skins/lightgray',
      setup: editor => {
        
        //editor.activeEditor.dom.setAttrib(tinymce.activeEditor.dom.select('table'), 'border', null);
        this.editor = editor;        
        editor.on('change', () => {
          const content = editor.getContent();
          this.onEditorContentChange.emit(content);
        });
        
        editor.on('init', (ed) => {
          if(editor.getContent() !=null && editor.getContent() !=""  && editor.getContent() !=undefined  ){
            
          }
          else{
            if (this.defaultSettings && this.defaultSettings.length > 0) {
              this.defaultSettings.forEach(element => {
                ed.target.editorCommands.execCommand(element.key, false, element.value);
              });
            }
          }
         
        });
      }
    });
  }

  ngOnDestroy(): void {
    tinymce.remove(this.editor);
  }
}
