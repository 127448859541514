import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Messaging } from './messaging';import { NotificationPrefAlternet } from './notification-pref-alternet';import { NotificationPreference } from './notification-preference'; @table('CallTypes')
export class CallType {
    constructor(callType?: CallType )  {
        let properties = [ "callCell", "callHome", "callOffice", "callTypeId", "description", "email", "faxHome", "faxOffice", "faxTriage", "holdForAM", "isCallType", "page", "shortDesc", "sortOrder", "messaging", "notificationPrefAlternet", "notificationPreferences",];
        for (let property of properties)
            if (callType && (callType[property] || callType[property] == 0))
                this[property] = callType[property];
    }
 
	@property('CallCell')
	callCell : boolean = false ;
 
	@property('CallHome')
	callHome : boolean = false ;
 
	@property('CallOffice')
	callOffice : boolean = false ;
 
	@property('CallTypeId')
	callTypeId : number =   0 ;
 
    @required()
    @maxLength(100)
	@property('Description')
	description : string = undefined ;
 
	@property('Email')
	email : boolean = false ;
 
	@property('FaxHome')
	faxHome : boolean = false ;
 
	@property('FaxOffice')
	faxOffice : boolean = false ;
 
	@property('FaxTriage')
	faxTriage : boolean = false ;
 
	@property('HoldForAM')
	holdForAM : boolean = false ;
 
	@property('IsCallType')
	isCallType : boolean = false ;
 
	@property('Page')
	page : boolean = false ;
 
    @required()
    @maxLength(20)
	@property('ShortDesc')
	shortDesc : string = undefined ;
 
	@property('SortOrder')
	sortOrder : number =   undefined;
	@nested(Messaging)
	messaging: Messaging[];

	@nested(NotificationPrefAlternet)
	notificationPrefAlternet: NotificationPrefAlternet[];

	@nested(NotificationPreference)
	notificationPreferences: NotificationPreference[];




}
