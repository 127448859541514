import { Injectable } from '@angular/core';

@Injectable()
export class ScheduleTelevisitDomain {
    constructor() { }
    
    timeConversionTo24Hour(time: any): any {
        if (time != null && time != undefined && time != "") {
            var splitText = time.split(" ");
            var splitTime = splitText[0].split(":");
            var finalTime = "";
            if (splitText[1] == "PM" || splitText[1] == "pm") {
                switch (splitTime[0]) {
                    case "00":
                        finalTime = "".concat("12", ":", splitTime[1])
                        break;
                    case "01":
                        finalTime = "".concat("13", ":", splitTime[1])
                        break;
                    case "02":
                        finalTime = "".concat("14", ":", splitTime[1])
                        break;
                    case "03":
                        finalTime = "".concat("15", ":", splitTime[1])
                        break;
                    case "04":
                        finalTime = "".concat("16", ":", splitTime[1])
                        break;
                    case "05":
                        finalTime = "".concat("17", ":", splitTime[1])
                        break;
                    case "06":
                        finalTime = "".concat("18", ":", splitTime[1])
                        break;
                    case "07":
                        finalTime = "".concat("19", ":", splitTime[1])
                        break;
                    case "08":
                        finalTime = "".concat("20", ":", splitTime[1])
                        break;
                    case "09":
                        finalTime = "".concat("21", ":", splitTime[1])
                        break;
                    case "10":
                        finalTime = "".concat("22", ":", splitTime[1])
                        break;
                    case "11":
                        finalTime = "".concat("23", ":", splitTime[1])
                        break;
                    case "12":
                        finalTime = "".concat("12", ":", splitTime[1])
                        break;
                }
            }
            else if (splitText[1] == "AM" || splitText[1] == "am") {
                switch (splitTime[0]) {
                    case "00":
                        finalTime = "".concat("00", ":", splitTime[1])
                        break;
                    case "01":
                        finalTime = "".concat("01", ":", splitTime[1])
                        break;
                    case "02":
                        finalTime = "".concat("02", ":", splitTime[1])
                        break;
                    case "03":
                        finalTime = "".concat("03", ":", splitTime[1])
                        break;
                    case "04":
                        finalTime = "".concat("04", ":", splitTime[1])
                        break;
                    case "05":
                        finalTime = "".concat("05", ":", splitTime[1])
                        break;
                    case "06":
                        finalTime = "".concat("06", ":", splitTime[1])
                        break;
                    case "07":
                        finalTime = "".concat("07", ":", splitTime[1])
                        break;
                    case "08":
                        finalTime = "".concat("08", ":", splitTime[1])
                        break;
                    case "09":
                        finalTime = "".concat("09", ":", splitTime[1])
                        break;
                    case "10":
                        finalTime = "".concat("10", ":", splitTime[1])
                        break;
                    case "11":
                        finalTime = "".concat("11", ":", splitTime[1])
                        break;
                    case "12":
                        finalTime = "".concat("00", ":", splitTime[1])
                        break;
                }
            }
            return finalTime == "" ? time : finalTime;
        }
        return null;
    }
    destroy(): void {

    }
}
