export const ROSTERS = [
	{
        "rostersId": 49,
        "rostersName": "On Call Roster"
    },
	{
        "rostersId": 50,
        "rostersName": "Hospital Rounding Roster"
    },
	{
        "rostersId": 51,
        "rostersName": "Vacation Roster"
    },
	{
        "rostersId": 5170,
        "rostersName": "Extended Newborn Roster"
    },
]