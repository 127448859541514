export enum CallTypesEnum {
    PatientToDoctor=1,
    DoctorToDoctor=2,
    Newborns=3,
    RxRefills=4,
    AnsweringSrtoDr=5,
    TriageToDr=6,
    FaxToOffice=7,
    AfterHoursTriage=8,
    OnCallOverride=9,
    RoundingDoctor=10,
    HospitalAdmission=11,
    PageMyDoctor=12,
    Appointment=13
}
