import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";

import {RxFormsModule} from "@rx/forms";
import {RxViewModule} from "@rx/view";
import {    RxTableModule} from "@rx/table";

import { ApplicationIssueAddComponent } from './application-issue-add.component'
import { APPLICATION_ISSUE_ADD_ROUTING } from './application-issue-add.routing'
import {ApplicationIssuesService } from "../application-issues.service";
import { ApplicationIssuesSharedComponentModule } from '../application-issues-shared-component.module';

@NgModule({
    imports: [
        APPLICATION_ISSUE_ADD_ROUTING ,
        CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,ApplicationIssuesSharedComponentModule,
		    ],
    declarations: [ApplicationIssueAddComponent ],
    exports: [RouterModule,ApplicationIssuesSharedComponentModule],
    providers: [ApplicationIssuesService]
})
export class ApplicationIssueAddModule { }