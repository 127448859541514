import { ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import { PageAccess } from "src/app/domain/authorization";

const USERS_ROUTES: Routes = [
	{
    path: '', 
	loadChildren: () => import('./list/user-list.module').then(m => m.UserListModule),
	canActivate: [PageAccess],
    data: { rootModuleId: 1033,pageName:'users', applicationModuleId: 16, accessItem: 'list',  keyName: 'userId', compositeKeys:[] }
	},
	{
    path: 'add', 
	loadChildren: () => import('./add/user-add.module').then(m => m.UserAddModule),
	canActivate: [PageAccess],
      data: { rootModuleId: 1033,pageName:'users', applicationModuleId: 16, accessItem: 'add',  keyName: 'userId', compositeKeys:[] }
	},
	{
    path: ':userId', 
	loadChildren: () => import('./edit/user-edit.module').then(m => m.UserEditModule),
	canActivate: [PageAccess],
      data: { rootModuleId: 1033,pageName:'users', applicationModuleId: 16, accessItem: 'edit',  keyName: 'userId', compositeKeys:[] }
	},
	{
		path: 'profile/:userId', 
		loadChildren: () => import('./profile/user-profile.module').then(m => m.UserProfileModule),
		canActivate: [PageAccess],
      data: { rootModuleId: 1033,pageName:'users', applicationModuleId: 16, accessItem: 'edit',  keyName: 'userId', compositeKeys:[] }
		},
];

export const USERS_ROUTING = RouterModule.forChild(USERS_ROUTES);
