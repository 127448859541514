import { NgModule } from '@angular/core';
import {RouterModule } from '@angular/router';


import { CALL_DB_ROUTING } from './call-db.routing';
import { CallDbService } from './call-db.service';

@NgModule({
    imports: [CALL_DB_ROUTING],
    exports: [RouterModule],
    providers: [CallDbService]
})
export class CallDbModule { }