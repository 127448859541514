import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vAhtStartTimes')
export class vAhtStartTime {
    constructor(vAhtStartTime?: vAhtStartTime )  {
        let properties = [ "aHTHourId", "aHTProviderId", "createdDateTime", "createdeById", "dayOfWeekId", "dayOfWeekName", "modifiedById", "modifiedDateTime", "openTimeFrom", "openTimeTo",];
        for (let property of properties)
            if (vAhtStartTime && (vAhtStartTime[property] || vAhtStartTime[property] == 0))
                this[property] = vAhtStartTime[property];
    }
 
    @range(1,2147483647)
	@property('AHTHourId')
	aHTHourId : number =   undefined;
 
    @range(1,2147483647)
	@property('AHTProviderId')
	aHTProviderId : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @range(1,2147483647)
	@property('CreatedeById')
	createdeById : number =   undefined;
 
    @range(1,2147483647)
	@property('DayOfWeekId')
	dayOfWeekId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('DayOfWeekName')
	dayOfWeekName : string = undefined ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @required()
	@property('OpenTimeFrom')
	openTimeFrom : Date =   undefined;
 
    @required()
	@property('OpenTimeTo')
	openTimeTo : Date =   undefined;



}
