import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vHospitalRoundingRosters')
export class vHospitalRoundingRoster {
    constructor(vHospitalRoundingRoster?: vHospitalRoundingRoster )  {
        let properties = [ "hospitalId", "hospitalName", "hospitalRoundingRosterId", "practiceId", "providerFullName", "providerId", "roundingDate",];
        for (let property of properties)
            if (vHospitalRoundingRoster && (vHospitalRoundingRoster[property] || vHospitalRoundingRoster[property] == 0))
                this[property] = vHospitalRoundingRoster[property];
    }
 
    @range(1,2147483647)
	@property('HospitalId')
	hospitalId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('HospitalName')
	hospitalName : string = undefined ;
 
    @range(1,2147483647)
	@property('HospitalRoundingRosterId')
	hospitalRoundingRosterId : number =   undefined;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @required()
    @maxLength(101)
	@property('ProviderFullName')
	providerFullName : string = undefined ;
 
    @range(1,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
 
    @required()
	@property('RoundingDate')
	roundingDate : Date =   undefined;



}
