import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router'

import { Subscription} from 'rxjs/Rx';

import { ApplicationConfiguration, ApplicationBroadcaster } from "@rx/core";
import {RxStorage } from "@rx/storage"
import { ValidationFailedComponent, UnAuthorizedAccessComponent } from "@rx/view";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    entryComponents: [ValidationFailedComponent, UnAuthorizedAccessComponent]
})
export class AppComponent implements OnInit {
    
    isShowDashboard: boolean = false;
    showElement: boolean = false;
    timeOutId: number;
    showComponent: boolean = false;
    isSideBarActive: boolean = true;
    configurationSubscription: Subscription;
    loginSubscription: Subscription;
    constructor(
        private router: Router,
        private storage: RxStorage,
        applicationBroadCaster: ApplicationBroadcaster
    ) {
        applicationBroadCaster.loginSubscriber.subscribe(t =>
        {
                this.loginClicked(t)
        });

        applicationBroadCaster.allTypeSubscriber.subscribe(t => {
          if (t && t.action == "sidebar") {
            this.showSideBar(t.value);
          }
          else if (t && t.action == "toggleSidebar") {
            this.showSideBar(!this.isSideBarActive);
          }
        })
        this.configurationSubscription = applicationBroadCaster.configurationSubscriber.subscribe(t => {
            this.configurationSubscription.unsubscribe();
        });
        
    }

    ngOnInit(): void {
         
        var auth = this.storage.local.get("auth");
        if (auth) {
            this.isShowDashboard = true;
            this.showElement = true;
        }
    }

    showSideBar(value: boolean) {
        this.isSideBarActive = value;
    }

    loginClicked(isClicked: boolean): void {
        window.setTimeout(() => {
          if (isClicked) {
                this.isShowDashboard = isClicked;
                this.showElement = isClicked;
                this.router.navigate(['dashboard']);
            }
        }, 50)
    }
}
