import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Practice } from './practice';import { Provider } from './provider'; @table('VacationRosters')
export class VacationRoster {
    constructor(vacationRoster?: VacationRoster )  {
        let properties = [ "createdById", "createdDateTime", "modifiedById", "modifiedDateTime", "offDate", "scheduleComment", "vacationRosterId", "practiceId", "providerId",];
        for (let property of properties)
            if (vacationRoster && (vacationRoster[property] || vacationRoster[property] == 0))
                this[property] = vacationRoster[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @required()
	@property('OffDate')
	offDate : Date =   undefined;
 
    @maxLength(500)
	@property('ScheduleComment')
	scheduleComment : string = undefined ;
 
	@property('VacationRosterId')
	vacationRosterId : number =   0 ;
 
    @range(0,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
	practice : Practice  ;
 
    @range(0,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
	provider : Provider  ;



}
