import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserProfilePhotoComponent } from './app-user-profile-photo.component';

@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, 
    ],
    declarations: [UserProfilePhotoComponent ],
    providers: [],
    exports: [RouterModule, UserProfilePhotoComponent]
})
export class UserProfilePhotoSharedComponentModule { }
