import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { User } from './user'; @table('UserDevices')
export class UserDevice {
    constructor(userDevice?: UserDevice )  {
        let properties = [ "deviceAddress", "deviceIdentity", "deviceToken", "deviceType", "userDeviceId", "userId",];
        for (let property of properties)
            if (userDevice && (userDevice[property] || userDevice[property] == 0))
                this[property] = userDevice[property];
    }
 
    @required()
    @maxLength(200)
	@property('DeviceAddress')
	deviceAddress : string = undefined ;
 
    @maxLength(200)
	@property('DeviceIdentity')
	deviceIdentity : string = undefined ;
 
    @required()
    @maxLength(200)
	@property('DeviceToken')
	deviceToken : string = undefined ;
 
    @range(1,2147483647)
	@property('DeviceType')
	deviceType : number =   undefined;
 
	@property('UserDeviceId')
	userDeviceId : number =   0 ;
 
    @range(0,2147483647)
	@property('UserId')
	userId : number =   undefined;
	user : User  ;



}
