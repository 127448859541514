import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('PrimaryConcerns')
export class PrimaryConcern {
    constructor(primaryConcern?: PrimaryConcern )  {
        let properties = [ "code", "primamryConcern", "primamryConcernId",];
        for (let property of properties)
            if (primaryConcern && (primaryConcern[property] || primaryConcern[property] == 0))
                this[property] = primaryConcern[property];
    }
 
    @required()
    @maxLength(10)
	@property('Code')
	code : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('PrimamryConcern')
	primamryConcern : string = undefined ;
 
	@property('PrimamryConcernId')
	primamryConcernId : number =   0 ;



}
