export const DATABASE_OPERATIONS = [
	{
        "databaseOperationId": 1,
        "databaseOperationName": "Add"
    },
	{
        "databaseOperationId": 2,
        "databaseOperationName": "Edit"
    },
	{
        "databaseOperationId": 3,
        "databaseOperationName": "Delete"
    },
	{
        "databaseOperationId": 4,
        "databaseOperationName": "List"
    },
	{
        "databaseOperationId": 15,
        "databaseOperationName": "Full"
    },
]