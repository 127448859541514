import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vAlerts')
export class vAlert {
    constructor(vAlert?: vAlert )  {
        let properties = [ "alertFor", "alertId", "alertTypeId", "applyToId", "applyToName", "createdById", "createdDateTime", "dayOfWeekId", "days", "fromDate", "fromTime", "fromTimeForDispaly", "message", "modifiedById", "modifiedDateTime", "ordinal", "practiceId", "toDate", "toTime", "toTimeForDisplay",];
        for (let property of properties)
            if (vAlert && (vAlert[property] || vAlert[property] == 0))
                this[property] = vAlert[property];
    }
 
	@property('AlertFor')
	alertFor : number =   undefined;
 
    @range(1,2147483647)
	@property('AlertId')
	alertId : number =   undefined;
 
    @range(1,2147483647)
	@property('AlertTypeId')
	alertTypeId : number =   undefined;
 
	@property('ApplyToId')
	applyToId : number =   undefined;
 
    @maxLength(100)
	@property('ApplyToName')
	applyToName : string = undefined ;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('DayOfWeekId')
	dayOfWeekId : number =   undefined;
 
    @maxLength(100)
	@property('Days')
	days : string = undefined ;
 
	@property('FromDate')
	fromDate : Date =   undefined;
 
	@property('FromTime')
	fromTime : Date =   undefined;
 
    @maxLength(4000)
	@property('FromTimeForDispaly')
	fromTimeForDispaly : string = undefined ;
 
	@property('Message')
	message : string = undefined ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('Ordinal')
	ordinal : number =   undefined;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
	@property('ToDate')
	toDate : Date =   undefined;
 
	@property('ToTime')
	toTime : Date =   undefined;
 
    @maxLength(4000)
	@property('ToTimeForDisplay')
	toTimeForDisplay : string = undefined ;



}
