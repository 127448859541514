import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { vCallDb, CallDb } from 'src/app/database-models';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { user } from '@rx/security';
import { CallDbViewModel } from '../domain/call-db.models';
import { OperationTypesEnum } from '../../../../const/operation-type';
import { FormsModule } from '@angular/forms';
import { FormState } from '../../../../domain/form-state';
import { ApplicationBroadcaster } from '@rx/core';
import { CallTypesEnum } from '../../../../const/call-type';
import { CallSentMethodEnum } from 'src/app/enums';


@Component({
    templateUrl: './call-db-view.component.html',
})
export class CallDbViewComponent extends CallDbDomain implements OnInit, OnDestroy {
    showComponent: boolean = false;
    callDb: CallDbViewModel;
    // public event: EventEmitter<any> = new EventEmitter();
    viewSubscription: Subscription;

    callInstructions: any;

    @Input() practiceId: number;
    @Input() sKey: number;
    @Input() newBornSkeyList : number[];
    @Input() callTypeId: number;
    @Input() spResult: any;
    PopUpTitle: string;
    constructor(
        private callDbService: CallDbService,
        private dialog: RxDialog,
        private route: ActivatedRoute,
        private router: Router,
        private applicationBroadCast: ApplicationBroadcaster,
        private popup: RxPopup,
    ) {
        super();
        this.practiceId = user.data['practiceId'];
    }

    ngOnInit(): void {
        
        if (this.viewSubscription)
            this.viewSubscription.unsubscribe();
        let Query = { 'sKey': this.sKey };
        this.callDb = JSON.parse(this.spResult)[0];

        this.callInstructions = this.getContinuePopupString(this.callDb, this.callTypeId);

        switch (this.callTypeId) {
            case CallTypesEnum.PatientToDoctor:
                this.PopUpTitle = 'Patient'
                break;
            case CallTypesEnum.Newborns:
                this.PopUpTitle = 'Newborn'
                break;
            case CallTypesEnum.DoctorToDoctor:
                this.PopUpTitle = 'Doctor'
                break;
            case CallTypesEnum.HospitalAdmission:
                this.PopUpTitle = 'Admission'
                break;
            case CallTypesEnum.Appointment:
                this.PopUpTitle = 'Appoinment'
                break;
            case CallTypesEnum.AnsweringSrtoDr:
                this.PopUpTitle = 'Answering Service'
                break;
            case CallTypesEnum.TriageToDr:
                this.PopUpTitle = 'Triage'
                break;
            case CallTypesEnum.RxRefills:
                this.PopUpTitle = 'Rx Refill'
                break;
        }

        this.showComponent = true;
    }

    HidePracticeSelectionPopup(value): void {
        // document.body.className = "";
        this.popup.hide(CallDbViewComponent, value);
    }

    submitCallDetails(): void {
        let callDb = new CallDb();
        callDb.sKey = this.sKey;
        callDb.practiceId = this.practiceId;
        callDb.operationType = OperationTypesEnum.Submit.toString();
        callDb.callTypeId = this.callTypeId;
        callDb.notPhysId = this.callDb['ProviderId'];
        callDb.notPrefId = this.callDb['NotPrefId'];
        callDb.onCallRosterId = this.callDb['OnCallRosterId'];
        callDb.onCallType = this.callDb['OnLocalOverride'] ? CallSentMethodEnum.LocalOverride : (this.callDb['IsPrimaryProvider'] ? CallSentMethodEnum.PrimaryCareProvider : (this.callDb['OnAht'] ? CallSentMethodEnum.AHT : (this.callDb['IsRoundingProvider'] ? CallSentMethodEnum.RoundingProvider : (this.callDb['OnGlobalOverride'] ? CallSentMethodEnum.GlobalOverride : CallSentMethodEnum.OnCallProvider))));
                            
        // callDb.cStartTimeDate = new Date();
        if(this.newBornSkeyList.length > 0){
            callDb.spResult = this.newBornSkeyList.join(',');
        }
        this.viewSubscription = this.callDbService.put(this.practiceId, callDb).subscribe(t => {
            FormState.remove(0);
            // this.router.navigate(['call-db/patient',this.practiceId]);
            this.applicationBroadCast.patientIdBroadCast(0);
            localStorage.removeItem('callId');
            this.HidePracticeSelectionPopup({ isEdit: false });
        },
            error => {
                this.popup.validationFailed(error);
            })
    }

    goBackToData(): void {
        localStorage.setItem('callId', this.sKey.toString());
        this.HidePracticeSelectionPopup({ isEdit: true });
    }

    ngOnDestroy(): void {
        if (this.viewSubscription)
            this.viewSubscription.unsubscribe();
        super.destroy();
    }
}
