import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Provider } from './provider';import { UserDevice } from './user-device'; @table('Users')
export class User {
    constructor(user?: User )  {
        let properties = [ "aboutMe", "applicationTimeZoneId", "contactNo", "createdByUserId", "createdDate", "email", "facebookProviderKey", "facebookUrl", "firstName", "image", "isBackendUser", "isFirstTimeLogin", "languageId", "lastName", "lattitude", "linkdinUrl", "loginBlocked", "longitude", "modifiedByUserId", "modifiedDate", "password", "passwordUpdationDateTime", "practiceId", "roleId", "salt", "securityAnswer", "securityQuestionId", "statusId", "statusMessage", "twitterUrl", "userId", "userLocation", "userName", "userProfilePhotoUrl", "verificationCode", "providers", "userDevices", "userPassword", "isChangePassword", "confirmPassword", "dateData", "oldPassword", "userTypeId", "timeZoneName", "fileExtension", "fileType", "profilePicture", "plateformId","IsFirstTelehealthVisit","loginAttempt","isPracticeManager",];
        for (let property of properties)
            if (user && (user[property] || user[property] == 0))
                this[property] = user[property];
    }
 
    @maxLength(500)
	@property('AboutMe')
	aboutMe : string = undefined ;
 
    @range(1,2147483647)
	@property('ApplicationTimeZoneId')
	applicationTimeZoneId : number =   undefined;
 
    @maxLength(12)
@required()
	@property('ContactNo')
	contactNo : string = undefined ;
 
	@property('CreatedByUserId')
	createdByUserId : number =   undefined;
 
	@property('CreatedDate')
	createdDate : Date =   undefined;
 
    @maxLength(150)
@required()
@email()
	@property('Email')
	email : string = undefined ;
 
	@property('FacebookProviderKey')
	facebookProviderKey : string = undefined ;
 
    @maxLength(100)
	@property('FacebookUrl')
	facebookUrl : string = undefined ;
 
    @required()
    @maxLength(100)
	@property('FirstName')
	firstName : string = undefined ;
 
	@property('Image')
	image : any =   undefined;
 
	@property('IsBackendUser')
	isBackendUser : boolean = false ;
	
	@property('IsPracticeManager')
	isPracticeManager : boolean = false ;

	@property('IsFirstTimeLogin')
	isFirstTimeLogin : boolean = false ;
 
	@property('IsFirstTelehealthVisit')
	IsFirstTelehealthVisit : boolean = false ;


    @range(1,2147483647)
	@property('LanguageId')
	languageId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('LastName')
	lastName : string = undefined ;
 
    @maxLength(100)
	@property('Lattitude')
	lattitude : string = undefined ;
 
    @maxLength(100)
	@property('LinkdinUrl')
	linkdinUrl : string = undefined ;
 
	@property('LoginBlocked')
	loginBlocked : boolean = false ;
 
    @maxLength(100)
	@property('Longitude')
	longitude : string = undefined ;
 
	@property('ModifiedByUserId')
	modifiedByUserId : number =   undefined;
 
	@property('ModifiedDate')
	modifiedDate : Date =   undefined;
 
    @maxLength(132)
	@property('Password')
	password : any =   undefined;
 
	@property('PasswordUpdationDateTime')
	passwordUpdationDateTime : Date =   undefined;
 
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @range(1,2147483647)
	@property('RoleId')
	roleId : number =   undefined;
 
    @maxLength(140)
	@property('Salt')
	salt : any =   undefined;
 
    @maxLength(50)
	@property('SecurityAnswer')
	securityAnswer : string = undefined ;
 
	@property('SecurityQuestionId')
	securityQuestionId : number =   undefined;
 
    @range(1,2147483647)
	@property('StatusId')
	statusId : number =   undefined;
 
    @maxLength(500)
	@property('StatusMessage')
	statusMessage : string = undefined ;
 
    @maxLength(100)
	@property('TwitterUrl')
	twitterUrl : string = undefined ;
 
	@property('UserId')
	userId : number =   0 ;
 
    @maxLength(100)
	@property('UserLocation')
	userLocation : string = undefined ;
 
    @required()
    @maxLength(50)
@pattern('^[a-zA-Z][A-Za-z0-9. ]+$')
	@property('UserName')
	userName : string = undefined ;
 
    @maxLength(500)
	@property('UserProfilePhotoUrl')
	userProfilePhotoUrl : string = undefined ;
 
	@property('VerificationCode')
	verificationCode : string = undefined ;
	@nested(Provider)
	providers: Provider[];

	@property('LoginAttempt')
	loginAttempt : number =   undefined;

	@nested(UserDevice)
	userDevices: UserDevice[];



	@required()
	@maxLength(20)
	@pattern('[A-Z]','capital')
	@pattern('[0-9]','number')
	@pattern('[a-z]','small')
	@pattern('[#$^+=!*()@%&]+','specialcharacter')
	userPassword : string =   undefined;
	isChangePassword : boolean = false ;
	@required()
	confirmPassword : string =   undefined;
	dateData : Date =   undefined;
	oldPassword : string =   undefined;
	// @nested(int?)
	// userTypeId : int?[] =   undefined;
	timeZoneName : string =   undefined;
	fileExtension : string =   undefined;
	fileType : string =   undefined;
	profilePicture : string =   undefined;
	// @nested(int?)
	// plateformId : int?[] =   undefined;

}
