import { Component, OnInit, ComponentFactoryResolver, NgZone, Output, OnDestroy, EventEmitter } from '@angular/core';
import { ApplicationBroadcaster } from "@rx/core";
import { Router } from "@angular/router";
import { RxStorage, RxPopup, RxToast } from "@rx";
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs";
import { user } from '@rx/security';
import { Subject } from "rxjs/Subject";
import * as  firebase from 'firebase';
import { User, vProvider } from '../../database-models';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RecordStatusEnum } from '../../enums';
import { TableNameEnum } from 'src/app/enums/table-name';
import { ScheduleTelevisitEditComponent } from '../schedule-televisit/edit/schedule-televisit-edit.component';
import { ScheduleTelevisitAddComponent } from '../schedule-televisit/add/schedule-televisit-add.component';
import { PracticesService } from '../agent-office/practices/practices.service';
import { ScheduleTelevisitService } from '../schedule-televisit/schedule-televisit.service';
import { ProvidersService } from '../agent-provider/providers/providers.service';
import { PracticeLookupGroup } from '../agent-office/practices/domain/practice.models';
import { TelehealthDashboardService } from './telehealth-dashboard';
import { ScheduleTelevisitViewComponent } from '../schedule-televisit/view/schedule-televisit-view.component';

@Component({
  //selector: 'app-telehealth-dashboard',
  templateUrl: './telehealth-dashboard.component.html',
  entryComponents: [ScheduleTelevisitAddComponent,ScheduleTelevisitEditComponent,ScheduleTelevisitViewComponent]
})
export class TelehealthDashboardComponent implements OnInit, OnDestroy {
  public chartData: any[];
  public chartDataWeek: any[];
  tableName: any = TableNameEnum.MyTeleVisits;
  @Output() ExpandSideBar = new EventEmitter();
  showComponent: boolean = false;
  isAdmin: boolean = true;
  anyPracticeSelected: boolean = false;
  private practiceId: number;
  calendarSource: any = [{
    roasterDate: new Date("04/12/2018"),
    info: ['Test Data'],
    isHighlight: true

  }]
  subscriptionList: any;
  listSubscription: Subscription;
  teleVisitSubscription: boolean = false;
  AnsweringSubscription: boolean = false;
  calendarSourceSubscription: Subject<any> = new Subject<any>();
  calendarSourceObservable: Observable<any>;
  televisitHistoryList: any = [];
  televisitProviderList: any = [];
  televisitHistoryListNew: any = [];
  televisitProviderListNew: any = [];
  icdData: any = [];
  cptData: any = [];
  myTelevisits: any = [];
  userObj: User;
  isPracticeManager: boolean = false;
  endDate: Date = undefined;
  startDate: Date = undefined;
  practiceName: string;
  scheduleTeleVisit: any;
  currentdate: any;
  practiceLookupGroup: PracticeLookupGroup;;
  constructor(
    private router: Router,
    private storage: RxStorage,
    private applicationBroadcaster: ApplicationBroadcaster,
    componentFactoryResolver: ComponentFactoryResolver,
    private popup: RxPopup,
    private practicesService: PracticesService,
    private scheduleTelevisitService: ScheduleTelevisitService,
    private providersService: ProvidersService,
    private telehealthDashboardService: TelehealthDashboardService,
    private fb: FormBuilder,
    private toast: RxToast,
  ) {
    this.popup.setComponent(componentFactoryResolver);
    this.practiceId = user.data['practiceId'];
    this.practiceName = user.data['practiceName'];
    if (user.data['roleId'] == 3)
      this.isPracticeManager = true;

    this.applicationBroadcaster.isPracticeSelectedSubscriber.subscribe(value => {
      this.anyPracticeSelected = value;

    });
    var auth = this.storage.local.get("auth");
    if (auth) {
    }
    else
      this.router.navigate(["login"]);
    this.calendarSourceObservable = this.calendarSourceSubscription.asObservable();

  }
  timeZone: any;
  staffUser: boolean = false;
  ehrdisable: boolean = false;
  providers: vProvider[];
  selectedProviderUserId: number;
  isProviderSelected: boolean = false;
  providerForm: FormGroup;
  televisitURL: any;
  ehrLink: any;
  providerId: number;
  dataRefresher: any;
  ngOnInit() {

    let URL = window.location.href;
    let host = URL.split("/")[0] || 0;
    let site = URL.split("/")[2] || 0;

    this.televisitURL = host + '//' + site + "/#/televisit/";
    var id = localStorage.getItem("selectedProviderUserId");

    if (id) {
      this.selectedProviderUserId = parseInt(id);
      this.isProviderSelected = true;
      this.providerForm = this.fb.group({
        userId: [this.selectedProviderUserId]
      });
      if (user.data["roleId"] == 1004 ||user.data["roleId"] == 1005 || user.data["roleId"] == 1011) {
        this.providersService.get(this.practiceId).subscribe(providers => {
          let provider = providers.where(x => x.userId == this.selectedProviderUserId).singleOrDefault();

          if (provider["optedForTelehealth"] != true) {
            this.selectedProviderUserId = 0;
            this.isProviderSelected = false;
            this.providerForm = this.fb.group({
              userId: [0]
            });
          }
        });

      }

    } else {
      this.providerForm = this.fb.group({
        userId: [0]
      });
    }

    this.calendarSourceSubscription.next(this.calendarSource);
    this.showComponent = true;

    if (this.practiceId > 0)
      this.anyPracticeSelected = true;

    this.listSubscription = this.practicesService.getBy([this.practiceId]).subscribe(practices => {
      
      this.practiceName = practices["data"].practiceName;
      this.ehrLink =  practices["data"].ehrLink;
      if (this.ehrLink == null || this.ehrLink == "") {
        this.ehrdisable = true;
      }
    });
 
    this.mainmethod(1)
    //this.refreshData();
  }
  getUpdatedData() {

    this.myTelevisits.forEach(element => {
      var d = new Date();
      var currentUtcDate = new Date(d.getTime() + (d.getTimezoneOffset() * 60000));

      var date = new Date(element.visitDate);

      var year = date.getFullYear();
      var month = date.getMonth() + 1; // Jan is 0, dec is 11
      var day = date.getDate();
      var dateString = '' + year + '-' + month + '-' + day;

      var visitDateWithTime = new Date(dateString + ' ' + element.fromTime);
      var s = visitDateWithTime.setMinutes(visitDateWithTime.getMinutes() - 10);
      if (currentUtcDate >= visitDateWithTime) {
        element.isStart = true;
      }
      else {
        element.isStart = false;
      }

      var visitDateWithTime1 = new Date(dateString + ' ' + element.fromTime);
      var visitDateWithTime2 = new Date(dateString + ' ' + element.toTime);

      if (currentUtcDate >= visitDateWithTime && currentUtcDate <= visitDateWithTime2) {
        element.callStarted = true;
      }
      else {
        element.callStarted = false;
      }

    });

  }
  isDateSelected: boolean = false;
  convertDateString(dateString: Date, type: boolean) {
    if (type)
      return dateString.getFullYear() + '-' + (dateString.getMonth() + 1) + '-' + dateString.getDate() + ' ' + '00:00:00';
    else
      return dateString.getFullYear() + '-' + (dateString.getMonth() + 1) + '-' + dateString.getDate() + ' ' + '23:59:59';
  }

  errmsg: string;
 
  mainmethod(num: any) {

    this.telehealthDashboardService.gettelevisitHistory(user.data["practiceId"], num).subscribe(list => {

      this.televisitHistoryList = list.where(t => t.practiceId == this.practiceId);
      this.televisitHistoryListNew = this.televisitHistoryList;
      this.televisitProviderList = list.where(t => t.userId == user.data["userId"]);
      this.televisitProviderListNew = this.televisitProviderList;

      this.providersService.get(this.practiceId, num).subscribe(providers => {
        this.providers = providers.where(x => x.optedForTelehealth == true && x.statusId == RecordStatusEnum.Active);

        if ((user.data["roleId"] == 1004 || user.data["roleId"] == 1005 || user.data["roleId"] == 1011) && this.selectedProviderUserId > 0) {
          this.getVisitlist1();
          
        }

      });


    });
  }
  // refreshData() {
  //   this.dataRefresher =
  //     setInterval(() => {
  //       this.mainmethod(2);
  //       //Passing the false flag would prevent page reset to 1 and hinder user interaction
  //     }, 30000);
  // }
  cancelPageRefresh() {
    if (this.dataRefresher) {
      clearInterval(this.dataRefresher);
    }
  }
  ngOnDestroy() {
    this.cancelPageRefresh();
  }
  goToehrLink() {
    window.open(this.ehrLink, "_blank");
  }
 
  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toast.show("URL copied to clipboard.", { status: 'success' });
  }

  getVisitlist1() {
    this.myTelevisits = this.televisitHistoryListNew.where(t => t.callStatus != 3 && t.pendingVisit == true && t.userId == this.selectedProviderUserId);

    this.myTelevisits.forEach(element => {
      element.url = this.televisitURL + element.televisitNo;
      // var mobile = (element.patientMobileNo.substring(0, 3) + "-" + element.patientMobileNo.substring(3, 6) + "-" + element.patientMobileNo.substring(6));
      // element.patientMobileNo = mobile;

    });
  }

  setProvider(event: any) {
    this.selectedProviderUserId = event.target.value;
    localStorage.setItem("selectedProviderUserId", this.selectedProviderUserId.toString());

    var data = this.providers.where(s => s.userId == this.selectedProviderUserId).singleOrDefault();
    var providerMobile = data["mobile"];
    localStorage.setItem("providerMobile", providerMobile);

    let providername = data["userName"];
    localStorage.setItem("selectedProviderName", providername);

    if (this.selectedProviderUserId > 0) {
      this.isProviderSelected = true;
      this.getVisitlist1();
    }
    else {
      this.isProviderSelected = false;
    }

    let userdata = user.data;
    firebase.analytics().setUserId('' + userdata.userId + '');

    firebase.analytics().setUserProperties({
      practiceId: '' + userdata.practiceId + '',
      roleId: '' + userdata.roleId + '',
      userName: '' + userdata.userName + '',
      practiceName: '' + userdata.practiceName + '',
      mobilenumber: '' + userdata.mobileNumber + '',
      isOptedForTelehealth: userdata.optedForTelehealth
    });

    firebase.analytics().setUserProperties({
      userId: this.selectedProviderUserId.toString(),
      providerId: '' + data["providerId"] + '',

    });
    firebase.analytics().logEvent('change_provider', {

    });
  }

  timeConversionTo12Hour(time: any): any {
    if (time != null && time != undefined && time != "") {
      var timeArray = time.split(":");
      var hours = parseInt(timeArray[0]);
      var isMr = hours >= 12 ? "PM" : "AM"
      var newhour = hours >= 12 ? (hours == 12 ? 12 : hours - 12) : (hours == 0 ? 12 : hours);
      var paddedNum = newhour.toString().length == 1 ? String("00" + newhour).slice(-2) : newhour;
      time = paddedNum + ":" + timeArray[1] + " " + isMr;
      return time;
    }
    else {
      return time;
    }
  }

  getNotVisitedList(event: any, num: any) {
    if (event.target.checked == true && num == 0) {
      this.televisitHistoryList = this.televisitHistoryList.where(t => t.practiceId == this.practiceId && t.isNoPatientData == true);
    }
    else if (event.target.checked == true && num == 1) {
      this.televisitProviderList = this.televisitProviderList.where(t => t.userId == user.data["userId"] && t.isNoPatientData == true);
    }
    else if (event.target.checked == false && num == 0) {
      this.televisitHistoryList = this.televisitHistoryListNew;
    }
    else if (event.target.checked == false && num == 1) {
      this.televisitProviderList = this.televisitProviderListNew;
    }
  }

  ngAfterViewInit(): void {
  
  }

  // showPopup(visitRoomId: string) {

  //   this.popup.show(TelevisitMasterSearchComponent, { visitRoomId: visitRoomId }).then(() => {
  //     this.ngOnInit()
  //   });
  // }

  getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
      if (name == cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if not found
    return null;
  }

  // editDictationdetail(televisitNo: number) {
  //   this.router.navigateByUrl('/dictation-form/edit/' + televisitNo);

  // }

  showDictationPopup(televisitMasterId: number) {
    this.popup.show(ScheduleTelevisitViewComponent, { televisitMasterId: televisitMasterId }).then(() => {
      this.ngOnInit()
    });
  }

  showSchedulevisitpopup() {
    this.popup.show(ScheduleTelevisitAddComponent).then(() => {
      this.ngOnInit();
    });
  }

  showSchedulevisitEditpopup(scheduleTelevisitId: number, data: any) {
    this.popup.show(ScheduleTelevisitEditComponent, { scheduleTelevisitId: scheduleTelevisitId, data: data }).then(() => {
      this.ngOnInit();
    });
  }

}




