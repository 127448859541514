import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";
import {RxViewModule } from '@rx/view';
import {RxFormsModule } from '@rx/forms';
import {RxTableModule } from "@rx/table";
import {DynamicComponentContainer } from '@rx/core';

import { ScheduleTelevisitAddComponent } from './add/schedule-televisit-add.component'
import { ScheduleTelevisitEditComponent } from './edit/schedule-televisit-edit.component'
import {ScheduleTelevisitService } from "./schedule-televisit.service";
import {SCHEDULE_TELEVISIT_SHARED_COMPONENT_CONTAINER } from './schedule-televisit-shared-component.container';

import { TwoDigitDecimaNumberDirective } from './add/twodigitdecimalnumber.directive';
import { ProvidersService } from '../agent-provider/providers/providers.service';
import { PracticesService } from '../agent-office/practices/practices.service';
import { ScheduleTelevisitViewComponent } from './view/schedule-televisit-view.component';
@NgModule({
    imports: [
        RxViewModule, RxFormsModule,
        CommonModule, FormsModule, ReactiveFormsModule, RxTableModule
    ],
    declarations: [ ScheduleTelevisitAddComponent, ScheduleTelevisitEditComponent,TwoDigitDecimaNumberDirective,ScheduleTelevisitViewComponent],
    providers: [ScheduleTelevisitService,PracticesService,ProvidersService ],
    exports: [RouterModule, ScheduleTelevisitAddComponent, ScheduleTelevisitEditComponent,ScheduleTelevisitViewComponent ],
    entryComponents: []

})
export class ScheduleTelevisitSharedComponentModule { }
//DynamicComponentContainer.register(SCHEDULE_TELEVISIT_SHARED_COMPONENT_CONTAINER );