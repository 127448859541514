import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";

import { API_HOST_URI, RxHttp, APP_VERSION } from '@rx/http';
import { RxValidation, RxFormsModule } from '@rx/forms';
import { RxViewModule, RxViewServiceModule } from '@rx/view';
import { RxStorageModule } from '@rx/storage';
import { RxSecurityModule, PermissionService } from "@rx/security";
import { RxTableModule } from "@rx/table";
import { ApplicationBroadcaster } from "@rx/core";

import { ApplicationResponse } from "./domain/application-response";
import { ApplicationRequestHeaders } from "./domain/application-request-headers";
import { CanActivatePage, ApplicationJsonConfiguration, UserAuthorizationService, PageAccess, ApplicationService, ChangeDetectionGuard } from "./domain/authorization";

import { APP_LAZY_ROUTING } from './components/start/app.lazy.routing';
import { AppComponent } from './components/start/app.component';
import { SideBarComponent } from './components/shared/side-bar/side-bar.component';
import { TopBarComponent } from './components/shared/top-bar/top-bar.component';
import { FooterBarComponent } from './components/shared/footer-bar/footer-bar.component';

import { environment } from '../environments/environment';

//import { DashboardComponent } from "src/app/components/dashboard/dashboard.component";
import { UsersService } from './components/manage-user-agent/users/users.service';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { TopBarService } from './components/shared/top-bar/top-bar.service';
import { LoginService } from './components/login/login.service';
import { LoginComponent } from './components/login/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { UnAuthorizedComponent } from './components/unauthorized/unauthorized.component';
import firebase = require('firebase');

@NgModule({
  declarations: [
    AppComponent, SideBarComponent, TopBarComponent, FooterBarComponent, NotFoundComponent, UnAuthorizedComponent, LoginComponent, ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule, ReactiveFormsModule, RxTableModule,
    HttpClientModule, RxSecurityModule, CommonModule, DashboardModule,
    RxFormsModule, RxViewModule, RxStorageModule, RxViewServiceModule, APP_LAZY_ROUTING
  ],
  providers: [PermissionService, RxValidation, LoginService, ApplicationBroadcaster, UsersService,TopBarService,
    {
      provide: API_HOST_URI,
      useValue: environment.apiRootUrl
    }
    ,
    {
      provide: APP_VERSION,
      useValue: environment.appVersion
    },
    { provide: 'RequestHeaders', useClass: ApplicationRequestHeaders },
    { provide: 'ResponseResult', useClass: ApplicationResponse }, RxHttp,
    { provide: 'PageAccess', useClass: PageAccess },
    { provide: 'ChangeDetectionGuard', useClass: ChangeDetectionGuard },
    UserAuthorizationService,
    CanActivatePage,
    ApplicationJsonConfiguration,
    PageAccess,
    ApplicationService, ChangeDetectionGuard
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
firebase.initializeApp(environment.firebase);