import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Practice } from './practice';import { GlobalSetting } from './global-setting'; @table('ApplicationTimeZones')
export class ApplicationTimeZone {
    constructor(applicationTimeZone?: ApplicationTimeZone )  {
        let properties = [ "abbreviation", "active", "applicationTimeZoneId", "applicationTimeZoneName", "comment", "countryId", "timezoneCaption", "utcOffset", "practices", "globalSettings",];
        for (let property of properties)
            if (applicationTimeZone && (applicationTimeZone[property] || applicationTimeZone[property] == 0))
                this[property] = applicationTimeZone[property];
    }
 
    @maxLength(50)
	@property('Abbreviation')
	abbreviation : string = undefined ;
 
	@property('Active')
	active : boolean = false ;
 
	@property('ApplicationTimeZoneId')
	applicationTimeZoneId : number =   0 ;
 
    @required()
    @maxLength(100)
	@property('ApplicationTimeZoneName')
	applicationTimeZoneName : string = undefined ;
 
    @required()
    @maxLength(200)
	@property('Comment')
	comment : string = undefined ;
 
    @range(1,2147483647)
	@property('CountryId')
	countryId : number =   undefined;
 
    @maxLength(100)
	@property('TimezoneCaption')
	timezoneCaption : string = undefined ;
 
	@property('UtcOffset')
	utcOffset : number =   undefined;
	@nested(Practice)
	practices: Practice[];

	@nested(GlobalSetting)
	globalSettings: GlobalSetting[];




}
