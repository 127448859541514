import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Messaging } from './messaging';import { Provider } from './provider'; @table('ProviderMessages')
export class ProviderMessage {
    constructor(providerMessage?: ProviderMessage )  {
        let properties = [ "createdById", "createdDateTime", "index", "modifiedById", "modifiedDateTime", "providerMessageId", "messagingId", "providerId",];
        for (let property of properties)
            if (providerMessage && (providerMessage[property] || providerMessage[property] == 0))
                this[property] = providerMessage[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('Index')
	index : number =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('ProviderMessageId')
	providerMessageId : number =   0 ;
 
	@property('MessagingId')
	messagingId : number =   undefined;
	messaging : Messaging  ;
 
    @range(0,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
	provider : Provider  ;



}
