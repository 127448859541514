import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi } from "@rx/security";

import { CallDb, vCallDb, } from 'src/app/database-models';
import { CallDbLookupGroup } from './domain/call-db.models';

@Injectable()
export class CallDbService {
    private practiceId: number;

    private get api(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/Practices/${this.practiceId}/CallDb`,
            // childModuleName: 'call-d-b',
            applicationModuleId: 2045,
            keyName: 'sKey'

        }
        return authorizeApi;
    }
    constructor(
        private http: RxHttp
    ) { }

    private patientId: Subject<number> = new BehaviorSubject<number>(0);
    node$ = this.patientId.asObservable();

    addNode(data: number) {
        this.patientId.next(data);
    }

    lookup<CallDbLookupGroup>(lookupActions: LookupAction[]): Promise<CallDbLookupGroup> {
        return this.http.lookup<CallDbLookupGroup>(lookupActions);
    }

    group<CallDbLookupGroup>(practiceId: number, params: any[] | {
        [key: string]: any;
    } | RequestQueryParams, lookupActions: LookupAction[]): Promise<CallDbLookupGroup> {
        this.practiceId = practiceId;
        return this.http.group<CallDbLookupGroup>(this.api, params, 'CallDb', lookupActions);
    }

    search(practiceId: number, search: any): Observable<any> {
        this.practiceId = practiceId;
        return this.http.search<any>(this.api, search);
    }

    get(practiceId: number, params?: any[]): Observable<any> {
        this.practiceId = practiceId;
      return this.http.get<vCallDb[]>(this.api, { 'lastName': params[0], 'phoneNumber': params[1], 'birthdate': params[2], 'mode': params[3]});
    }

    getBy(practiceId: number, params?: any[] | {
        [key: string]: any;
    } | RequestQueryParams): Observable<CallDb> {
        this.practiceId = practiceId;
        return this.http.get<CallDb>(this.api, params);
    }

    post(practiceId: number, callDb: CallDb): Observable<CallDb> {
        this.practiceId = practiceId;
        return this.http.post<CallDb>(this.api, callDb);
    }

    put(practiceId: number, callDb: CallDb): Observable<CallDb> {
        this.practiceId = practiceId;
        return this.http.put<CallDb>(this.api, callDb);
    }

    delete(practiceId: number, id: number): Observable<CallDb> {
        this.practiceId = practiceId;
        return this.http.delete<CallDb>(this.api, id);
    }

    filterLookup<T>(lookup: LookupAction, filterParameters: any[], queryParams?: { [key: string]: any }): Observable<T> {
        return this.http.filterLookup<T>(lookup, filterParameters, queryParams);
    }

    patch(practiceId: number, id: any, data: any): Observable<CallDb> {
        this.practiceId = practiceId;
        return this.http.patch<CallDb>(this.api, id, data);
    }

}


// @Injectable()
// export class NodeService {
//     private patientId: Subject<number> = new BehaviorSubject<number>(1);
//     node$ = this.patientId.asObservable();

//     addNode(data: number) {
//         this.patientId.next(data);
//     }
// }
