import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vOnCallRosters')
export class vOnCallRoster {
    constructor(vOnCallRoster?: vOnCallRoster )  {
        let properties = [ "onCallDate", "onCallRosterId", "onCallTime", "practiceId", "providerFullName", "providerId",];
        for (let property of properties)
            if (vOnCallRoster && (vOnCallRoster[property] || vOnCallRoster[property] == 0))
                this[property] = vOnCallRoster[property];
    }
 
    @required()
	@property('OnCallDate')
	onCallDate : Date =   undefined;
 
    @range(1,2147483647)
	@property('OnCallRosterId')
	onCallRosterId : number =   undefined;
 
    @required()
	@property('OnCallTime')
	onCallTime : Date =   undefined;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @required()
    @maxLength(101)
	@property('providerFullName')
	providerFullName : string = undefined ;
 
    @range(1,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;



}
