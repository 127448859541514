import { Component} from '@angular/core';
import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import {Observable } from 'rxjs/Rx';
import { AuthorizeApi} from "@rx/security";

@Component({
    selector: 'app-footer-bar',
    templateUrl: './footer-bar.component.html',
})
export class FooterBarComponent { 
    date  : any = new Date();
    version : String = "1.0.0"
    
    private get api(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/Version`,
            applicationModuleId: 4068,	
        }
        return authorizeApi;
    } 

    constructor(private http: RxHttp) {        
    }

    ngOnInit():void{
        this.getVersion().subscribe(v=>{
            console.log(v);
            this.version = v.version;
        }, error => {
            console.error(error);
            this.version = "1.0.0";
        });
    }
    
  getVersion(): Observable<any> {
    return this.http.get<any>(this.api);
  }
}
