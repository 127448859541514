import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { RxMessageComponent } from '@rx/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs/Rx';

import { ApplicationBroadcaster, ApplicationConfiguration, ApplicationUtility, ComponentCanDeactivate } from "@rx/core";
import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';
import { RxValidation } from '@rx/forms';

import { OnCallRostersService } from '../on-call-rosters.service';
import { OnCallRosterDomain } from '../domain/on-call-roster.domain';
import { OnCallRosterLookupGroup, OnCallRosterModel } from '../domain/on-call-roster.models';
import { OnCallRoster, vProvider } from 'src/app/database-models';
import { TimeSource } from 'src/app/const/time-source';
import { user } from '@rx/security';
import { RecordStatusEnum } from 'src/app/enums';
import { RosterLookups } from 'src/app/lookups/roster-lookups';
import { lookup } from 'dns';
import { ApplicationPermission } from 'src/app/domain/application-permission';


@Component({
    templateUrl: './on-call-roster-add.component.html',
    // entryComponents : [ HospitalRoundingRosterEditComponent,  VacationRosterEditComponent,  ExtendedNewbornRosterEditComponent, ]
})
export class OnCallRosterAddComponent extends OnCallRosterDomain implements OnInit, OnDestroy, ComponentCanDeactivate {

    isEditable: boolean = true;
    tabValue: string;
    practiceName: any;
    timeSource = TimeSource;
    showComponent: boolean = false;
    onCallRosterFormGroup: FormGroup;
    onCallRosterListFormGroup: FormGroup;
    addSubscription: Subscription;
    onCallRosterLookupGroup: OnCallRosterLookupGroup;
    currentMonth: Date = undefined;
    providerList: vProvider[] = []
    selectedDateList: Date[] = [];
    deleteDateList: Date[] = [];
    initialData: OnCallRoster[];
    deteleIdDataList: number[] = [];
    @Input() practiceId: number;
    calendarSource: any = []
    calendarSourceSubscription: Subject<any> = new Subject<any>();
    calendarSourceObservable: Observable<any>;
    hasAHT: boolean;
    resetSource: boolean = false;
    providers: any;
    constructor(
        private validation: RxValidation,
        private router: Router,
        private toast: RxToast,
        private dialog: RxDialog,
        private onCallRostersService: OnCallRostersService,
        private applicationBroadcaster: ApplicationBroadcaster,
        private popup: RxPopup,
    ) {
        super();
        if (user && user.data) {
            this.practiceId = user.data["practiceId"];
            this.practiceName = user.data["practiceName"];
        }
        this.calendarSourceObservable = this.calendarSourceSubscription.asObservable();
    }

    ngOnInit(): void {

        if (user.data["roleId"] == 1011) {
            this.isEditable = false;
          }
          
        //this.isEditable = ApplicationPermission.isAccess(["edit"]);

        this.tabValue = 'On Call Roster';
        var applicationUtility = new ApplicationUtility();
        this.currentMonth = this.currentMonth != undefined ? this.currentMonth : new Date();

        let monthDates = applicationUtility.GetWeekStartAndEndDateMonth(this.currentMonth);
        this.onCallRostersService.group(this.practiceId, [monthDates.startDate.toISOString(), monthDates.endDate.toISOString()], [RosterLookups.providers]).then(
            (response: any) => {
                this.hasAHT = response.OnCallRoster.hasAHT;
                this.onCallRosterLookupGroup = new OnCallRosterLookupGroup();
                this.onCallRosterLookupGroup = response;
                let parseJSON = JSON.parse(response.OnCallRoster.onCallRosters);
                this.providerList = response.providers.where(x => x.practiceId == this.practiceId);
                this.onCallRosterLookupGroup.providers = response.providers.where(x => x.statusId == RecordStatusEnum.Active && x.practiceId == this.practiceId);
                this.onCallRosterLookupGroup.onCallRoster = new OnCallRoster();
                this.onCallRosterLookupGroup.onCallRoster.createdById = user.data['userId'];
                this.onCallRosterLookupGroup.onCallRoster.createdDateTime = new Date();
                this.onCallRosterLookupGroup.onCallRoster.practiceId = this.practiceId;
                this.providers = response.providers.where(x => x.statusId == RecordStatusEnum.Active && x.practiceId == this.practiceId).map(x => ({ providerName: x.lastName + ', ' + x.firstName, providerId: x.providerId, lastName: x.lastName }));
                this.providers.sort(function (a, b) {
                    var textA = a.lastName.toUpperCase();
                    var textB = b.lastName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                this.calendarSource = [];
                if (JSON.parse(response.OnCallRoster.onCallRosters)[0].OnCallRosters) {
                    this.initialData = Object.assign([], JSON.parse(JSON.parse(response.OnCallRoster.onCallRosters)[0].OnCallRosters));
                }
                else {
                    this.initialData = [];
                }
                let dataList = this.initialData;

                this.bindCalanderResourse(dataList, this.currentMonth);
                this.calendarSourceSubscription.next(this.calendarSource);
                if (this.onCallRosterFormGroup) {
                    this.onCallRosterLookupGroup.onCallRoster.earlyAhtTime = null;
                    this.onCallRosterLookupGroup.onCallRoster.modifiedById = null;
                    this.onCallRosterLookupGroup.onCallRoster.modifiedDateTime = null;
                    this.onCallRosterLookupGroup.onCallRoster.remarks = null;
                    this.onCallRosterLookupGroup.onCallRoster.onCallDate = null;
                    this.onCallRosterLookupGroup.onCallRoster.onCallTime = null;
                    this.onCallRosterLookupGroup.onCallRoster.providerId = null;
                    this.onCallRosterLookupGroup.onCallRoster.isCurrentRoster = null;
                    this.onCallRosterFormGroup.setValue(this.onCallRosterLookupGroup.onCallRoster);
                }
                else
                    this.onCallRosterFormGroup = this.validation.getFormGroup(this.onCallRosterLookupGroup.onCallRoster);
                this.onCallRosterFormGroup.controls.onCallDate.setValidators(null);

                this.showComponent = true;
            });


    }

    compareFn = (a, b) => {

        if (new Date(a.onCallTime).getTime() < new Date(b.onCallTime).getTime())
            return -1;
        if (new Date(a.onCallTime).getTime() > new Date(b.onCallTime).getTime())
            return 1;
        return 0;
    };

    bindCalanderResourse(dataList: any, currentMonth): void {

        this.calendarSource = [];
        dataList.sort((a, b) => {
            return new Date('01/01/2001 ' + a.onCallTime).getTime() - new Date('01/01/2001 ' + b.onCallTime).getTime();
        });

        dataList.forEach(element => {
            let callDate = this.convertDateString(element.onCallDate)
            let sameDateData = this.calendarSource.where(x => x.roasterDate.getDate() == callDate.getDate() && x.roasterDate.getMonth() == callDate.getMonth() && x.roasterDate.getFullYear() == callDate.getFullYear());
            let dataEarlyAhtTime = this.timeConversionTo12Hour(element.earlyAhtTime);
            let provider = this.providerList.where(x => x.providerId == element.providerId);
            let dataOnCallTime = this.timeConversionTo12Hour(element.onCallTime);
            let str = element.secondaryProviderName == null ? '' : element.secondaryProviderName;
            str = str.replace(/\,/g, ',<br/>');

            let infoString = (dataEarlyAhtTime == null ? "<span>" : "<em><b>" + dataEarlyAhtTime + "</b></em></span> <br/><span> <em>Early AHT </em> </span> <br/> <span>") + ' <b>' + dataOnCallTime + '</b></span><br/> <span>' + provider[0].firstName + "</span> <span> " + provider[0].lastName + " </span><br/><span> " + str + "</span>" + (element.remarks == null ? "" : '<br/> <span class="comment">' + element.remarks + '</span>');
            let isHighlight = dataEarlyAhtTime == null ? (sameDateData.where(x => x.isHighlight == true).length > 0 ? true : false) : true;
            if (sameDateData.length == 0) {
                this.calendarSource.push({
                    roasterDate: callDate,
                    info: [{ id: element.onCallRosterId, data: infoString }],
                    isHighlight: isHighlight
                });
            }
            else if (sameDateData.length == 1) {
                sameDateData.forEach(element1 => {
                    element1.info.push({ id: element.onCallRosterId, data: infoString })
                    element1.isHighlight = isHighlight;
                });
            }

        });
    }

    callTab(tabName: string): void {
        this.tabValue = tabName

        if (tabName == "On Call Roster") {
            this.currentMonth = new Date();
            this.ngOnInit();
        }
    }

    convertDateString(date) {
        let dateString = String(date).replace("T00:00:00", "").split("-");
        return new Date(parseInt(dateString[0]), parseInt(dateString[1]) - 1, parseInt(dateString[2]))
    }

    updateSecondaryProvider(event: any) {
        var id = event.target.value;

        if (id != 0 && id != '') {
            this.providers = this.providerList.where(x => x.statusId == RecordStatusEnum.Active && x.practiceId == this.practiceId && x.providerId != id).map(x => ({ providerName: x.lastName + ', ' + x.firstName, providerId: x.providerId, lastName: x.lastName }));
        }
        else {
            this.onCallRosterFormGroup.controls.providerId.setValue(null);
            this.providers = this.providerList.where(x => x.statusId == RecordStatusEnum.Active && x.practiceId == this.practiceId).map(x => ({ providerName: x.lastName + ', ' + x.firstName, providerId: x.providerId, lastName: x.lastName }));
        }

        this.providers.sort(function (a, b) {
            var textA = a.lastName.toUpperCase();
            var textB = b.lastName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    }
    addOnCallRoster(): void {

        let selectedDateList = Object.assign([], this.selectedDateList);
        this.onCallRosterLookupGroup.onCallRosterModel = new OnCallRosterModel();
        this.onCallRosterLookupGroup.onCallRosterModel.onCallRosters = new Array<OnCallRoster>();
        let initialData = this.initialData;
        selectedDateList.forEach(element => {
            let onCallRosterObj = new OnCallRoster();
            onCallRosterObj.onCallTime = this.timeConversionTo24Hour(this.onCallRosterFormGroup.value.onCallTime);
            onCallRosterObj.providerId = this.onCallRosterFormGroup.value.providerId;
            onCallRosterObj.remarks = this.onCallRosterFormGroup.value.remarks;
            onCallRosterObj.earlyAhtTime = this.timeConversionTo24Hour(this.onCallRosterFormGroup.value.earlyAhtTime);
            onCallRosterObj.createdById = user.data['userId'];
            onCallRosterObj.createdDateTime = new Date();
            onCallRosterObj.practiceId = this.practiceId;
            onCallRosterObj.secondaryProviderIds = this.onCallRosterFormGroup.value.secondaryProviderIds;

            onCallRosterObj.onCallDate = element;
            let obj = initialData.where(x => new Date(x.onCallDate).toDateString() == new Date(element).toDateString()
                && x.providerId == this.onCallRosterFormGroup.value.providerId
                && this.timeConversionTo24Hour(x.onCallTime) == this.timeConversionTo24Hour(this.onCallRosterFormGroup.value.onCallTime));
            if (obj.length == 0) {
                this.onCallRosterLookupGroup.onCallRosterModel.onCallRosters.push(onCallRosterObj);
            } else {
            }
        });
        if (this.onCallRosterLookupGroup.onCallRosterModel.onCallRosters.length > 0) {
            var applicationUtility = new ApplicationUtility();
            let monthDates = applicationUtility.GetWeekStartAndEndDateMonth(this.currentMonth);
            this.onCallRosterLookupGroup.onCallRosterModel.dateFrom = monthDates.startDate.toISOString();
            this.onCallRosterLookupGroup.onCallRosterModel.dateTo = monthDates.endDate.toISOString();


            this.addSubscription = this.onCallRostersService.post(this.practiceId, this.onCallRosterLookupGroup.onCallRosterModel).subscribe(t => {

                this.bindCalanderResourse(t.onCallRosters, this.currentMonth);
                this.calendarSourceSubscription.next(this.calendarSource);

                this.onCallRosterFormGroup.controls.remarks.reset();
                this.onCallRosterFormGroup.controls.earlyAhtTime.reset();
                this.onCallRosterFormGroup.controls.onCallDate.reset();
                this.onCallRosterFormGroup.controls.providerId.reset();
                this.onCallRosterFormGroup.controls.onCallRosterId.reset();
                this.onCallRosterFormGroup.controls.onCallTime.reset();

                this.tag.clear();

                this.initialData = t.onCallRosters;
                this.selectedDateList = [];
                this.resetSource = true;

            },
                error => {
                    this.popup.validationFailed(error)
                })
        }
        else {
            this.onCallRosterFormGroup.controls.remarks.reset();
            this.onCallRosterFormGroup.controls.earlyAhtTime.reset();
            this.onCallRosterFormGroup.controls.onCallDate.reset();
            this.onCallRosterFormGroup.controls.providerId.reset();
            this.onCallRosterFormGroup.controls.onCallRosterId.reset();
            this.onCallRosterFormGroup.controls.onCallTime.reset();

            this.tag.clear();

        }
    }

    deleteOnCallRoster(id: number): void {

        this.dialog.confirmation(['this Roster'], "delete").then(dialogClick => {

            if (dialogClick == DialogClick.PrimaryOk) {
                this.onCallRostersService.delete(this.practiceId, id).subscribe(t => {
                    this.selectedDateList = [];
                    this.deteleIdDataList = [];

                    this.ngOnInit();
                }, error => {
                    for (var key in error)
                        this.dialog.alert(ApplicationConfiguration.get("toastValidationMessages.dependency"), error[key]);
                });
            }
        });
    }

    selectAllDate(dates: Date[]) {
        this.selectedDateList = dates;
    }

    dateSelected(selectedDate: Date): void {
        this.selectedDateList.push(selectedDate);
    }

    dateUnSelected(unSelectedDate: Date): void {
        let toBeUnselecedDate = this.selectedDateList.where(x => x == unSelectedDate);
        if (toBeUnselecedDate.length > 0) {
            let indexSelected = this.selectedDateList.indexOf(toBeUnselecedDate[0]);
            this.selectedDateList.splice(indexSelected, 1)
        }
    }

    monthChanged(date: Date): void {
        this.currentMonth = date;
        var applicationUtility = new ApplicationUtility();
        let monthDates = applicationUtility.GetWeekStartAndEndDateMonth(this.currentMonth);
        this.onCallRostersService.get(this.practiceId, [monthDates.startDate.toISOString(), monthDates.endDate.toISOString()]).subscribe(response => {

            let dataList: any = null; if (JSON.parse(response.onCallRosters)[0].OnCallRosters)
                dataList = JSON.parse(JSON.parse(response.onCallRosters)[0].OnCallRosters);
            else
                dataList = [];
            this.bindCalanderResourse(dataList, this.currentMonth);
            this.calendarSourceSubscription.next(this.calendarSource);
            this.selectedDateList = [];
            this.deleteDateList = [];
            if (this.resetSource)
                this.resetSource = false;
            setInterval(() => {
                this.resetSource = true;
            }, 500)

        });
    }

    idSelected(id: number): void {

        this.deleteOnCallRoster(id);
    }

    canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        return !this.onCallRosterFormGroup.dirty;
    }

    ngOnDestroy(): void {
        if (this.addSubscription)
            this.addSubscription.unsubscribe();
        super.destroy();
    }

    @ViewChild("rxtag") tag;
}
