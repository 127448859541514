import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('PagerCarriers')
export class PagerCarrier {
    constructor(pagerCarrier?: PagerCarrier )  {
        let properties = [ "email", "isWctp", "name", "pagerCoId", "serviceAddress", "serviceSenderName",];
        for (let property of properties)
            if (pagerCarrier && (pagerCarrier[property] || pagerCarrier[property] == 0))
                this[property] = pagerCarrier[property];
    }
 
    @required()
    @maxLength(50)
	@property('Email')
	email : string = undefined ;
 
	@property('IsWctp')
	isWctp : boolean = false ;
 
    @required()
    @maxLength(50)
	@property('Name')
	name : string = undefined ;
 
	@property('PagerCoId')
	pagerCoId : number =   0 ;
 
    @maxLength(200)
	@property('ServiceAddress')
	serviceAddress : string = undefined ;
 
    @maxLength(200)
	@property('ServiceSenderName')
	serviceSenderName : string = undefined ;



}
