import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Address } from './address'; @table('AhtProviderMaster')
export class AhtProviderMaster {
    constructor(ahtProviderMaster?: AhtProviderMaster )  {
        let properties = [ "afterHourTriageContact", "afterHourTriageName", "ahtProviderMasterId", "createdById", "createdDateTime", "instructions", "isActive", "modifiedById", "modifiedDateTime", "outboundCallsOnly", "ahtProviderMasterAddresses",];
        for (let property of properties)
            if (ahtProviderMaster && (ahtProviderMaster[property] || ahtProviderMaster[property] == 0))
                this[property] = ahtProviderMaster[property];
    }
 
    @required()
    @maxLength(100)
	@property('AfterHourTriageContact')
	afterHourTriageContact : string = undefined ;
 
    @required()
    @maxLength(100)
	@property('AfterHourTriageName')
	afterHourTriageName : string = undefined ;
 
	@property('AhtProviderMasterId')
	ahtProviderMasterId : number =   0 ;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @maxLength(500)
	@property('Instructions')
	instructions : string = undefined ;
 
	@property('IsActive')
	isActive : boolean = false ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('OutboundCallsOnly')
	outboundCallsOnly : boolean = false ;

	@nested(Address)
	ahtProviderMasterAddresses: Address[];



}
