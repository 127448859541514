import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { vCallRecordHistory } from 'src/app/database-models';
import { CallRecordHistoryService } from '../call-record-history.service';
import { CallRecordHistoryDomain } from '../domain/call-record-history.domain';


@Component({
    templateUrl: './call-record-history-view.component.html',
})
export class CallRecordHistoryViewComponent extends CallRecordHistoryDomain implements OnInit, OnDestroy {
    showComponent: boolean = false;
    callRecordHistory: vCallRecordHistory[];
    viewSubscription: Subscription;
    @Input() skey: number;
    endDate: Date = undefined;
    startDate: Date = undefined;
    constructor(
        private callRecordHistoryService: CallRecordHistoryService,
        private dialog: RxDialog,
        private router: Router,
        private popup: RxPopup,
    ) { super(); }

    ngOnInit(): void {
        if (this.viewSubscription)
            this.viewSubscription.unsubscribe();
        if (this.skey != undefined) {
            this.startDate = new Date();
            this.endDate = new Date();
            this.viewSubscription = this.callRecordHistoryService.getBy([this.skey,this.startDate.toISOString(), this.endDate.toISOString(), 0, false, false]).subscribe(callRecordHistory => {
                this.callRecordHistory = callRecordHistory;
                this.showComponent = true;
            });
        }
        else{

        }
    }

    HidePracticeSelectionPopup(): void {
        document.body.className = "";
        this.popup.hide(CallRecordHistoryViewComponent);
      }

    ngOnDestroy(): void {
        if (this.viewSubscription)
            this.viewSubscription.unsubscribe();
        super.destroy();
    }
}
