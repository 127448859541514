import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('PageMyDoctor')
export class PageMyDoctor {
    constructor(pageMyDoctor?: PageMyDoctor )  {
        let properties = [ "createdById", "createdDateTime", "dayOfWeekId", "fromTime", "lunchFrom", "lunchTo", "modifiedById", "modifiedDateTime", "officeClosed", "officeOpen", "pageMyDoctorId", "practiceId", "serviceTypeId", "toTime",];
        for (let property of properties)
            if (pageMyDoctor && (pageMyDoctor[property] || pageMyDoctor[property] == 0))
                this[property] = pageMyDoctor[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @range(1,2147483647)
	@property('DayOfWeekId')
	dayOfWeekId : number =   undefined;
 
	@property('FromTime')
	fromTime : Date =   undefined;
 
	@property('LunchFrom')
	lunchFrom : Date =   undefined;
 
	@property('LunchTo')
	lunchTo : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('OfficeClosed')
	officeClosed : Date =   undefined;
 
	@property('OfficeOpen')
	officeOpen : Date =   undefined;
 
	@property('PageMyDoctorId')
	pageMyDoctorId : number =   0 ;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @range(1,2147483647)
	@property('ServiceTypeId')
	serviceTypeId : number =   undefined;
 
	@property('ToTime')
	toTime : Date =   undefined;



}
