import { Injectable } from '@angular/core';

@Injectable()
export class PracticeDomain {
    constructor() { }

    destroy(): void {

    }
    formatDate(d, t): any {
        let month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        let stringDate = [year, month, day].join('-') + "T" + t;
        return new Date(stringDate);
      }

    //   toTimeZone(time, zone) {
    //     var format = 'YYYY/MM/DD HH:mm:ss ZZ';
    //     return moment(time, format).tz(zone).format(format);
    // }
}
