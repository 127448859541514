import { ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import { PageAccess } from "src/app/domain/authorization";

const APPLICATION_ISSUES_ROUTES: Routes = [
	{
    path: 'add', 
	loadChildren: () => import('./add/application-issue-add.module').then(m => m.ApplicationIssueAddModule),
	canActivate: [PageAccess],
    data: { rootModuleId: 2041, pageName: 'application-issues', applicationModuleId: 2043, accessItem: 'add',  keyName: 'applicationIssueId', compositeKeys:[] }
	},
];

export const APPLICATION_ISSUES_ROUTING = RouterModule.forChild(APPLICATION_ISSUES_ROUTES);
