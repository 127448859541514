import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";
import {RxViewModule } from '@rx/view';
import {RxFormsModule } from '@rx/forms';
import {RxTableModule } from "@rx/table";
import {DynamicComponentContainer } from '@rx/core';

import { ApplicationIssueListComponent } from './list/application-issue-list.component'
import {ApplicationIssuesService } from "./application-issues.service";
import {APPLICATION_ISSUES_SHARED_COMPONENT_CONTAINER } from './application-issues-shared-component.container';
@NgModule({
    imports: [
        RxViewModule, RxFormsModule,
        CommonModule, FormsModule, ReactiveFormsModule, RxTableModule
    ],
    declarations: [ ApplicationIssueListComponent, ],
    providers: [ApplicationIssuesService ],
    exports: [RouterModule, ApplicationIssueListComponent, ]
})
export class ApplicationIssuesSharedComponentModule { }
DynamicComponentContainer.register(APPLICATION_ISSUES_SHARED_COMPONENT_CONTAINER );