import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vPracticeDetails')
export class vPracticeDetail {
    constructor(vPracticeDetail?: vPracticeDetail )  {
        let properties = [ "addressId", "addressLine1", "addressLine2", "backline", "backlineExt", "cell", "city", "email", "fax", "homePhone", "officePhone", "officePhoneExt", "other", "postalCode", "practiceId", "practiceName",];
        for (let property of properties)
            if (vPracticeDetail && (vPracticeDetail[property] || vPracticeDetail[property] == 0))
                this[property] = vPracticeDetail[property];
    }
 
    @range(1,2147483647)
	@property('AddressId')
	addressId : number =   undefined;
 
    @required()
    @maxLength(200)
	@property('AddressLine1')
	addressLine1 : string = undefined ;
 
    @maxLength(200)
	@property('AddressLine2')
	addressLine2 : string = undefined ;
 
    @maxLength(50)
	@property('Backline')
	backline : string = undefined ;
 
    @maxLength(10)
	@property('BacklineExt')
	backlineExt : string = undefined ;
 
    @maxLength(12)
	@property('Cell')
	cell : string = undefined ;
 
    @maxLength(60)
	@property('City')
	city : string = undefined ;
 
    @maxLength(100)
	@property('Email')
	email : string = undefined ;
 
    @maxLength(50)
	@property('Fax')
	fax : string = undefined ;
 
    @maxLength(50)
	@property('HomePhone')
	homePhone : string = undefined ;
 
    @maxLength(50)
	@property('OfficePhone')
	officePhone : string = undefined ;
 
    @maxLength(10)
	@property('OfficePhoneExt')
	officePhoneExt : string = undefined ;
 
    @maxLength(50)
	@property('Other')
	other : string = undefined ;
 
    @maxLength(5)
	@property('PostalCode')
	postalCode : string = undefined ;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('PracticeName')
	practiceName : string = undefined ;



}
