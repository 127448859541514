import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Address } from './address'; @table('Contacts')
export class Contact {
    constructor(contact?: Contact )  {
        let properties = [ "cell", "contactGroupId", "contactId", "contactRefId", "contactTypeId", "createdById", "createdDateTime", "email", "faxNumber", "homePhone", "modifiedById", "modifiedDateTime", "name", "officePhone", "officePhoneExt", "title", "addressId",];
        for (let property of properties)
            if (contact && (contact[property] || contact[property] == 0))
                this[property] = contact[property];
    }
 
    @maxLength(12)
	@property('Cell')
	cell : string = undefined ;
 
    @range(1,2147483647)
	@property('ContactGroupId')
	contactGroupId : number =   undefined;
 
	@property('ContactId')
	contactId : number =   0 ;
 
    @range(1,2147483647)
	@property('ContactRefId')
	contactRefId : number =   undefined;
 
    @range(1,2147483647)
	@property('ContactTypeId')
	contactTypeId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @maxLength(100)
@email()
	@property('Email')
	email : string = undefined ;
 
    @maxLength(50)
	@property('FaxNumber')
	faxNumber : string = undefined ;
 
    @maxLength(50)
	@property('HomePhone')
	homePhone : string = undefined ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @maxLength(100)
	@property('Name')
	name : string = undefined ;
 
    @maxLength(50)
	@property('OfficePhone')
	officePhone : string = undefined ;
 
    @maxLength(10)
@numeric()
@pattern('[0-9]')
	@property('OfficePhoneExt')
	officePhoneExt : string = undefined ;
 
    @maxLength(5)
@pattern('[0-9]')
	@property('Title')
	title : string = undefined ;
 
    @range(0,2147483647)
	@property('AddressId')
	addressId : number =   undefined;
	address : Address  ;



}
