import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Practice } from './practice'; @table('PracticeTypes')
export class PracticeType {
    constructor(practiceType?: PracticeType )  {
        let properties = [ "createdById", "createdDateTime", "modifiedById", "modifiedDateTime", "practiceTypeId", "practiceTypeName", "practices",];
        for (let property of properties)
            if (practiceType && (practiceType[property] || practiceType[property] == 0))
                this[property] = practiceType[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('PracticeTypeId')
	practiceTypeId : number =   0 ;
 
    @required()
    @maxLength(50)
	@property('PracticeTypeName')
	practiceTypeName : string = undefined ;
	@nested(Practice)
	practices: Practice[];




}
