import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TelehealthDashboardComponent } from './telehealth-dashboard.component';


const TELEHEALTH_DASHBOARD_ADD_ROUTES: Routes = [{
  path: '', component: TelehealthDashboardComponent
}];

export const TELEHEALTH_DASHBOARD_ADD_ROUTING = RouterModule.forChild(TELEHEALTH_DASHBOARD_ADD_ROUTES);
