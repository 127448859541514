import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('AhtDivert')
export class AhtDivert {
    constructor(ahtDivert?: AhtDivert )  {
        let properties = [ "ahtDivertId", "ahtProviderId", "createdById", "createdDateTime", "divertStartTime", "divertTill", "modifiedById", "modifiedDateTime", "practiceId",];
        for (let property of properties)
            if (ahtDivert && (ahtDivert[property] || ahtDivert[property] == 0))
                this[property] = ahtDivert[property];
    }
 
	@property('AhtDivertId')
	ahtDivertId : number =   0 ;
 
    @range(1,2147483647)
	@property('AhtProviderId')
	ahtProviderId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('DivertStartTime')
	divertStartTime : Date =   undefined;
 
	@property('DivertTill')
	divertTill : number =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;



}
