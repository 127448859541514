import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { CallDb } from 'src/app/database-models';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { CallManagerLookups } from 'src/app/lookups';
import { PHONE_TYPES, RELATIONSHIPS, DELIVERY_TYPES, NOTIFICATION_PREFERENCES } from 'src/app/database-collections';
import { CallDbLookupGroup, CallDbListModel } from 'src/app/components/call-manager/call-db/domain/call-db.models';
import { CustomValidation, RxValidation, RxMessageComponent } from '@rx/forms';

import { ApplicationBroadcaster, ApplicationConfiguration } from '@rx/core';
import { CallTypesEnum } from '../../../../const/call-type';
import { FormState } from '../../../../domain/form-state';
import { CallDbListComponent } from '../list/call-db-list.component';
import { CallDbViewComponent } from '../view/call-db-view.component';
import { user } from '@rx/security';
import { OperationTypesEnum } from '../../../../const/operation-type';
import { RecordStatusEnum, NotificationPreferencesEnum } from '../../../../enums';
import { TimeSource } from '../../../../const/time-source';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-call-db-newborn',
    templateUrl: './call-db-newborn.component.html',
    entryComponents: [CallDbListComponent, CallDbViewComponent, RxMessageComponent],
})
export class CallDbNewbornComponent extends CallDbDomain implements OnInit, OnDestroy, OnChanges {
    formControls: string[];
    focusElementFormControlName: string;
    birthTypeList: { "deliveryTypesId": number; "deliveryTypesName": string; }[];
    toBeAsked: any;
    babyDetail: CallDb;
    timeSource = TimeSource;
    callDbNewbornLookupGroupList: CallDb[]
    phoneTypeList: { "phoneTypesId": number; "phoneTypesName": string; }[];
    private sub: any;
    showComponent: boolean = false;
    callDb: CallDb[];
    newbornSubscription: Subscription;
    callDbNewbornFormGroup: FormGroup;
    callDbNewbornLookupGroup: CallDbLookupGroup;
    @Input() practiceId: number;
    validationFailed: {
        [key: string]: any;
    }
    patientId: any;
    isEdit: boolean;
    babyIndex: number = 1;
    babyList: any;
    currentIndex: any;
    notificationTypes: { "notificationPreferencesId": number; "notificationPreferencesName": string; }[];
    newBorn: any;
    idList: any[];
    callEndDate: Date;
    isContinueClicked: boolean = true;
    constructor(
        private route: ActivatedRoute,
        private callDbService: CallDbService,
        private dialog: RxDialog,
        private validation: RxValidation,
        private applicationBroadcaster: ApplicationBroadcaster,
        private router: Router,
        private popup: RxPopup,
        private componentResolver: ComponentFactoryResolver,
        private toast: RxToast,
    ) {
        super();
        this.validationFailed = {};
        this.popup.setComponent(componentResolver)
        this.formControls = ["cFirstName", "cLastName", "hospitalId", "room", "cBNumberP", "borG", "goodBaby", "chFirstName", "chLastName", "mFirstName", "mLastName",
            "TermBaby", "gestation", "bPediatrician", "birthDate", "apgars1Min", "weight", "birthType", "textMessage", "serviceSubmittedUnder","insurance"];
    }

  ngOnInit(): void {
    
        this.callEndDate = new Date();
        this.showComponent = false
        if (localStorage.getItem('callId')) {
            this.patientId = parseInt(localStorage.getItem('callId'));
            this.applicationBroadcaster.patientIdBroadCast(this.patientId);
            this.isEdit = true;
        }
        else
            this.callDbService.node$.subscribe(n => this.patientId = n);
        this.sub = this.route.params.subscribe(params => {
            this.practiceId = params['practiceId'];
        });
        if (this.newbornSubscription)
            this.newbornSubscription.unsubscribe();
        this.phoneTypeList = PHONE_TYPES;
        // this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM);
        this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x => x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM && x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage);
      this.birthTypeList = DELIVERY_TYPES;
      
        if (this.patientId && this.patientId != 0) {
            this.callDbService.group(this.practiceId, [CallTypesEnum.Newborns, this.patientId, 0], [CallManagerLookups.providers, CallManagerLookups.hospitals]).then((response: any) => {
                this.callDbNewbornLookupGroup = new CallDbLookupGroup();
                this.callDbNewbornLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                this.callDbNewbornLookupGroup.hospitals = response.hospitals.where(x => x.practiceId == this.practiceId);
                this.callDbNewbornLookupGroup.callDb = new CallDb(JSON.parse(response.CallDb[0].callDb)[0]);
                let data = FormState.getState(this.patientId);
                if (data) {
                    // data.birthTime = this.timeConversionTo24Hour(data.holdUntillTime);
                    this.callDbNewbornLookupGroup.callDb = data
                }
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                let jsonData = JSON.parse(response.CallDb[0].annotationProps);
                jsonData['birthTime'] = { 'required': jsonData['birthDate']['required'] };
                jsonData['goodBaby'] = { 'required': false };
                jsonData['weight'] = { 'required': jsonData['weight']['required'] };
                jsonData['apgars10Min'] = { 'numeric': true };
                jsonData['apgars1Min'] = { 'required': jsonData['apgars1Min']['required'], 'numeric': true };
                jsonData['apgars5min'] = { 'required': jsonData['apgars1Min']['required'], 'numeric': true };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: jsonData,
                });
                if (this.callDbNewbornFormGroup) {
                    this.callDbNewbornFormGroup = undefined;
                }
                if (this.callDbNewbornLookupGroup.callDb.birthTime != null && this.callDbNewbornLookupGroup.callDb.birthTime != undefined && this.callDbNewbornLookupGroup.callDb.birthTime.toString().match("T")) {

                    let data = new Date(this.callDbNewbornLookupGroup.callDb.birthTime).toLocaleTimeString();
                    this.callDbNewbornLookupGroup.callDb.birthTime = this.timeConversionTo12Hour(data);
                }
                this.callDbNewbornFormGroup = this.validation.getFormGroup(this.callDbNewbornLookupGroup.callDb, customValidation);
                this.callDbNewbornFormGroup.controls.birthTime.setValue(this.callDbNewbornLookupGroup.callDb.birthTime);
                this.callDbNewbornFormGroup.controls.currentCallProviderId.setValue(null);
              this.callDbNewbornFormGroup.controls.currentCallNotPref.setValue(null);
          
                this.babyList = [{ 'data': this.callDbNewbornFormGroup.value, 'id': 1 }];
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.Newborns);
                for (let i = 0; i < this.formControls.length; i++) {
                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                this.showComponent = true;
                this.ngOnChanges();
            });
        } else {
            this.callDbService.group(this.practiceId, [CallTypesEnum.Newborns, 0, 0], [CallManagerLookups.providers, CallManagerLookups.hospitals]).then((response: any) => {
                this.callDbNewbornLookupGroup = new CallDbLookupGroup();
                this.callDbNewbornLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                this.callDbNewbornLookupGroup.hospitals = response.hospitals.where(x => x.practiceId == this.practiceId);
                let data = FormState.getState(0);
                if (data) {
                    // data.birthTime = this.timeConversionTo24Hour(data.birthTime);
                    this.callDbNewbornLookupGroup.callDb = data;
                } else {
                    this.callDbNewbornLookupGroup.callDb = new CallDb();
                    this.callDbNewbornLookupGroup.callDb.practiceId = this.practiceId
                    this.callDbNewbornLookupGroup.callDb.callTypeId = CallTypesEnum.Newborns;
                }
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                let jsonData = JSON.parse(response.CallDb[0].annotationProps);
                jsonData['birthTime'] = { 'required': jsonData['birthDate']['required'] };
                jsonData['goodBaby'] = { 'required': false };
                jsonData['weight'] = { 'required': jsonData['weight']['required'] };
                jsonData['apgars10Min'] = { 'numeric': true };
                jsonData['apgars1Min'] = { 'required': jsonData['apgars1Min']['required'], 'numeric': true };
                jsonData['apgars5min'] = { 'required': jsonData['apgars1Min']['required'], 'numeric': true };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: jsonData,
                });
                if (this.callDbNewbornFormGroup) {
                    this.callDbNewbornFormGroup = undefined;
                }
                this.callDbNewbornFormGroup = this.validation.getFormGroup(this.callDbNewbornLookupGroup.callDb, customValidation);
                this.callDbNewbornFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbNewbornFormGroup.controls.currentCallNotPref.setValue(null);
                this.callDbNewbornFormGroup.controls.goodBaby.setValue(1);
                this.callDbNewbornFormGroup.controls.borG.setValue('BB');
                this.callDbNewbornFormGroup.controls.birthTime.setValue(this.callDbNewbornLookupGroup.callDb.birthTime);
                this.babyList = [{ 'data': this.callDbNewbornFormGroup.value, 'id': 1 }];
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.Newborns);
                for (let i = 0; i < this.formControls.length; i++) {
                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                this.showComponent = true;
                this.ngOnChanges();
            });
        }
    }

    checkDate(value) {
        let date = new Date();
        if (value < date) {
            let age = this.calculateAge(value);
            this.callDbNewbornFormGroup.controls.age.setValue(age);
        }
        else {
            this.callDbNewbornFormGroup.controls.age.setValue(0);
        }
    }

    ngOnChanges(): void {
        this.callDbNewbornFormGroup.valueChanges.subscribe(val => {
            FormState.setState(this.callDbNewbornFormGroup.value.sKey, this.callDbNewbornFormGroup);
        });
    }

    showPopup(value) {
        this.popup.show(CallDbListComponent)
            .then(t => {
                if (t['patientId'])
                    this.patientId = t['patientId'];
                if (t['callDbData']) {
                    this.newBorn = t['callDbData'];
                    let data = new CallDb(JSON.parse(this.newBorn.callDb)[0])
                    this.callDbNewbornFormGroup.setValue(JSON.parse(this.newBorn.callDb)[0]);
                }
            })
    }

  continue() {
        this.babyList[this.babyIndex - 1].data = this.callDbNewbornFormGroup.value
        let dateValidationCount = [];
        if (!this.callDbNewbornFormGroup.value.multipleBirth) {
            this.babyList = [{ 'data': this.callDbNewbornFormGroup.value, 'id': 1 }];
        }
        if (this.babyList.length > 0) {
            this.babyList.forEach(element => {
                if (element.data.birthDate > new Date()) {
                    dateValidationCount.push({ 'id': element.id });
                }
                else {
                    element.data.operationType = OperationTypesEnum.EditData.toString();
                    element.data.sKey = (element.data.sKey) ? element.data.sKey : 0;
                    element.data.userName = user.data['userName'];
                    element.data.agentId = user.data['userId'];
                    element.data.cStartTimeDate = new Date();
                    element.data.cStartDate = this.callEndDate.toISOString();
                    element.data.callTypeId = CallTypesEnum.Newborns;
                    element.data.referenceNumber = undefined;
                    element.data.admissionTime = undefined;
                    element.data.admissionDate = undefined;
                    element.data.holdTillDt = undefined;
                    element.data.holdUntillTime = undefined;
                    element.data.onCallType = 0;
                }
            });
        }
        if (dateValidationCount.length > 0) {
            this.toast.show(ApplicationConfiguration.get("toastValidationMessages.birthDate"), { status: 'error' });
        }
        else {
            if (this.babyList.length > 1) {

                this.callDbNewbornLookupGroup.callDbArray = new CallDbListModel();
                this.callDbNewbornLookupGroup.callDbArray.CallDbArray = new Array<CallDb>();
                this.callDbNewbornLookupGroup.callDbArray.isEdit = this.isEdit ? this.isEdit : false;
                let Query = { 'CallDbArray': this.babyList, 'isEdit': this.isEdit ? this.isEdit : false }
                this.newbornSubscription = this.callDbService.search(this.practiceId, Query).subscribe(t => {
                    t['callDbArray'].forEach(element => {
                        if (element.data.birthTime) {
                            let time = new Date(element.data.birthTime);
                            element.data.birthTime = this.timeConversionTo12Hour(('0' + time.getHours()).slice(-2) + ':' + ('0' + time.getMinutes()).slice(-2) + ':' + ('0' + time.getSeconds()).slice(-2));
                        }
                        element.data['cBNumberP'] = element.data['cbNumberP'];
                    });
                    this.babyList = t['callDbArray']
                    this.isEdit = false;
                    FormState.remove(this.patientId);
                    localStorage.removeItem('callId');
                    let spResult = t['callDbArray'].where(x => x.parentRefNumber == null)[0];
                    this.patientId = spResult['data'].sKey;
                    this.babyIndex = 1;
                    this.currentIndex = 1;
                    this.callDbNewbornFormGroup.reset(spResult['data']);
                    this.callDbNewbornFormGroup.controls.cBNumberP.setValue(spResult['data']['cbNumberP']);
                    let skeyList = [];
                    this.babyList.forEach(element => {
                        // element['cBNumberP']  =  element['cbNumberP'];
                        skeyList.push(element.data.sKey);
                    });
                    this.popup.show(CallDbViewComponent, { sKey: spResult['data'].sKey, newBornSkeyList: skeyList, callTypeId: CallTypesEnum.Newborns, spResult: spResult['data'].spResult })
                        .then(t => {
                            if (t.isEdit) {
                                this.isContinueClicked = true;
                                this.isEdit = t.isEdit;
                                FormState.setState(this.callDbNewbornFormGroup.value.sKey, this.callDbNewbornFormGroup);
                            }
                            else {
                                FormState.remove(this.patientId);
                                this.callDbNewbornFormGroup.reset();
                                this.callDbService.addNode(0);
                                this.callDbNewbornFormGroup = this.setDefaultValue(this.callDbNewbornFormGroup, CallTypesEnum.Newborns);
                                this.callDbNewbornFormGroup.controls.callBack.setValue(false);
                                this.callDbNewbornFormGroup.controls.multipleBirth.setValue(false);
                                this.callDbNewbornFormGroup.controls.borG.setValue('BB');
                                this.babyList = [{ 'data': this.callDbNewbornFormGroup.value, 'id': 1 }];

                                let userData = user.data;
                                userData.practiceId = null;
                                userData.practiceName = null;
                                user.data = userData;
                                this.router.navigate(['dashboard']);
                                this.applicationBroadcaster.practiceSelectionBroadCast(false);
                            }
                        });
                });
            }
            else {
                let data = new CallDb(this.callDbNewbornFormGroup.value);
                data.userName = user.data['userName'];
                data.agentId = user.data['userId'];
                data.cStartTimeDate = new Date();
                data.referenceNumber = null;
                data.admissionTime = undefined;
                data.admissionDate = undefined;
                data.holdTillDt = undefined;
                data.holdUntillTime = undefined;
                if (data.birthTime) {
                    data.birthTime = this.timeConversionTo24Hour(data.birthTime);
                }
                if (data.birthDate > new Date()) {
                    this.toast.show(ApplicationConfiguration.get("toastValidationMessages.birthDate"), { status: 'error' });
                } else {
                    if (this.isEdit) {
                        data.operationType = OperationTypesEnum.EditData.toString();
                        data.sKey = this.patientId;
                        data.callTypeId = CallTypesEnum.Newborns;
                        this.newbornSubscription = this.callDbService.put(this.practiceId, data).subscribe(t => {
                            this.isEdit = false;
                            this.patientId = t.sKey;
                            this.callDbNewbornFormGroup.controls.sKey.setValue(t.sKey);
                            FormState.remove(this.patientId);
                            localStorage.removeItem('callId');
                            this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.Newborns, spResult: t.spResult })
                                .then(t => {
                                    if (t.isEdit) {
                                        this.isContinueClicked = true;
                                        this.isEdit = t.isEdit;
                                        FormState.setState(this.callDbNewbornFormGroup.value.sKey, this.callDbNewbornFormGroup);
                                    }
                                    else {
                                        FormState.remove(this.patientId);
                                        this.callDbNewbornFormGroup.reset();
                                        this.callDbService.addNode(0);
                                        this.callDbNewbornFormGroup = this.setDefaultValue(this.callDbNewbornFormGroup, CallTypesEnum.Newborns);
                                        this.callDbNewbornFormGroup.controls.callBack.setValue(false);
                                        this.callDbNewbornFormGroup.controls.multipleBirth.setValue(false);
                                        this.callDbNewbornFormGroup.controls.borG.setValue('BB');
                                        this.babyList = [{ 'data': this.callDbNewbornFormGroup.value, 'id': 1 }];
                                        let userData = user.data;
                                        userData.practiceId = null;
                                        userData.practiceName = null;
                                        user.data = userData;
                                        this.router.navigate(['dashboard']);
                                        this.applicationBroadcaster.practiceSelectionBroadCast(false);
                                    }
                                });
                        },
                            error => {
                                this.popup.validationFailed(error);
                            })
                    } else {
                        data.sKey = 0;
                        this.newbornSubscription = this.callDbService.post(this.practiceId, data).subscribe(t => {
                            this.patientId = t.sKey;
                            this.callDbNewbornFormGroup.controls.sKey.setValue(t.sKey);
                            FormState.remove(this.patientId);
                            localStorage.removeItem('callId');
                            this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.Newborns, spResult: t.spResult })
                                .then(t => {
                                    if (t.isEdit) {
                                        this.isContinueClicked = true;
                                        this.isEdit = t.isEdit;
                                        FormState.setState(this.callDbNewbornFormGroup.value.sKey, this.callDbNewbornFormGroup);
                                    }
                                    else {
                                        FormState.remove(this.patientId);
                                        this.callDbNewbornFormGroup.reset();
                                        this.callDbService.addNode(0);
                                        this.callDbNewbornFormGroup = this.setDefaultValue(this.callDbNewbornFormGroup, CallTypesEnum.Newborns);
                                        this.callDbNewbornFormGroup.controls.callBack.setValue(false);
                                        this.callDbNewbornFormGroup.controls.multipleBirth.setValue(false);
                                        this.callDbNewbornFormGroup.controls.borG.setValue('BB');
                                        this.babyList = [{ 'data': this.callDbNewbornFormGroup.value, 'id': 1 }];
                                        let userData = user.data;
                                        userData.practiceId = null;
                                        userData.practiceName = null;
                                        user.data = userData;
                                        this.router.navigate(['dashboard']);
                                        this.applicationBroadcaster.practiceSelectionBroadCast(false);
                                    }
                                });
                        },
                            error => {
                                this.popup.validationFailed(error);
                            })
                    }
                }
            }
        }
    }

    isContinue() {
      if (this.callDbNewbornFormGroup.valid && this.isContinueClicked) {         
            this.isContinueClicked = false;
            this.continue();
        }
    }

    changeBabyDetails(index) {
        this.babyList[this.currentIndex - 1].data = this.callDbNewbornFormGroup.value
        this.callDbNewbornFormGroup.reset(this.babyList[parseInt(index) - 1].data);
        this.currentIndex = parseInt(index);

    }

    clearFormGroup(): void {

        this.callDbService.addNode(0);
        this.isEdit = false;
        this.callDbNewbornFormGroup.reset();
        this.callDbNewbornFormGroup = this.setDefaultValue(this.callDbNewbornFormGroup, CallTypesEnum.Newborns);
        this.callDbNewbornFormGroup.controls.callBack.setValue(false);
        this.callDbNewbornFormGroup.controls.multipleBirth.setValue(false);
        this.callDbNewbornFormGroup.controls.borG.setValue('BB');
        if (this.patientId)
            FormState.remove(this.patientId);
        else
            FormState.remove(0);
        this.applicationBroadcaster.patientIdBroadCast(0);
        this.babyList = [{ 'data': this.callDbNewbornFormGroup.value, 'id': 1 }];
        this.currentIndex = null;
        this.babyIndex = 1;
        localStorage.removeItem('callId');

        let userData = user.data;
        userData.practiceId = null;
        userData.practiceName = null;
        user.data = userData;
        this.router.navigate(['dashboard']);
        this.applicationBroadcaster.practiceSelectionBroadCast(false);
    }

    changeBorG(event) {
        if (event.currentTarget.checked)
            this.callDbNewbornFormGroup.controls.borG.setValue('BB');
        else
            this.callDbNewbornFormGroup.controls.borG.setValue('BG');
    }

    changeTerm(event) {
        if (event.currentTarget.checked)
            this.callDbNewbornFormGroup.controls.gestation.setValue(undefined);
        else
            this.callDbNewbornFormGroup.controls.gestation.setValue('Term');
    }

    addAnotherBabyDetails(babyListLength) {
        if (babyListLength == 1)
            this.babyList[babyListLength - 1].data = this.callDbNewbornFormGroup.value;
        else
            this.babyList[this.currentIndex - 1].data = this.callDbNewbornFormGroup.value

        this.callDbNewbornFormGroup.controls.chFirstName.setValue(null);
        this.callDbNewbornFormGroup.controls.borG.setValue('BB');
        this.callDbNewbornFormGroup.controls.apgars1Min.setValue(null);
        this.callDbNewbornFormGroup.controls.apgars5min.setValue(null);
        this.callDbNewbornFormGroup.controls.apgars10Min.setValue(null);
        this.callDbNewbornFormGroup.controls.weight.setValue(null);
        this.callDbNewbornFormGroup.controls.sKey.setValue(0);
        this.babyList.push({ 'data': this.callDbNewbornFormGroup.value, 'id': this.babyList.length + 1 });
        this.babyIndex++;
        this.currentIndex = this.babyIndex;
        this.babyIndex = this.babyList.length
    }

    ngOnDestroy(): void {
        if (this.newbornSubscription)
            this.newbornSubscription.unsubscribe();
        super.destroy();
    }
}
