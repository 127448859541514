import { Injectable } from '@angular/core';

@Injectable()
export class CallRecordHistoryDomain {
    constructor() { }

    destroy(): void {

    }
}
