import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Practice } from './practice'; @table('AhtProviders')
export class AhtProvider {
    constructor(ahtProvider?: AhtProvider )  {
        let properties = [ "afterHourTriageContact", "ahtProviderId", "ahtProviderMasterId", "createdById", "createdDateTime", "instructions", "isActive", "modifiedById", "modifiedDateTime", "outboundCallsOnly", "practiceAccount", "practiceId",];
        for (let property of properties)
            if (ahtProvider && (ahtProvider[property] || ahtProvider[property] == 0))
                this[property] = ahtProvider[property];
    }
 
    @required()
    @maxLength(100)
	@property('AfterHourTriageContact')
	afterHourTriageContact : string = undefined ;
 
	@property('AhtProviderId')
	ahtProviderId : number =   0 ;
 
    @range(1,2147483647)
	@property('AhtProviderMasterId')
	ahtProviderMasterId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @maxLength(500)
	@property('Instructions')
	instructions : string = undefined ;
 
	@property('IsActive')
	isActive : boolean = false ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('OutboundCallsOnly')
	outboundCallsOnly : boolean = false ;
 
    @maxLength(50)
@maxLength(50)
	@property('PracticeAccount')
	practiceAccount : string = undefined ;
 
    @range(0,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
	practice : Practice  ;



}
