import { Injectable } from '@angular/core';
import {Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi} from "@rx/security";

import {  Alert, vAlert, } from 'src/app/database-models';
import { AlertLookupGroup } from './domain/alert.models';

@Injectable()
export class AlertsService {
	private practiceId: number;

	private get api(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/Practices/${this.practiceId}/Alerts`,
            // childModuleName: 'alerts',
            applicationModuleId :12,
            keyName:'alertId'
	
        }
        return authorizeApi;
    }
    constructor(
        private http: RxHttp
    ) { }

    lookup<AlertLookupGroup>(lookupActions: LookupAction[]): Promise<AlertLookupGroup> {
        return this.http.lookup<AlertLookupGroup>(lookupActions);
    }

    group<AlertLookupGroup>(practiceId : number, params: any[] | {
        [key: string]: any;
    } | RequestQueryParams, lookupActions: LookupAction[]): Promise<AlertLookupGroup> {
		this.practiceId = practiceId;
        return this.http.group<AlertLookupGroup>(this.api, params, 'Alert', lookupActions);
    }

	search(practiceId : number, search: any): Observable<vAlert[]> {
		this.practiceId = practiceId;
        return this.http.search<vAlert[]>(this.api, search);
    }

    get(practiceId : number  ): Observable<vAlert[]> {
		this.practiceId = practiceId;
        return this.http.get<vAlert[]>(this.api);
    }

    getBy(practiceId : number, params?: any[] | {
        [key: string]: any;
    } | RequestQueryParams): Observable<any> {
		this.practiceId = practiceId;
        return this.http.get<any>(this.api, params);
    }

    post(practiceId : number, alert: Alert): Observable<Alert> {
		this.practiceId = practiceId;
        return this.http.post<Alert>(this.api, alert);
    } 

    put(practiceId : number, alert: Alert): Observable<Alert> {
		this.practiceId = practiceId;
        return this.http.put<Alert>(this.api, alert);
    }

    delete(practiceId : number, id : number): Observable<Alert> {
		this.practiceId = practiceId;
        return this.http.delete<Alert>(this.api,id);
    }

	filterLookup<T>(lookup:LookupAction,filterParameters:any[],queryParams?: {[key:string]:any}):Observable<T> {
        return this.http.filterLookup<T>(lookup,filterParameters,queryParams);
    }

}
