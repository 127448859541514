import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Practice } from './practice';import { Provider } from './provider'; @table('OnCallRosters')
export class OnCallRoster {
    constructor(onCallRoster?: OnCallRoster )  {
        let properties = [ "createdById", "createdDateTime", "earlyAhtTime", "modifiedById", "modifiedDateTime", "onCallDate", "onCallRosterId", "onCallTime", "remarks", "practiceId", "providerId", "isCurrentRoster","secondaryProviderIds","secondaryProviderName"];
        for (let property of properties)
            if (onCallRoster && (onCallRoster[property] || onCallRoster[property] == 0))
                this[property] = onCallRoster[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('EarlyAhtTime')
	earlyAhtTime : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @required()
	@property('OnCallDate')
	onCallDate : Date =   undefined;
 
	@property('OnCallRosterId')
	onCallRosterId : number =   0 ;
 
    @required()
	@property('OnCallTime')
	onCallTime : Date =   undefined;
 
    @maxLength(500)
	@property('Remarks')
	remarks : string = undefined ;
 
    @range(0,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
	practice : Practice  ;
 
    @range(0,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
	provider : Provider  ;

	@property('SecondaryProviderName')
	secondaryProviderName : any =   undefined;
	@property('SecondaryProviderIds')
	secondaryProviderIds : any =   undefined;

	isCurrentRoster : string =   undefined;

}
