import { Component } from "@angular/core";
import { ApplicationBroadcaster, ApplicationConfiguration } from "@rx/core";
import { RxStorage, RxPopup } from "@rx";
import { Router, ActivatedRoute } from "@angular/router";
import { user } from "@rx/security";
import { AlertSearchComponent } from "../agent-alert/alerts/search/alert-search.component";
import { CallDbSearchComponent } from "../call-manager/call-db/search/call-db-search.component";
import { CallDbService } from "../call-manager/call-db/call-db.service";
import { Subscription } from "rxjs";
import { PageQueryViewModel } from "src/app/view-models/page-query-view-model";

@Component({
  templateUrl: './dashboard.component.html',
  entryComponents: [CallDbSearchComponent]
})
export class DashboardComponent {
  showComponent: boolean = false;
  callDb: any;
  anyPracticeSelected: boolean = false;
  practiceId: any;
  callTypeId: number = undefined;
  searchSubscription: Subscription;
  isCallDbList: boolean = false;
  callDbList: any;
  tableConfiguration: any;
  pageQueryModel = new PageQueryViewModel();
  constructor(
    private callDbService: CallDbService,
    private storage: RxStorage,
    private router: Router,
    private popup: RxPopup,
    private applicationBroadcaster: ApplicationBroadcaster,
    private activatedRoute: ActivatedRoute
  ) {
    this.tableConfiguration = ApplicationConfiguration.get('control.rxTable');
    var auth = this.storage.local.get("auth");
    if (auth) {

    }
    else
      this.router.navigate(["login"]);

    this.applicationBroadcaster.isPracticeSelectedSubscriber.subscribe(value => {
      if (this.activatedRoute.snapshot.children.length > 0) {
        this.ngOnInit();
      }
    })
  }

  ngOnInit(): void {

    if (localStorage.getItem("isUnlockedUser") == "true") {
      this.router.navigate(["/users/profile/" + user.data['userId']]);
    }
    console.log("dashboard");
    this.practiceId = user.data['practiceId'];
    this.anyPracticeSelected = true;
    this.searchSubscription = this.callDbService.get(1, ['', '', '', 6]).subscribe(callDb => {
      console.log("dashboard-call");
      this.isCallDbList = true;
      this.callDb = callDb[0];
      this.showComponent = true;
    });
  }

  formatDate(date) {
    if (date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    }
    else {
      return '';
    }
  }

  ngOnDestroy(): void {
    if (this.searchSubscription)
      this.searchSubscription.unsubscribe();
  }

  showPopup() {
    //this.popup.show(AlertSearchComponent).then(t => this.ngOnInit());;
    window.open("#/alerts/search", '_blank', 'location=yes,scrollbars=yes,status=yes')
    //this.router.navigate(['alerts/search'])
  }

  showCallDbPopup(popupType: number, title: string) {
    this.popup.show(CallDbSearchComponent, { popupType: popupType, popupTitle: title }).then(t => {
      this.ngOnInit()
    });

  }
}

