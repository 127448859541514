import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { RxValidation, CustomValidation, RxMessageComponent } from '@rx';
import { ApplicationBroadcaster, ApplicationConfiguration } from '@rx/core';
import { NOTIFICATION_PREFERENCES, PHONE_TYPES } from '../../../../database-collections';
import { CallDbViewComponent } from '../view/call-db-view.component';
import { CallDbListComponent } from '../list/call-db-list.component';
import { collectExternalReferences } from '@angular/compiler';
import { user } from '@rx/security';
import { CallTypesEnum } from '../../../../const/call-type';
import { OperationTypesEnum } from '../../../../const/operation-type';
import { FormState } from '../../../../domain/form-state';
import { RecordStatusEnum, NotificationPreferencesEnum } from '../../../../enums';
import { TimeSource } from '../../../../const/time-source';
import { FormGroup } from '@angular/forms';
import { CallDb } from 'src/app/database-models';
import { CallManagerLookups } from 'src/app/lookups';
import { CallDbLookupGroup } from '../domain/call-db.models';


@Component({
    selector: 'app-call-db-doctor',
    templateUrl: './call-db-doctor.component.html',
    entryComponents: [CallDbListComponent, CallDbViewComponent, RxMessageComponent]
})
export class CallDbDoctorComponent extends CallDbDomain implements OnInit, OnDestroy, OnChanges {
    formControls: string[];
    focusElementFormControlName: string;
    phoneTypeList: any;
    timeSource = TimeSource;
    toBeAsked: any = [];
    private sub: any;
    validationFailed: {
        [key: string]: any;
    }
    callDbDoctorFormGroup: FormGroup;
    callDbDoctorLookupGroup: CallDbLookupGroup;
    showComponent: boolean = false;
    callDb: CallDb[];
    doctorSubscription: Subscription;
    patientId: any;
    @Input() practiceId: number;
    notificationTypes: { "notificationPreferencesId": number; "notificationPreferencesName": string; }[];
    isEdit: boolean;
    doctor: any;
    callEndDate : Date= undefined;
    isContinueClicked: boolean = true;
    constructor(
        private route: ActivatedRoute,
        private callDbService: CallDbService,
        private dialog: RxDialog,
        private validation: RxValidation,
        private router: Router,
        private applicationBroadcaster: ApplicationBroadcaster,
        private toast: RxToast,
        private popup: RxPopup,
        private componentResolver: ComponentFactoryResolver,
    ) {
        super(); this.validationFailed = {};
        this.popup.setComponent(componentResolver);
        this.formControls = ["cFirstName", "cLastName", "facilityName", "cBNumberP", "cBNumberS", "chFirstName", "chLastName", "birthDate", "primaryPhysicianId", "holdTillDt", "textMessage","hospitalId",];
    }

    ngOnInit(): void {
        // this.route.queryParams.subscribe(t => {
        //     if (t["patientId"])
        //         this.patientId = t["patientId"];
        //     if (t['isEdit'])
        //         this.isEdit = t['isEdit'];
        // })
        this.showComponent = false
        this.callEndDate = new Date();
        if (localStorage.getItem('callId')) {
            this.patientId = parseInt(localStorage.getItem('callId'));
            this.applicationBroadcaster.patientIdBroadCast(this.patientId);
            this.isEdit = true;
        }
        else
            this.callDbService.node$.subscribe(n => this.patientId = n);

        this.sub = this.route.params.subscribe(params => {
            this.practiceId = params['practiceId'];
        });
        this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM && x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage);
        if (this.doctorSubscription)
            this.doctorSubscription.unsubscribe();
        if (this.patientId && this.patientId != 0) {
            this.callDbService.group(this.practiceId, [CallTypesEnum.DoctorToDoctor, this.patientId, 0], [CallManagerLookups.providers,CallManagerLookups.hospitals]).then((response: any) => {
                this.phoneTypeList = PHONE_TYPES;
                // this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM);
                this.callDbDoctorLookupGroup = new CallDbLookupGroup();
                this.callDbDoctorLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                this.callDbDoctorLookupGroup.hospitals = response.hospitals.where(x => x.practiceId == this.practiceId);
              
                this.callDbDoctorLookupGroup.callDb = new CallDb(JSON.parse(response.CallDb[0].callDb)[0]);
                let data = FormState.getState(this.patientId);
                if (data) {
                    // data.holdUntillTime = this.timeConversionTo24Hour(data.holdUntillTime);
                    this.callDbDoctorLookupGroup.callDb = data
                }
                let jsonData = JSON.parse(response.CallDb[0].annotationProps);
                // jsonData['holdUntillTime'] = { 'required': jsonData['holdTillDt']['required'] };
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: jsonData,
                });
                if (this.callDbDoctorFormGroup) {
                    this.callDbDoctorFormGroup = undefined;
                }
                this.callDbDoctorFormGroup = this.validation.getFormGroup(this.callDbDoctorLookupGroup.callDb, customValidation);
                this.callDbDoctorFormGroup.controls.holdUntillTime.setValue(this.callDbDoctorLookupGroup.callDb.holdUntillTime);
                this.callDbDoctorFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbDoctorFormGroup.controls.currentCallNotPref.setValue(null);
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.DoctorToDoctor);
                for (let i = 0; i < this.formControls.length; i++) {
                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                this.showComponent = true;
                this.ngOnChanges();
            });
        } else {
            this.callDbService.group(this.practiceId, [CallTypesEnum.DoctorToDoctor, 0, 0], [CallManagerLookups.providers,CallManagerLookups.hospitals]).then((response: any) => {
                this.phoneTypeList = PHONE_TYPES;
                // this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM);
                this.callDbDoctorLookupGroup = new CallDbLookupGroup();
                this.callDbDoctorLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                this.callDbDoctorLookupGroup.hospitals = response.hospitals.where(x => x.practiceId == this.practiceId);
                let data = FormState.getState(0);
                if (data) {
                    // data.holdUntillTime = this.timeConversionTo24Hour(data.holdUntillTime);
                    this.callDbDoctorLookupGroup.callDb = data;
                } else {
                    this.callDbDoctorLookupGroup.callDb = new CallDb();
                    this.callDbDoctorLookupGroup.callDb.practiceId = this.practiceId
                    this.callDbDoctorLookupGroup.callDb.callTypeId = CallTypesEnum.DoctorToDoctor;
                }
                let jsonData = JSON.parse(response.CallDb[0].annotationProps);
                //jsonData['holdUntillTime'] = { 'required': jsonData['holdTillDt']['required'] };
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: jsonData,
                });
                if (this.callDbDoctorFormGroup) {
                    this.callDbDoctorFormGroup = undefined;
                }
                this.callDbDoctorFormGroup = this.validation.getFormGroup(this.callDbDoctorLookupGroup.callDb, customValidation);
                this.callDbDoctorFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbDoctorFormGroup.controls.currentCallNotPref.setValue(null);
                // this.callDbDoctorFormGroup.controls.holdUntillTime.setValue(this.callDbDoctorLookupGroup.callDb.holdUntillTime);
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.DoctorToDoctor);
                for (let i = 0; i < this.formControls.length; i++) {
                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                this.showComponent = true;
                this.ngOnChanges();
            });
        }

    }

    ngOnChanges(): void {
        this.callDbDoctorFormGroup.valueChanges.subscribe(val => {
            FormState.setState(this.callDbDoctorFormGroup.value.sKey, this.callDbDoctorFormGroup);
        });
    }

    showPopup(value) {
        this.popup.show(CallDbListComponent)
            .then(t => {
                if (t['patientId'])
                    this.patientId = t['patientId'];
                if (t['callDbData']) {
                    this.doctor = t['callDbData'];
                    let data = new CallDb(JSON.parse(this.doctor.callDb)[0])
                    this.callDbDoctorFormGroup.setValue(JSON.parse(this.doctor.callDb)[0]);
                }
            })
    }

    isContinue() {
        if (this.callDbDoctorFormGroup.valid && this.isContinueClicked ){
            this.isContinueClicked = false;
            this.continue();}
    }

    continue() {
        let dataCompare = new Date();
        let isDateTrue = false;


        // if (this.callDbDoctorFormGroup.value.holdTillDt) {
        //     dataCompare = this.mergeDateTime(this.callDbDoctorFormGroup.value.holdTillDt, this.callDbDoctorFormGroup.value.holdUntillTime);
        //     isDateTrue = dataCompare < new Date();
        // }

        if (this.callDbDoctorFormGroup.value.birthDate > new Date()) {
            this.toast.show(ApplicationConfiguration.get("toastValidationMessages.birthDate"), { status: 'error' });
        }
        // else if (isDateTrue) {
        //     this.toast.show('past Date/Time for Hold Until Date/Time" can not be selected', { status: 'error' });
        // }
        else {
            let callDbObje = new CallDb();

            callDbObje.cFirstName = this.callDbDoctorFormGroup.controls.cFirstName.value;
            callDbObje.cLastName = this.callDbDoctorFormGroup.controls.cLastName.value;
            callDbObje.hospitalId = this.callDbDoctorFormGroup.controls.hospitalId.value;
            callDbObje.cBNumberP = this.callDbDoctorFormGroup.controls.cBNumberP.value;
            callDbObje.cBNumberS = this.callDbDoctorFormGroup.controls.cBNumberS.value;
            callDbObje.chFirstName = this.callDbDoctorFormGroup.controls.chFirstName.value;
            callDbObje.chLastName = this.callDbDoctorFormGroup.controls.chLastName.value;
            callDbObje.birthDate = this.callDbDoctorFormGroup.controls.birthDate.value;
            callDbObje.age = this.callDbDoctorFormGroup.controls.age.value;
            callDbObje.primaryPhysicianId = this.callDbDoctorFormGroup.controls.primaryPhysicianId.value;
            callDbObje.holdTillDt = this.callDbDoctorFormGroup.controls.holdTillDt.value;
            callDbObje.callTypeId = CallTypesEnum.DoctorToDoctor;
            callDbObje.textMessage = this.callDbDoctorFormGroup.controls.textMessage.value;
            callDbObje.currentCallProviderId = this.callDbDoctorFormGroup.controls.currentCallProviderId.value;
            callDbObje.currentCallNotPref = this.callDbDoctorFormGroup.controls.currentCallNotPref.value;
            callDbObje.practiceId = this.practiceId;
            callDbObje.facilityName = this.callDbDoctorFormGroup.controls.facilityName.value;
            if (this.callDbDoctorFormGroup.value.holdUntillTime) {
                callDbObje.holdUntillTime = this.timeConversionTo24Hour(this.callDbDoctorFormGroup.value.holdUntillTime);
            }
            callDbObje.userName = user.data['userName'];
            callDbObje.agentId = user.data['userId'];
            callDbObje.cStartTimeDate = this.callEndDate;
            callDbObje.cStartDate = this.callEndDate.toISOString();
            if (this.isEdit) {
                callDbObje.sKey = this.callDbDoctorFormGroup.controls.sKey.value;
                // this.callDbDoctorFormGroup.controls.operationType.setValue(OperationTypesEnum.EditData);
                callDbObje.operationType = OperationTypesEnum.EditData.toString();
                callDbObje.callTypeId = CallTypesEnum.DoctorToDoctor;
                // this.callDbDoctorFormGroup.controls.sKey.setValue(this.patientId);
                this.doctorSubscription = this.callDbService.put(this.practiceId, callDbObje).subscribe(t => {
                    // this.callDbDoctorFormGroup.controls.birthDate.setValue(null);
                    // this.callDbDoctorFormGroup.controls.age.setValue(0);
                    // this.callDbDoctorFormGroup.controls.holdUntillTime.setValue(null);
                    // this.callDbDoctorFormGroup.controls.holdTillDt.setValue(null);
                    this.isEdit = false;
                    this.patientId = t.sKey;
                    this.callDbDoctorFormGroup.controls.sKey.setValue(t.sKey);
                    FormState.remove(this.patientId);
                    localStorage.removeItem('callId');
                    this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.DoctorToDoctor, spResult: t.spResult })
                        // .then(t => this.ngOnInit());
                        .then(t => {
                            if (t.isEdit) {
                                this.isContinueClicked = true;
                                this.isEdit = t.isEdit;
                                // FormState.remove(this.patientId);
                                FormState.setState(this.callDbDoctorFormGroup.value.sKey, this.callDbDoctorFormGroup);
                            }
                            else {
                                FormState.remove(this.patientId);
                                this.callDbDoctorFormGroup.reset();
                                this.callDbService.addNode(0);
                                this.callDbDoctorFormGroup = this.setDefaultValue(this.callDbDoctorFormGroup, CallTypesEnum.DoctorToDoctor);
                                let userData = user.data;
                                userData.practiceId = null;
                                userData.practiceName = null;
                                user.data = userData;
                                this.router.navigate(['dashboard']);
                                this.applicationBroadcaster.practiceSelectionBroadCast(false);
                            }
                        });
                },
                    error => {
                        this.popup.validationFailed(error);
                    })
            } else {
                callDbObje.sKey = 0;
                this.doctorSubscription = this.callDbService.post(this.practiceId, callDbObje).subscribe(t => {
                    // this.callDbDoctorFormGroup.controls.birthDate.setValue(null);
                    // this.callDbDoctorFormGroup.controls.age.setValue(0);
                    // this.callDbDoctorFormGroup.controls.holdTillDt.setValue(null);
                    // this.callDbDoctorFormGroup.controls.holdUntillTime.setValue(null);
                    this.patientId = t.sKey;
                    this.callDbDoctorFormGroup.controls.sKey.setValue(t.sKey);
                    FormState.remove(this.patientId);
                    localStorage.removeItem('callId');
                    this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.DoctorToDoctor, spResult: t.spResult })
                        // .then(t => this.ngOnInit());
                        .then(t => {
                            if (t.isEdit) {
                                this.isContinueClicked = true;
                                this.isEdit = t.isEdit;
                                // FormState.remove(this.patientId);
                                FormState.setState(this.callDbDoctorFormGroup.value.sKey, this.callDbDoctorFormGroup);
                            }
                            else {
                                FormState.remove(this.patientId);
                                this.callDbDoctorFormGroup.reset();
                                this.callDbService.addNode(0);
                                this.callDbDoctorFormGroup = this.setDefaultValue(this.callDbDoctorFormGroup, CallTypesEnum.DoctorToDoctor);
                                let userData = user.data;
                                userData.practiceId = null;
                                userData.practiceName = null;
                                user.data = userData;
                                this.router.navigate(['dashboard']);
                                this.applicationBroadcaster.practiceSelectionBroadCast(false);
                            }
                        });
                },
                    error => {
                        this.popup.validationFailed(error);
                    })
            }
        }
    }

    checkDate(value) {
        let date = new Date();
        if (value < date) {
            let age = this.calculateAge(value);
            this.callDbDoctorFormGroup.controls.age.setValue(age);
        }
        else {
            this.callDbDoctorFormGroup.controls.age.setValue(0);
        }
    }

    clearFormGroup(): void {

        this.isEdit = false;
        this.callDbService.addNode(0);
        this.callDbDoctorFormGroup.reset();
        this.callDbDoctorFormGroup = this.setDefaultValue(this.callDbDoctorFormGroup, CallTypesEnum.DoctorToDoctor);
        if (this.patientId)
            FormState.remove(this.patientId);
        else
            FormState.remove(0);
        this.applicationBroadcaster.patientIdBroadCast(0);
        localStorage.removeItem('callId');

        let userData = user.data;
        userData.practiceId = null;
        userData.practiceName = null;
        user.data = userData;
        this.router.navigate(['dashboard']);
        this.applicationBroadcaster.practiceSelectionBroadCast(false);
    }

    ngOnDestroy(): void {
        if (this.doctorSubscription)
            this.doctorSubscription.unsubscribe();
        super.destroy();
    }
}
