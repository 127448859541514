import { required, numeric, maxLength } from "@rx/annotations";

export class ChangeCredentialViewModel {
  @required()
  userName: string = undefined;
 
  password: string = undefined;
 
  confirmPassword: string = undefined;
  verificationCode: string = undefined;
  isResetPassword: boolean = false;
}

export class GetUserDetailViewModel
{
    UserName:string=undefined
}

export class ChangePasswordViewModel{
  userId :number = 0
  isEmail :boolean = false; 
}

export class MobileVerificationModel {
  
  @maxLength(12)
  @required()
  mobileNumber: string = undefined;

  @required()
  optPassword : string= undefined;

  @required()
  @numeric()
  country_code : string = undefined;

  isMobileSubmited: boolean=undefined;

  userId : number = undefined;

}