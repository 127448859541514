import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { CallDb } from 'src/app/database-models';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { CallManagerLookups } from 'src/app/lookups/call-manager-lookups';
import { PHONE_TYPES, RELATIONSHIPS, NOTIFICATION_PREFERENCES } from 'src/app/database-collections';
import { CallDbLookupGroup } from 'src/app/components/call-manager/call-db/domain/call-db.models';
import { CustomValidation, RxValidation, RxMessageComponent } from '@rx';
import { FormGroup } from '@angular/forms';
import { ApplicationBroadcaster, ApplicationConfiguration } from '@rx/core';
import { CallDbListComponent } from '../list/call-db-list.component';
import { CallDbViewComponent } from '../view/call-db-view.component';
import { CallTypesEnum } from '../../../../const/call-type';
import { OperationTypesEnum } from '../../../../const/operation-type';
import { user } from '@rx/security';
import { FormState } from '../../../../domain/form-state';
import { RecordStatusEnum, NotificationPreferencesEnum } from '../../../../enums';


@Component({
    selector: 'app-call-db-appointment',
    templateUrl: './call-db-appointment.component.html',
    entryComponents: [CallDbListComponent, CallDbViewComponent, RxMessageComponent]
})
export class CallDbAppointmentComponent extends CallDbDomain implements OnInit, OnDestroy, OnChanges {
    formControls: string[];
    focusElementFormControlName: string;
    patientId: any;
    callDbAppointmentFormGroup: FormGroup;
    toBeAsked: any;
    callDbAppointmentLookupGroup: CallDbLookupGroup;
    relationshipList: { "relationshipId": number; "relationshipName": string; }[];
    phoneTypeList: { "phoneTypesId": number; "phoneTypesName": string; }[];
    private sub: any;
    showComponent: boolean = false;
    callDb: CallDb[];
    appointmentSubscription: Subscription;

    orderByColumn: string;

    sortOrder: string;

    pageIndex: number;

    rowCount: number;
    @Input() practiceId: number;
    validationFailed: {
        [key: string]: any;
    }
    isEdit: boolean;
    notificationTypes: { "notificationPreferencesId": number; "notificationPreferencesName": string; }[];
    appoinment: any;
    callEndDate: Date = undefined;
    jsonData: any;
    isContinueClicked: boolean = true;
    constructor(
        private route: ActivatedRoute,
        private callDbService: CallDbService,
        private validation: RxValidation,
        private dialog: RxDialog,
        private applicationBroadcaster: ApplicationBroadcaster,
        private router: Router,
        private popup: RxPopup,
        private toast: RxToast,
        private componentResolver: ComponentFactoryResolver
    ) {
        super();
        this.validationFailed = {};
        this.popup.setComponent(componentResolver)
        this.formControls = ["cFirstName", "cLastName", "chFirstName", "chLastName", "cBNumberP", "cBNumberS", "cRelationship2Ch", "birthDate", "primaryPhysicianId", "textMessage"];
    }

    ngOnInit(): void {
        // this.route.queryParams.subscribe(t => {
        //     if (t["patientId"])
        //         this.patientId = t["patientId"];
        //     if (t['isEdit'])
        //         this.isEdit = t['isEdit'];
        // })
        this.callEndDate = new Date();
        this.showComponent = false
        this.phoneTypeList = PHONE_TYPES;
        this.relationshipList = RELATIONSHIPS;
        this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM && x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage);
        if (localStorage.getItem('callId')) {
            this.patientId = parseInt(localStorage.getItem('callId'));
            this.applicationBroadcaster.patientIdBroadCast(this.patientId);
            this.isEdit = true;
        }
        else
            this.callDbService.node$.subscribe(n => this.patientId = n);
        this.sub = this.route.params.subscribe(params => {
            this.practiceId = params['practiceId'];
        });
        if (this.appointmentSubscription)
            this.appointmentSubscription.unsubscribe();
        if (this.patientId && this.patientId != 0) {
            this.callDbService.group(this.practiceId, [CallTypesEnum.Appointment, this.patientId, 0], [CallManagerLookups.providers]).then((response: any) => {


                this.callDbAppointmentLookupGroup = new CallDbLookupGroup();
                this.callDbAppointmentLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                this.callDbAppointmentLookupGroup.callDb = new CallDb(JSON.parse(response.CallDb[0].callDb)[0]);
                let data = FormState.getState(this.patientId);
                if (data) {
                    this.callDbAppointmentLookupGroup.callDb = data
                }
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                this.jsonData = JSON.parse(response.CallDb[0].annotationProps);
                // jsonData['cBNumberTypeP'] = { 'required': jsonData['cBNumberP']['required'] };
                // jsonData['cBNumberTypeS'] = { 'required': jsonData['cBNumberS']['required'] };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: this.jsonData,
                });
                if (this.callDbAppointmentFormGroup) {
                    this.callDbAppointmentFormGroup = undefined;
                }
                this.callDbAppointmentFormGroup = this.validation.getFormGroup(this.callDbAppointmentLookupGroup.callDb, customValidation);
                this.callDbAppointmentFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbAppointmentFormGroup.controls.currentCallNotPref.setValue(null);
                for (let i = 0; i < this.formControls.length; i++) {
                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.Appointment);
                this.showComponent = true;
                this.ngOnChanges();
            });
        }
        else {
            this.callDbService.group(this.practiceId, [CallTypesEnum.Appointment, 0, 0], [CallManagerLookups.providers]).then((response: any) => {

                this.phoneTypeList = PHONE_TYPES;
                this.relationshipList = RELATIONSHIPS;
                this.callDbAppointmentLookupGroup = new CallDbLookupGroup();
                this.callDbAppointmentLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                let data = FormState.getState(0);
                if (data) {
                    this.callDbAppointmentLookupGroup.callDb = data;
                } else {
                    this.callDbAppointmentLookupGroup.callDb = new CallDb();
                    this.callDbAppointmentLookupGroup.callDb.practiceId = this.practiceId
                    this.callDbAppointmentLookupGroup.callDb.callTypeId = CallTypesEnum.Appointment;
                }
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                this.jsonData = JSON.parse(response.CallDb[0].annotationProps);
                // jsonData['cBNumberTypeP'] = { 'required': jsonData['cBNumberP']['required'] };
                // jsonData['cBNumberTypeS'] = { 'required': jsonData['cBNumberS']['required'] };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: this.jsonData,
                });
                if (this.callDbAppointmentFormGroup) {
                    this.callDbAppointmentFormGroup = undefined;
                }
                this.callDbAppointmentFormGroup = this.validation.getFormGroup(this.callDbAppointmentLookupGroup.callDb, customValidation);
                this.callDbAppointmentFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbAppointmentFormGroup.controls.currentCallNotPref.setValue(null);

                for (let i = 0; i < this.formControls.length; i++) {
                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.Appointment);
                this.showComponent = true;
                this.ngOnChanges();
            });
        }

    }

    ngOnChanges(): void {
        this.callDbAppointmentFormGroup.valueChanges.subscribe(val => {
            FormState.setState(this.callDbAppointmentFormGroup.value.sKey, this.callDbAppointmentFormGroup);
        });
    }

    showPopup(value) {
        this.popup.show(CallDbListComponent)
            // .then(t => this.ngOnInit())
            .then(t => {
                
                if (t['patientId'])
                    this.patientId = t['patientId'];
                if (t['callDbData']) {
                    this.appoinment = t['callDbData'];
                    // this.jsonData = JSON.parse(this.appoinment.annotationProps);
                    var customValidation: CustomValidation[] = [];
                    customValidation.push({
                        annotationProps: this.jsonData,
                    });
                    let data = JSON.parse(this.appoinment.callDb)[0];

                    let dataObj = new CallDb();
                    dataObj.cFirstName = data.cFirstName;
                    dataObj.cLastName = data.cLastName;
                    dataObj.chFirstName = data.chFirstName;
                    dataObj.chLastName = data.chLastName;
                    dataObj.birthDate = data.birthDate;
                    dataObj.birthTime = data.birthTime;
                    dataObj.cBNumberP = data.cBNumberP;
                    dataObj.cBNumberS = data.cBNumberS;
                    dataObj.cRelationship2Ch = data.cRelationship2Ch;
                    dataObj.primaryPhysicianId = data.primaryPhysicianId;
                    dataObj.sKey = 0;
                    dataObj.callTypeId = CallTypesEnum.Appointment;
                    dataObj.practiceId = this.practiceId;

                    // data.textMessage = null;
                    // data.skey = 0;
                    // data.callTypeId = CallTypesEnum.Appointment;
                    if (this.callDbAppointmentFormGroup)
                        this.callDbAppointmentFormGroup = undefined;
                    this.callDbAppointmentFormGroup = this.validation.getFormGroup(dataObj, customValidation);
                    this.callDbAppointmentFormGroup.controls.currentCallProviderId.setValue(null);
                    this.callDbAppointmentFormGroup.controls.currentCallNotPref.setValue(null);
                }
            })
    }

    isContinue() {
        if (this.callDbAppointmentFormGroup.valid && this.isContinueClicked) {
            this.isContinueClicked = false;
            this.continue();
        }
    }

    continue() {
        if (this.callDbAppointmentFormGroup.value.birthDate > new Date()) {
            this.toast.show(ApplicationConfiguration.get("toastValidationMessages.birthDate"), { status: 'error' });
        } else {
            // this.callDbAppointmentFormGroup.controls.userName.setValue(user.data['userName']);
            // this.callDbAppointmentFormGroup.controls.agentId.setValue(user.data['userId']);
            // this.callDbAppointmentFormGroup.controls.cStartTimeDate.setValue(this.dateToUTC(new Date()));
            let callDbObj = new CallDb(this.callDbAppointmentFormGroup.value);
            callDbObj.userName = user.data['userName'];
            callDbObj.agentId = user.data['userId']
            callDbObj.cStartTimeDate = this.callEndDate;
            callDbObj.callTypeId = CallTypesEnum.Appointment;
            callDbObj.referenceNumber = null;
            callDbObj.admissionTime = undefined;
            callDbObj.admissionDate = undefined;
            callDbObj.holdTillDt = undefined;
            callDbObj.holdUntillTime = undefined;
            callDbObj.cStartDate = this.callEndDate.toISOString();

            if (this.isEdit) {
                // this.callDbAppointmentFormGroup.controls.sKey.setValue(this.patientId);
                // this.callDbAppointmentFormGroup.controls.operationType.setValue(OperationTypesEnum.EditData);
                callDbObj.sKey = this.patientId;
                callDbObj.operationType = OperationTypesEnum.EditData.toString();
                callDbObj.callTypeId = CallTypesEnum.Appointment;
                this.appointmentSubscription = this.callDbService.put(this.practiceId, callDbObj).subscribe(t => {
                    // this.callDbAppointmentFormGroup.controls.birthDate.setValue(null);
                    // this.callDbAppointmentFormGroup.controls.age.setValue(0);
                    // this.callDbAppointmentFormGroup.controls.birthDate.setValue(null);
                    this.isEdit = false;
                    this.patientId = t.sKey;
                    this.callDbAppointmentFormGroup.controls.sKey.setValue(t.sKey);
                    FormState.remove(this.patientId);
                    localStorage.removeItem('callId');
                    this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.Appointment, spResult: t.spResult })
                        // .then(t => this.ngOnInit());
                        .then(t => {
                            if (t.isEdit) {
                                this.isContinueClicked = true;
                                this.isEdit = t.isEdit;
                                // FormState.remove(this.patientId);
                                FormState.setState(this.callDbAppointmentFormGroup.value.sKey, this.callDbAppointmentFormGroup);
                            }
                            else {
                                FormState.remove(this.patientId);
                                this.callDbAppointmentFormGroup.reset();
                                this.callDbService.addNode(0);
                                this.callDbAppointmentFormGroup = this.setDefaultValue(this.callDbAppointmentFormGroup, CallTypesEnum.Appointment);
                                let userData = user.data;
                                userData.practiceId = null;
                                userData.practiceName = null;
                                user.data = userData;
                                this.router.navigate(['dashboard']);
                                this.applicationBroadcaster.practiceSelectionBroadCast(false);
                            }
                        });
                },
                    error => {
                        this.popup.validationFailed(error);
                    })
            } else {
                // this.callDbAppointmentFormGroup.controls.sKey.setValue(0);
                callDbObj.sKey = 0;
                this.appointmentSubscription = this.callDbService.post(this.practiceId, callDbObj).subscribe(t => {
                    // this.callDbAppointmentFormGroup.controls.birthDate.setValue(null);
                    // this.callDbAppointmentFormGroup.controls.age.setValue(0);
                    // this.callDbAppointmentFormGroup.controls.birthDate.setValue(null);
                    this.patientId = t.sKey;
                    this.callDbAppointmentFormGroup.controls.sKey.setValue(t.sKey);
                    FormState.remove(this.patientId);
                    localStorage.removeItem('callId');
                    this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.Appointment, spResult: t.spResult })
                        // .then(t => this.ngOnInit());
                        .then(t => {
                            if (t.isEdit) {
                                this.isContinueClicked = true;
                                this.isEdit = t.isEdit;
                                // FormState.remove(this.patientId);
                                FormState.setState(this.callDbAppointmentFormGroup.value.sKey, this.callDbAppointmentFormGroup);
                            }
                            else {
                                FormState.remove(this.patientId);
                                this.callDbAppointmentFormGroup.reset();
                                this.callDbService.addNode(0);
                                this.callDbAppointmentFormGroup = this.setDefaultValue(this.callDbAppointmentFormGroup, CallTypesEnum.Appointment);
                                let userData = user.data;
                                userData.practiceId = null;
                                userData.practiceName = null;
                                user.data = userData;
                                this.router.navigate(['dashboard']);
                                this.applicationBroadcaster.practiceSelectionBroadCast(false);
                            }
                        });
                },
                    error => {
                        this.popup.validationFailed(error);
                    })
            }
        }
    }

    checkDate(value) {
        let date = new Date();
        if (value < date) {
            let age = this.calculateAge(value);
            this.callDbAppointmentFormGroup.controls.age.setValue(age);
        }
        else {
            this.callDbAppointmentFormGroup.controls.age.setValue(0);
        }
    }

    ngOnDestroy(): void {
        if (this.appointmentSubscription)
            this.appointmentSubscription.unsubscribe();
        super.destroy();
    }

    clearFormGroup(): void {

        this.callDbService.addNode(0);
        this.isEdit = false;
        this.callDbAppointmentFormGroup.reset();
        this.callDbAppointmentFormGroup = this.setDefaultValue(this.callDbAppointmentFormGroup, CallTypesEnum.Appointment);
        if (this.patientId)
            FormState.remove(this.patientId);
        else
            FormState.remove(0);
        this.applicationBroadcaster.patientIdBroadCast(0);
        localStorage.removeItem('callId');

        let userData = user.data;
        userData.practiceId = null;
        userData.practiceName = null;
        user.data = userData;
        this.router.navigate(['dashboard']);
        this.applicationBroadcaster.practiceSelectionBroadCast(false);
    }
}
