import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick } from '@rx/view';

import { User, vUser } from 'src/app/database-models';
import { UsersService } from '../users.service';
import { UserDomain } from '../domain/user.domain';
import { user } from '@rx/security';
import { ApplicationConfiguration } from '@rx/core';
import { ManageUserAgentLookups } from 'src/app/lookups';
import { FormGroup } from '@angular/forms';
import { UserLookupGroup } from '../domain/user.models';
import { RxValidation } from '@rx/forms/validator';


@Component({
    templateUrl: './user-list.component.html',
})
export class UserListComponent extends UserDomain implements OnInit, OnDestroy {
    practiceName: any;
    showComponent: boolean = false;
    users: vUser[];
    listSubscription: Subscription;
    userFormGroup: FormGroup;
    userLookupGroup: UserLookupGroup;;
    validationFailed: {
        [key: string]: any;
    }
    constructor(
        private usersService: UsersService,
        private dialog: RxDialog,
        private router: Router,
        private toast: RxToast,
        private validation: RxValidation,
    ) {
        super();
        this.practiceName = user.data["practiceName"];
    }

    ngOnInit(): void {

        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        this.listSubscription = this.usersService.get().subscribe(users => {

            this.users = users.filter(t => t.userName != "azureuser");

            this.showComponent = true;
        });
    }

    onEdit(userId: number): void {
        this.router.navigateByUrl('/users/' + userId);
    }
    onDelete(userData: any): void {


        if (userData.userId == user.data['userId']) {
            this.toast.show(ApplicationConfiguration.get("toastValidationMessages.canNotDeleteUse"), { status: 'error' });
        }
        else {
            let msgString = ApplicationConfiguration.get("dialog.confirmation.messageType.delete");
            this.dialog.confirmation([userData.userName], "delete").then(dialogClick => {
                if (dialogClick == DialogClick.PrimaryOk) {
                    this.listSubscription = this.usersService.delete(userData.userId).subscribe(t => {
                        this.ngOnInit();

                    },
                        error => {
                            this.toast.show(ApplicationConfiguration.get("toastValidationMessages.canNotDeleteUse"), { status: 'error' });
                        })
                }
            })
        }

    }
    unlockUser(userData: any): void {
        let msgString = ApplicationConfiguration.get("toastValidationMessages.dependency");
        if (userData.userId == user.data['userId']) {
            this.toast.show(msgString, { status: 'error' });
        }
        else {
            this.dialog.confirmation([userData.userName], "unlock").then(dialogClick => {
                if (dialogClick == DialogClick.PrimaryOk) {
                    this.usersService.group([userData.userId], [ManageUserAgentLookups.roleTypes]).then(
                        (response: any) => {
                            this.userLookupGroup = response;
                            response.user.salt = null;
                            this.userLookupGroup = new UserLookupGroup();
                            this.userLookupGroup.user = new User(response.user);
                            this.userLookupGroup.user.password = null;
                            this.userLookupGroup.user.loginBlocked = false;
                            this.userLookupGroup.user.loginAttempt = 0;
                            this.userFormGroup = this.validation.getFormGroup(this.userLookupGroup.user);

                            this.usersService.put(this.userFormGroup.value).subscribe(t => {
                                this.ngOnInit();

                            },
                                error => {
                                    this.toast.show(error, { status: 'error' });
                                });

                        });

                }
            })
        }

    }
    ngOnDestroy(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        super.destroy();
    }
}
