import { Injectable } from '@angular/core';
import {Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi} from "@rx/security";

import {  Provider, vProvider, vProviderRecord, } from 'src/app/database-models';
import { ProviderLookupGroup } from './domain/provider.models';

@Injectable()
export class ProvidersService {
	private practiceId: number;

	private get api(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/Practices/${this.practiceId}/Providers`,
          //childModuleName: 'providers',
            applicationModuleId:1042,
            keyName:'providerId'
	
        }
        return authorizeApi;
    }
    constructor(
        private http: RxHttp
    ) { }

    lookup<ProviderLookupGroup>(lookupActions: LookupAction[]): Promise<ProviderLookupGroup> {
        return this.http.lookup<ProviderLookupGroup>(lookupActions);
    }

    group<ProviderLookupGroup>(practiceId : number, params: any[] | {
        [key: string]: any;
    } | RequestQueryParams, lookupActions: LookupAction[]): Promise<ProviderLookupGroup> {
		this.practiceId = practiceId;
        return this.http.group<ProviderLookupGroup>(this.api, params, 'vProviderRecord', lookupActions);
    }

	search(practiceId : number, search: any): Observable<vProvider[]> {
		this.practiceId = practiceId;
        return this.http.search<vProvider[]>(this.api, search);
    }

    get(practiceId: number,num : number = 1): Observable<any[]> {
        this.practiceId = practiceId;
        if(num == 2)
          return this.http.get<any[]>(this.api, null,false);
        else 
          return this.http.get<any[]>(this.api );
    
      }
    getBy(practiceId : number, params?: any[] | {
        [key: string]: any;
    } | RequestQueryParams): Observable<any> {
		this.practiceId = practiceId;
        return this.http.get<any>(this.api, params);
    }

    post(practiceId : number, provider: Provider): Observable<Provider> {
		this.practiceId = practiceId;
        return this.http.post<Provider>(this.api, provider);
    } 

    put(practiceId : number, provider: Provider): Observable<Provider> {
		this.practiceId = practiceId;
        return this.http.put<Provider>(this.api, provider);
    }

    delete(practiceId : number, id : number): Observable<Provider> {
		this.practiceId = practiceId;
        return this.http.delete<Provider>(this.api,id);
    }

	filterLookup<T>(lookup:LookupAction,filterParameters:any[],queryParams?: {[key:string]:any}):Observable<T> {
        return this.http.filterLookup<T>(lookup,filterParameters,queryParams);
    }

    patch(practiceId:number, providerId: any, data: any): Observable<Provider> {
        this.practiceId = practiceId;
        return this.http.patch<Provider>(this.api, providerId,data);
      }

}
