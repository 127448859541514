import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vCallCenters')
export class vCallCenter {
    constructor(vCallCenter?: vCallCenter )  {
        let properties = [ "callCenterID", "companyName",];
        for (let property of properties)
            if (vCallCenter && (vCallCenter[property] || vCallCenter[property] == 0))
                this[property] = vCallCenter[property];
    }
 
	@property('CallCenterID')
	callCenterID : number =   0 ;
 
    @required()
    @maxLength(100)
	@property('CompanyName')
	companyName : string = undefined ;



}
