export const AHT_LOCATIONS = [
	{
        "ahtLocationsId": 1078,
        "ahtLocationsName": "Children's Hospital Denver"
    },
	{
        "ahtLocationsId": 1079,
        "ahtLocationsName": "Rainbow Babies"
    },
	{
        "ahtLocationsId": 1080,
        "ahtLocationsName": "Night Nurse"
    },
	{
        "ahtLocationsId": 1082,
        "ahtLocationsName": "AHT Test"
    },
	{
        "ahtLocationsId": 1083,
        "ahtLocationsName": "AMBS Triage"
    },
	{
        "ahtLocationsId": 1084,
        "ahtLocationsName": "Triage 4 Pediatrics"
    },
	{
        "ahtLocationsId": 1085,
        "ahtLocationsName": "Pediatric Urgicare"
    },
]