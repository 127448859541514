import { Injectable } from '@angular/core';
import {Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi} from "@rx/security";

import { AhtDivertLookupGroup } from './domain/aht-divert.models';
import { AhtDivert } from 'src/app/database-models';

@Injectable()
export class AhtDivertService {
   

	private get api(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/AhtDivert`,
            applicationModuleId: 2046,
            keyName:'ahtDivertId'
	
        }
        return authorizeApi;
    }

    constructor(
        private http: RxHttp
    ) { }

    lookup<AhtDivertLookupGroup>(lookupActions: LookupAction[]): Promise<AhtDivertLookupGroup> {
        return this.http.lookup<AhtDivertLookupGroup>(lookupActions);
    }

    group<AhtDivertLookupGroup>(params: any[] | {
        [key: string]: any;
    } | RequestQueryParams, lookupActions: LookupAction[]): Promise<AhtDivertLookupGroup> {
        return this.http.group<AhtDivertLookupGroup>(this.api, params, 'ahtDivert', lookupActions);
    }

	search(search: any): Observable<AhtDivert[]> {
        return this.http.search<AhtDivert[]>(this.api, search);
    }

    get(params?:any[]): Observable<AhtDivert[]> {
        return this.http.get<AhtDivert[]>(this.api,{ 'orderByColumn' : params[0],'sortOrder' : params[1],'pageIndex' : params[2],'rowCount' : params[3], });
    }
    getBy(params?: any[] | {
        [key: string]: any;
    } | RequestQueryParams): Observable<AhtDivert> {
        return this.http.get<AhtDivert>(this.api, params); 
    }

    post(ahtDivert: any): Observable<any> {
        return this.http.post<any>(this.api, ahtDivert);
    }

    put(ahtDivert: AhtDivert): Observable<AhtDivert> {
        return this.http.put<AhtDivert>(this.api, ahtDivert);
    }

    delete(id : number): Observable<AhtDivert> {
        return this.http.delete<AhtDivert>(this.api,id);
    }

	filterLookup<T>(lookup:LookupAction,filterParameters:any[],queryParams?: {[key:string]:any}):Observable<T> {
        return this.http.filterLookup(lookup,filterParameters,queryParams);
    }

}
