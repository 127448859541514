import { LookupAction } from '@rx/http'

export class AgentOfficeLookups {
    static practices: LookupAction = {
        controllerName: "agentoffice",
        actionName: "practices",
		cacheOption: {
            isVersionBase: true,
            tableName:''
        }
    };

}
