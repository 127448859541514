export const STATES = [
	{
        "stateId": 62,
        "stateName": "CO"
    },
	{
        "stateId": 63,
        "stateName": "AK"
    },
	{
        "stateId": 3152,
        "stateName": "AL"
    },
	{
        "stateId": 3153,
        "stateName": "AR"
    },
	{
        "stateId": 3154,
        "stateName": "AZ"
    },
	{
        "stateId": 3155,
        "stateName": "CA"
    },
	{
        "stateId": 3156,
        "stateName": "CT"
    },
	{
        "stateId": 3157,
        "stateName": "DC"
    },
	{
        "stateId": 3158,
        "stateName": "DE"
    },
	{
        "stateId": 3159,
        "stateName": "FL"
    },
	{
        "stateId": 3160,
        "stateName": "GA"
    },
	{
        "stateId": 3161,
        "stateName": "HI"
    },
	{
        "stateId": 3162,
        "stateName": "IA"
    },
	{
        "stateId": 3163,
        "stateName": "ID"
    },
	{
        "stateId": 3164,
        "stateName": "IL"
    },
	{
        "stateId": 3165,
        "stateName": "IN"
    },
	{
        "stateId": 3166,
        "stateName": "KS"
    },
	{
        "stateId": 3167,
        "stateName": "KY"
    },
	{
        "stateId": 3168,
        "stateName": "LA"
    },
	{
        "stateId": 3169,
        "stateName": "MA"
    },
	{
        "stateId": 3170,
        "stateName": "MD"
    },
	{
        "stateId": 3171,
        "stateName": "ME"
    },
	{
        "stateId": 3172,
        "stateName": "MI"
    },
	{
        "stateId": 3173,
        "stateName": "MN"
    },
	{
        "stateId": 3174,
        "stateName": "MO"
    },
	{
        "stateId": 3175,
        "stateName": "MS"
    },
	{
        "stateId": 3176,
        "stateName": "MT"
    },
	{
        "stateId": 3177,
        "stateName": "NC"
    },
	{
        "stateId": 3178,
        "stateName": "ND"
    },
	{
        "stateId": 3179,
        "stateName": "NE"
    },
	{
        "stateId": 3180,
        "stateName": "NH"
    },
	{
        "stateId": 3181,
        "stateName": "NJ"
    },
	{
        "stateId": 3182,
        "stateName": "NM"
    },
	{
        "stateId": 3183,
        "stateName": "NV"
    },
	{
        "stateId": 3184,
        "stateName": "NY"
    },
	{
        "stateId": 3185,
        "stateName": "OH"
    },
	{
        "stateId": 3186,
        "stateName": "OK"
    },
	{
        "stateId": 3187,
        "stateName": "OR"
    },
	{
        "stateId": 3188,
        "stateName": "PA"
    },
	{
        "stateId": 3189,
        "stateName": "RI"
    },
	{
        "stateId": 3190,
        "stateName": "SC"
    },
	{
        "stateId": 3191,
        "stateName": "SD"
    },
	{
        "stateId": 3192,
        "stateName": "TN"
    },
	{
        "stateId": 3193,
        "stateName": "TX"
    },
	{
        "stateId": 3194,
        "stateName": "UT"
    },
	{
        "stateId": 3195,
        "stateName": "VA"
    },
	{
        "stateId": 3196,
        "stateName": "VT"
    },
	{
        "stateId": 3197,
        "stateName": "WA"
    },
	{
        "stateId": 3198,
        "stateName": "WI"
    },
	{
        "stateId": 3199,
        "stateName": "WV"
    },
	{
        "stateId": 3200,
        "stateName": "WY"
    },
	{
        "stateId": 3201,
        "stateName": "VI"
    },
]