import {ComponentContainer} from "@rx/core"
import { ApplicationIssueListComponent } from './list/application-issue-list.component'

export const APPLICATION_ISSUES_SHARED_COMPONENT_CONTAINER: ComponentContainer[] = [
	{
    component: ApplicationIssueListComponent,
    accessItem: 'list',
    applicationModuleId: 4,
	keyName: 'applicationIssueId',
	childModuleName :   undefined  ,
	pageName:'application-issues',
	rootModuleId:2
	},
];

