import { Injectable } from '@angular/core';
import {Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi} from "@rx/security";

import {  CallOutbound, } from 'src/app/database-models';
import { CallOutboundLookupGroup } from './domain/call-outbound.models';

@Injectable()
export class CallOutboundService {
	private practiceId: number;

	private get api(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/Practices/${this.practiceId}/CallOutbound`,
            // childModuleName: 'call-outbound',
            applicationModuleId: 2045,
            keyName:'callOutboundId'
	
        }
        return authorizeApi;
    }
    constructor(
        private http: RxHttp
    ) { }

    lookup<CallOutboundLookupGroup>(lookupActions: LookupAction[]): Promise<CallOutboundLookupGroup> {
        return this.http.lookup<CallOutboundLookupGroup>(lookupActions);
    }

    group<CallOutboundLookupGroup>(practiceId : number, params: any[] | {
        [key: string]: any;
    } | RequestQueryParams, lookupActions: LookupAction[]): Promise<CallOutboundLookupGroup> {
		this.practiceId = practiceId;
        return this.http.group<CallOutboundLookupGroup>(this.api, params, 'CallOutbound', lookupActions);
    }

	search(practiceId : number, search: any): Observable<CallOutbound[]> {
		this.practiceId = practiceId;
        return this.http.search<CallOutbound[]>(this.api, search);
    }

    get(practiceId : number  ): Observable<CallOutbound[]> {
		this.practiceId = practiceId;
        return this.http.get<CallOutbound[]>(this.api);
    }

    getBy(practiceId : number, params?: any[] | {
        [key: string]: any;
    } | RequestQueryParams): Observable<CallOutbound> {
		this.practiceId = practiceId;
        return this.http.get<CallOutbound>(this.api, params);
    }

    post(practiceId : number, callOutbound: CallOutbound): Observable<CallOutbound> {
		this.practiceId = practiceId;
        return this.http.post<CallOutbound>(this.api, callOutbound);
    } 

    put(practiceId : number, callOutbound: CallOutbound): Observable<CallOutbound> {
		this.practiceId = practiceId;
        return this.http.put<CallOutbound>(this.api, callOutbound);
    }

    delete(practiceId : number, id : number): Observable<CallOutbound> {
		this.practiceId = practiceId;
        return this.http.delete<CallOutbound>(this.api,id);
    }

	filterLookup<T>(lookup:LookupAction,filterParameters:any[],queryParams?: {[key:string]:any}):Observable<T> {
        return this.http.filterLookup<T>(lookup,filterParameters,queryParams);
    }
    patch(practiceId:number, id: any, data: any): Observable<CallOutbound> {
        this.practiceId = practiceId;
        return this.http.patch<CallOutbound>(this.api, id,data);
      }

}
