export const ISSUE_PRIORITIES = [
	{
        "issuePriorityId": 2105,
        "issuePriorityName": "Low"
    },
	{
        "issuePriorityId": 2106,
        "issuePriorityName": "Medium"
    },
	{
        "issuePriorityId": 2107,
        "issuePriorityName": "High"
    },
	{
        "issuePriorityId": 2108,
        "issuePriorityName": "Urgent"
    },
]