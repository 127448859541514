import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vRoleTypes')
export class vRoleType {
    constructor(vRoleType?: vRoleType )  {
        let properties = [ "isAgent", "roleId", "roleName",];
        for (let property of properties)
            if (vRoleType && (vRoleType[property] || vRoleType[property] == 0))
                this[property] = vRoleType[property];
    }
 
	@property('IsAgent')
	isAgent : boolean = false ;
 
	@property('RoleId')
	roleId : number =   0 ;
 
    @required()
    @maxLength(50)
	@property('RoleName')
	roleName : string = undefined ;



}
