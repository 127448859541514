export const CALL_OPERATION_TYPES = [
	{
        "callOperationTypeId": 5162,
        "callOperationTypeName": "Call Added"
    },
	{
        "callOperationTypeId": 5163,
        "callOperationTypeName": "Call Updated"
    },
	{
        "callOperationTypeId": 5164,
        "callOperationTypeName": "Call Submitted"
    },
]