import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";

import {RxFormsModule} from "@rx/forms";
import {RxViewModule} from "@rx/view";
import {    RxTableModule} from "@rx/table";

import { AhtDivertListComponent } from './aht-divert-list.component'
import { AHT_DIVERT_LIST_ROUTING } from './aht-divert-list.routing'
import {AhtDivertService } from "../aht-divert.service";
import { AhtProviderMasterService } from '../aht-provider-master.service';

@NgModule({
    imports: [
        AHT_DIVERT_LIST_ROUTING ,
        CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,
		    ],
    declarations: [AhtDivertListComponent ],
    exports: [RouterModule],
    providers: [AhtDivertService,AhtProviderMasterService]
})
export class AhtDivertListModule { }