import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";
import {RxViewModule } from '@rx/view';
import {RxFormsModule } from '@rx/forms';
import {RxTableModule } from "@rx/table";
import {DynamicComponentContainer } from '@rx/core';

import { OnCallRosterListComponent } from './list/on-call-roster-list.component'
import {OnCallRostersService } from "./on-call-rosters.service";
import {ON_CALL_ROSTERS_SHARED_COMPONENT_CONTAINER } from './on-call-rosters-shared-component.container';
@NgModule({
    imports: [
        RxViewModule, RxFormsModule,
        CommonModule, FormsModule, ReactiveFormsModule, RxTableModule
    ],
    declarations: [ OnCallRosterListComponent, ],
    providers: [OnCallRostersService ],
    exports: [RouterModule, OnCallRosterListComponent, ]
})
export class OnCallRostersSharedComponentModule { }
DynamicComponentContainer.register(ON_CALL_ROSTERS_SHARED_COMPONENT_CONTAINER );