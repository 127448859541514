import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vAddresses')
export class vAddress {
    constructor(vAddress?: vAddress )  {
        let properties = [ "address", "addressId", "addressRefId", "addressTypeId", "addressTypeRefId", "backline", "city", "email", "fax", "location", "officePhone", "postalCode", "state", "stateId",];
        for (let property of properties)
            if (vAddress && (vAddress[property] || vAddress[property] == 0))
                this[property] = vAddress[property];
    }
 
    @required()
    @maxLength(200)
	@property('Address')
	address : string = undefined ;
 
    @range(1,2147483647)
	@property('AddressId')
	addressId : number =   undefined;
 
    @range(1,2147483647)
	@property('AddressRefId')
	addressRefId : number =   undefined;
 
    @range(1,2147483647)
	@property('AddressTypeId')
	addressTypeId : number =   undefined;
 
    @range(1,2147483647)
	@property('AddressTypeRefId')
	addressTypeRefId : number =   undefined;
 
    @maxLength(50)
	@property('Backline')
	backline : string = undefined ;
 
    @maxLength(60)
	@property('City')
	city : string = undefined ;
 
    @maxLength(100)
	@property('Email')
	email : string = undefined ;
 
    @maxLength(50)
	@property('Fax')
	fax : string = undefined ;
 
    @maxLength(100)
	@property('Location')
	location : string = undefined ;
 
    @maxLength(50)
	@property('OfficePhone')
	officePhone : string = undefined ;
 
    @maxLength(5)
	@property('PostalCode')
	postalCode : string = undefined ;
 
    @maxLength(100)
	@property('State')
	state : string = undefined ;
 
	@property('StateId')
	stateId : number =   undefined;



}
