import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick } from '@rx/view';

import { vAlert } from 'src/app/database-models';
import { AlertsService } from '../alerts.service';
import { AlertDomain } from '../domain/alert.domain';
import { DomSanitizer } from '@angular/platform-browser';
import { user } from '@rx/security';
import { ApplicationBroadcaster } from '@rx/core';
import { ApplyToEnum, AlertTypeEnum } from '../../../../enums';
import { CallTypesEnum } from '../../../../const/call-type';


@Component({
    selector: 'app-alert-list',
    templateUrl: './alert-list.component.html',
})
export class AlertListComponent extends AlertDomain implements OnInit, OnDestroy, OnChanges {
    anyPracticeSelected: boolean;
    showComponent: boolean = false;
    specialMessage: any = [];
    generalMessage: any = [];
    alerts: vAlert[];
    listSubscription: Subscription;
    @Input() practiceId: number;
    @Input() callTypeId: number;
    @Input() isVisible: any;

    previousCallTypeId: CallTypesEnum;
    constructor(
        private alertsService: AlertsService,
        private dialog: RxDialog,
        private sanitizer: DomSanitizer,
        private applicationBroadcaster: ApplicationBroadcaster,
        private router: Router,
    ) {
        super();
        if (user && user.data) {
            this.practiceId = user.data["practiceId"];
        }
        //  this.applicationBroadcaster.isPracticeSelectedSubscriber.subscribe(value => {
        //         this.practiceId = user.data["practiceId"];
        //         this.showComponent = false;
        //         if (value) {
        //             console.log("alert-practiceId");
        //             this.ngOnInit();
        //         }
        //     });   

        // this.applicationBroadcaster.callTypeIdSubscriber.subscribe(value => {
        //     // this.previousCallTypeId = this.callTypeId;
        //     if (this.callTypeId != CallTypesEnum.Newborns && value != CallTypesEnum.Newborns) {
        //         this.callTypeId = value;
        //     }
        //     else {
        //         this.showComponent = false;
        //         this.callTypeId = value;
        //         console.log("alert-calltypeid");
        //         this.ngOnInit();
        //     }
        // });
    }
    ngOnChanges(change: any) {
        if (change.callTypeId.currentValue != 3 && change.callTypeId.previousValue == 3)
            this.ngOnInit();
        else if (change.callTypeId.currentValue == 3 && change.callTypeId.previousValue != 3)
            this.ngOnInit();
    }
    ngOnInit(): void {
        
        this.specialMessage = [];
        this.showComponent = false
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        if (this.practiceId != undefined && this.practiceId != null && this.practiceId != 0) {

            this.listSubscription = this.alertsService.get(this.practiceId).subscribe(alerts => {
                if (this.callTypeId == undefined) {
                    
                    this.alerts = alerts.where(t => t.applyToId != ApplyToEnum.NewbornOnly && t.alertTypeId == AlertTypeEnum.SpecialMessages)
                }
                else if (this.callTypeId == 3) {
                    this.alerts = alerts.where(t => t.applyToId == ApplyToEnum.NewbornOnly || t.applyToId == ApplyToEnum.AllScreens)
                }
                // else if(this.isVisible){
                //     this.alerts = alerts.where(t=> (t.applyToId == ApplyToEnum.AllEXCEPTNewborn || t.applyToId == ApplyToEnum.AllScreens  ) &&  t.alertTypeId == AlertTypeEnum.SpecialMessages)
                // }
                else {
                    this.alerts = alerts.where(t => t.applyToId == ApplyToEnum.AllEXCEPTNewborn || t.applyToId == ApplyToEnum.AllScreens)
                }
                this.alerts.forEach(element => {
                    this.specialMessage.push(this.sanitizer.bypassSecurityTrustHtml(element.message.toString()));
                });
                this.generalMessage = this.sanitizer.bypassSecurityTrustHtml(this.alerts.toString());
                this.showComponent = true;
            });
        }
        else {
            this.specialMessage = [];
        }
    }



    ngOnDestroy(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        super.destroy();
    }
}
