import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";

import {RxFormsModule} from "@rx/forms";
import {RxViewModule} from "@rx/view";
import {    RxTableModule} from "@rx/table";

import { CallDbEditComponent } from './call-db-edit.component'
import { CALL_DB_EDIT_ROUTING } from './call-db-edit.routing'
import {CallDbService } from "../call-db.service";
import { CallDbSharedComponentModule } from 'src/app/components/call-manager/call-db/call-db-shared-component.module'
import { AlertsSharedComponentModule } from '../../../agent-alert/alerts/alerts-shared-component.module';
import { PracticesSharedComponentModule } from '../../../agent-office/practices/practices-shared-component.module';
import { ProvidersSharedComponentModule } from '../../../agent-provider/providers/providers-shared-component.module';
import { TechnicalTermsSharedComponentModule } from '../../../technical-term/technical-terms/technical-terms-shared-component.module';

@NgModule({
    imports: [
        CALL_DB_EDIT_ROUTING ,
        CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,AlertsSharedComponentModule,
		CallDbSharedComponentModule, PracticesSharedComponentModule,ProvidersSharedComponentModule,TechnicalTermsSharedComponentModule],
    declarations: [CallDbEditComponent ],
    exports: [RouterModule],
    providers: [CallDbService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CallDbEditModule { }