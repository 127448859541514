import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('AhtDivertHistory')
export class AhtDivertHistory {
    constructor(ahtDivertHistory?: AhtDivertHistory )  {
        let properties = [ "aHTDivertHistoryId", "createdById", "createdDateTime", "divertMinutes", "divertMsgSent", "dTOffDivert", "dTOnDivert", "modifiedById", "modifiedDateTime", "practiceId", "totalMinutes",];
        for (let property of properties)
            if (ahtDivertHistory && (ahtDivertHistory[property] || ahtDivertHistory[property] == 0))
                this[property] = ahtDivertHistory[property];
    }
 
	@property('AHTDivertHistoryId')
	aHTDivertHistoryId : number =   0 ;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('DivertMinutes')
	divertMinutes : number =   undefined;
 
	@property('DivertMsgSent')
	divertMsgSent : number =   undefined;
 
	@property('DTOffDivert')
	dTOffDivert : Date =   undefined;
 
	@property('DTOnDivert')
	dTOnDivert : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('PracticeId')
	practiceId : number =   undefined;
 
	@property('TotalMinutes')
	totalMinutes : number =   undefined;



}
