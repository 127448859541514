import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi } from "@rx/security";

import { ApplicationIssue, } from 'src/app/database-models';
import { ApplicationIssueLookupGroup } from './domain/application-issue.models';

@Injectable()
export class ApplicationIssuesService {


  private get api(): AuthorizeApi {
    var authorizeApi: AuthorizeApi = {
      api: `api/ApplicationIssues`,
      applicationModuleId: 2043,
      keyName: 'applicationIssueId'

    }
    return authorizeApi;
  }

  constructor(
    private http: RxHttp
  ) { }

  lookup<ApplicationIssueLookupGroup>(lookupActions: LookupAction[]): Promise<ApplicationIssueLookupGroup> {
    return this.http.lookup<ApplicationIssueLookupGroup>(lookupActions);
  }

  group<ApplicationIssueLookupGroup>(params: any[] | {
    [key: string]: any;
  } | RequestQueryParams, lookupActions: LookupAction[]): Promise<ApplicationIssueLookupGroup> {
    return this.http.group<ApplicationIssueLookupGroup>(this.api, params, 'applicationIssue', lookupActions);
  }

  search(search: any): Observable<ApplicationIssue[]> {
    return this.http.search<ApplicationIssue[]>(this.api, search);
  }

  get(): Observable<ApplicationIssue[]> {
    return this.http.get<ApplicationIssue[]>(this.api);
  }

  getBy(params?: any[] | {
    [key: string]: any;
  } | RequestQueryParams): Observable<ApplicationIssue> {
    return this.http.get<ApplicationIssue>(this.api, params);
  }

  post(applicationIssue: ApplicationIssue): Observable<ApplicationIssue> {
    return this.http.post<ApplicationIssue>(this.api, applicationIssue);
  }

  put(applicationIssue: ApplicationIssue): Observable<ApplicationIssue> {
    return this.http.put<ApplicationIssue>(this.api, applicationIssue);
  }

  delete(id: number): Observable<ApplicationIssue> {
    return this.http.delete<ApplicationIssue>(this.api, id);
  }

  filterLookup<T>(lookup: LookupAction, filterParameters: any[], queryParams?: { [key: string]: any }): Observable<T> {
    return this.http.filterLookup(lookup, filterParameters, queryParams);
  }

}
