import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { vCallDb, CallDb } from 'src/app/database-models';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { user } from '@rx/security';
import { CallTypesEnum } from '../../../../const/call-type';
import { PracticesService } from '../../../agent-office/practices/practices.service';
import { CallManagerLookups } from '../../../../lookups';
import { NOTIFICATION_TYPES, NOTIFICATION_PREFERENCES } from '../../../../database-collections';
import { CallOutboundService } from '../../call-outbound/call-outbound.service';
import { ApplicationBroadcaster } from '@rx/core';
import { OperationTypesEnum } from '../../../../const/operation-type';
import { NotificationPreferencesEnum } from 'src/app/enums';


@Component({
    templateUrl: './call-db-search.component.html',
})
export class CallDbSearchComponent extends CallDbDomain implements OnInit, OnDestroy {
    text: any;
    notificationId: any;
    providerId: any;
    data: any;
    notificationPreference: { "notificationPreferencesId": number; "notificationPreferencesName": string; }[];
    providers: any;
    patientDetails: any;
    selectedData: any;
    isActiveGrid: boolean = true;
    showComponent: boolean = false;
    callDb: any;
    searchSubscription: Subscription;

    orderByColumn: string;

    sortOrder: string;

    outboundComment: any;

    pageIndex: number;

    rowCount: number;
    @Input() practiceId: number;
    @Input() popupType: number;
    @Input() popupTitle: string;
    @Input() isGridCall: boolean;
    @Input() callDetails: any;
    questionCode: any;
    constructor(
        private callDbService: CallDbService,
        private route: ActivatedRoute,
        private dialog: RxDialog,
        private router: Router,
        private popup: RxPopup,
        private practicesService: PracticesService,
        private callOutboundService: CallOutboundService,
        private applicationBroadcaster: ApplicationBroadcaster,
    ) {
        super();
        this.practiceId = user.data['practiceId'];
    }

    ngOnInit(): void {
       
        if (this.searchSubscription)
            this.searchSubscription.unsubscribe();
        if (!this.isGridCall) {
            this.searchSubscription = this.callDbService.get(1, ['', '', '', this.popupType]).subscribe(callDb => {
                
                this.callDb = callDb;
                this.showComponent = true;
            });
        }
        else {
            this.selectedData = this.callDetails;
            this.callDbService.group(this.callDetails.PracticeId, [this.callDetails.CallTypeId, this.callDetails.CallID, 1], [CallManagerLookups.providers]).then((t: any) => {
                this.providers = t.providers.where(x => x.practiceId == JSON.parse(t.CallDb[0].callDb)[0].PracticeId);
                if (this.providers.length > 0)
                    this.providerId = this.providers[0].providerId;
                else
                    this.providerId = 0;
                let notPrefList = NOTIFICATION_PREFERENCES;
                this.notificationPreference = notPrefList.where(x => x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM && x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage);;
                if (JSON.parse(t.CallDb[0].callDb)[0])
                    this.patientDetails = JSON.parse(t.CallDb[0].callDb)[0];
                else
                    this.patientDetails = new CallDb();
                if (JSON.parse(t.CallDb[0].fileds))
                    this.questionCode = JSON.parse(t.CallDb[0].fileds);
                else
                    this.questionCode = {};
                this.showComponent = true;
                this.isActiveGrid = false;


            });


        }
        
    }

    hidePopup() {
        this.popup.hide(CallDbSearchComponent, { "popupType": this.popupType })
    }

    showActiveDetails(rowData: any) {
        
        this.selectedData = rowData;
        this.callDbService.group(rowData.PracticeId, [rowData.CallTypeId, rowData.CallID, 1], [CallManagerLookups.providers]).then((t: any) => {
            this.providers = t.providers.where(x => x.practiceId == JSON.parse(t.CallDb[0].callDb)[0].PracticeId);
            let sortedArray = this.providers.sort((obj1, obj2) => {
                if (obj1.firstName.toLowerCase() > obj2.firstName.toLowerCase()) {
                    return 1;
                }

                if (obj1.firstName.toLowerCase() < obj2.firstName.toLowerCase()) {
                    return -1;
                }

                return 0;
            });
            this.providerId = sortedArray[0].providerId;
            this.providerId = this.providers[0].providerId;
            let notPrefList = NOTIFICATION_PREFERENCES;
            this.notificationPreference = notPrefList.where(x => x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM  && x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage);
            this.patientDetails = JSON.parse(t.CallDb[0].callDb)[0];
            this.questionCode = JSON.parse(t.CallDb[0].fileds);
            this.isActiveGrid = false;

        });

    }

    postCallStatus(status: boolean) {
        
        // if (this.popupType == 4) {
        //     if (status) {
        //         this.data = {
        //             'processed': 1,
        //             'comment': this.outboundComment,
        //             // "operationType":OperationTypesEnum.EditData
        //         }
        //     }
        //     else {
        //         this.data = {
        //             'processed': 0,
        //             'comment': this.outboundComment,
        //             //"operationType":OperationTypesEnum.EditData
        //         }
        //     }

        //     this.callOutboundService.patch(this.practiceId, this.selectedData.CallID, this.data).subscribe(t => {
        //         this.popup.hide(CallDbSearchComponent);
        //         this.applicationBroadcaster.outboundgridBroadCast("true");
        //     },
        //         error => {

        //         })
        // }
        // else {
        if (status) {
            this.data = {
                'Processed': 1,
                'comments': this.outboundComment,
                "operationType": OperationTypesEnum.EditData
            }
        }
        else {
            this.data = {
                'Processed': 0,
                'comments': this.outboundComment,
                "operationType": OperationTypesEnum.EditData
            }
        }
        this.callDbService.patch(this.practiceId, this.selectedData.CallID, this.data).subscribe(t => {
            if (this.popupType == 4) {
                if (status) {
                    this.data = {
                        'processed': 1,
                        // 'comment': this.outboundComment,
                        // "operationType":OperationTypesEnum.EditData
                    }
                }
                else {
                    this.data = {
                        'processed': 0,
                        // 'comment': this.outboundComment,
                        //"operationType":OperationTypesEnum.EditData
                    }
                }

                this.callOutboundService.patch(this.practiceId, this.selectedData.CallID, this.data).subscribe(t => {
                    this.popup.hide(CallDbSearchComponent);
                    this.applicationBroadcaster.outboundgridBroadCast("true");
                },
                    error => {

                    })
            }
            else { this.popup.hide(CallDbSearchComponent); }
        },
            error => {

            })
        // }
    }



    redirectToCallType() {
        let practiceId = this.selectedData.PracticeId == undefined ? this.selectedData.practiceId : this.selectedData.PracticeId;
        this.practicesService.getBy([practiceId]).subscribe(t => {
            
            let userData = user.data;
            userData["practiceName"] = t['data'].practiceName;
            userData["practiceId"] = t['data'].practiceId;
            user.data = userData;
            this.practiceId = t['data'].practiceId;
            this.applicationBroadcaster.practiceSelectionBroadCast(true)

            switch (this.selectedData.CallTypeId) {
                case CallTypesEnum.PatientToDoctor:
                    this.router.navigate(['/call-db/patient/', this.practiceId]);
                    break;
                case CallTypesEnum.Newborns:
                    this.router.navigate(['/call-db/newborn/', this.practiceId]);
                    break;
                case CallTypesEnum.DoctorToDoctor:
                    this.router.navigate(['/call-db/hospital-lab-doctor/', this.practiceId]);
                    break;
                case CallTypesEnum.HospitalAdmission:
                    this.router.navigate(['/call-db/admission/', this.practiceId]);
                    break;
                case CallTypesEnum.Appointment:
                    this.router.navigate(['/call-db/appointment/', this.practiceId]);
                    break;
                case CallTypesEnum.AnsweringSrtoDr:
                    this.router.navigate(['/call-db/answering-service/', this.practiceId]);
                    break;
                case CallTypesEnum.TriageToDr:
                    this.router.navigate(['/call-db/triage/', this.practiceId]);
                    break;
                case CallTypesEnum.RxRefills:
                    this.router.navigate(['/call-db/rxRefill/', this.practiceId]);
                    break;
            }
            // this.applicationBroadCast.patientIdBroadCast(this.selectedData.CallID);
            // this.callDbService.addNode(this.selectedData.CallID);
            localStorage.setItem('callId', this.selectedData.CallID);
            this.popup.hide(CallDbSearchComponent);
        })
    }


    forwardCall() {
        let dateobj = new Date();
        let DateString = dateobj.getDate() + '-' + dateobj.getMonth() + '-' + dateobj.getFullYear() + ' ' + dateobj.getHours() + ':' + dateobj.getMinutes() + ':' + dateobj.getSeconds();

        var forwardCallData = {}

        forwardCallData = {
            "SentTo": null,
            "SentVia": null,
            "NotPhysId": this.providerId,
            "notPrefId": this.notificationId,
            "textMessage": this.text,
            "operationType": OperationTypesEnum.ForwardCall
        }


        this.callDbService.patch(this.practiceId, this.selectedData.CallID, forwardCallData).subscribe(t => {
            this.popup.hide(CallDbSearchComponent);
            this.applicationBroadcaster.outboundgridBroadCast("true");
        },
            error => {

            })
    }


    ngOnDestroy(): void {
        if (this.searchSubscription)
            this.searchSubscription.unsubscribe();
        super.destroy();
    }
}
