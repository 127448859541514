export const DELIVERY_TYPES = [
	{
        "deliveryTypesId": 2146,
        "deliveryTypesName": "C-Section"
    },
	{
        "deliveryTypesId": 2147,
        "deliveryTypesName": "Vaginal"
    },
	{
        "deliveryTypesId": 2148,
        "deliveryTypesName": "Unknown"
    },
]