import { nested } from '@rx/annotations';
import {
	OnCallRoster, vProvider,
} from 'src/app/database-models';

export class OnCallRosterLookupGroup {
	providers: vProvider[];
	onCallRoster: OnCallRoster;
	onCallRosterModel: OnCallRosterModel;
}

export class OnCallRosterModel {
	constructor(OnCallRosterModel?: OnCallRosterModel) {
		let properties = ["PracticeOfficeHour"];
		for (let property of properties)
			if (OnCallRosterModel && OnCallRosterModel[property])
				this[property] = OnCallRosterModel[property];
	}

	@nested(OnCallRoster)
	onCallRosters: Array<OnCallRoster> = undefined;
	dateFrom: string = undefined;
	dateTo: string = undefined;
}
