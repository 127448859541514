import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";

import {RxFormsModule} from "@rx/forms";
import {RxViewModule} from "@rx/view";
import {    RxTableModule} from "@rx/table";

import { OnCallOverrideAddComponent } from './on-call-override-add.component'
import { ON_CALL_OVERRIDE_ADD_ROUTING } from './on-call-override-add.routing'
import {OnCallOverrideService } from "../on-call-override.service";
import { OnCallOverrideSharedComponentModule } from 'src/app/components/agent-on-call-override/on-call-override/on-call-override-shared-component.module'
import { RxCoreModule } from '@rx/core';

@NgModule({
    imports: [
        ON_CALL_OVERRIDE_ADD_ROUTING ,
        CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,RxCoreModule,
		OnCallOverrideSharedComponentModule,     ],
    declarations: [OnCallOverrideAddComponent ],
    exports: [RouterModule],
    providers: [OnCallOverrideService]
})
export class OnCallOverrideAddModule { }