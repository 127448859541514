import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vProviderRecords')
export class vProviderRecord {
    constructor(vProviderRecord?: vProviderRecord )  {
        let properties = [ "additionalTextCount", "additionalTextInterval", "backline", "backlineExt", "confirmPassword", "createdById", "createdDateTime", "email", "enableAlternateNotification", "enableNotificationPlus", "fax", "firstName", "homePhone", "isAutomateCalls", "isNewBornCallDelivery", "isPracticeManager", "lastName", "mi", "mobile", "modifiedBy", "modifiedById", "modifiedDateTime", "officePhone", "officePhoneExt", "optedForTelehealth", "pagerCoId", "pagerNumber", "password", "phonetic", "practiceId", "providerId", "responseWaitTime", "statusId", "tital", "userId", "userName", "userProfilePhotoUrl",];
        for (let property of properties)
            if (vProviderRecord && (vProviderRecord[property] || vProviderRecord[property] == 0))
                this[property] = vProviderRecord[property];
    }
 
	@property('AdditionalTextCount')
	additionalTextCount : number =   undefined;
 
	@property('AdditionalTextInterval')
	additionalTextInterval : number =   undefined;
 
    @maxLength(50)
	@property('Backline')
	backline : string = undefined ;
 
    @maxLength(10)
	@property('BacklineExt')
	backlineExt : string = undefined ;
 
    @required()
    @maxLength(10)
	@property('ConfirmPassword')
	confirmPassword : string = undefined ;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @maxLength(100)
	@property('Email')
	email : string = undefined ;
 
	@property('EnableAlternateNotification')
	enableAlternateNotification : boolean = false ;
 
	@property('EnableNotificationPlus')
	enableNotificationPlus : boolean = false ;
 
    @maxLength(50)
	@property('Fax')
	fax : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('FirstName')
	firstName : string = undefined ;
 
    @maxLength(50)
	@property('HomePhone')
	homePhone : string = undefined ;
 
	@property('IsAutomateCalls')
	isAutomateCalls : boolean = false ;
 
	@property('IsNewBornCallDelivery')
	isNewBornCallDelivery : boolean = false ;
 
	@property('IsPracticeManager')
	isPracticeManager : boolean = false ;
 
    @required()
    @maxLength(50)
	@property('LastName')
	lastName : string = undefined ;
 
    @maxLength(10)
	@property('Mi')
	mi : string = undefined ;
 
    @maxLength(12)
	@property('Mobile')
	mobile : string = undefined ;
 
    @maxLength(50)
	@property('ModifiedBy')
	modifiedBy : string = undefined ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
    @maxLength(22)
	@property('ModifiedDateTime')
	modifiedDateTime : string = undefined ;
 
    @maxLength(50)
	@property('OfficePhone')
	officePhone : string = undefined ;
 
    @maxLength(10)
	@property('OfficePhoneExt')
	officePhoneExt : string = undefined ;
 
	@property('OptedForTelehealth')
	optedForTelehealth : boolean = false ;
 
	@property('PagerCoId')
	pagerCoId : number =   undefined;
 
    @maxLength(12)
	@property('PagerNumber')
	pagerNumber : string = undefined ;
 
    @required()
    @maxLength(10)
	@property('Password')
	password : string = undefined ;
 
    @maxLength(50)
	@property('Phonetic')
	phonetic : string = undefined ;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @range(1,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
 
	@property('ResponseWaitTime')
	responseWaitTime : number =   undefined;
 
    @range(1,2147483647)
	@property('StatusId')
	statusId : number =   undefined;
 
    @maxLength(50)
	@property('Tital')
	tital : string = undefined ;
 
	@property('UserId')
	userId : number =   undefined;
 
    @required()
    @maxLength(50)
	@property('UserName')
	userName : string = undefined ;
 
    @maxLength(500)
	@property('UserProfilePhotoUrl')
	userProfilePhotoUrl : string = undefined ;



}
