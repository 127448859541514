import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewChild, Output, EventEmitter, OnChanges, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';
import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { CustomValidation, RxValidation, RxMessageComponent } from '@rx';
import { FormGroup } from '@angular/forms';
import { CallDbLookupGroup } from '../domain/call-db.models';
import { PHONE_TYPES, RELATIONSHIPS, NOTIFICATION_TYPES, NOTIFICATION_PREFERENCES } from '../../../../database-collections';
import { CallDbListComponent } from '../list/call-db-list.component';
import { ApplicationBroadcaster, ApplicationConfiguration } from '@rx/core';
import { CallDbViewComponent } from '../view/call-db-view.component';
import { OperationTypesEnum } from '../../../../const/operation-type';
import { CallTypesEnum } from '../../../../const/call-type';
import { user } from '@rx/security';
import { FormState } from '../../../../domain/form-state';
import { RecordStatusEnum, NotificationPreferencesEnum } from '../../../../enums';
import { CallDb } from 'src/app/database-models';
import { CallManagerLookups } from 'src/app/lookups';


@Component({
    selector: 'app-call-db-patient',
    templateUrl: './call-db-patient.component.html',
    entryComponents: [CallDbListComponent, CallDbViewComponent, RxMessageComponent]
})
export class CallDbPatientComponent extends CallDbDomain implements OnInit, OnDestroy, OnChanges {
    formControls: string[];
    focusElementFormControlName: string;
    patientId: any;
    relationshipList: { "relationshipId": number; "relationshipName": string; }[];
    showComponent: boolean = false;
    callDb: CallDb[];
    patientSubscription: Subscription;
    callDbPatientFormGroup: FormGroup;
    callDbPatientLookupGroup: CallDbLookupGroup;
    @Input() practiceId: number;
    @Input() callTypeId: number;
    @Input() callEndDate: Date;
    toBeAsked: any = [];
    isEdit: boolean = false;
    private sub: any;
    validationFailed: {
        [key: string]: any;
    }
    phoneTypeList: any = [];
    notificationTypes: { "notificationPreferencesId": number; "notificationPreferencesName": string; }[];
    patientData: any;
    element: Node;
    jsonData: any;
    isContinueClicked: boolean = true;
    constructor(
        private route: ActivatedRoute,
        private callDbService: CallDbService,
        private router: Router,
        private validation: RxValidation,
        private applicationBroadcaster: ApplicationBroadcaster,
        private popup: RxPopup,
        private componentResolver: ComponentFactoryResolver,
        private toast: RxToast,
    ) {
        super();
        this.validationFailed = {};

        this.formControls = ["cFirstName", "cLastName", "chFirstName", "chLastName", "birthDate", "cBNumberP", "cBNumberS", "cRelationship2Ch", "primaryPhysicianId", "textMessage", "urgent"];
        this.popup.setComponent(componentResolver);
    }

    ngOnInit(): void {
        this.showComponent = false
        this.callEndDate = new Date();
        if (localStorage.getItem('callId')) {
            this.patientId = parseInt(localStorage.getItem('callId'));
            this.applicationBroadcaster.patientIdBroadCast(this.patientId);
            this.isEdit = true;
        }
        else {
            this.callDbService.node$.subscribe(n => this.patientId = n);
            this.applicationBroadcaster.patientIdBroadCast(this.patientId);
        }
        this.showComponent = false;
        this.sub = this.route.params.subscribe(params => {
            this.practiceId = params['practiceId'];
        });
        if (this.patientSubscription)
            this.patientSubscription.unsubscribe();
        this.phoneTypeList = PHONE_TYPES;
        // this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x => x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM);
        this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM && x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage);
        this.relationshipList = RELATIONSHIPS;

        if (this.patientId && this.patientId != 0) {
            this.callDbService.group(this.practiceId, [CallTypesEnum.PatientToDoctor, this.patientId, 0], [CallManagerLookups.providers, CallManagerLookups.primaryConcerns]).then((response: any) => {
                this.callDbPatientLookupGroup = new CallDbLookupGroup();

                this.callDbPatientLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                this.callDbPatientLookupGroup.primamryConcerns = response.primaryConcerns;
                this.callDbPatientLookupGroup.callDb = new CallDb(JSON.parse(response.CallDb[0].callDb)[0]);
                let data = FormState.getState(this.patientId);
                if (data) {
                    this.callDbPatientLookupGroup.callDb = data;
                }
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                this.jsonData = JSON.parse(response.CallDb[0].annotationProps);
                // jsonData['cBNumberTypeP'] = { 'required': jsonData['cBNumberP']['required'] };
                // jsonData['cBNumberTypeS'] = { 'required': jsonData['cBNumberS']['required'] };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: this.jsonData,
                });
                if (this.callDbPatientFormGroup) {
                    this.callDbPatientFormGroup = undefined;
                }
                this.callDbPatientFormGroup = this.validation.getFormGroup(this.callDbPatientLookupGroup.callDb, customValidation);
                this.callDbPatientFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbPatientFormGroup.controls.currentCallNotPref.setValue(null);

                for (let i = 0; i < this.formControls.length; i++) {

                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                if (this.callDbPatientFormGroup.controls.urgent.value)
                    this.applicationBroadcaster.openTechincalSectionBroadCast(true);
                else
                    this.applicationBroadcaster.openTechincalSectionBroadCast(false);
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.PatientToDoctor);
                this.showComponent = true;
                this.ngOnChanges();
            });
        } else {
            this.callDbService.group(this.practiceId, [CallTypesEnum.PatientToDoctor, 0, 0], [CallManagerLookups.providers, CallManagerLookups.primaryConcerns]).then((response: any) => {
                this.callDbPatientLookupGroup = new CallDbLookupGroup();

                this.callDbPatientLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                this.callDbPatientLookupGroup.primamryConcerns = response.primaryConcerns;
                let data = FormState.getState(0);
                if (data) {
                    this.callDbPatientLookupGroup.callDb = data;
                } else {
                    this.callDbPatientLookupGroup.callDb = new CallDb();
                    this.callDbPatientLookupGroup.callDb.practiceId = this.practiceId
                    this.callDbPatientLookupGroup.callDb.callTypeId = CallTypesEnum.PatientToDoctor;
                }
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                this.jsonData = JSON.parse(response.CallDb[0].annotationProps);
                // jsonData['cBNumberTypeP'] = { 'required': jsonData['cBNumberP']['required'] };
                // jsonData['cBNumberTypeS'] = { 'required': jsonData['cBNumberS']['required'] };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: this.jsonData,
                });
                if (this.callDbPatientFormGroup) {
                    this.callDbPatientFormGroup = undefined;
                }
                this.callDbPatientFormGroup = this.validation.getFormGroup(this.callDbPatientLookupGroup.callDb, customValidation);
                this.callDbPatientFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbPatientFormGroup.controls.currentCallNotPref.setValue(null);
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.PatientToDoctor);

                for (let i = 0; i < this.formControls.length; i++) {

                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                if (this.callDbPatientFormGroup.controls.urgent.value)
                    this.applicationBroadcaster.openTechincalSectionBroadCast(true);
                else
                    this.applicationBroadcaster.openTechincalSectionBroadCast(false);
                this.showComponent = true;
                this.ngOnChanges();
            });
        }
    }

    findFocusField(value: string): boolean {
        if (this.focusElementFormControlName == value) {
            return true
        }
        else {
            return false
        }
    }

    ngOnChanges(): void {
        this.callDbPatientFormGroup.valueChanges.subscribe(val => {
            FormState.setState(this.callDbPatientFormGroup.value.sKey, this.callDbPatientFormGroup);
        });
    }

    showPopup(value) {
        this.popup.show(CallDbListComponent)
            .then(t => {
                
                if (t['patientId'])
                    this.patientId = t['patientId'];
                if (t['callDbData']) {
                    this.patientData = t['callDbData'];
                    // this.toBeAsked = JSON.parse(this.patientData.toBeAsked);
                    // let jsonData = JSON.parse(this.patientData.annotationProps);
                    var customValidation: CustomValidation[] = [];
                    customValidation.push({
                        annotationProps: this.jsonData,
                    });
                    
                    let data = JSON.parse(this.patientData.callDb)[0];
                    let dataObj = new CallDb();
                    dataObj.cFirstName = data.cFirstName;
                    dataObj.cLastName = data.cLastName;
                    dataObj.chFirstName = data.chFirstName;
                    dataObj.chLastName = data.chLastName;
                    dataObj.birthDate = data.birthDate;
                    dataObj.birthTime = data.birthTime;
                    dataObj.cBNumberP = data.cBNumberP;
                    dataObj.cBNumberS = data.cBNumberS;
                    dataObj.cRelationship2Ch = data.cRelationship2Ch;
                    dataObj.primaryPhysicianId = data.primaryPhysicianId;
                    dataObj.sKey = 0;
                    dataObj.callTypeId = CallTypesEnum.PatientToDoctor;
                    dataObj.practiceId = this.practiceId;

                    // data.textMessage = null;
                    // data.skey = 0;
                    // data.callTypeId = CallTypesEnum.PatientToDoctor;
                    if (this.callDbPatientFormGroup)
                        this.callDbPatientFormGroup = undefined;
                    this.callDbPatientFormGroup = this.validation.getFormGroup(dataObj, customValidation);
                    this.callDbPatientFormGroup.controls.currentCallProviderId.setValue(null);
                    this.callDbPatientFormGroup.controls.currentCallNotPref.setValue(null);
                }
            })
    }

    isContinue() {
        if (this.callDbPatientFormGroup.valid && this.isContinueClicked) {
            this.isContinueClicked = false;
            this.continue();
        }
    }

    continue() {
        document.body.classList.remove("show-termSection");
        let callDbObj = new CallDb(this.callDbPatientFormGroup.value);
        callDbObj.userName = user.data['userName'];
        callDbObj.agentId = user.data['userId'];
        callDbObj.cStartTimeDate = this.callEndDate;
        callDbObj.cEndTimeDate = new Date();
        callDbObj.cStartDate = this.callEndDate.toISOString();
        callDbObj.admissionTime = undefined;
        callDbObj.admissionDate = undefined;
        callDbObj.holdTillDt = undefined;
        callDbObj.holdUntillTime = undefined;
        callDbObj.referenceNumber = null;
        if (this.callDbPatientFormGroup.value.birthDate > new Date()) {
            this.toast.show(ApplicationConfiguration.get("toastValidationMessages.birthDate"), { status: 'error' });
        } else {
            if (this.isEdit) {
                callDbObj.operationType = OperationTypesEnum.EditData.toString();
                callDbObj.sKey = this.patientId;
                callDbObj.callTypeId = CallTypesEnum.PatientToDoctor;
                this.patientSubscription = this.callDbService.put(this.practiceId, callDbObj).subscribe(t => {
                    this.isEdit = false;
                    this.patientId = t.sKey;
                    localStorage.removeItem('callId');
                    this.callDbPatientFormGroup.controls.sKey.setValue(t.sKey);
                    this.callDbPatientFormGroup.controls.callTypeId.setValue(CallTypesEnum.PatientToDoctor);
                    FormState.remove(this.patientId);
                    this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.PatientToDoctor, spResult: t.spResult })
                        .then(t => {
                            if (t.isEdit) {
                                this.isContinueClicked = true;
                                this.isEdit = t.isEdit;
                                FormState.setState(this.callDbPatientFormGroup.value.sKey, this.callDbPatientFormGroup);
                            }
                            else {
                                FormState.remove(this.patientId);
                                this.callDbPatientFormGroup.reset();
                                this.callDbService.addNode(0);
                                this.callDbPatientFormGroup = this.setDefaultValue(this.callDbPatientFormGroup, CallTypesEnum.PatientToDoctor);
                                let userData = user.data;
                                userData.practiceId = null;
                                userData.practiceName = null;
                                user.data = userData;
                                this.router.navigate(['dashboard']);
                                this.applicationBroadcaster.practiceSelectionBroadCast(false);
                            }
                        });
                },
                    error => {
                        this.popup.validationFailed(error);
                    })
            } else {
                callDbObj.sKey = 0;
                this.patientSubscription = this.callDbService.post(this.practiceId, callDbObj).subscribe(t => {
                    this.patientId = t.sKey;
                    this.callDbPatientFormGroup.controls.sKey.setValue(t.sKey);
                    this.callDbPatientFormGroup.controls.callTypeId.setValue(CallTypesEnum.PatientToDoctor);
                    FormState.remove(this.patientId);
                    localStorage.removeItem('callId');
                    this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.PatientToDoctor, spResult: t.spResult })
                        .then(t => {
                            if (t.isEdit) {
                                this.isContinueClicked = true;
                                this.isEdit = t.isEdit;
                                FormState.setState(this.callDbPatientFormGroup.value.sKey, this.callDbPatientFormGroup);
                            }
                            else {
                                FormState.remove(this.patientId);
                                this.callDbPatientFormGroup.reset();
                                this.callDbService.addNode(0);
                                this.callDbPatientFormGroup = this.setDefaultValue(this.callDbPatientFormGroup, CallTypesEnum.PatientToDoctor);
                                let userData = user.data;
                                userData.practiceId = null;
                                userData.practiceName = null;
                                user.data = userData;
                                this.router.navigate(['dashboard']);
                                this.applicationBroadcaster.practiceSelectionBroadCast(false);
                            }
                        });
                },
                    error => {
                        this.popup.validationFailed(error);
                    })
            }
        }
    }

    checkDate(value) {
        let date = new Date();
        if (value < date) {
            let age = this.calculateAge(value);
            this.callDbPatientFormGroup.controls.age.setValue(age);
        }
        else {
            this.callDbPatientFormGroup.controls.age.setValue(0);
        }
    }

    clearFormGroup(): void {


        this.callDbService.addNode(0);
        this.isEdit = false;

        this.callDbPatientFormGroup.reset();
        this.callDbPatientFormGroup = this.setDefaultValue(this.callDbPatientFormGroup, CallTypesEnum.PatientToDoctor);
        if (this.patientId)
            FormState.remove(this.patientId);
        else
            FormState.remove(0);


        this.applicationBroadcaster.patientIdBroadCast(0);
        localStorage.removeItem('callId');

        let userData = user.data;
        userData.practiceId = null;
        userData.practiceName = null;
        user.data = userData;
        this.router.navigate(['dashboard']);
        this.applicationBroadcaster.practiceSelectionBroadCast(false);
        document.body.classList.remove("show-termSection");
    }

    openHelp(val) {
        if (!val) {
            // document.body.classList.remove("show-termSection");
            this.applicationBroadcaster.openTechincalSectionBroadCast(true);
        }
        else {
            // document.body.classList.add("show-termSection");
            this.applicationBroadcaster.openTechincalSectionBroadCast(false);
        }

    }

    ngOnDestroy(): void {
        if (this.patientSubscription)
            this.patientSubscription.unsubscribe();
        this.element = undefined;
        super.destroy();
    }
}
