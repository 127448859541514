import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vPrimaryConcerns')
export class vPrimaryConcern {
    constructor(vPrimaryConcern?: vPrimaryConcern )  {
        let properties = [ "primamryConcern", "primamryConcernId",];
        for (let property of properties)
            if (vPrimaryConcern && (vPrimaryConcern[property] || vPrimaryConcern[property] == 0))
                this[property] = vPrimaryConcern[property];
    }
 
    @required()
    @maxLength(50)
	@property('PrimamryConcern')
	primamryConcern : string = undefined ;
 
	@property('PrimamryConcernId')
	primamryConcernId : number =   0 ;



}
