import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Contact } from './contact';import { PracticeOfficeHour } from './practice-office-hour'; @table('Addresses')
export class Address {
    constructor(address?: Address )  {
        let properties = [ "addressId", "addressLine1", "addressLine2", "addressRefId", "addressTypeId", "addressTypeRefId", "backline", "backlineExt", "cell", "city", "countryId", "createdById", "createdDateTime", "email", "fax", "homePhone", "location", "modifiedById", "modifiedDateTime", "officePhone", "officePhoneExt", "other", "postalCode", "stateId", "textMsgDevice", "contacts", "practiceOfficeHours",];
        for (let property of properties)
            if (address && (address[property] || address[property] == 0))
                this[property] = address[property];
    }
 
	@property('AddressId')
	addressId : number =   0 ;
 
    @required()
    @maxLength(200)
	@property('AddressLine1')
	addressLine1 : string = undefined ;
 
    @maxLength(200)
	@property('AddressLine2')
	addressLine2 : string = undefined ;
 
    @range(1,2147483647)
	@property('AddressRefId')
	addressRefId : number =   undefined;
 
    @range(1,2147483647)
	@property('AddressTypeId')
	addressTypeId : number =   undefined;
 
	@property('AddressTypeRefId')
	addressTypeRefId : number =   undefined;
 
    @maxLength(50)
	@property('Backline')
	backline : string = undefined ;
 
    @maxLength(10)
@numeric()
@pattern('[0-9]')
	@property('BacklineExt')
	backlineExt : string = undefined ;
 
    @maxLength(12)
	@property('Cell')
	cell : string = undefined ;
 
    @maxLength(60)
	@property('City')
	city : string = undefined ;
 
	@property('CountryId')
	countryId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @maxLength(100)
@email()
	@property('Email')
	email : string = undefined ;
 
    @maxLength(50)
	@property('Fax')
	fax : string = undefined ;
 
    @maxLength(50)
	@property('HomePhone')
	homePhone : string = undefined ;
 
    @maxLength(100)
	@property('Location')
	location : string = undefined ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @maxLength(50)
	@property('OfficePhone')
	officePhone : string = undefined ;
 
    @maxLength(10)
@numeric()
@pattern('[0-9]')
	@property('OfficePhoneExt')
	officePhoneExt : string = undefined ;
 
    @maxLength(50)
	@property('Other')
	other : string = undefined ;
 
    @maxLength(5)
	@property('PostalCode')
	postalCode : string = undefined ;
 
	@property('StateId')
	stateId : number =   undefined;
 
	@property('TextMsgDevice')
	textMsgDevice : number =   undefined;
	@nested(Contact)
	contacts: Contact[];

	@nested(PracticeOfficeHour)
	practiceOfficeHours: PracticeOfficeHour[];




}
