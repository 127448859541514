export const ISSUE_STATUS = [
	{
        "issueStatusId": 2109,
        "issueStatusName": "Open"
    },
	{
        "issueStatusId": 2110,
        "issueStatusName": "Development"
    },
	{
        "issueStatusId": 2111,
        "issueStatusName": "returned"
    },
	{
        "issueStatusId": 2112,
        "issueStatusName": "Closed"
    },
]