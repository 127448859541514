import { Component, EventEmitter, Input, OnInit, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs/Rx';
import { user } from "@rx/security";
import { RxToast, RxPopup } from "@rx/view";
import { RxStorage } from "@rx/storage";
import { RxValidation } from '@rx/forms';
import { ApplicationConfiguration, ApplicationPage, ApplicationBroadcaster } from "@rx/core";
import { LoginService } from '../login.service';
import { UsersService } from '../../manage-user-agent/users/users.service';
import { ChangeCredentialViewModel, GetUserDetailViewModel, ChangePasswordViewModel } from '../domain/ChangeCredentialViewModel';

// import { UsersService } from '../../manage-user/users/users.service';
// import { ChangeCredentialViewModel } from '../domain/ChangeCredentialViewModel';



@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

  changePasswordViewModel: ChangePasswordViewModel;
  msgType: boolean;
  showDetails: boolean;
  phoneno: any;
  email: any;
  getUserDetailViewModel: GetUserDetailViewModel;
  resetPasswordFormGroup: FormGroup;
  changeCredentialViewModel: ChangeCredentialViewModel;
  resetPasswordSubscription: Subscription;

  timeOutId: number;
  activationKey: string;
  userName: string;
  isActivateUser: boolean;
  userId: number;
  editSubscription: Subscription;
  PasswordPattern: string = "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$^+=!*()@%&]).{8,16}";
  constructor(private router: Router,
    private loginService: LoginService,
    private usersService: UsersService,
    private validation: RxValidation,
    private activatedRoute: ActivatedRoute,
    private toast: RxToast,
    private popup: RxPopup,
    private storage: RxStorage
  ) {

    this.isActivateUser = false;
    this.activatedRoute.queryParams.subscribe(param => {
      this.activationKey = param["activationkey"];
      this.userName = param["username"];
      this.isActivateUser = param["isActivateUser"];
      this.userId = param["userId"];
      this.resetPasswordFormGroup = this.validation.getFormGroup(new ChangeCredentialViewModel());
    });

    var auth = this.storage.local.get("auth");
    if (auth != undefined) {
      this.router.navigate(["login"]);
    }


  }

  ngOnInit(): void {

  }
  resetPassword(): void {
    // 
    // this.changeCredentialViewModel = this.resetPasswordFormGroup.value;
    // this.changeCredentialViewModel.userName = this.userName;
    // this.changeCredentialViewModel.verificationCode = this.activationKey;
    // this.changeCredentialViewModel.isResetPassword = true;
    // this.resetPasswordSubscription = this.loginService.put(this.changeCredentialViewModel).subscribe(t => {
    //   this.toast.show("Reset password updated succesfully", { status: 'success' });
    //   window.location.href = "/";
    // }, error => {
    //   this.toast.show(error, { status: 'error' });
    // });
  }
  activateUser(): void {

    //   this.editSubscription = this.usersService.patch(this.userId).subscribe(t => {
    //     if (t) {
    //       this.toast.show("User activated succesfully", { status: 'success' });
    //     }
    //     else {
    //       this.toast.show("Some Problem in User Activation", { status: 'error' });
    //     }
    //     this.router.navigate(["events"]);
    //   },
    //     error => {
    //       this.popup.validationFailed(error);
    //     })
  }



  searchUserDetails(): void {
    this.getUserDetailViewModel = new GetUserDetailViewModel();
    this.getUserDetailViewModel.UserName = this.resetPasswordFormGroup.controls.userName.value;

    this.resetPasswordSubscription = this.loginService.getUserDetails(this.getUserDetailViewModel).subscribe(t => {

      if (t) {
        if (t.userId != 0) {
          this.showDetails = true;
          this.phoneno = "";
          this.email = t.email;
          this.phoneno = t.phoneNumber.substr(t.phoneNumber.length - 3);
          this.changePasswordViewModel = new ChangePasswordViewModel();
          this.changePasswordViewModel.userId = t.userId;
        }
        else {
          this.resetPasswordFormGroup.controls.userName.setValue(null);
          this.toast.show(t.responceMessage, { status: 'error' });
        }
      }
    }, error => {

      this.resetPasswordFormGroup.controls.userName.setValue(null);
      this.toast.show("No user found associated with this User name / Email / Phone number", { status: 'error' });
    });
  }

  sendNewPassword(): void {

    this.changePasswordViewModel.isEmail = this.msgType;
    this.resetPasswordSubscription = this.loginService.put(this.changePasswordViewModel).subscribe(t => {
      this.toast.show(t.responceMessage, { status: 'success' });


      window.location.href = "/";
    }, error => {
      this.toast.show("Invalid Phone number/Email Address", { status: 'error' });
    });
  }

}
