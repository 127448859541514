import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { vCallDb, CallDb } from 'src/app/database-models';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { CallManagerLookups } from '../../../../lookups';
import { PHONE_TYPES, NOTIFICATION_PREFERENCES, RELATIONSHIPS } from '../../../../database-collections';
import { CallDbLookupGroup } from '../domain/call-db.models';
import { FormGroup } from '@angular/forms';
import { RxValidation, CustomValidation, RxMessageComponent } from '@rx';
import { CallDbListComponent } from '../list/call-db-list.component';
import { ApplicationConfiguration, ApplicationBroadcaster } from '@rx/core';
import { CallDbViewComponent } from '../view/call-db-view.component';
import { CallTypesEnum } from '../../../../const/call-type';
import { OperationTypesEnum } from '../../../../const/operation-type';
import { user } from '@rx/security';
import { FormState } from '../../../../domain/form-state';
import { RecordStatusEnum, NotificationPreferencesEnum } from '../../../../enums';


@Component({
    selector: 'app-call-db-rxrefill',
    templateUrl: './call-db-rxrefill.component.html',
    entryComponents: [CallDbListComponent, CallDbViewComponent, RxMessageComponent]
})
export class CallDbRxrefillComponent extends CallDbDomain implements OnInit, OnDestroy, OnChanges {
    showComponent: boolean = false;
    formControls: string[];
    focusElementFormControlName: string;
    callDb: vCallDb[];
    callEndDate: Date;
    rxrefillSubscription: Subscription;
    callDbRxRefillFormGroup: FormGroup;
    callDbRxRefillLookupGroup: CallDbLookupGroup;
    validationFailed: {
        [key: string]: any;
    }
    @Input() practiceId: number;
    patientId: any;
    private sub: any;
    phoneTypeList: { "phoneTypesId": number; "phoneTypesName": string; }[];
    notificationTypes: { "notificationPreferencesId": number; "notificationPreferencesName": string; }[];
    relationshipList: { "relationshipId": number; "relationshipName": string; }[];
    toBeAsked: any;
    isEdit: boolean;
    rxReFill: any;
    jsonData: any;
    isContinueClicked: boolean = true;

    constructor(
        private callDbService: CallDbService,
        private dialog: RxDialog,
        private router: Router,
        private route: ActivatedRoute,
        private validation: RxValidation,
        private popup: RxPopup,
        private componentResolver: ComponentFactoryResolver,
        private applicationBroadcaster: ApplicationBroadcaster,
        private toast: RxToast,
    ) {
        super();
        this.validationFailed = {};
        this.formControls = ["cFirstName", "cLastName", "chFirstName", "chLastName", "birthDate", "cBNumberP", "cBNumberS", "cRelationship2Ch", "primaryPhysicianId", "pharmacyName", "pharmacyNumber", "textMessage", "urgent"];
        this.popup.setComponent(componentResolver)
    }

    ngOnInit(): void {
        // this.route.queryParams.subscribe(t => {
        //     if (t["patientId"])
        //         this.patientId = t["patientId"];
        //     if (t['isEdit'])
        //         this.isEdit = t['isEdit'];
        // })
        this.callEndDate = new Date();
        this.showComponent = false
        if (localStorage.getItem('callId')) {
            this.patientId = parseInt(localStorage.getItem('callId'));
            this.applicationBroadcaster.patientIdBroadCast(this.patientId);
            this.isEdit = true;
        }
        else
            this.callDbService.node$.subscribe(n => this.patientId = n);
        this.sub = this.route.params.subscribe(params => {
            this.practiceId = params['practiceId'];
        });
        if (this.rxrefillSubscription)
            this.rxrefillSubscription.unsubscribe();
        this.phoneTypeList = PHONE_TYPES;
        // this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM);
        this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x => x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM && x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage);
        this.relationshipList = RELATIONSHIPS;
        if (this.patientId && this.patientId != 0) {
            this.callDbService.group(this.practiceId, [CallTypesEnum.RxRefills, this.patientId, 0], [CallManagerLookups.providers, CallManagerLookups.primaryConcerns]).then((response: any) => {
                this.callDbRxRefillLookupGroup = new CallDbLookupGroup();
                this.callDbRxRefillLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                this.callDbRxRefillLookupGroup.primamryConcerns = response.primaryConcerns;
                this.callDbRxRefillLookupGroup.callDb = new CallDb(JSON.parse(response.CallDb[0].callDb)[0]);
                let data = FormState.getState(this.patientId);
                if (data) {
                    this.callDbRxRefillLookupGroup.callDb = data
                }
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                this.jsonData = JSON.parse(response.CallDb[0].annotationProps);
                // jsonData['cBNumberTypeP'] = { 'required': jsonData['cBNumberP']['required'] };
                // jsonData['cBNumberTypeS'] = { 'required': jsonData['cBNumberS']['required'] };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: this.jsonData,
                });
                if (this.callDbRxRefillFormGroup) {
                    this.callDbRxRefillFormGroup = undefined;
                }
                this.callDbRxRefillFormGroup = this.validation.getFormGroup(this.callDbRxRefillLookupGroup.callDb, customValidation);
                this.callDbRxRefillFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbRxRefillFormGroup.controls.currentCallNotPref.setValue(null);
                for (let i = 0; i < this.formControls.length; i++) {
                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.RxRefills);
                this.showComponent = true;
                this.ngOnChanges();
            });
        } else {
            this.callDbService.group(this.practiceId, [CallTypesEnum.RxRefills, 0, 0], [CallManagerLookups.providers, CallManagerLookups.primaryConcerns]).then((response: any) => {
                this.callDbRxRefillLookupGroup = new CallDbLookupGroup();
                this.callDbRxRefillLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                this.callDbRxRefillLookupGroup.primamryConcerns = response.primaryConcerns;
                let data = FormState.getState(0);
                if (data) {
                    this.callDbRxRefillLookupGroup.callDb = data;
                } else {
                    this.callDbRxRefillLookupGroup.callDb = new CallDb();
                    this.callDbRxRefillLookupGroup.callDb.practiceId = this.practiceId
                    this.callDbRxRefillLookupGroup.callDb.callTypeId = CallTypesEnum.RxRefills;
                }
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                this.jsonData = JSON.parse(response.CallDb[0].annotationProps);
                // jsonData['cBNumberTypeP'] = { 'required': jsonData['cBNumberP']['required'] };
                // jsonData['cBNumberTypeS'] = { 'required': jsonData['cBNumberS']['required'] };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: this.jsonData,
                });
                if (this.callDbRxRefillFormGroup) {
                    this.callDbRxRefillFormGroup = undefined;
                }
                this.callDbRxRefillFormGroup = this.validation.getFormGroup(this.callDbRxRefillLookupGroup.callDb, customValidation);
                this.callDbRxRefillFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbRxRefillFormGroup.controls.currentCallNotPref.setValue(null);
                for (let i = 0; i < this.formControls.length; i++) {
                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.RxRefills);
                this.showComponent = true;
                this.ngOnChanges();
            });
        }

    }

    ngOnChanges(): void {
        this.callDbRxRefillFormGroup.valueChanges.subscribe(val => {
            FormState.setState(this.callDbRxRefillFormGroup.value.sKey, this.callDbRxRefillFormGroup);
        });
    }

    showPopup(value) {
        this.popup.show(CallDbListComponent)
            .then(t => {

                if (t['patientId'])
                    this.patientId = t['patientId'];
                if (t['callDbData']) {
                    this.rxReFill = t['callDbData'];
                    // this.jsonData = JSON.parse(this.rxReFill.annotationProps);
                    var customValidation: CustomValidation[] = [];
                    customValidation.push({
                        annotationProps: this.jsonData,
                    });
                    let data = JSON.parse(this.rxReFill.callDb)[0];

                    let dataObj = new CallDb();
                    dataObj.cFirstName = data.cFirstName;
                    dataObj.cLastName = data.cLastName;
                    dataObj.chFirstName = data.chFirstName;
                    dataObj.chLastName = data.chLastName;
                    dataObj.birthDate = data.birthDate;
                    dataObj.birthTime = data.birthTime;
                    dataObj.cBNumberP = data.cBNumberP;
                    dataObj.cBNumberS = data.cBNumberS;
                    dataObj.cRelationship2Ch = data.cRelationship2Ch;
                    dataObj.primaryPhysicianId = data.primaryPhysicianId;
                    dataObj.sKey = 0;
                    dataObj.callTypeId = CallTypesEnum.RxRefills;
                    dataObj.practiceId = this.practiceId;

                    // data.textMessage = null;
                    // data.skey = 0;
                    // data.callTypeId = CallTypesEnum.RxRefills;
                    if (this.callDbRxRefillFormGroup)
                        this.callDbRxRefillFormGroup = undefined;
                    this.callDbRxRefillFormGroup = this.validation.getFormGroup(dataObj, customValidation);
                    this.callDbRxRefillFormGroup.controls.currentCallProviderId.setValue(null);
                    this.callDbRxRefillFormGroup.controls.currentCallNotPref.setValue(null);
                }
            })
    }

    continue() {

        let callDbObj = new CallDb(this.callDbRxRefillFormGroup.value);


        callDbObj.userName = user.data['userName'];
        callDbObj.agentId = user.data['userId'];
        callDbObj.cStartTimeDate = this.callEndDate;
        callDbObj.cStartDate = this.callEndDate.toISOString();
        callDbObj.cEndTimeDate = new Date();
        callDbObj.referenceNumber = null;
        callDbObj.admissionTime = null;
        callDbObj.admissionDate = null;
        callDbObj.holdTillDt = null;
        callDbObj.holdUntillTime = null;
        callDbObj.callTypeId = CallTypesEnum.RxRefills;

        if (callDbObj.birthDate > new Date()) {
            this.toast.show(ApplicationConfiguration.get("toastValidationMessages.birthDate"), { status: 'error' });
        } else {
            if (this.isEdit) {
                callDbObj.operationType = OperationTypesEnum.EditData.toString();
                callDbObj.sKey = this.patientId;
                callDbObj.callTypeId = CallTypesEnum.RxRefills;
                this.rxrefillSubscription = this.callDbService.put(this.practiceId, callDbObj).subscribe(t => {
                    this.isEdit = false;
                    this.patientId = t.sKey;
                    this.callDbRxRefillFormGroup.controls.sKey.setValue(t.sKey);
                    this.callDbRxRefillFormGroup.controls.callTypeId.setValue(CallTypesEnum.RxRefills);
                    FormState.remove(this.patientId);
                    localStorage.removeItem('callId');
                    this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.RxRefills, spResult: t.spResult })
                        .then(t => {
                            if (t.isEdit) {
                                this.isContinueClicked = true;
                                this.isEdit = t.isEdit;
                                // FormState.remove(this.patientId);
                                FormState.setState(this.callDbRxRefillFormGroup.value.sKey, this.callDbRxRefillFormGroup);
                            }
                            else {
                                FormState.remove(this.patientId);
                                this.callDbRxRefillFormGroup.reset();
                                this.callDbService.addNode(0);
                                this.callDbRxRefillFormGroup = this.setDefaultValue(this.callDbRxRefillFormGroup, CallTypesEnum.RxRefills);
                                let userData = user.data;
                                userData.practiceId = null;
                                userData.practiceName = null;
                                user.data = userData;
                                this.router.navigate(['dashboard']);
                                this.applicationBroadcaster.practiceSelectionBroadCast(false);
                            }
                        });
                },
                    error => {
                        this.popup.validationFailed(error);
                    })
            } else {
                callDbObj.sKey=0;
                this.rxrefillSubscription = this.callDbService.post(this.practiceId, callDbObj).subscribe(t => {
                    this.patientId = t.sKey;
                    this.callDbRxRefillFormGroup.controls.sKey.setValue(t.sKey);
                    this.callDbRxRefillFormGroup.controls.callTypeId.setValue(CallTypesEnum.RxRefills);
                    FormState.remove(this.patientId);
                    localStorage.removeItem('callId');
                    this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.RxRefills, spResult: t.spResult })
                        .then(t => {
                            if (t.isEdit) {
                                this.isContinueClicked = true;
                                this.isEdit = t.isEdit;
                                // FormState.remove(this.patientId);
                                FormState.setState(this.callDbRxRefillFormGroup.value.sKey, this.callDbRxRefillFormGroup);
                            }
                            else {
                                FormState.remove(this.patientId);
                                this.callDbRxRefillFormGroup.reset();
                                this.callDbService.addNode(0);
                                this.callDbRxRefillFormGroup = this.setDefaultValue(this.callDbRxRefillFormGroup, CallTypesEnum.RxRefills);
                                let userData = user.data;
                                userData.practiceId = null;
                                userData.practiceName = null;
                                user.data = userData;
                                this.router.navigate(['dashboard']);
                                this.applicationBroadcaster.practiceSelectionBroadCast(false);
                            }
                        });
                },
                    error => {
                        this.popup.validationFailed(error);
                    })
            }
        }
    }

    checkDate(value) {
        let date = new Date();
        if (value < date) {
            let age = this.calculateAge(value);
            this.callDbRxRefillFormGroup.controls.age.setValue(age);
        }
        else {
            this.callDbRxRefillFormGroup.controls.age.setValue(0);
        }
    }

    isContinue() {
        if (this.callDbRxRefillFormGroup.valid && this.isContinueClicked) {
            this.isContinueClicked = false;
            this.continue();
        }
    }

    clearFormGroup(): void {

        this.callDbService.addNode(0);
        this.isEdit = false;
        this.callDbRxRefillFormGroup.reset();
        this.callDbRxRefillFormGroup = this.setDefaultValue(this.callDbRxRefillFormGroup, CallTypesEnum.RxRefills);
        if (this.patientId)
            FormState.remove(this.patientId);
        else
            FormState.remove(0);
        this.applicationBroadcaster.patientIdBroadCast(0);
        localStorage.removeItem('callId');

        let userData = user.data;
        userData.practiceId = null;
        userData.practiceName = null;
        user.data = userData;
        this.router.navigate(['dashboard']);
        this.applicationBroadcaster.practiceSelectionBroadCast(false);
    }

    changeCallType(value) {
        if (!value.target.checked) {
            this.callDbRxRefillFormGroup.controls.urgent.setValue(false)
            // this.callDbRxRefillFormGroup.controls.callTypeId.setValue(CallTypesEnum.RxRefills.toString());
        } else {
            this.callDbRxRefillFormGroup.controls.urgent.setValue(true);
            // this.callDbRxRefillFormGroup.controls.callTypeId.setValue(CallTypesEnum.PatientToDoctor.toString());
        }
    }

    ngOnDestroy(): void {
        if (this.rxrefillSubscription)
            this.rxrefillSubscription.unsubscribe();
        super.destroy();
    }
}
