import {Injectable } from "@angular/core";
import {Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { UserAuthenticationViewModel, UserCredentialModel } from "src/app/components/login/domain/login.models";
import { ChangeCredentialViewModel, GetUserDetailViewModel, ChangePasswordViewModel } from "./domain/ChangeCredentialViewModel";


@Injectable()
export class LoginService {
    private username: string;
    private api: string = 'api/UserAuthentication/login'
    private apiforgotpassword: string = 'api/UserAuthentication/forgotpassword'
    private apichangepassword: string = 'api/UserAuthentication/ChangePassword'
    private getUserDetailapi: string ='api/UserAuthentication/GetUserDetails'
    

    constructor(
        private http: RxHttp
    ) { }

    post(userCredentialViewModel: UserCredentialModel): Observable<UserAuthenticationViewModel> {
        return this.http.post<UserAuthenticationViewModel>(this.api, userCredentialViewModel, false);
    }
    postForgotPassword(userCredentialViewModel: UserCredentialModel): Observable<any> {
        return this.http.post<any>(this.apiforgotpassword, userCredentialViewModel, false);
    }
    put(userCredentialViewModel: ChangePasswordViewModel): Observable<any> {
        return this.http.post<any>(this.apichangepassword, userCredentialViewModel, false);
    }
    getUserDetails(userCredentialViewModel: GetUserDetailViewModel): Observable<any> {
        return this.http.post<any>(this.getUserDetailapi,userCredentialViewModel, false);
    }

}
