
import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxMessageComponent } from '@rx/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';
import { DatePipe } from '@angular/common';
import { ComponentCanDeactivate } from "@rx/core";
import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';
import { RxValidation } from '@rx/forms';

import { user } from '@rx/security';

import { ScheduleTelevisitService } from '../schedule-televisit.service';
import { ScheduleTelevisitDomain } from '../domain/schedule-televisit.domain';
import { ScheduleTelevisitLookupGroup } from '../domain/schedule-televisit.models';
import { userInfo } from 'os';
import { TimeSource } from 'src/app/const/time-source';
import { PracticesService } from '../../agent-office/practices/practices.service';
import { ScheduleTelevisit } from 'src/app/database-models/schedule-televisit';
import * as  firebase from 'firebase';
@Component({
    templateUrl: './schedule-televisit-add.component.html',
    entryComponents: [RxMessageComponent]
})
export class ScheduleTelevisitAddComponent extends ScheduleTelevisitDomain implements OnInit, OnDestroy, ComponentCanDeactivate {
    showComponent: boolean = false;
    @Input() providerId: number;
    scheduleTelevisitFormGroup: FormGroup;
    addSubscription: Subscription;
    scheduleTelevisitLookupGroup: ScheduleTelevisitLookupGroup;;
    timeSource = TimeSource;
    hours: any;
    minutes: any;
    seconds: any;
    Date: any;
    day: any;
    month: any;
    result: any;
    currtime: any;
    totime: any;
    minDate: any;
    isNow: boolean = false;
    isForcedschedule: boolean = false
    practiceId: number;
    validationFailed: {
        [key: string]: any;
    }
    isNowflag: boolean = false;
    formList: any = [];
    optedForCopayment: boolean = false;
    constructor(
        private validation: RxValidation,
        private router: Router,
        private popup: RxPopup,
        private toast: RxToast,
        private scheduleTelevisitService: ScheduleTelevisitService,
        private el: ElementRef,
        private practicesService: PracticesService,

    ) {
        super();
        this.validationFailed = {};
        this.providerId = user.data['userId']
        this.practiceId = user.data['practiceId'];
        this.optedForCopayment = user.data['optedForCopayment'];

        this.minDate = new Date();

        if (user.data["roleId"] == 1004 ||user.data["roleId"] == 1005 || user.data["roleId"] == 1011) {
            var id = localStorage.getItem("selectedProviderUserId");
            var userId = parseInt(id);

            if (userId > 0) {
                this.providerId = userId;
            }
        }
    }

    ngOnInit(): void {
        this.scheduleTelevisitLookupGroup = new ScheduleTelevisitLookupGroup();

        this.scheduleTelevisitLookupGroup.scheduleTelevisit = new ScheduleTelevisit();
        this.scheduleTelevisitFormGroup = this.validation.getFormGroup(this.scheduleTelevisitLookupGroup.scheduleTelevisit);
        this.showComponent = true;

        var date = new Date();
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        var minutes = date.getMinutes();
        var hours = date.getHours();
        this.seconds = date.getSeconds();

        this.day = this.Day(day)
        this.month = this.Month(monthIndex)
        this.minutes = this.Minutes(minutes)
        this.hours = this.Hours(hours)
        this.seconds = this.Day(this.seconds)

        this.Date = year + '/' + this.month + '/' + this.day + " " + this.hours + ":" + this.minutes + ":" + this.seconds;

        this.scheduleTelevisitFormGroup.controls['comments'].setValue('web');
        this.scheduleTelevisitFormGroup.controls['createdById'].setValue(user.data['userId']);
        this.scheduleTelevisitFormGroup.controls['providerId'].setValue(this.providerId);
        this.scheduleTelevisitFormGroup.controls['practiceId'].setValue(this.practiceId);
        this.scheduleTelevisitFormGroup.controls['createdDateTime'].setValue(this.Date);
        this.scheduleTelevisitFormGroup.controls['isWeb'].setValue(true);
        this.scheduleTelevisitFormGroup.controls['isGroupCall'].setValue(false);
        // if (this.optedForCopayment) {
        //     this.scheduleTelevisitFormGroup.controls['coPayAmount'].setValidators([Validators.required]);

        // }

        this.scheduleTelevisitService.getFormMapping(this.practiceId).subscribe(list => {
            this.formList = list;
        });

    }
    parentNo: boolean = false;
    childNo: boolean = false;


    changeMobileNo(text: any) {
        if (text == "now") {

            if (this.hours > 12) {
                this.hours -= 12;
            } else if (this.hours === 0) {
                this.hours = 12;
            }

            this.isNow = true;
            this.scheduleTelevisitFormGroup.controls['visitDate'].setValue(new Date());
            this.scheduleTelevisitFormGroup.controls['fromTime'].setValue(this.hours + ":" + this.minutes);
        }
        if (text == "later") {
            this.isNow = false;
        }
    }

    addForm(event) {

        this.scheduleTelevisitFormGroup.controls['parentMobileNo'].setValidators([Validators.required]);

        var sms = this.scheduleTelevisitFormGroup.value.sms;
        if (sms != null && sms != "") {
            this.scheduleTelevisitFormGroup.controls['parentMobileNo'].setValue(this.scheduleTelevisitFormGroup.value.sms);
        }
        else {
            var cm = this.scheduleTelevisitFormGroup.value.parentMobileNo;
            if (cm == null || cm == "")
                this.scheduleTelevisitFormGroup.controls['parentMobileNo'].setValue('');
        }

    }
    addFormchild(event) {
        this.scheduleTelevisitFormGroup.controls['childMobileNo'].setValidators([Validators.required]);
        var cm = this.scheduleTelevisitFormGroup.value.childMobileNo;
        if (cm == null || cm == "")
            this.scheduleTelevisitFormGroup.controls['childMobileNo'].setValue('');
    }

    removeParent() {
        var parentFormIds = this.scheduleTelevisitFormGroup.value.parentFormIds;
        if (parentFormIds && parentFormIds != "") {
            var arr = parentFormIds.split(",");
            if (arr.length == 1 || arr.length == 0) {
                this.scheduleTelevisitFormGroup.controls['parentMobileNo'].setValidators([Validators.nullValidator]);
                this.scheduleTelevisitFormGroup.controls['parentMobileNo'].setValue(null);

            }
        }
    }
    removeChild() {
        var childFormIds = this.scheduleTelevisitFormGroup.value.childFormIds;
        if (childFormIds && childFormIds != "") {
            var arr = childFormIds.split(",");
            if (arr.length == 1 || arr.length == 0) {
                this.scheduleTelevisitFormGroup.controls['childMobileNo'].setValidators([Validators.nullValidator]);
                this.scheduleTelevisitFormGroup.controls['childMobileNo'].setValue(null);
            }
        }
    }
    change(event) {
        if (event.target.id == "latertab") {
            this.isNow = false;
            this.scheduleTelevisitFormGroup.controls['sms'].setValue(this.scheduleTelevisitFormGroup.value.sms);
            this.scheduleTelevisitFormGroup.controls['patientFirstName'].setValue(this.scheduleTelevisitFormGroup.value.patientFirstName);
            this.scheduleTelevisitFormGroup.controls['patientLastName'].setValue(this.scheduleTelevisitFormGroup.value.patientLastName);
            if (this.scheduleTelevisitFormGroup.value.duration != null) {
                this.scheduleTelevisitFormGroup.controls['duration'].setValue(parseInt(this.scheduleTelevisitFormGroup.value.duration));
            }
            this.scheduleTelevisitFormGroup.controls['visitDate'].setValue(null);
            this.scheduleTelevisitFormGroup.controls['fromTime'].setValue(null);
            this.scheduleTelevisitFormGroup.controls['toTime'].setValue(null);
        }
        if (event.target.id == "nowtab") {
            this.isNow = true;

            if (this.hours > 12) {
                this.hours -= 12;
            } else if (this.hours === 0) {
                this.hours = 12;
            }

            this.scheduleTelevisitFormGroup.controls['sms'].setValue(this.scheduleTelevisitFormGroup.value.sms);
            this.scheduleTelevisitFormGroup.controls['patientFirstName'].setValue(this.scheduleTelevisitFormGroup.value.patientFirstName);
            this.scheduleTelevisitFormGroup.controls['patientLastName'].setValue(this.scheduleTelevisitFormGroup.value.patientLastName);
            if (this.scheduleTelevisitFormGroup.value.duration != null) {
                this.scheduleTelevisitFormGroup.controls['duration'].setValue(parseInt(this.scheduleTelevisitFormGroup.value.duration));
            }

            this.scheduleTelevisitFormGroup.controls['visitDate'].setValue(new Date());
            this.scheduleTelevisitFormGroup.controls['fromTime'].setValue(this.hours + ":" + this.minutes);
            //this.scheduleTelevisitFormGroup.controls['fromTime'].setValue(null);
            this.scheduleTelevisitFormGroup.controls['toTime'].setValue(null);
        }
    }

    addScheduleTelevisit(): void {

        let mobile = this.scheduleTelevisitFormGroup.value.sms;

        mobile = mobile.replace(/\-/g, '');
        this.scheduleTelevisitFormGroup.controls['sms'].setValue(mobile);

        // get utc date of visitdate for firebase logevents ------------------------------
        var fromTime = this.timeConversionTo24Hour(this.scheduleTelevisitFormGroup.value.fromTime);
        var datePipe = new DatePipe('en-US');
        var changeformate = datePipe.transform(this.scheduleTelevisitFormGroup.value.visitDate, 'yyyy/MM/dd')
        changeformate = changeformate.replace(/\//g, '-');

        var UtcTimefrom = new Date(changeformate + " " + fromTime + ":" + "00");
        var fromdateutc = this.Hours(UtcTimefrom.getUTCHours()) + ":" + this.Minutes(UtcTimefrom.getUTCMinutes()) + ":00";
        let dateutc = UtcTimefrom.getUTCFullYear() + "-" + this.Month(UtcTimefrom.getUTCMonth()) + "-" + this.Day(UtcTimefrom.getUTCDate()) + "T" + fromdateutc;
        //------------------------------------------------------------------------

        if (this.totime != null) {
            if (this.isNow == false) {
                let data = this.scheduleTelevisitFormGroup.value;

                data.fromTime = this.timeConversionTo24Hour(this.scheduleTelevisitFormGroup.value.fromTime);
                data.toTime = this.timeConversionTo24Hour(this.scheduleTelevisitFormGroup.value.fromTime);
                if (data.coPayAmount == null || data.coPayAmount == "") {
                    data.coPayAmount = 0;
                }
                this.addSubscription = this.scheduleTelevisitService.post(data).subscribe(t => {
                    this.result = t;
                    if (this.result.errormessage != null) {
                        this.isForcedschedule = true
                    }
                    else {
                        this.addScreeningFormToFireabse(t);

                        let userdata = user.data;
                        firebase.analytics().setUserId('' + userdata.userId + '');

                        firebase.analytics().setUserProperties({
                            userId: '' + userdata.userId + '',
                            practiceId: '' + userdata.practiceId + '',
                            roleId: '' + userdata.roleId + '',
                            userName: '' + userdata.userName + '',
                            practiceName: '' + userdata.practiceName + '',
                            mobilenumber: '' + userdata.mobileNumber + '',
                            providerId: '' + userdata.providerId + '',
                            isOptedForTelehealth: userdata.optedForTelehealth
                        });
                        firebase.analytics().logEvent('create_televisit', {
                            televisitId: '' + t["televisitId"] + '',
                            visitingDate: dateutc.toString(),
                            duration: '' + data.duration + '',
                            createdBy: '' + data.createdById + '',
                            isFromMessageDetail: false
                        });
                        this.toast.show("Telehealth invitation has been sent for the scheduled time. ", { status: 'success' });
                        this.popup.hide(ScheduleTelevisitAddComponent);
                    }
                },
                    error => {
                        this.popup.hide(ScheduleTelevisitAddComponent);
                        console.log(error);

                    })
            }
            else {

                this.scheduleTelevisitFormGroup.controls['isNow'].setValue(true);
                let data = this.scheduleTelevisitFormGroup.value;
                data.fromTime = this.timeConversionTo24Hour(this.scheduleTelevisitFormGroup.value.fromTime)
                data.toTime = data.fromTime;

                if (data.coPayAmount == null || data.coPayAmount == "") {
                    data.coPayAmount = 0;
                }

                this.isNowflag = true;
                this.addSubscription = this.scheduleTelevisitService.post(data).subscribe(t => {
                    this.result = t;
                    if (this.result.errormessage != null) {
                        this.isForcedschedule = true;
                    }
                    else {
                        this.addScreeningFormToFireabse(t);
                        let userdata = user.data;
                        firebase.analytics().setUserId('' + userdata.userId + '');

                        firebase.analytics().setUserProperties({
                            userId: '' + userdata.userId + '',
                            practiceId: '' + userdata.practiceId + '',
                            roleId: '' + userdata.roleId + '',
                            userName: '' + userdata.userName + '',
                            practiceName: '' + userdata.practiceName + '',
                            mobilenumber: '' + userdata.mobileNumber + '',
                            providerId: '' + userdata.providerId + '',
                            isOptedForTelehealth: userdata.optedForTelehealth
                        });
                        firebase.analytics().logEvent('create_televisit', {
                            televisitId: '' + t["televisitId"] + '',
                            visitingDate: dateutc.toString(),
                            duration: '' + data.duration + '',
                            createdBy: '' + data.createdById + '',
                            isFromMessageDetail: false
                        });
                        this.toast.show("Telehealth invitation has been sent for the scheduled time. ", { status: 'success' });
                        this.popup.hide(ScheduleTelevisitAddComponent);
                    }
                },
                    error => {
                        this.popup.hide(ScheduleTelevisitAddComponent);
                        console.log(error);
                    })
            }
        }
        else {
            this.toast.show("please select duration", { status: 'error' });
        }

    }

    addScreeningFormToFireabse(t: any) {
        var obj = {};
        var childList = [];
        var parentList = [];

        t["childList"].forEach(element => {
            var name = this.formList.where(x => x.screeningFormId == element.FormId);
            var objchild = {
                formId: element.FormId,
                formName: name[0].formName,
                formNo: element.FormNo
            };
            childList.push(objchild);
        });
        t["parentList"].forEach(element => {
            var name = this.formList.where(x => x.screeningFormId == element.FormId);
            var objparent = {
                formId: element.FormId,
                formName: name[0].formName,
                formNo: element.FormNo
            };
            parentList.push(objparent);
        });
        obj['childScreeningForm'] = childList;
        obj['parentScreeningForm'] = parentList;
        firebase.firestore().collection("TelevisitForm").doc(t["televisitId"].toString()).set(obj, { merge: true });

    }
    timeToMins(time) {
        var b = time.split(':');
        return b[0] * 60 + +b[1];
    }

    // Convert minutes to a time in format hh:mm
    // Returned value is in range 00  to 24 hrs
    timeFromMins(mins) {
        function z(n) { return (n < 10 ? '0' : '') + n; }
        var h = (mins / 60 | 0) % 24;
        var m = mins % 60;
        return z(h) + ':' + z(m);
    }

    // Add two times in hh:mm format
    addTimes(t0, t1) {
        return this.timeFromMins(this.timeToMins(t0) + this.timeToMins(t1));
    }
    hidePopup() {
        this.popup.hide(ScheduleTelevisitAddComponent);
    }

    duration(event) {
        var select = event.target.value
        if (select != null) {
            var fromhr = parseInt(select);
            var hours = Math.floor(fromhr / 60);
            var minutes = fromhr % 60;
            this.totime = this.Hours(hours) + ":" + this.Minutes(minutes);
            this.scheduleTelevisitFormGroup.controls['duration'].setValue(parseInt(select));
        }
    }
    Forcedschedule() {

        let data = this.scheduleTelevisitFormGroup.value;
        let mobile = this.scheduleTelevisitFormGroup.value.sms;
        mobile = mobile.replace(/\-/g, '');
        data.fromTime = this.timeConversionTo24Hour(this.scheduleTelevisitFormGroup.value.fromTime);
        data.toTime = data.fromTime;
        data.sms = mobile;
        data.isForcedSchedule = true;

        // get utc date of visitdate for firebase logevents ------------------------------
        var fromTime = this.timeConversionTo24Hour(this.scheduleTelevisitFormGroup.value.fromTime);
        var datePipe = new DatePipe('en-US');
        var changeformate = datePipe.transform(this.scheduleTelevisitFormGroup.value.visitDate, 'yyyy/MM/dd')
        changeformate = changeformate.replace(/\//g, '-');

        var UtcTimefrom = new Date(changeformate + " " + fromTime + ":" + "00");
        var fromdateutc = this.Hours(UtcTimefrom.getUTCHours()) + ":" + this.Minutes(UtcTimefrom.getUTCMinutes()) + ":00";
        let dateutc = UtcTimefrom.getUTCFullYear() + "-" + this.Month(UtcTimefrom.getUTCMonth()) + "-" + this.Day(UtcTimefrom.getUTCDate()) + "T" + fromdateutc;
        //------------------------------------------------------------------------

        if (this.isNowflag) {
            this.scheduleTelevisitFormGroup.controls['isNow'].setValue(true);
        }
        this.addSubscription = this.scheduleTelevisitService.post(data).subscribe(t => {
            this.result = t;
            if (this.result.errormessage != null) {

                this.isForcedschedule = true;
            }
            else {
                this.addScreeningFormToFireabse(t);

                let userdata = user.data;
                firebase.analytics().setUserId('' + userdata.userId + '');

                firebase.analytics().setUserProperties({
                    userId: '' + userdata.userId + '',
                    practiceId: '' + userdata.practiceId + '',
                    roleId: '' + userdata.roleId + '',
                    userName: '' + userdata.userName + '',
                    practiceName: '' + userdata.practiceName + '',
                    mobilenumber: '' + userdata.mobileNumber + '',
                    providerId: '' + userdata.providerId + '',
                    isOptedForTelehealth: userdata.optedForTelehealth
                });
                firebase.analytics().logEvent('create_televisit', {
                    televisitId: '' + t["televisitId"] + '',
                    visitingDate: dateutc.toString(),
                    duration: '' + data.duration + '',
                    createdBy: '' + data.createdById + '',
                    isFromMessageDetail: false
                });
                this.toast.show("Telehealth invitation has been sent for the scheduled time. ", { status: 'success' });
                this.popup.hide(ScheduleTelevisitAddComponent);

            }
        },
            error => {
                this.popup.hide(ScheduleTelevisitAddComponent);
                console.log(error);
            })

    }
    Day(day) {
        if (day < 10) {
            return '0' + day;
        }
        else {
            return day + '';
        }

    }
    Month(monthIndex) {
        if ((monthIndex + 1) < 10) {
            return '0' + (monthIndex + 1);
        }
        else {
            return (monthIndex + 1) + '';
        }

    }
    Minutes(minutes) {
        if (minutes < 10) {
            return '0' + minutes;
        }
        else {
            return minutes + '';
        }

    }

    Hours(hours) {
        if (hours < 10) {
            return '0' + hours;
        }
        else {
            return hours + '';
        }

    }


    canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        return !this.scheduleTelevisitFormGroup.dirty;
    }

    ngOnDestroy(): void {
        if (this.addSubscription)
            this.addSubscription.unsubscribe();
        super.destroy();
    }

}
