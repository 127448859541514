import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxFormsModule } from "@rx/forms";
import { RxViewModule } from "@rx/view";
import { RxTableModule } from "@rx/table";
import { DASHBOARD_ADD_ROUTING } from './dashboard.routing';
import { DashboardComponent } from './dashboard.component';
import { PracticesSharedComponentModule } from '../agent-office/practices/practices-shared-component.module';
import { AlertsSharedComponentModule } from 'src/app/components/agent-alert/alerts/alerts-shared-component.module';
import { CallDbSharedComponentModule } from '../call-manager/call-db/call-db-shared-component.module';
import { OnCallRostersSharedComponentModule } from '../agent-roster/on-call-rosters/on-call-rosters-shared-component.module';

 



@NgModule({
  imports: [
    DASHBOARD_ADD_ROUTING,
    CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,AlertsSharedComponentModule,
    PracticesSharedComponentModule,  CallDbSharedComponentModule,OnCallRostersSharedComponentModule
  ],
  declarations: [DashboardComponent],
  exports: [RouterModule],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule { }
