import { NgModule } from '@angular/core';
import {RouterModule } from '@angular/router';


import { ON_CALL_OVERRIDE_ROUTING } from './on-call-override.routing';
import { OnCallOverrideService } from './on-call-override.service';

@NgModule({
    imports: [ON_CALL_OVERRIDE_ROUTING],
    exports: [RouterModule],
    providers: [OnCallOverrideService]
})
export class OnCallOverrideModule { }