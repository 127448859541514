import { Component, EventEmitter, Input, OnInit, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/Rx';

import { RxToast, RxPopup } from "@rx/view";
import { RxStorage } from "@rx/storage";

import { RxValidation } from '@rx/forms';
import { user, UserPermissionCache } from "@rx/security";
import { ApplicationConfiguration, ApplicationPage, ApplicationBroadcaster } from "@rx/core";
import { Router } from '@angular/router';
import { UserCredentialModel } from '../domain/login.models';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnDestroy, OnInit {
  loginFormGroup: FormGroup;
  timeOutId: number;
  loginSubscription: Subscription;
  minutes: number;
  userName: string;
  isForgotPassword: boolean = false;
  userCredentialViewModel: UserCredentialModel;
  showComponent: boolean;
  constructor(private applicationBroadCaster: ApplicationBroadcaster, private storage: RxStorage,
    private router: Router,
    private validation: RxValidation,
    private loginService: LoginService,
    private toast: RxToast,
  ) {

  }

  ngOnInit() {
    
     this.loginFormGroup = this.validation.getFormGroup(new UserCredentialModel());
      this.isForgotPassword = false;
      this.showComponent = true;

    // var auth = this.storage.local.get("auth");
    // if (auth)
    //   this.router.navigate(["dashboard"]);
    // else {
    //   this.loginFormGroup = this.validation.getFormGroup(new UserCredentialModel());
    //   this.isForgotPassword = false;
    //   this.showComponent = true;
    // }
  }

  login(): void {

    if (this.loginSubscription)
      this.loginSubscription.unsubscribe();
    this.userCredentialViewModel = this.loginFormGroup.value;
    this.userCredentialViewModel.failedCount = this.storage.local.get('failedCount') == undefined ? 0 : this.storage.local.get('failedCount');
    
    this.loginService.post(this.loginFormGroup.value).subscribe(t => {
      if (t.failedLogin) {
        this.toast.show(t.validationMessage, { status: 'error' });
      }
      else {

        document.cookie = "requestContext=true; SameSite=None;Secure";
        this.storage.local.save('auth', t.token);
        
        var auth = this.storage.local.get('auth');
        if (auth){
          //this.router.navigate(["dashboard"]);
        }
        else {
          this.loginFormGroup = this.validation.getFormGroup(new UserCredentialModel());
          this.isForgotPassword = false;
          this.showComponent = true;
        }


        user.data = { 'fullName': t.fullName, 'roleId': t.roleId, 'userName': t.userName, 'userId': t.userId, 'practiceId': t.practiceId, 'practiceName': '','ProfilePicture':t.profilePicture }

        user.authorizationPermissionItem = t.modules;
        localStorage.setItem('isUnlockedUser', t.isUnlockedUser);
        for (var rootModuleId in t.modules) {
          let userPermissionCache = new UserPermissionCache({ rootModuleId: parseInt(rootModuleId), permission: t.modules[rootModuleId], requestedDate: this.getDate() });
          user.permissions.push(userPermissionCache);
        }
        this.timeOutId = window.setTimeout(() => {
          window.clearTimeout(this.timeOutId);
          this.applicationBroadCaster.loginBroadCast(true)
        }, 50);

        
      }

      this.storage.local.save('failedCount', t.failedCount);
      }, error => {
    });
  }

  getDate(): Date {
    let now = new Date();
    return new Date(now.getTime() + this.minutes * 60000)
  }

  submitForgotPassword(): void {
    // 
    // this.userCredentialViewModel = this.loginFormGroup.value;
    // this.userCredentialViewModel.username = this.userName;
    // this.userCredentialViewModel.password = null;
    // this.userCredentialViewModel.failedCount = 0;



    // if (this.userCredentialViewModel.username != null && this.userCredentialViewModel.username != "") {
    //   this.loginSubscription = this.loginService.postForgotPassword(this.userCredentialViewModel).subscribe(t => {
    //     let message = "Forgot password link sent to registered Email-ID.";
    //     this.toast.show(message, { status: 'success' });       
    //     this.userName = null
    //     this.ngOnInit()
    //   }, error => {        
    //     this.toast.show(error, { status: 'error' });
    //   });
    // }
    // else {
    //   this.toast.show("Please enter Username", { status: 'error' });
    // }
    this.router.navigateByUrl("/reset-password");
  }
  forgotPassword(): boolean {

    this.isForgotPassword = true;
    return this.isForgotPassword;
  }

  ngOnDestroy(): void {
  }
}
