import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick } from '@rx/view';

import { CallDb } from 'src/app/database-models';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { CallManagerLookups } from 'src/app/lookups';
import { PHONE_TYPES } from 'src/app/database-collections';
import { CallDbLookupGroup } from 'src/app/components/call-manager/call-db/domain/call-db.models';
import { CustomValidation, RxValidation, RxPopup, RxMessageComponent } from '@rx';
import { FormGroup } from '@angular/forms';
import { ApplicationBroadcaster, ApplicationConfiguration } from '@rx/core';
import { NOTIFICATION_PREFERENCES, RELATIONSHIPS } from '../../../../database-collections';
import { CallDbListComponent } from '../list/call-db-list.component';
import { CallDbViewComponent } from '../view/call-db-view.component';
import { OperationTypesEnum } from '../../../../const/operation-type';
import { CallTypesEnum } from '../../../../const/call-type';
import { user } from '@rx/security';
import { FormState } from '../../../../domain/form-state';
import { RecordStatusEnum, NotificationPreferencesEnum } from '../../../../enums';


@Component({
    selector: 'app-call-db-triage',
    templateUrl: './call-db-triage.component.html',
    entryComponents: [CallDbListComponent, CallDbViewComponent, RxMessageComponent]
})
export class CallDbTriageComponent extends CallDbDomain implements OnInit, OnDestroy, OnChanges {
    relationshipList: { "relationshipId": number; "relationshipName": string; }[];
    notificationTypes: any;
    patientId: any;
    toBeAsked: any;
    phoneTypeList: { "phoneTypesId": number; "phoneTypesName": string; }[];
    private sub: any;
    showComponent: boolean = false;
    callDb: CallDb[];
    triageSubscription: Subscription;
    @Input() practiceId: number;
    callDbTriageFormGroup: FormGroup;
    isEdit: boolean = false;
    validationFailed: {
        [key: string]: any;
    }
    callDbTriageLookupGroup: CallDbLookupGroup;
    triage: any;
    isContinueClicked: boolean = true;
    constructor(
        private route: ActivatedRoute,
        private callDbService: CallDbService,
        private dialog: RxDialog,
        private router: Router,
        private validation: RxValidation,
        private applicationBroadcaster: ApplicationBroadcaster,
        private popup: RxPopup,
        private activatedRoute: ActivatedRoute,
        private componentResolver: ComponentFactoryResolver,
        private toast: RxToast,

    ) {
        super();
        this.validationFailed = {};
        this.popup.setComponent(componentResolver)
    }

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.practiceId = params['practiceId'];
        });
        // this.activatedRoute.queryParams.subscribe(t => {
        //     if (t["patientId"])
        //         this.patientId = t["patientId"];
        //     if (t['isEdit'])
        //         this.isEdit = t['isEdit'];
        // })
        this.showComponent = false
        this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM && x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage);
        if (localStorage.getItem('callId')) {
            this.patientId = parseInt(localStorage.getItem('callId'));
            this.applicationBroadcaster.patientIdBroadCast(this.patientId);
            this.isEdit = true;
        }
        else
            this.callDbService.node$.subscribe(n => this.patientId = n);
        if (this.triageSubscription)
            this.triageSubscription.unsubscribe();
        if (this.patientId && this.patientId != 0) {
            this.callDbService.group(this.practiceId, [CallTypesEnum.TriageToDr, this.patientId, 0], [CallManagerLookups.providers]).then((response: any) => {
                this.phoneTypeList = PHONE_TYPES;
                // this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM);
                this.relationshipList = RELATIONSHIPS;
                this.callDbTriageLookupGroup = new CallDbLookupGroup();
                this.callDbTriageLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                this.callDbTriageLookupGroup.callDb = new CallDb(JSON.parse(response.CallDb[0].callDb)[0]);
                let data = FormState.getState(this.patientId);
                if (data) {
                    this.callDbTriageLookupGroup.callDb = data
                }
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                let jsonData = JSON.parse(response.CallDb[0].annotationProps);
                // jsonData['cBNumberTypeP'] = { 'required': jsonData['cBNumberP']['required'] };
                // jsonData['cBNumberTypeS'] = { 'required': jsonData['cBNumberS']['required'] };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: jsonData,
                });
                if (this.callDbTriageFormGroup) {
                    this.callDbTriageFormGroup = undefined;
                }
                this.callDbTriageFormGroup = this.validation.getFormGroup(this.callDbTriageLookupGroup.callDb, customValidation);
                this.callDbTriageFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbTriageFormGroup.controls.currentCallNotPref.setValue(null);
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.TriageToDr);
                this.showComponent = true;
                this.ngOnChanges();
            });
        }
        else {
            this.callDbService.group(this.practiceId, [CallTypesEnum.TriageToDr, 0, 0], [CallManagerLookups.providers]).then((response: any) => {
                this.phoneTypeList = PHONE_TYPES;
                // this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM);
                this.relationshipList = RELATIONSHIPS;
                this.callDbTriageLookupGroup = new CallDbLookupGroup();
                this.callDbTriageLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                let data = FormState.getState(0);
                if (data) {
                    this.callDbTriageLookupGroup.callDb = data;
                } else {
                    this.callDbTriageLookupGroup.callDb = new CallDb();
                    this.callDbTriageLookupGroup.callDb.practiceId = this.practiceId
                    this.callDbTriageLookupGroup.callDb.callTypeId = CallTypesEnum.TriageToDr;
                }
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                let jsonData = JSON.parse(response.CallDb[0].annotationProps);
                // jsonData['cBNumberTypeP'] = { 'required': jsonData['cBNumberP']['required'] };
                // jsonData['cBNumberTypeS'] = { 'required': jsonData['cBNumberS']['required'] };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: jsonData,
                });
                if (this.callDbTriageFormGroup) {
                    this.callDbTriageFormGroup = undefined;
                }
                this.callDbTriageFormGroup = this.validation.getFormGroup(this.callDbTriageLookupGroup.callDb, customValidation);
                this.callDbTriageFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbTriageFormGroup.controls.currentCallNotPref.setValue(null);
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.TriageToDr);
                this.showComponent = true;
                this.ngOnChanges()
            });
        }
    }

    ngOnChanges(): void {
        this.callDbTriageFormGroup.valueChanges.subscribe(val => {
            FormState.setState(this.callDbTriageFormGroup.value.sKey, this.callDbTriageFormGroup);
        });
    }

    showPopup(value) {
        this.popup.show(CallDbListComponent)
            .then(t => {
                if (t['patientId'])
                    this.patientId = t['patientId'];
                if (t['callDbData']) {
                    this.triage = t['callDbData'];
                    let data = new CallDb(JSON.parse(this.triage.callDb)[0])
                    this.callDbTriageFormGroup.setValue(JSON.parse(this.triage.callDb)[0]);
                }
            })
    }

    isContinue() {
        if (this.callDbTriageFormGroup.valid&& this.isContinueClicked ){
            this.isContinueClicked = false;
            this.continue();}
    }

    continue() {
        this.callDbTriageFormGroup.controls.userName.setValue(user.data['userName']);
        this.callDbTriageFormGroup.controls.agentId.setValue(user.data['userId']);
        this.callDbTriageFormGroup.controls.cStartTimeDate.setValue(new Date());
        this.callDbTriageFormGroup.controls.referenceNumber.setValue(null);
        if (this.callDbTriageFormGroup.value.birthDate > new Date()) {
            this.toast.show(ApplicationConfiguration.get("toastValidationMessages.birthDate"), { status: 'error' });
        } else {
            if (this.isEdit) {
                this.callDbTriageFormGroup.controls.operationType.setValue(OperationTypesEnum.EditData);
                this.callDbTriageFormGroup.controls.sKey.setValue(this.patientId);
                this.callDbTriageFormGroup.controls.callTypeId.setValue(CallTypesEnum.TriageToDr);
                this.triageSubscription = this.callDbService.put(this.practiceId, this.callDbTriageFormGroup.value).subscribe(t => {
                    // this.callDbTriageFormGroup.controls.birthDate.setValue(null);
                    // this.callDbTriageFormGroup.controls.age.setValue(0);
                    this.isEdit = false;
                    this.patientId = t.sKey;
                    localStorage.removeItem('callId');
                    this.callDbTriageFormGroup.controls.sKey.setValue(t.sKey);
                    FormState.remove(this.patientId);
                    this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.TriageToDr, spResult: t.spResult })
                        .then(t => {
                            if (t.isEdit) {
                                this.isContinueClicked = true;
                                this.isEdit = t.isEdit;
                                // FormState.remove(this.patientId);
                                FormState.setState(this.callDbTriageFormGroup.value.sKey, this.callDbTriageFormGroup);
                            }
                            else {
                                FormState.remove(this.patientId);
                                this.callDbTriageFormGroup.reset();
                                this.callDbService.addNode(0);
                                this.callDbTriageFormGroup = this.setDefaultValue(this.callDbTriageFormGroup, CallTypesEnum.TriageToDr);
                                let userData = user.data;
                                userData.practiceId = null;
                                userData.practiceName = null;
                                user.data = userData;
                                this.router.navigate(['dashboard']);
                                this.applicationBroadcaster.practiceSelectionBroadCast(false);
                            }
                        });
                },
                    error => {
                        this.popup.validationFailed(error);
                    })
            }
            else {
                this.callDbTriageFormGroup.controls.sKey.setValue(0);
                this.triageSubscription = this.callDbService.post(this.practiceId, this.callDbTriageFormGroup.value).subscribe(t => {
                    // this.callDbTriageFormGroup.controls.birthDate.setValue(null);
                    // this.callDbTriageFormGroup.controls.age.setValue(0);
                    this.patientId = t.sKey;
                    this.callDbTriageFormGroup.controls.callTypeId.setValue(CallTypesEnum.TriageToDr);
                    this.callDbTriageFormGroup.controls.sKey.setValue(t.sKey);
                    FormState.remove(this.patientId);
                    localStorage.removeItem('callId');
                    this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.TriageToDr, spResult: t.spResult })
                        .then(t => {
                            if (t.isEdit) {
                                this.isContinueClicked = true;
                                this.isEdit = t.isEdit;
                                // FormState.remove(this.patientId);
                                FormState.setState(this.callDbTriageFormGroup.value.sKey, this.callDbTriageFormGroup);
                            }
                            else {
                                FormState.remove(this.patientId);
                                this.callDbTriageFormGroup.reset();
                                this.callDbService.addNode(0);
                                this.callDbTriageFormGroup = this.setDefaultValue(this.callDbTriageFormGroup, CallTypesEnum.TriageToDr);
                                let userData = user.data;
                                userData.practiceId = null;
                                userData.practiceName = null;
                                user.data = userData;
                                this.router.navigate(['dashboard']);
                                this.applicationBroadcaster.practiceSelectionBroadCast(false);
                            }
                        });
                },
                    error => {
                        this.popup.validationFailed(error);
                    })
            }
        }
    }

    checkDate(value) {
        let date = new Date();
        if (value < date) {
            let age = this.calculateAge(value);
            this.callDbTriageFormGroup.controls.age.setValue(age);
        }
        else {
            this.callDbTriageFormGroup.controls.age.setValue(0);
        }
    }

    clearFormGroup(): void {

        this.callDbService.addNode(0);
        this.isEdit = false;
        this.callDbTriageFormGroup.reset();
        this.callDbTriageFormGroup = this.setDefaultValue(this.callDbTriageFormGroup, CallTypesEnum.TriageToDr);
        if (this.patientId)
            FormState.remove(this.patientId);
        else
            FormState.remove(0);
        this.applicationBroadcaster.patientIdBroadCast(0);
        localStorage.removeItem('callId');

        let userData = user.data;
        userData.practiceId = null;
        userData.practiceName = null;
        user.data = userData;
        this.router.navigate(['dashboard']);
        this.applicationBroadcaster.practiceSelectionBroadCast(false);
    }

    ngOnDestroy(): void {
        if (this.triageSubscription)
            this.triageSubscription.unsubscribe();
        super.destroy();
    }
}
