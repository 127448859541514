import { ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import { PageAccess } from "src/app/domain/authorization";

const PROVIDERS_ROUTES: Routes = [
	{
    path: '', 
	loadChildren: () => import('./list/provider-list.module').then(m => m.ProviderListModule),
	canActivate: [PageAccess],
    data: { rootModuleId: 1037, pageName: 'providers', applicationModuleId: 1042, accessItem: 'list',  keyName: 'providerId', compositeKeys:[] }
	},
];

export const PROVIDERS_ROUTING = RouterModule.forChild(PROVIDERS_ROUTES);
