import { NgModule } from '@angular/core';
import {RouterModule } from '@angular/router';


import { CALL_RECORD_HISTORY_ROUTING } from './call-record-history.routing';
import { CallRecordHistoryService } from './call-record-history.service';

@NgModule({
    imports: [CALL_RECORD_HISTORY_ROUTING],
    exports: [RouterModule],
    providers: [CallRecordHistoryService]
})
export class CallRecordHistoryModule { }