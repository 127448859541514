export enum DivertMinutesEnum {
    ThirtyMin=30,
    SixtyMin=60,
    ninetyMin=90,
    oneTwentyMin=120,
}

export const DivertMinutes = [
	{
        "divertMinValue": 30,
    },
	{
        "divertMinValue": 60,
    },
	{
        "divertMinValue": 90,
    },
    {
        "divertMinValue": 120,
    },
]
