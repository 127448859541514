import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vPagerCarriers')
export class vPagerCarrier {
    constructor(vPagerCarrier?: vPagerCarrier )  {
        let properties = [ "name", "pagerCoID",];
        for (let property of properties)
            if (vPagerCarrier && (vPagerCarrier[property] || vPagerCarrier[property] == 0))
                this[property] = vPagerCarrier[property];
    }
 
    @required()
    @maxLength(50)
	@property('Name')
	name : string = undefined ;
 
	@property('PagerCoID')
	pagerCoID : number =   0 ;



}
