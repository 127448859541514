import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { CallType } from './call-type';import { Provider } from './provider'; @table('NotificationPrefAlternet')
export class NotificationPrefAlternet {
    constructor(notificationPrefAlternet?: NotificationPrefAlternet )  {
        let properties = [ "carrierId", "createdById", "createdDateTime", "dayOfWeekId", "fromTime", "modifiedById", "modifiedDateTime", "notificationPrefId", "notificationReceipent", "notificationTypeId", "toTime", "callTypeId", "providerId",];
        for (let property of properties)
            if (notificationPrefAlternet && (notificationPrefAlternet[property] || notificationPrefAlternet[property] == 0))
                this[property] = notificationPrefAlternet[property];
    }
 
	@property('CarrierId')
	carrierId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('DayOfWeekId')
	dayOfWeekId : number =   undefined;
 
	@property('FromTime')
	fromTime : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('NotificationPrefId')
	notificationPrefId : number =   0 ;
 
    @maxLength(100)
	@property('NotificationReceipent')
	notificationReceipent : string = undefined ;
 
    @range(1,2147483647)
	@property('NotificationTypeId')
	notificationTypeId : number =   undefined;
 
	@property('ToTime')
	toTime : Date =   undefined;
 
    @range(0,2147483647)
	@property('CallTypeId')
	callTypeId : number =   undefined;
	callType : CallType  ;
 
    @range(0,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
	provider : Provider  ;



}
