import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { UserMessage } from './user-message';import { UserMessageMapping } from './user-message-mapping'; @table('MessageTransactionLog')
export class MessageTransactionLog {
    constructor(messageTransactionLog?: MessageTransactionLog )  {
        let properties = [ "createdById", "createdDateTime", "messageTransactionLogId", "optionalMessage", "recieverProviderId", "senderProviderId", "messageId", "userMessageMapping",];
        for (let property of properties)
            if (messageTransactionLog && (messageTransactionLog[property] || messageTransactionLog[property] == 0))
                this[property] = messageTransactionLog[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('MessageTransactionLogId')
	messageTransactionLogId : number =   0 ;
 
    @maxLength(200)
	@property('OptionalMessage')
	optionalMessage : string = undefined ;
 
    @range(1,2147483647)
	@property('RecieverProviderId')
	recieverProviderId : number =   undefined;
 
    @range(1,2147483647)
	@property('SenderProviderId')
	senderProviderId : number =   undefined;
 
    @range(0,2147483647)
	@property('MessageId')
	messageId : number =   undefined;
	userMessage : UserMessage  ;
	@nested(UserMessageMapping)
	userMessageMapping: UserMessageMapping[];




}
