export const ADDMISSION_TYPES = [
	{
        "addmissionTypesId": 2149,
        "addmissionTypesName": "In Patient"
    },
	{
        "addmissionTypesId": 2150,
        "addmissionTypesName": "Out Patient"
    },
	{
        "addmissionTypesId": 2151,
        "addmissionTypesName": "Emergency Room"
    },
]