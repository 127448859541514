import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick } from '@rx/view';

import { vProvider } from 'src/app/database-models';
import { ProvidersService } from '../providers.service';
import { ProviderDomain } from '../domain/provider.domain';
import { user } from '@rx/security';


@Component({
  templateUrl: './provider-list.component.html',
})
export class ProviderListComponent extends ProviderDomain implements OnInit, OnDestroy {
  practiceName: any;
  editProviderValue: any;
  showComponent: boolean = false;
  providers: vProvider[];
  listSubscription: Subscription;
  @Input() practiceId: number;
  mobile: number;
  homePhone: number;
  backline: number;
  constructor(
    private providersService: ProvidersService,
    private dialog: RxDialog,
    private toast: RxToast,
    private router: Router,
  ) {
    super();
    this.practiceId = user.data['practiceId'];
    this.practiceName = user.data['practiceName'];
  }

  ngOnInit(): void {

    if (this.listSubscription)
      this.listSubscription.unsubscribe();
    this.listSubscription = this.providersService.get(this.practiceId).subscribe(providers => {
      this.providers = providers;
      this.showComponent = true;
    });
  }
  editProvider(editProviderValue: any): void {

    this.editProviderValue = editProviderValue
    this.homePhone = editProviderValue.homePhone
    this.mobile = editProviderValue.mobile
    this.backline = editProviderValue.backline
  }
  saveProvider(UpdateProvider: any): void {

    var provider = {
      'homePhone': this.homePhone,
      'mobile': this.mobile,
      'backline': this.backline
    }



    this.listSubscription = this.providersService.patch(this.practiceId, UpdateProvider.providerId, provider).subscribe(providers => {

      this.homePhone = null
      this.mobile = null
      this.backline = null
      this.editProviderValue = null;
      this.ngOnInit();
    },
      error => {
        this.toast.show(error,{ status: 'error' });

      })
  }


  cancelEditProvider(): void {
    this.editProviderValue = null;
  }


  ngOnDestroy(): void {
    if (this.listSubscription)
      this.listSubscription.unsubscribe();
    super.destroy();
  }
}
