import { Component, OnInit } from '@angular/core';
import { RxStorage } from '@rx';
import { ApplicationBroadcaster } from '@rx/core';
import { user } from '@rx/security';
import { FormState } from '../../../domain/form-state';
import { CallDbService } from '../../call-manager/call-db/call-db.service';
import { Subscription } from 'rxjs';
import { SideBarService } from './side-bar.service';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  providers: [SideBarService]
})
export class SideBarComponent implements OnInit {
  private practiceId: number;
  activeMenu: string;
  toggleMenu: boolean;
  activeSubMenu: string;
  anyPracticeSelected: boolean = false;
  showComponent: boolean = false;
  roleId: number;
  
  constructor(private storage: RxStorage,
    private applicationBroadcaster: ApplicationBroadcaster,
    private sideBarService: SideBarService,
  ) {
    this.applicationBroadcaster.isPracticeSelectedSubscriber.subscribe(value => {
      this.anyPracticeSelected = value;     
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.practiceId = user.data['practiceId'];
   
    this.activeMenu = 'dashboard';

    if (this.practiceId > 0 && localStorage.getItem("isUnlockedUser") != "true")
      this.anyPracticeSelected = true;

      this.sideBarService.postAccess().subscribe(t => {

        user.authorizationPermissionItem = t;
        this.showComponent = true;
      }, error => {
        // this.isApplied = true;
      });
  }


  changeActiveMenu(activeMenu: string): void {

    FormState.remove(0);
    localStorage.removeItem('callId');
    if (this.activeMenu == activeMenu) {
      this.toggleMenu = !this.toggleMenu;
    } else {
      this.toggleMenu = true;

      this.activeMenu = activeMenu;
      this.activeSubMenu = '';
      this.storage.session.save("selectedModule", activeMenu);
    }
  }

  changeActiveSubMenu(activeSubMenu: string): void {
    this.activeSubMenu = activeSubMenu;
    this.storage.session.save("selectedRootModule", activeSubMenu);
    FormState.remove(0);
    localStorage.removeItem('callId');
  }

}
