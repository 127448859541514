export const SERVICE_TYPES = [
	{
        "serviceTypesId": 2099,
        "serviceTypesName": "Scheduled"
    },
	{
        "serviceTypesId": 2100,
        "serviceTypesName": "24 hours"
    },
	{
        "serviceTypesId": 2101,
        "serviceTypesName": "Disabled"
    },
]