import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Address } from './address';import { Practice } from './practice'; @table('PracticeOfficeHours')
export class PracticeOfficeHour {
    constructor(practiceOfficeHour?: PracticeOfficeHour )  {
        let properties = [ "createdById", "createdDateTime", "dayOfWeekId", "lunchFrom", "lunchTo", "modifiedById", "modifiedDateTime", "officeClosed", "officeOpen", "practiceOfficeHoursId", "addressId", "practiceId",];
        for (let property of properties)
            if (practiceOfficeHour && (practiceOfficeHour[property] || practiceOfficeHour[property] == 0))
                this[property] = practiceOfficeHour[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @range(1,2147483647)
	@property('DayOfWeekId')
	dayOfWeekId : number =   undefined;
 
	@property('LunchFrom')
	lunchFrom : Date =   undefined;
 
	@property('LunchTo')
	lunchTo : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('OfficeClosed')
	officeClosed : Date =   undefined;
 
	@property('OfficeOpen')
	officeOpen : Date =   undefined;
 
	@property('PracticeOfficeHoursId')
	practiceOfficeHoursId : number =   0 ;
 
    @range(0,2147483647)
	@property('AddressId')
	addressId : number =   undefined;
	address : Address  ;
 
    @range(0,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
	practice : Practice  ;



}
