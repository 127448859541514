import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vOnCallOverride')
export class vOnCallOverride {
    constructor(vOnCallOverride?: vOnCallOverride )  {
        let properties = [ "createdBy", "createdById", "firstName", "fromDate", "fromTime", "lastName", "notificationMethod", "notificationPrefId", "onCallOverrideId", "practiceId", "providerId", "toDate", "toTime",];
        for (let property of properties)
            if (vOnCallOverride && (vOnCallOverride[property] || vOnCallOverride[property] == 0))
                this[property] = vOnCallOverride[property];
    }
 
    @required()
    @maxLength(201)
	@property('CreatedBy')
	createdBy : string = undefined ;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
    @maxLength(101)
	@property('FirstName')
	firstName : string = undefined ;
 
    @required()
	@property('FromDate')
	fromDate : Date =   undefined;
 
    @maxLength(4000)
	@property('FromTime')
	fromTime : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('LastName')
	lastName : string = undefined ;
 
    @required()
    @maxLength(100)
	@property('NotificationMethod')
	notificationMethod : string = undefined ;
 
	@property('NotificationPrefId')
	notificationPrefId : number =   undefined;
 
    @range(1,2147483647)
	@property('OnCallOverrideId')
	onCallOverrideId : number =   undefined;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @range(1,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
 
    @required()
	@property('ToDate')
	toDate : Date =   undefined;
 
    @maxLength(4000)
	@property('ToTime')
	toTime : string = undefined ;



}
