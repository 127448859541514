export const environment = {
    production: true,
    apiRootUrl: 'https://agent-api-dev.remedyoncall.com/',
    appVersion: 1,
    iframeSrc: 'https://boltgw-uat.cardconnect.com/itoke/ajax-tokenizer.html',
    firebase: {
      apiKey: "AIzaSyAoVEs_lDuk6Jfm62HsvBpC7g7a6jHOGL8",
      authDomain: "remedyoncall-13cf0.firebaseapp.com",
      databaseURL: "https://remedyoncall-13cf0.firebaseio.com",
      projectId: "remedyoncall-13cf0",
      storageBucket: "remedyoncall-13cf0.appspot.com",
      messagingSenderId: "659462731189",
      appId: "1:659462731189:web:a034a1296e0101c95995fc",
      measurementId: "G-9TDQWGWW6B"
    }
  
    
  };
  