import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";
import {RxViewModule } from '@rx/view';
import {RxFormsModule } from '@rx/forms';
import {RxTableModule } from "@rx/table";
import {DynamicComponentContainer } from '@rx/core';

import { TechnicalTermListComponent } from './list/technical-term-list.component'
import {TechnicalTermsService } from "./technical-terms.service";
import {TECHNICAL_TERMS_SHARED_COMPONENT_CONTAINER } from './technical-terms-shared-component.container';
import { PracticesService } from '../../agent-office/practices/practices.service';
@NgModule({
    imports: [
        RxViewModule, RxFormsModule,
        CommonModule, FormsModule, ReactiveFormsModule, RxTableModule
    ],
    declarations: [ TechnicalTermListComponent, ],
    providers: [TechnicalTermsService,PracticesService ],
    exports: [RouterModule, TechnicalTermListComponent, ]
})
export class TechnicalTermsSharedComponentModule { }
DynamicComponentContainer.register(TECHNICAL_TERMS_SHARED_COMPONENT_CONTAINER );