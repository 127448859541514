import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('SecurityQuestions')
export class SecurityQuestion {
    constructor(securityQuestion?: SecurityQuestion )  {
        let properties = [ "active", "securityQuestionId", "securityQuestionName",];
        for (let property of properties)
            if (securityQuestion && (securityQuestion[property] || securityQuestion[property] == 0))
                this[property] = securityQuestion[property];
    }
 
	@property('Active')
	active : boolean = false ;
 
	@property('SecurityQuestionId')
	securityQuestionId : number =   0 ;
 
    @required()
    @maxLength(50)
	@property('SecurityQuestionName')
	securityQuestionName : string = undefined ;



}
