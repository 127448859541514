import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('AgentQuestionMapping')
export class AgentQuestionMapping {
    constructor(agentQuestionMapping?: AgentQuestionMapping )  {
        let properties = [ "agentQueId", "agentQueMappingId", "createdById", "createdDateTime", "hTMLControl", "modifiedById", "modifiedDateTime", "msgIndex", "practiceId", "required", "toBeAsked", "usedInMsg",];
        for (let property of properties)
            if (agentQuestionMapping && (agentQuestionMapping[property] || agentQuestionMapping[property] == 0))
                this[property] = agentQuestionMapping[property];
    }
 
    @range(1,2147483647)
	@property('AgentQueId')
	agentQueId : number =   undefined;
 
	@property('AgentQueMappingId')
	agentQueMappingId : number =   0 ;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @maxLength(20)
	@property('HTMLControl')
	hTMLControl : string = undefined ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @range(1,2147483647)
	@property('MsgIndex')
	msgIndex : number =   undefined;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
	@property('Required')
	required : boolean = false ;
 
	@property('ToBeAsked')
	toBeAsked : boolean = false ;
 
	@property('UsedInMsg')
	usedInMsg : boolean = false ;



}
