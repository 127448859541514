import { ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import { PageAccess } from "src/app/domain/authorization";

const CALL_DB_ROUTES: Routes = [
	{
    path: '', 
	loadChildren: () => import('./edit/call-db-edit.module').then(m => m.CallDbEditModule),
	canActivate: [PageAccess],
    data: { rootModuleId: 33,pageName:'call-db', applicationModuleId: 2045, accessItem: 'edit',  keyName: 'sKey', compositeKeys:[] }
	},
];

export const CALL_DB_ROUTING = RouterModule.forChild(CALL_DB_ROUTES);
