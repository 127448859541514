import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vForwardMessageTransactions')
export class vForwardMessageTransaction {
    constructor(vForwardMessageTransaction?: vForwardMessageTransaction )  {
        let properties = [ "callTypeId", "createdById", "createdDateTime", "forwardTime", "isForward", "message", "messageId", "messageTransactionLogId", "messageType", "modifiedById", "modifiedDateTime", "notificationCount", "notificationMessage", "notificationSendTime", "optionalMessage", "patientName", "practiceId", "recievedBy", "recieverProviderId", "senderMessageTransactionLogId", "senderProviderId", "skey", "userId", "userMessageMappingId",];
        for (let property of properties)
            if (vForwardMessageTransaction && (vForwardMessageTransaction[property] || vForwardMessageTransaction[property] == 0))
                this[property] = vForwardMessageTransaction[property];
    }
 
	@property('CallTypeId')
	callTypeId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @required()
	@property('ForwardTime')
	forwardTime : Date =   undefined;
 
	@property('IsForward')
	isForward : boolean = false ;
 
    @required()
	@property('Message')
	message : string = undefined ;
 
    @range(1,2147483647)
	@property('MessageId')
	messageId : number =   undefined;
 
    @range(1,2147483647)
	@property('MessageTransactionLogId')
	messageTransactionLogId : number =   undefined;
 
    @range(1,2147483647)
	@property('MessageType')
	messageType : number =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @range(1,2147483647)
	@property('NotificationCount')
	notificationCount : number =   undefined;
 
    @required()
	@property('NotificationMessage')
	notificationMessage : string = undefined ;
 
    @required()
	@property('NotificationSendTime')
	notificationSendTime : Date =   undefined;
 
    @maxLength(200)
	@property('OptionalMessage')
	optionalMessage : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('PatientName')
	patientName : string = undefined ;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @required()
    @maxLength(101)
	@property('RecievedBy')
	recievedBy : string = undefined ;
 
    @range(1,2147483647)
	@property('RecieverProviderId')
	recieverProviderId : number =   undefined;
 
	@property('SenderMessageTransactionLogId')
	senderMessageTransactionLogId : number =   undefined;
 
    @range(1,2147483647)
	@property('SenderProviderId')
	senderProviderId : number =   undefined;
 
    @range(1,2147483647)
	@property('Skey')
	skey : number =   undefined;
 
    @range(1,2147483647)
	@property('UserId')
	userId : number =   undefined;
 
    @range(1,2147483647)
	@property('UserMessageMappingId')
	userMessageMappingId : number =   undefined;



}
