import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Practice } from './practice'; @table('Alerts')
export class Alert {
    constructor(alert?: Alert )  {
        let properties = [ "alertFor", "alertId", "alertTypeId", "applyToId", "createdById", "createdDateTime", "dayOfWeekId", "fromDate", "fromTime", "message", "modifiedById", "modifiedDateTime", "ordinal", "toDate", "toTime", "practiceId",];
        for (let property of properties)
            if (alert && (alert[property] || alert[property] == 0))
                this[property] = alert[property];
    }
 
	@property('AlertFor')
	alertFor : number =   undefined;
 
	@property('AlertId')
	alertId : number =   0 ;
 
    @range(1,2147483647)
	@property('AlertTypeId')
	alertTypeId : number =   undefined;
 
	@property('ApplyToId')
	applyToId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('DayOfWeekId')
	dayOfWeekId : number =   undefined;
 
	@property('FromDate')
	fromDate : Date =   undefined;
 
	@property('FromTime')
	fromTime : Date =   undefined;
 
	@property('Message')
	message : string = undefined ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('Ordinal')
	ordinal : number =   undefined;
 
	@property('ToDate')
	toDate : Date =   undefined;
 
	@property('ToTime')
	toTime : Date =   undefined;
 
    @range(0,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
	practice : Practice  ;

}
