import { Injectable } from '@angular/core';

@Injectable()
export class CallOutboundDomain {
    constructor() { }

    destroy(): void {

    }
}
