import { Injectable, Inject, ReflectiveInjector } from "@angular/core";
import { Observable } from 'rxjs/Rx';
import { RxStorage } from "@rx/storage"
import { Http, RequestOptions, Headers } from "@angular/http";
import { API_HOST_URI, RequestHeaders, ResponseResult } from "@rx/http";

import { UserAuthorizationViewModel } from './user-authorization.models';
import { user } from '@rx/security'
import { throwError } from "rxjs";
@Injectable()
export class UserAuthorizationService {
  private api: string = 'api/userauthorization'
  storage: RxStorage;
  constructor(private http: Http,
    @Inject(API_HOST_URI) private hostUri: string,
    @Inject('RequestHeaders') private requestHeaders: RequestHeaders,
    @Inject('ResponseResult') private responseResult: ResponseResult,
    private requestOptions: RequestOptions) {
    let injector: any = ReflectiveInjector.resolveAndCreate([RxStorage]);
    this.storage = injector.get(RxStorage);
  }

  postAuthorize(data: any): Observable<any> {
    var auth = this.storage.local.get('auth');
    return this.http.post(
      this.hostUri.concat(this.api, '/', 'authorize'),
      JSON.stringify(data),
      new RequestOptions({ headers: new Headers({ "Content-Type": "application/json", "Authorization": auth }) })).catch(error => this.handleError(error, "POST", false));
  }

  postLogOut(): Observable<any> {
    var auth = this.storage.local.get('auth');
    return this.http.post(this.hostUri.concat(this.api, '/', 'logout'), JSON.stringify({ userId: 3 }), // kept static userId as of now as per given by ajay sir.
      new RequestOptions({ headers: new Headers({ "Content-Type": "application/json", "Authorization": auth }) }));
  }
  checkLock(data: any): Observable<any> {
    let auth = this.storage.local.get('auth');
    return this.http.post(
      this.hostUri.concat('api/recordlocks'),
      JSON.stringify(data),
      new RequestOptions({ headers: new Headers({ "Content-Type": "application/json", "Authorization": auth }) }));
  }

  unLockRecord(data: any): Observable<any> {
    let auth = this.storage.local.get('auth');
    return this.http.delete(
      this.hostUri.concat(`api/recordlocks${data.applicationModuleId}/${data.mainRecordId}/${data.childModuleName}`),
      new RequestOptions({ headers: new Headers({ "Content-Type": "application/json", "Authorization": auth }) }));
  }
  handleError(error: any, requestMethod: string, showToast: boolean) {
    if (this.responseResult.check(error, requestMethod, showToast)) {
      if (error.status == 400) {
        error = <{
          [key: string]: any;
        }>error.json();
        return throwError(error);
      } else {
        error = <{
          [key: string]: any;
        }>error.json();
        this.responseResult.error(error)
        return throwError(error);
      }
    }
  }
}
