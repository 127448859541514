import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick } from '@rx/view';

import { vAlert, Alert } from 'src/app/database-models';
import { AlertsService } from '../alerts.service';
import { AlertDomain } from '../domain/alert.domain';
import { user } from '@rx/security';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationBroadcaster } from '@rx/core';
import { AlertTypeEnum } from '../../../../enums';

@Component({
    selector: 'app-alert-view',
    templateUrl: './alert-view.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AlertViewComponent extends AlertDomain implements OnInit, OnDestroy {
    showComponent: boolean = false;
    // alerts: vAlert[];
    // alert: Alert;
    welcomeMessage: any = null;
    viewSubscription: Subscription;
    @Input() practiceId: number;

    constructor(
        private alertsService: AlertsService,
        private dialog: RxDialog,
        private router: Router,
        private applicationBroadcaster: ApplicationBroadcaster,
        private sanitizer: DomSanitizer
    ) {
        super();
        if (user && user.data) {
            this.practiceId = user.data["practiceId"];
        }
        this.applicationBroadcaster.isPracticeSelectedSubscriber.subscribe(value => {
            this.practiceId = user.data["practiceId"];
            this.showComponent = false;
            this.ngOnInit();
        });
    }

    ngOnInit(): void {
        if (this.practiceId != undefined && this.practiceId != null && this.practiceId != 0) {
            if (this.viewSubscription)
                this.viewSubscription.unsubscribe();
            this.viewSubscription = this.alertsService.getBy(this.practiceId, [AlertTypeEnum.WelcomeScript]).subscribe(alerts => {
                
                if (alerts[0] == undefined) {
                    this.welcomeMessage = ""
                }
                else {
                    this.welcomeMessage = alerts[0].message == undefined ? "" : this.sanitizer.bypassSecurityTrustHtml(alerts[0].message);
                }
                this.showComponent = true;
            });
        }
        else {
            this.welcomeMessage = "";
        }

    }



    ngOnDestroy(): void {
        if (this.viewSubscription)
            this.viewSubscription.unsubscribe();
        super.destroy();
    }
}
