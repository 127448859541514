import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { RxMessageComponent } from '@rx/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { ComponentCanDeactivate, ApplicationConfiguration } from "@rx/core";
import { RxToast, RxDialog, DialogClick } from '@rx/view';
import { RxValidation } from '@rx/forms';
import { OnCallOverride, } from 'src/app/database-models';

import { AgentOnCallOverrideLookups, } from 'src/app/lookups';
import { OnCallOverrideService } from '../on-call-override.service';
import { OnCallOverrideDomain } from '../domain/on-call-override.domain';
import { OnCallOverrideLookupGroup } from '../domain/on-call-override.models';

import { OnCallOverrideListComponent } from 'src/app/components/agent-on-call-override/on-call-override/list/on-call-override-list.component';
import { user } from '@rx/security';
import { NOTIFICATION_PREFERENCES } from 'src/app/database-collections';
import { RecordStatusEnum, NotificationPreferencesEnum } from 'src/app/enums';
import { TimeSource } from '../../../../const/time-source';

@Component({
    templateUrl: './on-call-override-add.component.html',
    entryComponents: [OnCallOverrideListComponent, RxMessageComponent]
})
export class OnCallOverrideAddComponent extends OnCallOverrideDomain implements OnInit, OnDestroy, ComponentCanDeactivate {
    practiceName: any;
    showComponent: boolean = false;
    onCallOverrideFormGroup: FormGroup;
    addSubscription: Subscription;
    onCallOverrideLookupGroup: OnCallOverrideLookupGroup;
    @Input() practiceId: number;
    notificationPreferences: any;
    showOnCallOverride: boolean;
    showAddForm: boolean = false;
    provider: any;
    timeSource = TimeSource;
    validationFailed: {
        [key: string]: any;
    }
    onCallProvider: any;
    currentOnCallProvider: any;
    constructor(
        private validation: RxValidation,
        private router: Router,
        private toast: RxToast,
        private onCallOverrideService: OnCallOverrideService,
    ) {
        super();
        this.validationFailed = {};
        if (user && user.data) {
            this.practiceId = user.data["practiceId"];
            this.practiceName = user.data["practiceName"];
        }
    }

    ngOnInit(): void {
        // this.onCallOverrideService.lookup([AgentOnCallOverrideLookups.providers,]).then(
        this.onCallOverrideService.group(this.practiceId, [0], [AgentOnCallOverrideLookups.providers]).then((response: any) => {
            // (response: OnCallOverrideLookupGroup) => {
            this.validationFailed = {};
            this.onCallProvider = response.OnCallOverride[0];
            this.currentOnCallProvider = response.OnCallOverride[0].providerId;
            this.notificationPreferences = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage && x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM);
            this.onCallOverrideLookupGroup = response;

            this.provider = response.providers.filter(t => t.practiceId == this.practiceId && t.statusId == RecordStatusEnum.Active);
            this.onCallOverrideLookupGroup.onCallOverride = new OnCallOverride(this.onCallOverrideLookupGroup.onCallOverride);
            this.onCallOverrideFormGroup = this.validation.getFormGroup(this.onCallOverrideLookupGroup.onCallOverride);
            this.onCallOverrideFormGroup.controls['practiceId'].setValue(this.practiceId);
            this.setDefaultValue(this.onCallOverrideFormGroup);

            this.showOnCallOverride = true;
            this.showComponent = true;
            this.showAddForm = true;
        });
    }


    addOnCallOverride(): void {
        this.showAddForm = false;
        this.showOnCallOverride = false;
        var frDate = new Date((this.onCallOverrideFormGroup.value.fromDate.getMonth() + 1 )+'/'+this.onCallOverrideFormGroup.value.fromDate.getDate()+'/'+this.onCallOverrideFormGroup.value.fromDate.getFullYear()+' '+this.timeConversionTo24Hour(this.onCallOverrideFormGroup.value.fromTime)+":00");
        var toDate = new Date((this.onCallOverrideFormGroup.value.toDate.getMonth()+1)+'/'+this.onCallOverrideFormGroup.value.toDate.getDate()+'/'+this.onCallOverrideFormGroup.value.toDate.getFullYear()+' '+ this.timeConversionTo24Hour(this.onCallOverrideFormGroup.value.toTime) +":00");

        if (frDate < toDate) {

            this.onCallOverrideFormGroup.value.notificationPrefId == null ? this.onCallOverrideFormGroup.value.notificationPrefId : this.onCallOverrideFormGroup.controls.notificationPrefId.setValue(parseInt(this.onCallOverrideFormGroup.value.notificationPrefId));
            if (this.onCallOverrideFormGroup.value.providerId == null) {
                if (this.currentOnCallProvider == null) {
                    this.toast.show(ApplicationConfiguration.get("toastValidationMessages.noOnCallRoster"), { status: 'error' });
                    this.showOnCallOverride = true;
                    this.onCallOverrideFormGroup.reset();
                    this.ngOnInit();
                }
                else {
                    this.onCallOverrideFormGroup.controls.providerId.setValue(this.currentOnCallProvider);
                    // this.onCallOverrideFormGroup.value.fromTime = frTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
                    // this.onCallOverrideFormGroup.value.toTime = toTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
                    this.onCallOverrideFormGroup.value.fromTime = this.timeConversionTo24Hour(this.onCallOverrideFormGroup.value.fromTime)
                    this.onCallOverrideFormGroup.value.toTime = this.timeConversionTo24Hour(this.onCallOverrideFormGroup.value.toTime) 
                    this.addSubscription = this.onCallOverrideService.post(this.practiceId, this.onCallOverrideFormGroup.value).subscribe(t => {
                        this.showOnCallOverride = true;
                        this.onCallOverrideFormGroup.reset();
                        this.ngOnInit();
                    },
                        error => {
                            this.showAddForm = true;
                            this.showOnCallOverride = true;
                        })
                }
            }
            else {
                this.onCallOverrideFormGroup.controls.providerId.setValue(parseInt(this.onCallOverrideFormGroup.value.providerId));
                // this.onCallOverrideFormGroup.value.fromTime = frTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
                // this.onCallOverrideFormGroup.value.toTime = toTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
                this.onCallOverrideFormGroup.value.fromTime = this.timeConversionTo24Hour(this.onCallOverrideFormGroup.value.fromTime)
                this.onCallOverrideFormGroup.value.toTime = this.timeConversionTo24Hour(this.onCallOverrideFormGroup.value.toTime)
                this.addSubscription = this.onCallOverrideService.post(this.practiceId, this.onCallOverrideFormGroup.value).subscribe(t => {
                    this.showOnCallOverride = true;
                    this.onCallOverrideFormGroup.reset();
                    this.ngOnInit();
                },
                    error => {
                        this.showAddForm = true;
                        this.showOnCallOverride = true;
                    })
            }


        }
        else {
            this.toast.show(ApplicationConfiguration.get("toastValidationMessages.startTimeLessthenEndTime") + " Or " + ApplicationConfiguration.get("toastValidationMessages.formDateLessthenToDate"), { status: 'error' });
            this.showAddForm = true;
            this.showOnCallOverride = true;
        }
    }

    canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        return !this.onCallOverrideFormGroup.dirty;
    }

    ngOnDestroy(): void {
        if (this.addSubscription)
            this.addSubscription.unsubscribe();
        super.destroy();
    }

}
