export const CONTACT_GROUPS = [
	{
        "contactGroupId": 64,
        "contactGroupName": "Emergency"
    },
	{
        "contactGroupId": 65,
        "contactGroupName": "Billing"
    },
	{
        "contactGroupId": 66,
        "contactGroupName": "Holiday Protocol"
    },
	{
        "contactGroupId": 1086,
        "contactGroupName": "Default"
    },
	{
        "contactGroupId": 5174,
        "contactGroupName": "Medical"
    },
	{
        "contactGroupId": 5175,
        "contactGroupName": "Account"
    },
]