import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallDbPatientComponent } from 'src/app/components/call-manager/call-db/patient/call-db-patient.component';
import { CallDbTriageComponent } from 'src/app/components/call-manager/call-db/triage/call-db-triage.component';
import { CallDbAnsserviceComponent } from 'src/app/components/call-manager/call-db/ansservice/call-db-ansservice.component';
import { CallDbAppointmentComponent } from 'src/app/components/call-manager/call-db/appointment/call-db-appointment.component';
import { CallDbAdmissionComponent } from 'src/app/components/call-manager/call-db/admission/call-db-admission.component';
import { CallDbDoctorComponent } from 'src/app/components/call-manager/call-db/doctor/call-db-doctor.component';
import { CallDbNewbornComponent } from 'src/app/components/call-manager/call-db/newborn/call-db-newborn.component';
import { CallDbRxrefillComponent } from './rxrefill/call-db-rxrefill.component';


export const call_db_edit_children_routing: Routes = [
    {
        path: 'patient/:practiceId', component: CallDbPatientComponent
    },
    {
        path: 'newborn/:practiceId', component: CallDbNewbornComponent
    },
    {
        path: 'hospital-lab-doctor/:practiceId', component: CallDbDoctorComponent
    },
    {
        path: 'admission/:practiceId', component: CallDbAdmissionComponent
    },
    {
        path: 'appointment/:practiceId', component: CallDbAppointmentComponent
    },
    {
        path: 'answering-service/:practiceId', component: CallDbAnsserviceComponent
    },
    {
        path: 'triage/:practiceId', component: CallDbTriageComponent
    },
    {
        path: 'rxRefill/:practiceId', component: CallDbRxrefillComponent
    },
];
