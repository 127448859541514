import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageAccess } from 'src/app/domain/authorization';

const SCHEDULE_TELEVISIT_ROUTES: Routes = [
  // {
  //   path: '',
  //   loadChildren: './view/schedule-televisit-view.module#ScheduleTelevisitViewModule',
  //   canActivate: [PageAccess],
  //   data: { rootModuleId: 4053, pageName: 'schedule-televisit', applicationModuleId: 4068, accessItem: 'add', keyName: 'scheduleTelevisitId', compositeKeys: [] }
  // },
  //{
  //  path: '',
  //  loadChildren: './add/schedule-televisit-add.module#ScheduleTelevisitAddModule',
  //  canActivate: [PageAccess],
  //  data: { rootModuleId: 4053, pageName: 'schedule-televisit', applicationModuleId: 4068, accessItem: 'add', keyName: 'scheduleTelevisitId', compositeKeys: [] }
  //},

  {
    path: '',
    loadChildren: () => import('./list/schedule-televisit-list.module').then(m => m.ScheduleTelevisitListModule),
    canActivate: [PageAccess],
    data: { rootModuleId: 4053, pageName: 'schedule-televisit', applicationModuleId: 5104, accessItem: 'list', keyName: 'scheduleTelevisitId', compositeKeys: [] }
  },

];

export const SCHEDULE_TELEVISIT_ROUTING = RouterModule.forChild(SCHEDULE_TELEVISIT_ROUTES);
