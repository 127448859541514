import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";

import {RxFormsModule} from "@rx/forms";
import {RxViewModule} from "@rx/view";
import {    RxTableModule} from "@rx/table";

import { OnCallRosterAddComponent } from './on-call-roster-add.component'
import { ON_CALL_ROSTER_ADD_ROUTING } from './on-call-roster-add.routing'
import {OnCallRostersService } from "../on-call-rosters.service";
import { CalendarModule } from 'src/app/components/shared/calendar/calendar.module';
// import { HospitalRoundingRostersSharedComponentModule } from 'app/components/roster/hospital-rounding-rosters/hospital-rounding-rosters-shared-component.module'
// import { VacationRostersSharedComponentModule } from 'app/components/roster/vacation-rosters/vacation-rosters-shared-component.module'
// import { ExtendedNewbornRostersSharedComponentModule } from 'app/components/roster/extended-newborn-rosters/extended-newborn-rosters-shared-component.module'

@NgModule({
    imports: [
        ON_CALL_ROSTER_ADD_ROUTING ,
        CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,
		CalendarModule  
       ],
    declarations: [OnCallRosterAddComponent ],
    exports: [RouterModule],
    providers: [OnCallRostersService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OnCallRosterAddModule { }