export enum NotificationPreferencesEnum {
        Text = 1093,
        MobileApp = 1094,
        CallCell = 1095,
        CallHome = 1096,
        CallOffice = 1097,
        CallBackline = 1098,
        Pager = 5154,
        HoldForAM = 2129,
        FaxOffice = 2130,
        FaxOther = 2131,
        PatchHome = 2132,
        PatchOffice = 2133,
        PatchCell = 2134,
        AfterHourTriage = 2152,
}
