import { Component, OnInit, OnDestroy,  Input,ComponentFactoryResolver} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import {RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { vProvider } from 'src/app/database-models';
import { ProvidersService } from '../providers.service';
import { ProviderDomain } from '../domain/provider.domain';
import { CallTypesEnum } from '../../../../const/call-type';
import { ApplicationBroadcaster } from '@rx/core';
import { user } from '@rx/security';



@Component({
    templateUrl: './provider-view.component.html',
})
export class ProviderViewComponent extends ProviderDomain implements OnInit, OnDestroy {
    showComponent: boolean = false;
    providers: vProvider[];
    onCallRoster :any;
    viewSubscription: Subscription;
    @Input()  practiceId :number;
    @Input() providerId: number;
    isOnCallRosterDetails: boolean = false;
    calltypeEnum: any;
    constructor(
        private providersService: ProvidersService,    
        private dialog: RxDialog,
        private router: Router,
        private applicationBroadcaster: ApplicationBroadcaster,
        private popup: RxPopup,
    ) { super();
        this.applicationBroadcaster.isPracticeSelectedSubscriber.subscribe(value => {
            this.practiceId = user.data["practiceId"];
            this.showComponent = false;
            this.ngOnInit();
        });
        this.practiceId = user.data["practiceId"];}

    ngOnInit(): void {
        if (this.viewSubscription)
            this.viewSubscription.unsubscribe();
        this.viewSubscription = this.providersService.getBy(this.practiceId,[this.providerId]).subscribe((providers) => {

            if (providers == "")
                    this.isOnCallRosterDetails = false
                else {
                    this.calltypeEnum = CallTypesEnum;
                    this.onCallRoster = providers[0];
                    // this.onCallRoster.preference.where(x=>x.CallTypeId == CallTypesEnum.Appointment)[0].NotificationType = this.onCallRoster.preference.where(x=>x.CallTypeId == CallTypesEnum.PatientToDoctor)[0].NotificationType
                    // this.onCallRoster.preference.where(x=>x.CallTypeId == CallTypesEnum.HospitalAdmission)[0].NotificationType = this.onCallRoster.preference.where(x=>x.CallTypeId == CallTypesEnum.PatientToDoctor)[0].NotificationType
                    this.isOnCallRosterDetails = true;
                }
                // this.onCallRoster['preference'].forEach(element => {
                //     if(element.CallTypeId == 
                // });

                this.showComponent = true;
        });
        this.showComponent = true;
    }

    HideProviderPopup(){
        document.body.className = "";
        this.popup.hide(ProviderViewComponent);
    }

    ngOnDestroy(): void {
        if (this.viewSubscription)
            this.viewSubscription.unsubscribe();
        super.destroy();
    }
}
