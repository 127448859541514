import { FormGroup } from "@angular/forms";

export class FormState {
  static jObject = {};
  static setState(clientId: number, formGroup: FormGroup) {
    // if (clientId != undefined && clientId != null) {
    //   FormState.jObject[clientId] = formGroup.value;
    //   if (JSON.parse(localStorage.getItem('jObject')))
    //     localStorage.removeItem('jObject');
    //   localStorage.setItem('jObject', JSON.stringify(FormState.jObject));
    // }
    if (clientId != undefined && clientId != null) {
      if (FormState.jObject)
        FormState.jObject = {}
      FormState.jObject[clientId] = formGroup.value;
    }
  }

  static getState(clientId: number) {
    // if (JSON.parse(localStorage.getItem('jObject')))
    //   return JSON.parse(localStorage.getItem('jObject'))[clientId]
    // else
    //   return null

    if (FormState.jObject)
      return FormState.jObject[clientId]
    else
      return null
  }

  static remove(clientId: number) {
    // FormState.jObject[clientId] = undefined;
    // localStorage.removeItem('jObject');
    // localStorage.removeItem('callId');
    // delete FormState.jObject[clientId];

    FormState.jObject= {};
     localStorage.removeItem('callId');
  }
}
