import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { PmdUser } from './pmd-user'; @table('PmdPatients')
export class PmdPatient {
    constructor(pmdPatient?: PmdPatient )  {
        let properties = [ "active", "briefMessage", "dOB", "firstName", "lastName", "officeToVisitId", "patientIsChild", "pmdPatientID", "providerId", "relationship", "pmdUserID",];
        for (let property of properties)
            if (pmdPatient && (pmdPatient[property] || pmdPatient[property] == 0))
                this[property] = pmdPatient[property];
    }
 
	@property('Active')
	active : boolean = false ;
 
    @maxLength(1500)
	@property('BriefMessage')
	briefMessage : string = undefined ;
 
    @required()
	@property('DOB')
	dOB : Date =   undefined;
 
    @required()
    @maxLength(50)
	@property('FirstName')
	firstName : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('LastName')
	lastName : string = undefined ;
 
	@property('OfficeToVisitId')
	officeToVisitId : number =   undefined;
 
	@property('PatientIsChild')
	patientIsChild : boolean = false ;
 
	@property('PmdPatientID')
	pmdPatientID : number =   0 ;
 
    @range(1,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
 
    @range(1,2147483647)
	@property('Relationship')
	relationship : number =   undefined;
 
    @range(0,2147483647)
	@property('PmdUserID')
	pmdUserID : number =   undefined;
	pmdUser : PmdUser  ;



}
