import { ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule } from '@angular/router';

import { CallDbEditComponent } from './call-db-edit.component'
import { call_db_edit_children_routing } from 'src/app/components/call-manager/call-db/call-db-edit-children.routing';

const CALL_DB_EDIT_ROUTES: Routes = [{
    path: '', component: CallDbEditComponent,children: call_db_edit_children_routing
}];

export const CALL_DB_EDIT_ROUTING  = RouterModule.forChild(CALL_DB_EDIT_ROUTES);