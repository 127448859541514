import { LookupAction } from '@rx/http'

export class AgentOnCallOverrideLookups {
    static providers: LookupAction = {
        controllerName: "agentoncalloverride",
        actionName: "providers",
		cacheOption: {
            isVersionBase: true,
            tableName:''
        }
    };

}
