import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { DEFAULT_USERPROFILE_PHOTO_URL } from '../../../const/default.const';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-profile-photo',
  templateUrl: './app-user-profile-photo.component.html',
})
export class UserProfilePhotoComponent implements OnInit, OnDestroy {

  showComponent: boolean = false;
  @Input() userProfileUrl: string;
  @Input() height: string;
  @Input() width: string;
  @Input() customStyle: string;
  @Input() customClass: string;
  @Input() alterText: string = '';
  @Input() userId: number;
  
  constructor(private router: Router) {
  }

  ngOnInit(): void {
    
    if (this.userProfileUrl == null || this.userProfileUrl == '') {
      this.userProfileUrl = DEFAULT_USERPROFILE_PHOTO_URL;
    }
    this.showComponent = true;
  }

  ngOnDestroy(): void {
  }

  }
