import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Practice } from './practice';import { CallDb } from './call-db';import { UserMessageMapping } from './user-message-mapping';import { MessageTransactionLog } from './message-transaction-log'; @table('UserMessages')
export class UserMessage {
    constructor(userMessage?: UserMessage )  {
        let properties = [ "createdById", "createdDateTime", "message", "messageId", "modifiedById", "modifiedDateTime", "notificationMessage", "patientName", "practiceId", "skey", "userMessageMapping", "messageTransactionLog",];
        for (let property of properties)
            if (userMessage && (userMessage[property] || userMessage[property] == 0))
                this[property] = userMessage[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @required()
	@property('Message')
	message : string = undefined ;
 
	@property('MessageId')
	messageId : number =   0 ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @required()
	@property('NotificationMessage')
	notificationMessage : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('PatientName')
	patientName : string = undefined ;
 
    @range(0,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
	practice : Practice  ;
 
    @range(0,2147483647)
	@property('Skey')
	skey : number =   undefined;
	callDb : CallDb  ;
	@nested(UserMessageMapping)
	userMessageMapping: UserMessageMapping[];

	@nested(MessageTransactionLog)
	messageTransactionLog: MessageTransactionLog[];




}
