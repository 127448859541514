export const APPLY_TOES = [
	{
        "applyToId": 1099,
        "applyToName": "All Screens"
    },
	{
        "applyToId": 1100,
        "applyToName": "Newborn Only"
    },
	{
        "applyToId": 1101,
        "applyToName": "All EXCEPT Newborn"
    },
	{
        "applyToId": 1103,
        "applyToName": "Main Selection Screen Only"
    },
]