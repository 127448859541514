import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { RxMessageComponent } from '@rx/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { ComponentCanDeactivate, ApplicationConfiguration } from "@rx/core";
import { RxToast, RxDialog, DialogClick } from '@rx/view';
import { RxValidation } from '@rx/forms';
import { Alert, } from 'src/app/database-models';

import { } from 'src/app/lookups';
import { AlertsService } from '../alerts.service';
import { AlertDomain } from '../domain/alert.domain';
import { AlertLookupGroup } from '../domain/alert.models';
import { vAlert } from '../../../../database-models';
import { user } from '@rx/security';
import { DAYS_OF_WEEKS, APPLY_TOES } from '../../../../database-collections';
import { CustomValidation } from '@rx';
import { TimeSource } from '../../../../const/time-source';


@Component({
  templateUrl: './alert-add.component.html',
  entryComponents: [RxMessageComponent]
})
export class AlertAddComponent extends AlertDomain implements OnInit, OnDestroy, ComponentCanDeactivate {

  showComponent: boolean = false;
  alertFormGroup: FormGroup;
  walertFormGroup: FormGroup;
  galertFormGroup: FormGroup;
  salertFormGroup: FormGroup;
  nalertFormGroup: FormGroup;
  addSubscription: Subscription;
  editSubscription: Subscription;
  listSubscription: Subscription;
  alertLookupGroup: AlertLookupGroup;

  selectedAlert: any;
  affectingAlertDown: any;
  affectingAlertUp: any;

  practiceName: string;
  daysOfWeek: any;
  applyToDrp: any;
  config: any;
  flag: number = 47;
  alerts: vAlert[];
  alertListGenralMsg: any;
  alertListSpecialMsg: any;
  objSearchAlert: any;
  index: number;
  alertSequenceData: any;
  alertSequence: any = [];
  isDownBtnDisable: boolean = true;
  isUpBtnDisable: boolean = true;
  applyClass: any;
  timeSource = TimeSource;
  defaultSettings: any = [
    {
      key: "Bold",
      value: true
    },
    {
      key: "FontSize",
      value: "12pt"
    },
    {
      key: "FontName",
      value: "Verdana"
    },
    {
      key: "ForeColor",
      value: "red"
    }
  ];
  validationFailed: {
    [key: string]: any;
  }
  @Input() practiceId: number;
  btnSaveText: string = "Save";;
  tabValue: string;
  constructor(
    private validation: RxValidation,
    private router: Router,
    private toast: RxToast,
    private dialog: RxDialog,
    private alertsService: AlertsService,
  ) {
    super();
    this.validationFailed = {};
    if (user && user.data) {
      this.practiceId = user.data["practiceId"];
      this.practiceName = user.data["practiceName"];
    }
  }

  ngOnInit(): void {
    this.alertLookupGroup = new AlertLookupGroup();
    this.tabValue = 'Special Massages';
    this.daysOfWeek = DAYS_OF_WEEKS;
    this.applyToDrp = APPLY_TOES;
    this.validationFailed = {};

    this.alertLookupGroup.alert = new Alert();

    let scustomValidation: CustomValidation[] = [];
    scustomValidation.push({
      annotationProps: {
        'applyToId': {
          required: true,
        },
        'fromDate': {
          required: true,
        },
        'toDate': {
          required: true,
        },
        'fromTime': {
          required: true,
        },
        'toTime': {
          required: true,
        }
      },
    });


    this.salertFormGroup = this.validation.getFormGroup(this.alertLookupGroup.alert, scustomValidation);
    this.nalertFormGroup = this.validation.getFormGroup(this.alertLookupGroup.alert);


    this.setDefaultValue(this.salertFormGroup, 47);

    this.getSingleAlert(47);

  }
  editSpecialAlert(alert: Alert) {

    this.btnSaveText = "Update";
    this.salertFormGroup.controls['fromTime'].setValue(alert['fromTimeForDispaly']);
    this.salertFormGroup.controls['toTime'].setValue(alert['toTimeForDisplay']);
    this.salertFormGroup.controls['applyToId'].setValue(alert['applyToId']);
    this.salertFormGroup.controls['fromDate'].setValue(new Date(alert['fromDateForEdit']));
    this.salertFormGroup.controls['toDate'].setValue(new Date(alert['toDateForEdit']));

    this.salertFormGroup.controls['alertId'].setValue(alert['alertId']);
    this.salertFormGroup.controls['message'].setValue(alert['message']);
    this.salertFormGroup.controls['ordinal'].setValue(alert['ordinal']);
    this.alert.editor.setContent(this.salertFormGroup.value.message);

    this.setDefaultValue(this.salertFormGroup, 47);

    console.log(this.salertFormGroup.value);


  }
  addAlert(alertTypeId): void {

    this.addSubscription = this.alertsService.post(this.practiceId, this.alertFormGroup.value).subscribe(t => {
      this.clearFormGroup(this.alertFormGroup);
      this.getSingleAlert(alertTypeId);
    },
      error => {

      })
  }


  editAlert(alertTypeId, alerts): void {

    this.applyClass = "";
    this.editSubscription = this.alertsService.put(this.practiceId, alerts).subscribe(t => {
      this.clearFormGroup(this.alertFormGroup);
      this.getSingleAlert(alertTypeId);
      this.isUpBtnDisable = true;
      this.isDownBtnDisable = true;
    },
      error => {
        //this.popup.validationFailed(error);
      })
  }

  editorKeyupHandler(value: any, control: string, alertFormGroup: any): void {

    alertFormGroup.controls[control].setValue(value);
  }

  tabClick(alertTypeId, tabValue) {

    this.showComponent = false;
    this.tabValue = tabValue;
    this.flag = alertTypeId;

    this.getSingleAlert(alertTypeId);

    this.showComponent = true;
  }

  saveAlerts(alertFormGroup, alertType) {
    let alerts = [];
    let validationCheck: boolean = true;
    this.alertFormGroup = alertFormGroup;
    this.alertFormGroup.controls['alertTypeId'].setValue(alertType);
    this.setDefaultValue(this.alertFormGroup, alertType);
    let fromTime = new Date();
    let toTime = new Date();
    if (alertType == 46 || alertType == 47) {
      fromTime = new Date((this.alertFormGroup.value.fromDate.getMonth() + 1) + '/' + this.alertFormGroup.value.fromDate.getDate() + '/' + this.alertFormGroup.value.fromDate.getFullYear() + ' ' + this.timeConversionTo24Hour(this.alertFormGroup.value.fromTime) + ":00");
      toTime = new Date((this.alertFormGroup.value.toDate.getMonth() + 1) + '/' + this.alertFormGroup.value.toDate.getDate() + '/' + this.alertFormGroup.value.toDate.getFullYear() + ' ' + this.timeConversionTo24Hour(this.alertFormGroup.value.toTime) + ":00");

      this.alertFormGroup.value.fromTime = this.timeConversionTo24Hour(this.alertFormGroup.value.fromTime);
      this.alertFormGroup.value.toTime = this.timeConversionTo24Hour(this.alertFormGroup.value.toTime);
    }
    let timeObj = { "start": alertFormGroup.value.fromTime, "end": alertFormGroup.value.toTime };
    if (this.checkValidations(this.alertFormGroup, fromTime, toTime)) {
      alerts.push(this.alertFormGroup.value);
      if (this.alertFormGroup.value["alertId"] == null || this.alertFormGroup.value["alertId"] == 0) {
        this.addAlert(alertType);
      }
      else {
        this.editAlert(alertType, alerts);
      }
      this.clearFormGroup(alertType);
      if (this.alert)
        this.alert.editor.setContent('')
    }
    this.btnSaveText = "Save";
  }

  @ViewChild("alert") alert;

  getSingleAlert(alertTypeId) {

    this.alertsService.getBy(this.practiceId, [alertTypeId]).subscribe(
      (response) => {
        this.alertLookupGroup = new AlertLookupGroup();
        let test = response[0];
        this.alertLookupGroup.alert = new Alert(test);

        switch (alertTypeId) {
          case 47:
            this.alertListSpecialMsg = response;
            break;
          case 48:
            this.nalertFormGroup = this.validation.getFormGroup(this.alertLookupGroup.alert);
            this.alert.editor.setContent(this.nalertFormGroup.value.message)
            break;
        }
        this.showComponent = true;
debugger
      });
  }

  checkValidations(alertFormGroup, fromTime, toTime) {
    if (alertFormGroup.controls.alertTypeId.value == 46 || alertFormGroup.controls.alertTypeId.value == 47) {
      if (fromTime < toTime) {
      }
      else {
        this.toast.show(ApplicationConfiguration.get("toastValidationMessages.formDateLessthenToDate") + " Or " + ApplicationConfiguration.get("toastValidationMessages.startTimeLessthenEndTime"), { status: 'error' });
        return false;
      }
    }
    if (alertFormGroup.controls.message.value == "") {
      this.toast.show(ApplicationConfiguration.get("toastValidationMessages.blankAlert"), { status: 'error' });
      return false;
    }
    return true;
  }

  clearFormGroup(alertTypeId) {

    switch (alertTypeId) {
      case 47:
        this.salertFormGroup.controls.message.setValue('');
        this.salertFormGroup.controls.applyToId.setValue('');
        this.salertFormGroup.controls.fromTime.setValue('');
        this.salertFormGroup.controls.toTime.setValue('');
        this.salertFormGroup.controls.fromDate.setValue('');
        this.salertFormGroup.controls.toDate.setValue('');
        this.salertFormGroup.controls.dayOfWeekId.setValue('');
        break;
      case 48:
        this.nalertFormGroup.controls.message.setValue('');
        this.nalertFormGroup.controls.applyToId.setValue('');
        this.nalertFormGroup.controls.fromTime.setValue('');
        this.nalertFormGroup.controls.toTime.setValue('');
        this.nalertFormGroup.controls.fromDate.setValue('');
        this.nalertFormGroup.controls.toDate.setValue('');
        this.nalertFormGroup.controls.dayOfWeekId.setValue('');
        break;
    }
  }

  moveUp(alertList: any, tableEvent: any) {
    let indexSelectedAlert = alertList.indexOf(this.selectedAlert);
    let indexAffectedAlert = alertList.indexOf(this.affectingAlertUp);

    // let index = alertList[this.index].ordinal - 1;
    let alert = alertList[this.index];
    // alertList[index - 1].ordinal = alertList[index - 1].ordinal + 1;
    // alertList[index].ordinal = alertList[index].ordinal - 1;

    let changingOrdinal = alertList[indexSelectedAlert].ordinal;
    alertList[indexSelectedAlert].ordinal = alertList[indexAffectedAlert].ordinal;
    alertList[indexAffectedAlert].ordinal = changingOrdinal;

    alertList = alertList.sort((a, b) => b.ordinal - a.ordinal);
    tableEvent.bindData(alertList, true);
    this.getMsgSequenceData(alertList, tableEvent, alert)
  }

  moveDown(alertList: any, tableEvent: any) {
    let indexSelectedAlert = alertList.indexOf(this.selectedAlert);
    let indexAffectedAlert = alertList.indexOf(this.affectingAlertDown);

    // let index = alertList[this.index].ordinal - 1;
    let alert = alertList[this.index];


    let changingOrdinal = alertList[indexSelectedAlert].ordinal;
    alertList[indexSelectedAlert].ordinal = alertList[indexAffectedAlert].ordinal;
    alertList[indexAffectedAlert].ordinal = changingOrdinal;


    // alertList[index + 1].ordinal = alertList[index + 1].ordinal - 1;
    // alertList[index].ordinal = alertList[index].ordinal + 1;
    alertList = alertList.sort((a, b) => b.ordinal - a.ordinal);
    tableEvent.bindData(alertList, true);
    this.getMsgSequenceData(alertList, tableEvent, alert)
  }

  getMsgSequenceData(alertList: any, tableEvent: any, alert: any) {


    this.index = alertList.indexOf(alert);

    this.selectedAlert = alert;
    this.affectingAlertDown = alertList[this.index + 1] == undefined ? null : alertList[this.index + 1];
    this.affectingAlertUp = alertList[this.index - 1] == undefined ? null : alertList[this.index - 1];

    if (this.index == 0) {
      this.isUpBtnDisable = true;
      this.isDownBtnDisable = false;
    }
    else if (this.index == (alertList.length - 1)) {
      this.isDownBtnDisable = true;
      this.isUpBtnDisable = false;
    }
    else {
      this.isDownBtnDisable = false;
      this.isUpBtnDisable = false;
    }

    this.applyClass = "row.alertId == " + alertList[this.index].alertId + " ? 'blue-background' :''"
  }

  deleteAlert(alert: Alert) {

    let practiceId = user.data['practiceId']
    let msgString = ApplicationConfiguration.get("toastValidationMessages.dependency");
    this.dialog.confirmation(["Alert"], "delete").then(dialogClick => {
      if (dialogClick == DialogClick.PrimaryOk) {
        this.alertsService.delete(practiceId, alert.alertId).subscribe(providers => {
          this.getSingleAlert(alert.alertTypeId);
        },
          error => {
            this.toast.show(msgString, { status: 'error' });
          });
      }
    })
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return !this.alertFormGroup.dirty;
  }

  ngOnDestroy(): void {
    if (this.addSubscription)
      this.addSubscription.unsubscribe();
    super.destroy();
  }

}
