import { ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import { PageAccess } from "src/app/domain/authorization";

const ON_CALL_OVERRIDE_ROUTES: Routes = [
	{
    path: 'add', 
	loadChildren: () => import('./add/on-call-override-add.module').then(m => m.OnCallOverrideAddModule),
	canActivate: [PageAccess],
    data: { rootModuleId: 1037,pageName:'on-call-override', applicationModuleId: 14, accessItem: 'add',  keyName: 'onCallOverrideId', compositeKeys:[] }
	},
];

export const ON_CALL_OVERRIDE_ROUTING = RouterModule.forChild(ON_CALL_OVERRIDE_ROUTES);
