import { Injectable } from '@angular/core';
import { CallDbViewModel } from './call-db.models';
import { NotificationPreferencesEnum } from '../../../../enums';
import { FormGroup } from '@angular/forms';
import { CallDb } from '../../../../database-models';
import { user } from '@rx/security';
import { FormState } from '../../../../domain/form-state';

@Injectable()
export class CallDbDomain {
  constructor() { }

  destroy(): void {
    // localStorage.removeItem('callId');
    // this.callDbService.addNode(0);
    // FormState.remove(0);
  }

  getContinuePopupString(data: CallDbViewModel, callTypeId: number): any {
    let notificationObj: string = '';
    let sentData: string = '';
    let sentTo: string = ''
    let sendVia: string = '';
    let submitString: string = ''
    let phoneticString = data.Phonetic == null ? '' : ' (pronounced ' + data.Phonetic + ')'
    let practicName = user.data['practiceName']
    if (callTypeId == 1 && (data.OnAht == true && data.OnAhtDivert == false) && (data.OnLocalOverride == false))//1 - patient to doc
    {
      sentTo = 'After Hours Triage';
      sendVia = 'AHT';
      sentData = 'Currently Doctor ' + data.ProviderName + phoneticString + ' is the On-Call physician for this practice, however, the calls for this practice are being handled by their After Hours Triage.<br />I will forward this information to them.<br />Please call back if you have not received a call from the nurse in ' + data.WithinTime + ' minutes.';
    }
    else {
      sentTo = data.ProviderName;
      sendVia = data.NotificationPref;

      let isFaxOrHold: boolean = false;
      switch (data.NotificationPref.trim()) {
        case 'Call Home':
        case 'Call Office':
        case 'Call Cell':
          notificationObj = 'call the Doctor';
          break;
        case 'Patch Home':
        case 'Patch Cell':
        case 'Patch Office':
          notificationObj = 'connect the Doctor';
          break;
        case 'Fax Office':
          isFaxOrHold = true;
          notificationObj = 'notify';
          break;
        case 'Fax Other':
          notificationObj = 'fax the Doctor';
          break;
        case 'Hold For AM':          
          isFaxOrHold = true;
          notificationObj = 'save this message for the Doctor';
          break;
        case 'After Hours Triage':
          notificationObj = 'Notify After Hours Triage';
          break;
        default:
          notificationObj = 'notify';
          break;
      }
      if (notificationObj == "Notify After Hours Triage") {
        sentData = sentData + 'I will <u>' + notificationObj;
      }
      else if (data.NotificationPref.includes('Patch')) {
        sentData = sentData + '<font class="text-danger">Please hold while I patch you through to the doctor on call.</font><br /><br/>';
      }
      else {

        if (callTypeId == 7) {

        }
        if((callTypeId == 3 || callTypeId == 11 || callTypeId ==13) && (data.NotificationPref.trim() == "Hold For AM" || data.NotificationPref.trim() == "Fax Office")){
          sentData = "Thank you for calling. Your information will be sent to "+ practicName + " and you should receive a return call during normal business hours."
        }
        
        else {      
          sentData = sentData + 'I will <u>' + notificationObj + ' ' + sentTo + '</u>';

          if (data.OnLocalOverride || data.OnGlobalOverride) {
            sentData = sentData + phoneticString;

            if (callTypeId == 3) {
              sentData = sentData + ' who will be notified of the newborn for this practice.<br/>';
            }
            else {
              sentData = sentData + ' who will be notified for this practice.<br/>';
            }
          }
          else {
            if (callTypeId == 3) {
              sentData = sentData + phoneticString + ' who is on-call for the practice and will be notified of the newborn.';
            }
            else {
              sentData = sentData + phoneticString + ' who is on-call for the practice.<br/>';
            }
          }
          if((callTypeId == 3 ||callTypeId == 13 ||callTypeId == 11) && (isFaxOrHold)) {}
          else{
            sentData = sentData + '<br />If your phone rejects blocked calls, please hang up, pick up the receiver again and dial *87 so the Provider can call you back.<br /><br />If you do not hear back from anyone in <u>' + data.WithinTime + '</u> minutes, please call us back at the phone number that you just dialed.';
          }
        }
      }
    }

    if (data.NotificationPref.includes('Patch')) {
      submitString = '<span class="text-primary">(Click here to Patch the Call!)</span>'
    }
    else {
      submitString = '<span class="text-primary">(Click here to process this call!)</span>'
    }
    return { 'sendData': sentData, 'submitString': submitString, 'sendTo': sentTo, 'sendVia': sendVia };
  }

  dateToUTC(date1) {
    let date = new Date(date1);
    if (date != null && date != undefined)
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    else
      return null;
  }

  timeConversionTo12Hour(time: any): any {
    if (time != null && time != undefined && time != "") {
      var timeArray = time.split(":");
      var hours = parseInt(timeArray[0]);
      var isMr = hours >= 12 ? "PM" : "AM"
      var newhour = hours >= 12 ? (hours == 12 ? 12 : hours - 12) : (hours == 0 ? 12 : hours);
      var paddedNum = newhour.toString().length == 1 ? String("00" + newhour).slice(-2) : newhour;
      time = paddedNum + ":" + timeArray[1] + " " + isMr;
      return time;
    }
    else {
      return time;
    }
  }

  timeConversionTo24Hour(time: any): any {

    if (time != null && time != undefined && time != "") {
      var splitText = time.split(" ");
      var splitTime = splitText[0].split(":");
      var finalTime = "";
      if (splitText[1] == "PM" || splitText[1] == "pm") {
        switch (splitTime[0]) {
          case "00":
            finalTime = "".concat("12", ":", splitTime[1])
            break;
          case "01":
            finalTime = "".concat("13", ":", splitTime[1])
            break;
          case "02":
            finalTime = "".concat("14", ":", splitTime[1])
            break;
          case "03":
            finalTime = "".concat("15", ":", splitTime[1])
            break;
          case "04":
            finalTime = "".concat("16", ":", splitTime[1])
            break;
          case "05":
            finalTime = "".concat("17", ":", splitTime[1])
            break;
          case "06":
            finalTime = "".concat("18", ":", splitTime[1])
            break;
          case "07":
            finalTime = "".concat("19", ":", splitTime[1])
            break;
          case "08":
            finalTime = "".concat("20", ":", splitTime[1])
            break;
          case "09":
            finalTime = "".concat("21", ":", splitTime[1])
            break;
          case "10":
            finalTime = "".concat("22", ":", splitTime[1])
            break;
          case "11":
            finalTime = "".concat("23", ":", splitTime[1])
            break;
          case "12":
            finalTime = "".concat("12", ":", splitTime[1])
            break;
        }
      }
      else if (splitText[1] == "AM" || splitText[1] == "am") {
        switch (splitTime[0]) {
          case "00":
            finalTime = "".concat("00", ":", splitTime[1])
            break;
          case "01":
            finalTime = "".concat("01", ":", splitTime[1])
            break;
          case "02":
            finalTime = "".concat("02", ":", splitTime[1])
            break;
          case "03":
            finalTime = "".concat("03", ":", splitTime[1])
            break;
          case "04":
            finalTime = "".concat("04", ":", splitTime[1])
            break;
          case "05":
            finalTime = "".concat("05", ":", splitTime[1])
            break;
          case "06":
            finalTime = "".concat("06", ":", splitTime[1])
            break;
          case "07":
            finalTime = "".concat("07", ":", splitTime[1])
            break;
          case "08":
            finalTime = "".concat("08", ":", splitTime[1])
            break;
          case "09":
            finalTime = "".concat("09", ":", splitTime[1])
            break;
          case "10":
            finalTime = "".concat("10", ":", splitTime[1])
            break;
          case "11":
            finalTime = "".concat("11", ":", splitTime[1])
            break;
          case "12":
            finalTime = "".concat("00", ":", splitTime[1])
            break;
        }
      }
      return finalTime == "" ? time : finalTime;
    }
    return null;
  }

  mergeDateTime(date, time): any {
    if (date) {
      let nDate = new Date(date);
      let datevalue = (nDate.getMonth() + 1) + '/' + nDate.getDate() + '/' + nDate.getFullYear() + ' '
      return new Date(datevalue + time)
    }
    else {
      return date
    }
  }

  calculateAge(value): any {
    let now = new Date();
    let yearNow = now.getFullYear();
    let monthNow = now.getMonth();
    let yearDob = value.getFullYear();
    let monthDob = value.getMonth();
    let age = {};
    let ageString = "";
    let yearString = "";
    let monthString = "";
    let dayString = "";
    let yearAge = yearNow - yearDob;
    let monthAge, dateAge;
    if (monthNow >= monthDob) { monthAge = monthNow - monthDob; }
    else {
      yearAge--;
      monthAge = 12 + monthNow - monthDob;
    }
    age = {
      years: yearAge,
      months: monthAge,
    };
    if (age['years'] > 1) yearString = " years";
    else yearString = " year";
    if (age['months'] > 1) monthString = " months";
    else monthString = " month";
    if ((age['years'] > 0) && (age['months'] > 0))
      ageString = age['years'] + yearString + ", " + age['months'] + monthString;
    else if ((age['years'] == 0) && (age['months'] > 0))
      ageString = age['months'] + monthString;
    else if ((age['years'] > 0) && (age['months'] == 0))
      ageString = age['years'] + yearString;
    else ageString = "0 year 0 month";

    return ageString;
  }

  formatDate(date) {
    if (date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    }
    else {
      return '';
    }
  }


  setDefaultValue(formGroup: FormGroup, callTypeId: number) {
    formGroup.controls.practiceId.setValue(user.data['practiceId']);
    formGroup.controls.callTypeId.setValue(callTypeId);
    formGroup.controls.userName.setValue(user.data['userName']);
    formGroup.controls.agentId.setValue(user.data['userId']);
    formGroup.controls.urgent.setValue(false);
    formGroup.controls.admissionTime.setValue(null);
    formGroup.controls.readytoProcess.setValue(false);
    formGroup.controls.age.setValue(0);
    return formGroup;
  }

  claerOverrideValue(formGroup: FormGroup) {
    formGroup.controls.currentCallProviderId.setValue(null);
    formGroup.controls.currentCallNotPref.setValue(null);
    return formGroup;
  }
}
