import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { AgentQuestionAnswer } from './agent-question-answer'; @table('AgentQuestionMaster')
export class AgentQuestionMaster {
    constructor(agentQuestionMaster?: AgentQuestionMaster )  {
        let properties = [ "agentQueMasterId", "callTypeId", "createdById", "createdDateTime", "hTMLControl", "modifiedById", "modifiedDateTime", "question", "questionCode", "questionIndex", "agentQuestionAnswer",];
        for (let property of properties)
            if (agentQuestionMaster && (agentQuestionMaster[property] || agentQuestionMaster[property] == 0))
                this[property] = agentQuestionMaster[property];
    }
 
	@property('AgentQueMasterId')
	agentQueMasterId : number =   0 ;
 
    @range(1,2147483647)
	@property('CallTypeId')
	callTypeId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @maxLength(20)
	@property('HTMLControl')
	hTMLControl : string = undefined ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @required()
    @maxLength(200)
	@property('Question')
	question : string = undefined ;
 
    @required()
    @maxLength(200)
	@property('QuestionCode')
	questionCode : string = undefined ;
 
	@property('QuestionIndex')
	questionIndex : number =   undefined;
	@nested(AgentQuestionAnswer)
	agentQuestionAnswer: AgentQuestionAnswer[];




}
