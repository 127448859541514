export const ISSUE_TYPES = [
	{
        "issueTypeId": 2102,
        "issueTypeName": "Bug"
    },
	{
        "issueTypeId": 2103,
        "issueTypeName": "Issue"
    },
	{
        "issueTypeId": 2104,
        "issueTypeName": "Enhancement"
    },
]