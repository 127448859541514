import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vProviders')
export class vProvider {
    constructor(vProvider?: vProvider )  {
        let properties = [ "backline", "email", "fax", "firstName", "homePhone", "lastModified", "lastName", "mi", "mobile", "modifiedBy", "modifiedDateTime", "officePhone", "officePhoneExt", "optedForTelehealth", "phonetic", "practiceId", "providerId", "statusId", "subscriptionId", "tital", "userId", "userName", "userProfilePhotoUrl",];
        for (let property of properties)
            if (vProvider && (vProvider[property] || vProvider[property] == 0))
                this[property] = vProvider[property];
    }
 
    @maxLength(50)
	@property('Backline')
	backline : string = undefined ;
 
    @maxLength(100)
	@property('Email')
	email : string = undefined ;
 
    @maxLength(50)
	@property('Fax')
	fax : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('FirstName')
	firstName : string = undefined ;
 
    @maxLength(50)
	@property('HomePhone')
	homePhone : string = undefined ;
 
    @maxLength(4000)
	@property('lastModified')
	lastModified : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('LastName')
	lastName : string = undefined ;
 
    @maxLength(10)
	@property('Mi')
	mi : string = undefined ;
 
    @maxLength(12)
	@property('Mobile')
	mobile : string = undefined ;
 
    @maxLength(50)
	@property('ModifiedBy')
	modifiedBy : string = undefined ;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @maxLength(50)
	@property('OfficePhone')
	officePhone : string = undefined ;
 
    @maxLength(10)
	@property('OfficePhoneExt')
	officePhoneExt : string = undefined ;
 
	@property('OptedForTelehealth')
	optedForTelehealth : boolean = false ;
 
    @maxLength(50)
	@property('Phonetic')
	phonetic : string = undefined ;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @range(1,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
 
    @range(1,2147483647)
	@property('StatusId')
	statusId : number =   undefined;
 
	@property('SubscriptionId')
	subscriptionId : string = undefined ;
 
    @maxLength(50)
	@property('Tital')
	tital : string = undefined ;
 
    @range(1,2147483647)
	@property('UserId')
	userId : number =   undefined;
 
    @required()
    @maxLength(50)
	@property('UserName')
	userName : string = undefined ;
 
    @maxLength(500)
	@property('UserProfilePhotoUrl')
	userProfilePhotoUrl : string = undefined ;



}
