export enum DaysOfWeekEnum {
        Monday = 52,
        Tuesday = 53,
        Wednesday = 54,
        Thursday = 55,
        Friday = 56,
        Saturday = 57,
        Sunday = 58,
        MonthruFri = 59,
        SatandSun = 60,
        AllDays = 61,
}
