import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vHospitals')
export class vHospital {
    constructor(vHospital?: vHospital )  {
        let properties = [ "hospitalId", "hospitalName", "practiceId",];
        for (let property of properties)
            if (vHospital && (vHospital[property] || vHospital[property] == 0))
                this[property] = vHospital[property];
    }
 
	@property('HospitalId')
	hospitalId : number =   0 ;
 
    @required()
    @maxLength(100)
	@property('HospitalName')
	hospitalName : string = undefined ;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;



}
