import { Injectable } from '@angular/core';
import {Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi} from "@rx/security";


import { ScheduleTelevisitLookupGroup } from './domain/schedule-televisit.models';
import { ScheduleTelevisit } from 'src/app/database-models/schedule-televisit';
import { TelevisitScreeningForm } from 'src/app/database-models/televisit-screening-form';
import { TelehealthChartData } from 'src/app/database-models/telehealth-chartdata';

@Injectable()
export class ScheduleTelevisitService {
    private providerId: number;
    private practiceId: number;
    private currentdate: Date;
    private televisitMasterId: number;

	private get api(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/ScheduleTelevisit`,
            applicationModuleId: 4068,
            keyName:'scheduleTelevisitId'
	
        }
        return authorizeApi;
    }   
    constructor(
        private http: RxHttp
    ) { }

    lookup<ScheduleTelevisitLookupGroup>(lookupActions: LookupAction[]): Promise<ScheduleTelevisitLookupGroup> {
        return this.http.lookup<ScheduleTelevisitLookupGroup>(lookupActions);
    }

    group<ScheduleTelevisitLookupGroup>(params: any[] | {
        [key: string]: any;
    } | RequestQueryParams, lookupActions: LookupAction[]): Promise<ScheduleTelevisitLookupGroup> {
        return this.http.group<ScheduleTelevisitLookupGroup>(this.api, params, 'scheduleTelevisit', lookupActions);
    }

	search(search: any): Observable<ScheduleTelevisit[]> {
        return this.http.search<ScheduleTelevisit[]>(this.api, search);
    }

    get(): Observable<ScheduleTelevisit[]> {
        return this.http.get<ScheduleTelevisit[]>(this.api);
    }

    getBy(params?: any[] | {
        [key: string]: any;
    } | RequestQueryParams): Observable<ScheduleTelevisit> {
        return this.http.get<ScheduleTelevisit>(this.api, params); 
    }
   
    post(scheduleTelevisit: ScheduleTelevisit): Observable<ScheduleTelevisit> {
        return this.http.post<ScheduleTelevisit>(this.api, scheduleTelevisit,false);
    }

    put(scheduleTelevisit: ScheduleTelevisit): Observable<ScheduleTelevisit> {
        return this.http.put<ScheduleTelevisit>(this.api, scheduleTelevisit,false);
    }

    delete(id : number): Observable<ScheduleTelevisit> {
        return this.http.delete<ScheduleTelevisit>(this.api,id);
    }

	filterLookup<T>(lookup:LookupAction,filterParameters:any[],queryParams?: {[key:string]:any}):Observable<T> {
        return this.http.filterLookup(lookup,filterParameters,queryParams);
    }

    private get scheduleVisitData(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/ScheduleTelevisit/search?PageNumber=1&FromDate=${this.currentdate}&RowCount=100&OrderByColumn=TelevisitId&SortOrder=ASC&ProviderId=${this.providerId}`,
            applicationModuleId: 4068,
            keyName:'scheduleTelevisitId'
	
        }
        return authorizeApi;
    }    
    getScheduleVisitData(currentdate :any,providerId : number):Observable<ScheduleTelevisit[]> {
        
        this.providerId = providerId;
        this.currentdate=currentdate;
        return this.http.get<ScheduleTelevisit[]>(this.scheduleVisitData);
    }

    private get scheduleVisitHistoryData(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/ScheduleTelevisit/search/GetVisitCountBy/${this.countby}/${this.providerId}/${this.startDate}/${this.endDate}`,
            applicationModuleId: 4068,
            keyName:'scheduleTelevisitId'
	
        }
        return authorizeApi;
    }   
    private countby:string; 
    private startDate:string; 
    private endDate:string; 
    getScheduleVisitHistoryCahrtData(providerId : any,CountBy:string,num : number,startDate ,endDate):Observable<TelehealthChartData[]> {
        this.providerId = providerId;
        this.countby=CountBy;
        this.startDate=startDate;
        this.endDate=endDate;
        if(num == 2 )
        return this.http.get<TelehealthChartData[]>(this.scheduleVisitHistoryData,null,false);
        else
        return this.http.get<TelehealthChartData[]>(this.scheduleVisitHistoryData);

    }

    // private get mostUsedIcdData(): AuthorizeApi {
    //     var authorizeApi: AuthorizeApi = {
    //         api: `api/icdcodemaster/search/GetMostUsedIcdData/${this.practiceId}`,
    //         applicationModuleId: 4068,
    //         keyName:'scheduleTelevisitId'
	
    //     }
    //     return authorizeApi;
    // }       
    // GetMostUsedIcdData(practiceId : number):Observable<any[]> {
    //     this.practiceId = practiceId;
    //     return this.http.get<any[]>(this.mostUsedIcdData,null,false);
    // }

    // private get mostUsedCptData(): AuthorizeApi {
    //     var authorizeApi: AuthorizeApi = {
    //         api: `api/cptcodemaster/search/GetMostUsedCptData/${this.practiceId}`,
    //         applicationModuleId: 4068,
    //         keyName:'scheduleTelevisitId'
	
    //     }
    //     return authorizeApi;
    // }       
    // GetMostUsedCptData(practiceId : number):Observable<any[]> {
    //     this.practiceId = practiceId;
    //     return this.http.get<any[]>(this.mostUsedCptData,null,false);
    // }
    private get apigetFormMapping(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/practices/${this.practiceId}/PracticeFormMapping`,
            applicationModuleId: 4067,
            keyName:'televisitMasterId'
	
        }
        return authorizeApi;
    }
    getFormMapping(practiceId : number): Observable<any[]> {
        this.practiceId = practiceId;
        return this.http.get<any[]>(this.apigetFormMapping);
    }

    private get api_getFormData(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/televisitmaster/${this.televisitMasterId}/TelevisitScreeningForms`,
            //childModuleName: 'dictation-form',
            applicationModuleId: 4083,
            keyName:'screeningFormId'
        }
        return authorizeApi;
    }

    getFormData(televisitMasterId : number  ): Observable<TelevisitScreeningForm[]> {
        this.televisitMasterId = televisitMasterId;
        return this.http.get<TelevisitScreeningForm[]>(this.api_getFormData);
    }

   
}
