import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { AhtDivertService } from '../aht-divert.service';
import { AhtDivertDomain } from '../domain/aht-divert.domain';
import { AhtDivert, AhtProviderMaster } from 'src/app/database-models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { user } from '@rx/security';
import { ApplicationConfiguration } from '@rx/core';
import { DivertMinutes } from 'src/app/const/divert-minutes';
import { AhtProviderMasterService } from '../aht-provider-master.service';


@Component({
    templateUrl: './aht-divert-list.component.html',
})
export class AhtDivertListComponent extends AhtDivertDomain implements OnInit, OnDestroy {
    isEditable: boolean = true;
    showComponent: boolean = false;
    ahtDivert: any;
    listSubscription: Subscription;
    practiceName: any;
    divertMinute: any;
    divertMin: number = 30;
    addAllCallOnDivert: boolean = false;
    removeAllDivertCall: boolean = false;
    ahtDivertOrigenalData: any;

    orderByColumn: string = null;

    sortOrder: string = null;

    pageIndex: number = 0;

    rowCount: number = 0;
    practicList: any = [];
    divertCallList: any = [];
    ahtProviderMaster: AhtProviderMaster[];
    ahtStartTimeFormGroup: FormGroup;
    practiceId: number;
    constructor(
        private ahtDivertService: AhtDivertService,
        private dialog: RxDialog,
        private popup: RxPopup,
        private router: Router,
        private toast: RxToast,
        private ahtProviderMasterService: AhtProviderMasterService,
        private fb: FormBuilder,
    ) {
        super();
        if (user && user.data) {
            this.practiceName = user.data["practiceName"];
            this.practiceId = user.data["practiceId"];
        }
    }

    ngOnInit(): void {
        this.showComponent = false;
        //this.isEditable = ApplicationPermission.isAccess(["edit"]);
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        this.listSubscription = this.ahtDivertService.get([this.orderByColumn, this.sortOrder, this.pageIndex, this.rowCount]).subscribe(ahtDivert => {
            this.divertMinute = DivertMinutes;
            this.ahtDivert = ahtDivert;
            this.ahtDivertOrigenalData = ahtDivert;
            this.showComponent = true;
        });


        this.listSubscription = this.ahtProviderMasterService.get().subscribe(ahtProviderMaster => {
            this.ahtProviderMaster = ahtProviderMaster;
        });

        this.ahtStartTimeFormGroup = this.fb.group({
            ahtProviderId: ['', Validators.required],
            practiceIds: ['', Validators.required],
            minutes: ['', Validators.required],
        })
    }
    @ViewChild("rxtag") tag;
    onchangeProvider() {
        var ahtProviderId = this.ahtStartTimeFormGroup.value.ahtProviderId;

        this.tag.clear();
        this.ahtProviderMasterService.GetAhtPracticeList(ahtProviderId).subscribe(result => {

            this.practicList = result;
            if (result.length > 0) {
                this.practicList.push({
                    practiceId: 0,
                    practiceName: "All",
                });
            }
            this.practicList.sort((a, b) => a.practiceId - b.practiceId)

        });
    }

    onDelete(ahtDivert: any): void {
        let msgString = ApplicationConfiguration.get("toastValidationMessages.dependency");

        this.dialog.confirmation([ahtDivert.AfterHourTriageContact], "delete", "confirmation", true).then(dialogClick => {
            if (dialogClick == DialogClick.PrimaryOk) {
                this.listSubscription = this.ahtDivertService.delete(ahtDivert.ahtDivertId).subscribe(t => {
                    this.ngOnInit();
                },
                    error => {
                        this.toast.show(msgString, { status: 'error' });
                    })
            }
            if (dialogClick == DialogClick.SecondaryOk) {
                this.listSubscription = this.ahtProviderMasterService.DeleteAhtDivertCallsForPractice(ahtDivert.ahtProviderId).subscribe(result => {
                    this.toast.show("Data deleted successfully", { status: 'success' });
                    this.ngOnInit();
                },
                    error => {
                        this.toast.show(msgString, { status: 'error' });
                    })
            }
        })
    }

    submitDivertCalls(): void {

        this.ahtDivert.forEach(divertCall => {
            this.divertCallList.push({
                practiceId: divertCall.practiceId,
                ahtProviderId: divertCall.ahtProviderId,
                ahtDivertId: divertCall.ahtDivertId,
                divertTill: divertCall.divertTill,
                isOnDivert: divertCall.isOnDivert,
            });
        });
        this.listSubscription = this.ahtDivertService.post({ Query: JSON.stringify(this.divertCallList) }).subscribe(t => {

            this.divertCallList = [];
            this.addAllCallOnDivert = false;
            this.removeAllDivertCall = false;
            setTimeout(() => {
                this.ngOnInit();
            }, 3000);

        },
            error => {
                this.popup.validationFailed(error);
            });
    }

    divertAllCall(value) {

        this.addAllCallOnDivert = !this.addAllCallOnDivert;
        if (!this.addAllCallOnDivert) {
            this.ahtDivert = this.ahtDivertOrigenalData;
        }
        else {
            this.ahtDivert.forEach(element => {
                element.isOnDivert = true;
                element.divertTill = this.divertMin;
            });
        }
    }

    addAhtDivertCalls() {
        var array = [];
        this.divertCallList = [];

        var data = this.ahtStartTimeFormGroup.value;

        if (data.practiceIds.indexOf("0") != -1) {
            array = this.practicList.where(x => x.practiceId != 0);

            if (array.length > 0) {
                array.forEach(element => {
                    this.divertCallList.push({
                        practiceId: element.practiceId,
                        ahtProviderId: data.ahtProviderId,
                        ahtDivertId: 0,
                        divertTill: data.minutes,
                        isOnDivert: true,
                    });
                });
            }

        }
        else {
            array = data.practiceIds.split(',');
            if (array.length > 0) {
                array.forEach(element => {
                    this.divertCallList.push({
                        practiceId: element,
                        ahtProviderId: data.ahtProviderId,
                        ahtDivertId: 0,
                        divertTill: data.minutes,
                        isOnDivert: true,
                    });
                });
            }
        }

        this.ahtStartTimeFormGroup.controls.ahtProviderId.reset();
        this.ahtStartTimeFormGroup.controls.minutes.reset();
        this.tag.clear();

        this.listSubscription = this.ahtDivertService.post({ Query: JSON.stringify(this.divertCallList) }).subscribe(t => {
            if (t != undefined) {
                this.ahtDivert = t;
            }
        },
            error => {
                this.popup.validationFailed(error);
            });
    }
    removeAllCall(event) {

        this.removeAllDivertCall = !this.removeAllDivertCall;
        if (event.target.checked) {
            this.ahtDivert = this.ahtDivertOrigenalData;
        }
        else {
            this.ahtDivert.forEach(element => {
                element.isOnDivert = false;
                element.divertTill = 0;
            });
        }
    }

    fillAhtDIvertGrid() {
        this.listSubscription = this.ahtDivertService.get([this.orderByColumn, this.sortOrder, this.pageIndex, this.rowCount]).subscribe(ahtDivert => {
            this.divertMinute = DivertMinutes;

            this.ahtDivert = ahtDivert;
            this.ahtDivertOrigenalData = ahtDivert;
            this.showComponent = true;

            console.log(ahtDivert);
        });
    }

    ngOnDestroy(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        super.destroy();
    }
}
