import { NgModule } from '@angular/core';
import {RouterModule } from '@angular/router';


import { CALL_OUTBOUND_ROUTING } from './call-outbound.routing';
import { CallOutboundService } from './call-outbound.service';

@NgModule({
    imports: [CALL_OUTBOUND_ROUTING],
    exports: [RouterModule],
    providers: [CallOutboundService]
})
export class CallOutboundModule { }