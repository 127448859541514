import { ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import { PageAccess } from "src/app/domain/authorization";

const CALL_OUTBOUND_ROUTES: Routes = [
	{
    path: ':callOutboundId', 
	loadChildren: () => import('./edit/call-outbound-edit.module').then(m => m.CallOutboundEditModule),
	canActivate: [PageAccess],
    data: { rootModuleId: 33,pageName:'call-outbound', applicationModuleId: 2045, accessItem: 'edit',  keyName: 'callOutboundId', compositeKeys:[] }
	},
];

export const CALL_OUTBOUND_ROUTING = RouterModule.forChild(CALL_OUTBOUND_ROUTES);
