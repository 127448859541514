import { LookupAction } from '@rx/http'

export class RosterLookups {
    static hospitals: LookupAction = {
        controllerName: "roster",
        actionName: "hospitals",
		cacheOption: {
            isVersionBase: true,
            tableName:''
        }
    };

    static providers: LookupAction = {
        controllerName: "roster",
        actionName: "providers",
		cacheOption: {
            isVersionBase: true,
            tableName:''
        }
    };

}
