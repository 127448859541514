import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vApplicationTimeZones')
export class vApplicationTimeZone {
    constructor(vApplicationTimeZone?: vApplicationTimeZone )  {
        let properties = [ "applicationTimeZoneId", "applicationTimeZoneName", "comment", "timeZoneName",];
        for (let property of properties)
            if (vApplicationTimeZone && (vApplicationTimeZone[property] || vApplicationTimeZone[property] == 0))
                this[property] = vApplicationTimeZone[property];
    }
 
    @range(1,2147483647)
	@property('ApplicationTimeZoneId')
	applicationTimeZoneId : number =   undefined;
 
    @maxLength(100)
	@property('ApplicationTimeZoneName')
	applicationTimeZoneName : string = undefined ;
 
    @required()
    @maxLength(200)
	@property('Comment')
	comment : string = undefined ;
 
    @required()
    @maxLength(100)
	@property('TimeZoneName')
	timeZoneName : string = undefined ;



}
