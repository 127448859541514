import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { RxMessageComponent } from '@rx/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';
import { RxValidation } from '@rx/forms';
import { CallDb, } from 'src/app/database-models';

import { } from 'src/app/lookups';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { CallDbLookupGroup } from '../domain/call-db.models';
import { ApplicationBroadcaster } from '@rx/core';

import { CallDbPatientComponent } from 'src/app/components/call-manager/call-db/patient/call-db-patient.component';
import { CallDbNewbornComponent } from 'src/app/components/call-manager/call-db/newborn/call-db-newborn.component';
import { CallDbDoctorComponent } from 'src/app/components/call-manager/call-db/doctor/call-db-doctor.component';
import { CallDbAdmissionComponent } from 'src/app/components/call-manager/call-db/admission/call-db-admission.component';
import { CallDbAppointmentComponent } from 'src/app/components/call-manager/call-db/appointment/call-db-appointment.component';
import { CallDbAnsserviceComponent } from 'src/app/components/call-manager/call-db/ansservice/call-db-ansservice.component';
import { CallDbTriageComponent } from 'src/app/components/call-manager/call-db/triage/call-db-triage.component';
import { user } from '@rx/security';
import { CallDbRxrefillComponent } from '../rxrefill/call-db-rxrefill.component';
import { PracticeSearchComponent } from '../../../agent-office/practices/search/practice-search.component';
import { ProviderViewComponent } from '../../../agent-provider/providers/view/provider-view.component';
import { FormState } from '../../../../domain/form-state';


@Component({
    templateUrl: './call-db-edit.component.html',
    entryComponents: [CallDbPatientComponent, CallDbRxrefillComponent, CallDbNewbornComponent, CallDbDoctorComponent, CallDbAdmissionComponent, CallDbAppointmentComponent, CallDbAnsserviceComponent, CallDbTriageComponent, PracticeSearchComponent, ProviderViewComponent]
})
export class CallDbEditComponent extends CallDbDomain implements OnInit, OnDestroy {
    tabValue: string = "Patient"
    showComponent: boolean = false;
    callDbFormGroup: FormGroup;
    editSubscription: Subscription;
    callDbLookupGroup: CallDbLookupGroup;
    sKey: number;
    isCallType: boolean = false;
    @Input() practiceId: number;
    callTypeId: number = undefined;
    isTrue: boolean = true;
    practiceName: string = undefined;
    patientId: number = undefined;
    constructor(
        private validation: RxValidation,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private toast: RxToast,
        private callDbService: CallDbService,
        private dialog: RxDialog,
        private applicationBroadcaster: ApplicationBroadcaster,
        private popup: RxPopup
    ) {
        super();
        activatedRoute.params.subscribe((param: any) => {
            this.sKey = param['sKey']
        });
        this.applicationBroadcaster.practiceNameSubscriber.subscribe(value => this.practiceName = value);
        if (user.data['practiceId'] != null && parseInt(this.activatedRoute.snapshot.children[0].url[1].path) != user.data['practiceId']) {
            let userData = user.data;
            userData['practiceId'] = parseInt(this.activatedRoute.snapshot.children[0].url[1].path);
            user.data = userData;
        }

        this.practiceId = user.data['practiceId'] == null ? parseInt(this.activatedRoute.snapshot.children[0].url[1].path) : user.data['practiceId'];
        
        this.practiceName = user.data['practiceName'];
        
        this.applicationBroadcaster.patientIdSubscriber.subscribe(value => {
            
            this.patientId = value;
            this.ngOnInit();
        })
    }

    ngOnInit(): void {
        switch (this.activatedRoute.snapshot.children[0].url[0].path) {
            case "admission":
                this.tabValue = 'Admission';
                this.callTypeId = 0;
                break;
            case "answering-service":
                this.tabValue = 'Answering Service';
                this.callTypeId = 0;
                break;
            case "appointment":
                this.tabValue = 'Appointment';
                this.callTypeId = 0;
                break;
            case "hospital-lab-doctor":
                this.tabValue = 'Hospital / Lab / Doctor';
                this.callTypeId = 2;
                break;
            case "newborn":
                this.tabValue = 'Newborn';
                this.callTypeId = 3;
                break;
            case "patient":
                this.tabValue = 'Patient';
                this.callTypeId = 1;
                break;
            case "triage":
                this.tabValue = 'Triage';
                this.callTypeId = 0;
                break;
            case "rxRefill":
                this.tabValue = 'Rx Refill';
                this.callTypeId = 4;
                break;
        }
        this.showComponent = true;
    }

    tabChange(routeValue, tabValue): void {
        switch (routeValue) {
            case "admission":
                this.callTypeId = 0;
                break;
            case "answering-service":
                this.callTypeId = 0;
                break;
            case "appointment":
                this.callTypeId = 0;
                break;
            case "hospital-lab-doctor":
                this.callTypeId = 2;
                break;
            case "newborn":
                this.callTypeId = 3;
                break;
            case "patient":
                this.callTypeId = 1;
                break;
            case "triage":
                this.callTypeId = 0;
                break;
            case "rxRefill":
                this.callTypeId = 4;
                break;
        }
        document.body.classList.remove("show-termSection");
        // let data = FormState.getState(this.patientId);
        // if (data) {
        //     data.urgent = false;
        //     FormState.setState(data.sKey, data);
        // }
        this.tabValue = tabValue
        let currentString = "call-db" + '/' + routeValue;
        this.router.navigate([currentString, this.practiceId]);
        this.callDbService.addNode(this.patientId);
        this.applicationBroadcaster.openTechincalSectionBroadCast(false);
    }

    editCallDb(): void {
        this.editSubscription = this.callDbService.put(this.practiceId, this.callDbFormGroup.value).subscribe(t => {

        },
            error => {
                this.popup.validationFailed(error);
            })
    }

    canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        return !this.callDbFormGroup.dirty;
    }

    ngOnDestroy(): void {
        if (this.editSubscription)
            this.editSubscription.unsubscribe();
        super.destroy();
    }
}
