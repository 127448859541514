import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vCallRecordHistory')
export class vCallRecordHistory {
    constructor(vCallRecordHistory?: vCallRecordHistory )  {
        let properties = [ "callRecordHistoryId", "callsentTo", "callSentVia", "callType", "callTypeCode", "callTypeId", "createdBy", "createdDate", "cStartTimeDate", "onCallType", "operationType", "practiceName", "remarks", "skey",];
        for (let property of properties)
            if (vCallRecordHistory && (vCallRecordHistory[property] || vCallRecordHistory[property] == 0))
                this[property] = vCallRecordHistory[property];
    }
 
    @range(1,2147483647)
	@property('CallRecordHistoryId')
	callRecordHistoryId : number =   undefined;
 
    @maxLength(50)
	@property('CallsentTo')
	callsentTo : string = undefined ;
 
    @maxLength(50)
	@property('CallSentVia')
	callSentVia : string = undefined ;
 
    @required()
    @maxLength(20)
	@property('CallType')
	callType : string = undefined ;
 
    @required()
    @maxLength(20)
	@property('CallTypeCode')
	callTypeCode : string = undefined ;
 
	@property('CallTypeId')
	callTypeId : number =   undefined;
 
	@property('CreatedBy')
	createdBy : number =   undefined;
 
	@property('CreatedDate')
	createdDate : Date =   undefined;
 
	@property('CStartTimeDate')
	cStartTimeDate : Date =   undefined;
 
    @maxLength(100)
	@property('OnCallType')
	onCallType : string = undefined ;
 
    @maxLength(100)
	@property('OperationType')
	operationType : string = undefined ;
 
    @required()
    @maxLength(100)
	@property('PracticeName')
	practiceName : string = undefined ;
 
    @maxLength(500)
	@property('Remarks')
	remarks : string = undefined ;
 
    @range(1,2147483647)
	@property('Skey')
	skey : number =   undefined;



}
