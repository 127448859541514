import { Injectable } from '@angular/core';
import {Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi} from "@rx/security";

import {  CallRecordHistory, vCallRecordHistory, } from 'src/app/database-models';
import { CallRecordHistoryLookupGroup } from './domain/call-record-history.models';

@Injectable()
export class CallRecordHistoryService {
   

	private get api(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/CallRecordHistory`,
            applicationModuleId: 2049,
            keyName:'callRecordHistoryId'
	
        }
        return authorizeApi;
    }

    constructor(
        private http: RxHttp
    ) { }

    lookup<CallRecordHistoryLookupGroup>(lookupActions: LookupAction[]): Promise<CallRecordHistoryLookupGroup> {
        return this.http.lookup<CallRecordHistoryLookupGroup>(lookupActions);
    }

    group<CallRecordHistoryLookupGroup>(params: any[] | {
        [key: string]: any;
    } | RequestQueryParams, lookupActions: LookupAction[]): Promise<CallRecordHistoryLookupGroup> {
        return this.http.group<CallRecordHistoryLookupGroup>(this.api, params, 'callRecordHistory', lookupActions);
    }

	search(search: any): Observable<vCallRecordHistory[]> {
        return this.http.search<vCallRecordHistory[]>(this.api, search);
    }

    get(): Observable<vCallRecordHistory[]> {
        return this.http.get<vCallRecordHistory[]>(this.api);
    }

    getBy(params?: any[] | {
        [key: string]: any;
    } | RequestQueryParams): Observable<any> {
        return this.http.get<any>(this.api, params); 
    }

    post(callRecordHistory: CallRecordHistory): Observable<CallRecordHistory> {
        return this.http.post<CallRecordHistory>(this.api, callRecordHistory);
    }

    put(callRecordHistory: CallRecordHistory): Observable<CallRecordHistory> {
        return this.http.put<CallRecordHistory>(this.api, callRecordHistory);
    }

    delete(id : number): Observable<CallRecordHistory> {
        return this.http.delete<CallRecordHistory>(this.api,id);
    }

	filterLookup<T>(lookup:LookupAction,filterParameters:any[],queryParams?: {[key:string]:any}):Observable<T> {
        return this.http.filterLookup(lookup,filterParameters,queryParams);
    }

}
