import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Practice } from './practice';import { Provider } from './provider';import { RoundingHospitalMapping } from './rounding-hospital-mapping'; @table('HospitalRoundingRosters')
export class HospitalRoundingRoster {
    constructor(hospitalRoundingRoster?: HospitalRoundingRoster )  {
        let properties = [ "createdById", "createdDateTime", "fromTime", "hospitalRoundingRosterId", "modifiedById", "modifiedDateTime", "onCallComment", "roundingDate", "toTime", "practiceId", "providerId", "roundingHospitalMapping",];
        for (let property of properties)
            if (hospitalRoundingRoster && (hospitalRoundingRoster[property] || hospitalRoundingRoster[property] == 0))
                this[property] = hospitalRoundingRoster[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('FromTime')
	fromTime : Date =   undefined;
 
	@property('HospitalRoundingRosterId')
	hospitalRoundingRosterId : number =   0 ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @maxLength(500)
	@property('OnCallComment')
	onCallComment : string = undefined ;
 
    @required()
	@property('RoundingDate')
	roundingDate : Date =   undefined;
 
	@property('ToTime')
	toTime : Date =   undefined;
 
    @range(0,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
	practice : Practice  ;
 
    @range(0,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
	provider : Provider  ;
	@nested(RoundingHospitalMapping)
	roundingHospitalMapping: RoundingHospitalMapping[];




}
