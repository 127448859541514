import {ComponentContainer} from "@rx/core"
import { ProviderViewComponent } from './view/provider-view.component'

export const PROVIDERS_SHARED_COMPONENT_CONTAINER: ComponentContainer[] = [
	{
    component: ProviderViewComponent,
    accessItem: 'list',
    applicationModuleId: 1042,
	keyName: 'providerId',
	//childModuleName :  'providers'  ,
	childModuleName :  undefined  ,
	pageName:'providers',
	rootModuleId:1037
	},
];

