export const ALERT_TYPES = [
	{
        "alertTypeId": 45,
        "alertTypeName": "Welcome Script"
    },
	{
        "alertTypeId": 46,
        "alertTypeName": "General Messages"
    },
	{
        "alertTypeId": 47,
        "alertTypeName": "Special Messages"
    },
	{
        "alertTypeId": 48,
        "alertTypeName": "No Response Plan"
    },
]