export const NOTIFICATION_PREFERENCES = [
	{
        "notificationPreferencesId": 1093,
        "notificationPreferencesName": "Text"
    },
	{
        "notificationPreferencesId": 1094,
        "notificationPreferencesName": "Mobile App"
    },
	{
        "notificationPreferencesId": 1095,
        "notificationPreferencesName": "Call Cell"
    },
	{
        "notificationPreferencesId": 1096,
        "notificationPreferencesName": "Call Home"
    },
	{
        "notificationPreferencesId": 1097,
        "notificationPreferencesName": "Call Office"
    },
	{
        "notificationPreferencesId": 1098,
        "notificationPreferencesName": "Call Backline"
    },
	{
        "notificationPreferencesId": 2129,
        "notificationPreferencesName": "Hold For AM"
    },
	{
        "notificationPreferencesId": 2130,
        "notificationPreferencesName": "Fax Office"
    },
	{
        "notificationPreferencesId": 2131,
        "notificationPreferencesName": "Fax Other"
    },
	{
        "notificationPreferencesId": 2132,
        "notificationPreferencesName": "Patch Home"
    },
	{
        "notificationPreferencesId": 2133,
        "notificationPreferencesName": "Patch Office"
    },
	{
        "notificationPreferencesId": 2134,
        "notificationPreferencesName": "Patch Cell"
    },
	{
        "notificationPreferencesId": 2152,
        "notificationPreferencesName": "After Hour Triage"
    },
	{
        "notificationPreferencesId": 5154,
        "notificationPreferencesName": "Pager"
    },
]