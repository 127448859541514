export const NOTIFICATION_TYPES = [
	{
        "notificationTypesId": 2126,
        "notificationTypesName": "Text"
    },
	{
        "notificationTypesId": 2127,
        "notificationTypesName": "Call Cell"
    },
	{
        "notificationTypesId": 2128,
        "notificationTypesName": "Call Home"
    },
]