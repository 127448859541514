import { ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import { PageAccess } from "src/app/domain/authorization";

const ALERTS_ROUTES: Routes = [
	{
    path: 'add', 
	loadChildren: () => import('./add/alert-add.module').then(m => m.AlertAddModule),
	canActivate: [PageAccess],
    data: { rootModuleId: 2,pageName:'alerts', applicationModuleId: 12, accessItem: 'add',  keyName: 'alertId', compositeKeys:[] }
	},
	{
    path: 'search', 
	loadChildren: () => import('./search/alert-search.module').then(m => m.AlertSearchModule),
	canActivate: [PageAccess],
    data: { rootModuleId: 2,pageName:'alerts', applicationModuleId: 12, accessItem: 'list',  keyName: 'alertId', compositeKeys:[] }
	},
];

export const ALERTS_ROUTING = RouterModule.forChild(ALERTS_ROUTES);
