import {ComponentContainer} from "@rx/core"
import { CallRecordHistoryViewComponent } from './view/call-record-history-view.component'

export const CALL_RECORD_HISTORY_SHARED_COMPONENT_CONTAINER: ComponentContainer[] = [
	{
    component: CallRecordHistoryViewComponent,
    accessItem: 'view',
     applicationModuleId: undefined,
	keyName: 'callRecordHistoryId',
	childModuleName : "callrecorddetails",
	pageName:'call-record-history',
	rootModuleId:1037
	},
];

