import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vCallDb')
export class vCallDb {
    constructor(vCallDb?: vCallDb )  {
        let properties = [ "birthdate", "caller", "callTypeId", "cStartTimeDate", "description", "patient", "practiceId", "primary", "processed", "refNum", "sentTo", "sentVia", "shortDesc", "time",];
        for (let property of properties)
            if (vCallDb && (vCallDb[property] || vCallDb[property] == 0))
                this[property] = vCallDb[property];
    }
 
	@property('Birthdate')
	birthdate : Date =   undefined;
 
    @maxLength(101)
	@property('Caller')
	caller : string = undefined ;
 
	@property('CallTypeId')
	callTypeId : number =   undefined;
 
	@property('CStartTimeDate')
	cStartTimeDate : Date =   undefined;
 
    @required()
    @maxLength(100)
	@property('Description')
	description : string = undefined ;
 
    @required()
    @maxLength(101)
	@property('Patient')
	patient : string = undefined ;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @required()
    @maxLength(101)
	@property('Primary')
	primary : string = undefined ;
 
	@property('Processed')
	processed : boolean = false ;
 
    @range(1,2147483647)
	@property('RefNum')
	refNum : number =   undefined;
 
    @maxLength(50)
	@property('SentTo')
	sentTo : string = undefined ;
 
    @maxLength(50)
	@property('SentVia')
	sentVia : string = undefined ;
 
    @required()
    @maxLength(20)
	@property('ShortDesc')
	shortDesc : string = undefined ;
 
    @maxLength(4000)
	@property('Time')
	time : string = undefined ;



}
