import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vUsers')
export class vUser {
    constructor(vUser?: vUser )  {
        let properties = [ "email", "fullName", "isBackendUser", "loginBlocked", "practiceId", "practiceName", "roleName", "statusId", "userId", "userName",];
        for (let property of properties)
            if (vUser && (vUser[property] || vUser[property] == 0))
                this[property] = vUser[property];
    }
 
    @maxLength(150)
	@property('Email')
	email : string = undefined ;
 
    @required()
    @maxLength(201)
	@property('FullName')
	fullName : string = undefined ;
 
	@property('IsBackendUser')
	isBackendUser : boolean = false ;
 
	@property('LoginBlocked')
	loginBlocked : boolean = false ;
 
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @maxLength(100)
	@property('PracticeName')
	practiceName : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('RoleName')
	roleName : string = undefined ;
 
    @range(1,2147483647)
	@property('StatusId')
	statusId : number =   undefined;
 
    @range(1,2147483647)
	@property('UserId')
	userId : number =   undefined;
 
    @required()
    @maxLength(50)
	@property('UserName')
	userName : string = undefined ;



}
