import { required, maxLength, range, nested, table, property, alpha, alphaNumeric, numeric, uppercase, pattern, minDate, maxNumber, maxDate, lowercase, hexColor, email, contains, compare } from '@rx/annotations';
import { UserMessage } from './user-message'; import { NotificationPlusTransaction } from './notification-plus-transaction'; @table('CallDb')
export class CallDb {
  constructor(callDb?: CallDb) {
    let properties = ["admissionDate", "admissionTime", "admissionType", "age", "ageM", "agentId", "apgars10Min", "apgars1Min", "apgars5min", "attendingPhysician", "babysDischargeDate", "babysEthnicity", "babysRace", "birthDate", "birthTime", "birthType", "borG", "bPediatrician", "bPediatricianId", "callBack", "callTypeId", "cBNumberP", "cBNumberS", "cBNumberTypeP", "cBNumberTypeS", "cEndTimeDate", "cFirstName", "chFirstName", "chiefComplaint", "chLastName", "cLastName", "cName", "comments", "cPCP", "cRelationship2Ch", "cStartTimeDate", "cTitle", "currentCallNotPref", "currentCallProviderId", "department", "dischargedTo", "eMRPatientId", "facilityName", "gestation", "goodBaby", "holdTillDt", "holdUntillTime", "hospitalId", "languageSpoken", "lastMessageStatus", "lastUpDate", "messageLogRecNo", "mFirstName", "mLastName", "mothersDischargeDate", "multipleBirth", "notPhysId", "notPrefId", "obGyn", "onCallDr", "onCallDrNotPref", "onCallRosterId", "parentRefNumber", "pCDBCode", "pharmacyName", "pharmacyNumber", "practiceId", "primaryPhysicianId", "processDT", "processed", "readytoProcess", "referenceNumber", "room", "sentTo", "sentToPhysicianId", "sentVia", "serviceSubmittedUnder", "sKey", "textMessage", "timeNo", "urgent", "userName", "weight", "userMessages", "notificationPlusTransactions", "operationType", "spResult", "cStartDate", "onCallType","insurance"];
    for (let property of properties)
      if (callDb && (callDb[property] || callDb[property] == 0))
        this[property] = callDb[property];
  }

  @property('AdmissionDate')
  admissionDate: Date = undefined;

  @property('AdmissionTime')
  admissionTime: Date = undefined;

  @maxLength(50)
  @property('AdmissionType')
  admissionType: string = undefined;

  @maxLength(50)
  @property('Age')
  age: string = undefined;

  @maxLength(10)
  @property('AgeM')
  ageM: string = undefined;

  @property('AgentId')
  agentId: number = undefined;

  @numeric()
  @pattern('[0-9]')
  @property('Apgars10Min')
  apgars10Min: number = undefined;

  @numeric()
  @pattern('[0-9]')
  @property('Apgars1Min')
  apgars1Min: number = undefined;

  @numeric()
  @pattern('[0-9]')
  @property('Apgars5min')
  apgars5min: number = undefined;

  @maxLength(50)
  @property('AttendingPhysician')
  attendingPhysician: string = undefined;

  @property('BabysDischargeDate')
  babysDischargeDate: Date = undefined;

  @maxLength(50)
  @property('BabysEthnicity')
  babysEthnicity: string = undefined;

  @maxLength(50)
  @property('BabysRace')
  babysRace: string = undefined;

  @property('BirthDate')
  birthDate: Date = undefined;

  @property('BirthTime')
  birthTime: Date = undefined;

  @property('BirthType')
  birthType: number = undefined;

  @maxLength(10)
  @property('BorG')
  borG: string = undefined;

  @maxLength(50)
  @property('BPediatrician')
  bPediatrician: string = undefined;

  @property('BPediatricianId')
  bPediatricianId: number = undefined;

  @property('CallBack')
  callBack: boolean = false;

  @property('CallTypeId')
  callTypeId: number = undefined;

  @maxLength(50)
  @property('CBNumberP')
  cBNumberP: string = undefined;

  @maxLength(50)
  @property('CBNumberS')
  cBNumberS: string = undefined;

  @property('CBNumberTypeP')
  cBNumberTypeP: number = undefined;

  @property('CBNumberTypeS')
  cBNumberTypeS: number = undefined;

  @property('CEndTimeDate')
  cEndTimeDate: Date = undefined;

  @maxLength(50)
  @property('CFirstName')
  cFirstName: string = undefined;

  @maxLength(50)
  @property('ChFirstName')
  chFirstName: string = undefined;

  @maxLength(50)
  @property('ChiefComplaint')
  chiefComplaint: string = undefined;

  @maxLength(50)
  @property('ChLastName')
  chLastName: string = undefined;

  @maxLength(50)
  @property('CLastName')
  cLastName: string = undefined;

  @maxLength(50)
  @property('CName')
  cName: string = undefined;

  @maxLength(1000)
  @property('Comments')
  comments: string = undefined;

  @maxLength(50)
  @property('CPCP')
  cPCP: string = undefined;

  @property('CRelationship2Ch')
  cRelationship2Ch: number = undefined;

  @property('CStartTimeDate')
  cStartTimeDate: Date = undefined;

  @maxLength(5)
  @property('CTitle')
  cTitle: string = undefined;

  @property('CurrentCallNotPref')
  currentCallNotPref: number = undefined;

  @property('CurrentCallProviderId')
  currentCallProviderId: number = undefined;

  @maxLength(50)
  @property('Department')
  department: string = undefined;

  @maxLength(50)
  @property('DischargedTo')
  dischargedTo: string = undefined;

  @maxLength(50)
  @property('EMRPatientId')
  eMRPatientId: string = undefined;

  @maxLength(100)
  @property('FacilityName')
  facilityName: string = undefined;

  @maxLength(50)
  @property('Gestation')
  gestation: string = undefined;

  @property('GoodBaby')
  goodBaby: boolean = false;

  @property('HoldTillDt')
  holdTillDt: Date = undefined;

  @property('holdUntillTime')
  holdUntillTime: Date = undefined;

  @property('HospitalId')
  hospitalId: number = undefined;

  @maxLength(50)
  @property('LanguageSpoken')
  languageSpoken: string = undefined;

  @property('LastMessageStatus')
  lastMessageStatus: number = undefined;

  @property('LastUpDate')
  lastUpDate: Date = undefined;

  @maxLength(50)
  @property('MessageLogRecNo')
  messageLogRecNo: string = undefined;

  @maxLength(50)
  @property('MFirstName')
  mFirstName: string = undefined;

  @maxLength(50)
  @property('MLastName')
  mLastName: string = undefined;

  @property('MothersDischargeDate')
  mothersDischargeDate: Date = undefined;

  @property('MultipleBirth')
  multipleBirth: boolean = false;

  @property('NotPhysId')
  notPhysId: number = undefined;

  @property('NotPrefId')
  notPrefId: number = undefined;

  @maxLength(50)
  @property('ObGyn')
  obGyn: string = undefined;

  @maxLength(50)
  @property('OnCallDr')
  onCallDr: string = undefined;

  @maxLength(50)
  @property('OnCallDrNotPref')
  onCallDrNotPref: string = undefined;
  
  @property('OnCallRosterId')
  onCallRosterId : number =   undefined;

  @property('ParentRefNumber')
  parentRefNumber: number = undefined;

  @property('PCDBCode')
  pCDBCode: number = undefined;

  @maxLength(200)
  @property('PharmacyName')
  pharmacyName: string = undefined;

  @maxLength(12)
  @property('PharmacyNumber')
  pharmacyNumber: string = undefined;

  @property('PracticeId')
  practiceId: number = undefined;

  @property('PrimaryPhysicianId')
  primaryPhysicianId: number = undefined;

  @property('ProcessDT')
  processDT: Date = undefined;

  @property('Processed')
  processed: boolean = false;

  @property('ReadytoProcess')
  readytoProcess: boolean = false;

  @numeric()
  @property('ReferenceNumber')
  referenceNumber: number = undefined;

  @maxLength(50)
  @property('Room')
  room: string = undefined;

  @maxLength(50)
  @property('SentTo')
  sentTo: string = undefined;

  @property('SentToPhysicianId')
  sentToPhysicianId: number = undefined;

  @maxLength(50)
  @property('SentVia')
  sentVia: string = undefined;

  @maxLength(200)
  @property('ServiceSubmittedUnder')
  serviceSubmittedUnder: string = undefined;

  @property('SKey')
  sKey: number = 0;

  @maxLength(1500)
  @property('TextMessage')
  textMessage: string = undefined;

  @property('TimeNo')
  timeNo: number = undefined;

  @property('Urgent')
  urgent: boolean = false;

  @maxLength(50)
  @property('UserName')
  userName: string = undefined;

  @maxLength(50)
  @property('Weight')
  weight: string = undefined;

  @maxLength(50)
  @property('Insurance')
  insurance: string = undefined;
  @nested(UserMessage)
  userMessages: UserMessage[];

  @nested(NotificationPlusTransaction)
  notificationPlusTransactions: NotificationPlusTransaction[];



  operationType: string = undefined;
  spResult: string = undefined;
  cStartDate: string = undefined;
  onCallType: number = undefined;

}
