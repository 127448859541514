import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs/Rx';

@Injectable()
export class ApplicationBroadcaster {
  private loginSubject: Subject<boolean> = new Subject<boolean>();

  public loginSubscriber: Observable<boolean>;

  private allTypeSubject: Subject<boolean> = new Subject<any>();

  public allTypeSubscriber: Observable<any>;

  private configurationSubject: Subject<boolean> = new Subject<boolean>();

  public configurationSubscriber: Observable<boolean>;

  private addressIdSubject: Subject<number> = new Subject<number>();

  public addressIdSubscriber: Observable<number>;

  private hospitalIdSubject: Subject<number> = new Subject<number>();

  public hospitalIdSubscriber: Observable<number>;

  private isPracticeSelectedSubject: Subject<boolean> = new Subject<boolean>();

  public isPracticeSelectedSubscriber: Observable<boolean>;

  public showHospitalListSubscriber: Observable<any>;

  private isshowHospitalListSubject: Subject<boolean> = new Subject<any>();

  public showVacationTabSubscriber: Observable<any>;

  private isShowVacationTabSubject: Subject<boolean> = new Subject<any>();

  public updateEmailIdSubscriber: Observable<any>;

  private updateEmailIdSubject: Subject<string> = new Subject<string>();

  public callTypeIdSubscriber: Observable<any>;

  private callTypeIdSubject: Subject<number> = new Subject<number>();

  public patientIdSubscriber : Observable<any>;

  private patientIdSubject :Subject<number> = new Subject<number>();

  public openTechnicalSectionSubscriber : Observable<any>;

  private openTechnicalSectionSubject :Subject<Boolean> = new Subject<Boolean>();

  public practiceNameSubscriber : Observable<string>;

  private practiceNameSubject :Subject<string> = new Subject<string>();

  public outboundSubscriber : Observable<string>;

  private outboundSubject :Subject<string> = new Subject<string>();




  constructor() {
    this.loginSubscriber = this.loginSubject.asObservable();
    this.configurationSubscriber = this.configurationSubject.asObservable();
    this.allTypeSubscriber = this.allTypeSubject.asObservable();
    this.addressIdSubscriber = this.addressIdSubject.asObservable();
    this.hospitalIdSubscriber = this.hospitalIdSubject.asObservable();
    this.isPracticeSelectedSubscriber = this.isPracticeSelectedSubject.asObservable();
    this.showHospitalListSubscriber = this.isshowHospitalListSubject.asObservable();
    this.showVacationTabSubscriber = this.isShowVacationTabSubject.asObservable();
    this.updateEmailIdSubscriber = this.updateEmailIdSubject.asObservable();
    this.callTypeIdSubscriber = this.callTypeIdSubject.asObservable();
    this.patientIdSubscriber = this.patientIdSubject.asObservable();
    this.openTechnicalSectionSubscriber = this.openTechnicalSectionSubject.asObservable();
    this.practiceNameSubscriber = this.practiceNameSubject.asObservable();
    this.outboundSubscriber = this.outboundSubject.asObservable();

  }

  loginBroadCast(value: boolean): void {
    this.loginSubject.next(value);
  }

  vacationBroadCast(value: boolean): void {
    this.isShowVacationTabSubject.next(value);
  }

  allTypeBroadCast(value: any): void {
    this.allTypeSubject.next(value);
  }

  addressIdBroadCast(value: number): void {
    this.addressIdSubject.next(value);
  }

  practiceSelectionBroadCast(value: boolean): void {
    this.isPracticeSelectedSubject.next(value);
  }

  configurationBroadCast(value: boolean): void {
    this.configurationSubject.next(value);
  }

  hospitalBroadCast(value: number): void {
    this.hospitalIdSubject.next(value);
  }
  showHospitalListBroadCast(value: any): void {
    this.isshowHospitalListSubject.next(value);
  }

  updateEmailIdBroadCast(value: any): void {
    this.updateEmailIdSubject.next(value);
  }

  callTypeIdBroadCast(value :number): void{
    this.callTypeIdSubject.next(value);
  }

  patientIdBroadCast(value : number):void{
    this.patientIdSubject.next(value);
  }
 
  openTechincalSectionBroadCast(value : boolean):void{
    this.openTechnicalSectionSubject.next(value);
  }

  practiceNameBroadCast(value : string):void{
    this.practiceNameSubject.next(value);
  }
  
  outboundgridBroadCast(value : string):void{
    this.outboundSubject.next(value);
  }

}
