import { ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import { PageAccess } from "src/app/domain/authorization";

const CALL_RECORD_HISTORY_ROUTES: Routes = [
	{
    path: '', 
	loadChildren: () => import('./list/call-record-history-list.module').then(m => m.CallRecordHistoryListModule),
	canActivate: [PageAccess],
    data: { rootModuleId: 1037,pageName:'call-record-history', applicationModuleId: 2049, accessItem: 'list',  keyName: 'callRecordHistoryId', compositeKeys:[] }
	},
];

export const CALL_RECORD_HISTORY_ROUTING = RouterModule.forChild(CALL_RECORD_HISTORY_ROUTES);
