import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { AgentQuestionMaster } from './agent-question-master'; @table('AgentQuestionAnswer')
export class AgentQuestionAnswer {
    constructor(agentQuestionAnswer?: AgentQuestionAnswer )  {
        let properties = [ "agentQuestionAnswerId", "createdById", "createdDateTime", "modifiedById", "modifiedDateTime", "preDefinedAnswer", "agentQueMasterId",];
        for (let property of properties)
            if (agentQuestionAnswer && (agentQuestionAnswer[property] || agentQuestionAnswer[property] == 0))
                this[property] = agentQuestionAnswer[property];
    }
 
	@property('AgentQuestionAnswerId')
	agentQuestionAnswerId : number =   0 ;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @required()
    @maxLength(200)
	@property('PreDefinedAnswer')
	preDefinedAnswer : string = undefined ;
 
    @range(0,2147483647)
	@property('AgentQueMasterId')
	agentQueMasterId : number =   undefined;
	agentQuestionMaster : AgentQuestionMaster  ;



}
