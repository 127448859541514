import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxFormsModule } from "@rx/forms";
import { RxViewModule } from "@rx/view";
import { RxTableModule } from "@rx/table";
import { AppCalendarComponent } from './app-calendar.component';


@NgModule({
  imports: [
    CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,
  ],
  declarations: [AppCalendarComponent],
  exports: [RouterModule, AppCalendarComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CalendarModule { }
