export const ALTERNATE_NOTIFICATION_PREFERENCES = [
	{
        "alternateNotificationPreferencesId": 5155,
        "alternateNotificationPreferencesName": "Call"
    },
	{
        "alternateNotificationPreferencesId": 5156,
        "alternateNotificationPreferencesName": "Email"
    },
	{
        "alternateNotificationPreferencesId": 5157,
        "alternateNotificationPreferencesName": "Pager"
    },
	{
        "alternateNotificationPreferencesId": 5158,
        "alternateNotificationPreferencesName": "Text"
    },
	{
        "alternateNotificationPreferencesId": 5168,
        "alternateNotificationPreferencesName": "Mobile App"
    },
	{
        "alternateNotificationPreferencesId": 5169,
        "alternateNotificationPreferencesName": "Fax"
    },
]