export const PHONE_TYPES = [
	{
        "phoneTypesId": 2135,
        "phoneTypesName": "Home"
    },
	{
        "phoneTypesId": 2136,
        "phoneTypesName": "Cell"
    },
	{
        "phoneTypesId": 2137,
        "phoneTypesName": "Work"
    },
	{
        "phoneTypesId": 2138,
        "phoneTypesName": "Other"
    },
]