export const CANCEL_VISIT_REASONS = [
	{
        "cancelVisitReasonId": 5197,
        "cancelVisitReasonName": "Parent / Patient request"
    },
	{
        "cancelVisitReasonId": 5198,
        "cancelVisitReasonName": "Office cancelled"
    },
	{
        "cancelVisitReasonId": 5199,
        "cancelVisitReasonName": "No show"
    },
	{
        "cancelVisitReasonId": 5200,
        "cancelVisitReasonName": "Other"
    },
    {
        "cancelVisitReasonId": 5201,
        "cancelVisitReasonName": "Office reschedule"
    },
]