import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vPracticeTypes')
export class vPracticeType {
    constructor(vPracticeType?: vPracticeType )  {
        let properties = [ "practiceTypeId", "practiceTypeName",];
        for (let property of properties)
            if (vPracticeType && (vPracticeType[property] || vPracticeType[property] == 0))
                this[property] = vPracticeType[property];
    }
 
	@property('PracticeTypeId')
	practiceTypeId : number =   0 ;
 
    @required()
    @maxLength(50)
	@property('PracticeTypeName')
	practiceTypeName : string = undefined ;



}
