import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup, TabModel } from '@rx/view';

import { vPractice } from 'src/app/database-models';
import { PracticesService } from '../practices.service';
import { PracticeDomain } from '../domain/practice.domain';

//import { PracticeListComponent } from 'src/app/components/office/practices/list/practice-list.component';
import { user } from '@rx/security';
import { PracticeListComponent } from '../list/practice-list.component';
import { Provider, PracticeOfficeHour, Address } from '../../../../database-models';
import { PracticeSearchComponent } from '../search/practice-search.component';
import { days, monthsShort } from '@rx/forms/datepicker/datepicker.const';
import { DAYS_OF_WEEKS, BRANCH_TYPES } from '../../../../database-collections';
import { DaysOfWeekEnum, BranchTypeEnum, StateEnum, AlertTypeEnum } from '../../../../enums';
import { ProviderViewComponent } from '../../../agent-provider/providers/view/provider-view.component';
import { FormState } from '../../../../domain/form-state';
import { ApplicationBroadcaster } from '@rx/core';
import { AlertsService } from 'src/app/components/agent-alert/alerts/alerts.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'app-practice-view',
  templateUrl: './practice-view.component.html',
  entryComponents: [PracticeListComponent, PracticeSearchComponent, ProviderViewComponent]
})
export class PracticeViewComponent extends PracticeDomain implements OnInit, OnDestroy {
  dayName: string;
  practiceOfficeHours: any;
  onCallRosterProvider: any;
  Practiceaddress: any;
  practiceName: string;
  showPracticeSelector: boolean = false;
  practiceId: any;
  showComponent: boolean = false;
  practices: any;
  viewSubscription: Subscription;
  @Input() isCalldbScreen: boolean = false;

  private _clockSubscription: Subscription;
  time: Date;
  practiceTimeZoneDate: Date;
  timeOut: any;
  isCalled: boolean = false;
  private counter$: Date;
  private subscription: Subscription;
  private message: any;
  timeZoneName: any;
  stateName: string;
  comment: string;
  welcomeMessage: any;

  constructor(
    private practicesService: PracticesService,
    private dialog: RxDialog,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private toast: RxToast,
    private applicationBroadcaster: ApplicationBroadcaster,
    private popup: RxPopup,
    private activatedRoute: ActivatedRoute,
    private alertsService: AlertsService,
    private sanitizer: DomSanitizer,
  ) { super(); this.popup.setComponent(componentFactoryResolver); }

  clockWatch() {
    this.isCalled = true;
    var date = new Date(this.practiceTimeZoneDate);
    if (this.message)
      date = new Date(this.message);
    date.setSeconds(date.getSeconds() + 1);
    this.message = date;

    this.timeOut = window.setTimeout(() => {
      if (this.timeOut)
        window.clearTimeout(this.timeOut);
      this.clockWatch();
    }, 1000)
  }

  ngOnInit(): void {

    this.showComponent = false;
    if (this.viewSubscription)
      this.viewSubscription.unsubscribe();
    if (user && user.data && user.data["practiceId"]) {
      // if(parseInt(this.activatedRoute.snapshot.children[0].url[1].path))
      this.practiceId = user.data["practiceId"];
    }
    else if (this.activatedRoute.snapshot.children.length > 0) {
      let userData = user.data;
      userData['practiceId'] = parseInt(this.activatedRoute.snapshot.children[0].url[1].path);
      user.data = userData;
      this.practiceId = parseInt(this.activatedRoute.snapshot.children[0].url[1].path);
    }

    this.Practiceaddress = null;
    this.onCallRosterProvider = null;

    if (this.practiceId != null) {
      this.viewSubscription = this.practicesService.getBy([this.practiceId]).subscribe(practices => {

        this.practices = practices['data'];
        this.practiceName = practices['data'].practiceName;
        this.practiceTimeZoneDate = new Date(practices['data'].practiceTimeZoneDate);
        this.message = this.practiceTimeZoneDate;
        if (!this.isCalled)
          this.clockWatch();
        this.Practiceaddress = practices['data'].practiceAddresses.where(x => x.addressTypeRefId == BranchTypeEnum.Main)[0] == undefined ? new Address() : practices['data'].practiceAddresses.where(x => x.addressTypeRefId == BranchTypeEnum.Main)[0];
        this.stateName = this.Practiceaddress.stateId ? StateEnum[this.Practiceaddress.stateId] : null;
        this.onCallRosterProvider = JSON.parse(practices['spResult']);
        this.timeZoneName = practices['data'].applicationTimeZone.timezoneCaption;
        this.comment = this.onCallRosterProvider[0]['Remarks'];
        let dow = new Date();
        this.dayName = days[dow.getDay()]
        let dayId = dow.getDay();
        let dayNumber = 0;
        switch (dayId) {

          case 0:
            dayNumber = DaysOfWeekEnum.Sunday;
            break;
          case 1:
            dayNumber = DaysOfWeekEnum.Monday;
            break;
          case 2:
            dayNumber = DaysOfWeekEnum.Tuesday;
            break;
          case 3:
            dayNumber = DaysOfWeekEnum.Wednesday;
            break;
          case 4:
            dayNumber = DaysOfWeekEnum.Thursday;
            break;
          case 5:
            dayNumber = DaysOfWeekEnum.Friday;
            break;
          case 6:
            dayNumber = DaysOfWeekEnum.Saturday;
            break;
        }
        this.practiceOfficeHours = practices['data'].practiceOfficeHours.where(t => t.dayOfWeekId == dayNumber)[0];
        this.practiceOfficeHours = this.practiceOfficeHours == undefined ? new PracticeOfficeHour() : this.practiceOfficeHours;
        this.practiceOfficeHours.officeOpen = this.timeConversionTo12Hour(this.practiceOfficeHours.officeOpen);
        this.practiceOfficeHours.officeClosed = this.timeConversionTo12Hour(this.practiceOfficeHours.officeClosed);
        this.practiceOfficeHours.lunchFrom = this.timeConversionTo12Hour(this.practiceOfficeHours.lunchFrom);
        this.practiceOfficeHours.lunchTo = this.timeConversionTo12Hour(this.practiceOfficeHours.lunchTo);
        let userData = user.data;
        userData["practiceName"] = this.practiceName;
        user.data = userData;
        this.applicationBroadcaster.practiceNameBroadCast(userData["practiceName"]);
        this.applicationBroadcaster.practiceSelectionBroadCast(true);

        if (this.practiceId != undefined && this.practiceId != null && this.practiceId != 0) {
          this.alertsService.getBy(this.practiceId, [AlertTypeEnum.WelcomeScript]).subscribe(alerts => {
            if (alerts[0] == undefined) {
              this.welcomeMessage = ""
            }
            else {

              this.welcomeMessage = alerts[0].message == undefined ? "" : alerts[0].message;
              var str =this.welcomeMessage.replace(/<[^>]+>/g, '');
              this.welcomeMessage =str.replace(/\&nbsp;/g, ' ');
              this.welcomeMessage = this.welcomeMessage.replace('agent name', this.onCallRosterProvider[0].onCallLabel);
            
            }
            this.showComponent = true;
          });
        }
        else {
          this.welcomeMessage = "";
        }
        this.showComponent = true;
      });
    }
    else {
      this.practiceName = 'No Practice Selected';
      this.Practiceaddress = new Address();
      this.onCallRosterProvider = new Provider();
      this.practiceOfficeHours = new PracticeOfficeHour();
      this.showComponent = true;
    }
  }

  openPracticeWebsite(url: string) {
    if (url.split('https://').length > 1) {
      window.open(url, '_blank')
    }
    else {
      window.open('//' + url, '_blank')
    }
  }

  timeConversionTo12Hour(time: any): any {
    if (time != null && time != undefined && time != "") {
      var timeArray = time.split(":");
      var hours = parseInt(timeArray[0]);
      var isMr = hours >= 12 ? "PM" : "AM"
      var newhour = hours >= 12 ? (hours == 12 ? 12 : hours - 12) : (hours == 0 ? 12 : hours);
      var paddedNum = newhour.toString().length == 1 ? String("00" + newhour).slice(-2) : newhour;
      time = paddedNum + ":" + timeArray[1] + " " + isMr;
      return time;
    }
    else {
      return time;
    }
  }

  timeConversionTo24Hour(time: any): any {

    if (time != null && time != undefined && time != "") {
      var splitText = time.split(" ");
      var splitTime = splitText[0].split(":");
      var finalTime = "";
      if (splitText[1] == "PM" || splitText[1] == "pm") {
        switch (splitTime[0]) {
          case "00":
            finalTime = "".concat("12", ":", splitTime[1])
            break;
          case "01":
            finalTime = "".concat("13", ":", splitTime[1])
            break;
          case "02":
            finalTime = "".concat("14", ":", splitTime[1])
            break;
          case "03":
            finalTime = "".concat("15", ":", splitTime[1])
            break;
          case "04":
            finalTime = "".concat("16", ":", splitTime[1])
            break;
          case "05":
            finalTime = "".concat("17", ":", splitTime[1])
            break;
          case "06":
            finalTime = "".concat("18", ":", splitTime[1])
            break;
          case "07":
            finalTime = "".concat("19", ":", splitTime[1])
            break;
          case "08":
            finalTime = "".concat("20", ":", splitTime[1])
            break;
          case "09":
            finalTime = "".concat("21", ":", splitTime[1])
            break;
          case "10":
            finalTime = "".concat("22", ":", splitTime[1])
            break;
          case "11":
            finalTime = "".concat("23", ":", splitTime[1])
            break;
          case "12":
            finalTime = "".concat("12", ":", splitTime[1])
            break;
        }
      }
      else if (splitText[1] == "AM" || splitText[1] == "am") {
        switch (splitTime[0]) {
          case "00":
            finalTime = "".concat("00", ":", splitTime[1])
            break;
          case "01":
            finalTime = "".concat("01", ":", splitTime[1])
            break;
          case "02":
            finalTime = "".concat("02", ":", splitTime[1])
            break;
          case "03":
            finalTime = "".concat("03", ":", splitTime[1])
            break;
          case "04":
            finalTime = "".concat("04", ":", splitTime[1])
            break;
          case "05":
            finalTime = "".concat("05", ":", splitTime[1])
            break;
          case "06":
            finalTime = "".concat("06", ":", splitTime[1])
            break;
          case "07":
            finalTime = "".concat("07", ":", splitTime[1])
            break;
          case "08":
            finalTime = "".concat("08", ":", splitTime[1])
            break;
          case "09":
            finalTime = "".concat("09", ":", splitTime[1])
            break;
          case "10":
            finalTime = "".concat("10", ":", splitTime[1])
            break;
          case "11":
            finalTime = "".concat("11", ":", splitTime[1])
            break;
          case "12":
            finalTime = "".concat("00", ":", splitTime[1])
            break;
        }
      }
      return finalTime == "" ? time : finalTime;
    }
    return null;
  }

  showPopup() {
    //this.popup.show(AlertSearchComponent).then(t => this.ngOnInit());;
    window.open("#/alerts/search", '_blank', 'location=yes,scrollbars=yes,status=yes')
    //this.router.navigate(['alerts/search'])
  }

  showPracticeListComponent(vPractice: vPractice): void {
    this.popup.show(PracticeListComponent)
    // .then(t => this.ngOnInit());
  }


  ShowPracticeSearchComponent(vPractice: vPractice): void {
    if (this.practiceId != null) {
      FormState.remove(0);
      this.popup.show(PracticeSearchComponent)
        .then(t => this.ngOnInit());
    }
    else {
      this.toast.show("Please select any practice.", { 'status': "error" })
    }
  }

  showProviderComponent(): void {
    if (this.practiceId != null) {
      this.popup.show(ProviderViewComponent, { providerId: this.onCallRosterProvider[0].providerId }).then(t => this.ngOnInit());
    }
    else {
      this.toast.show("Please select any practice.", { 'status': "error" })
    }
  }

  ngOnDestroy(): void {
    if (this.viewSubscription)
      this.viewSubscription.unsubscribe();

    if (this._clockSubscription)
      this._clockSubscription.unsubscribe();
    window.clearTimeout(this.timeOut);
    super.destroy();
  }


}
function htmlToPlaintext(text) {

  return text ? String(text).replace(/<[^>]+>/gm, ' ') : '';
}
