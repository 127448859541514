import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi } from "@rx/security";

import { Practice, vPractice, } from 'src/app/database-models';
import { vTelevisitHistory } from 'src/app/database-models/v-televisit-history';

@Injectable()
export class TelehealthDashboardService {
    private clock: Observable<Date>;

    constructor(
        private http: RxHttp
    ) {
    }

    private practiceId: number;
	private startDate: any;
	private endDate: any;
	private filterById: any;
	private isDateSelected: any;
    private get apiHistory(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/CustomTelevisit/${this.practiceId}/GetHistory`,
            applicationModuleId: 4067,
            keyName:'televisitMasterId'
	
        }
        return authorizeApi;
    }
    gettelevisitHistory(PracticeId : number,num:number): Observable<vTelevisitHistory[]> {
        this.practiceId = PracticeId; 
        if(num == 1)
              return this.http.get<vTelevisitHistory[]>(this.apiHistory);
        else
            return this.http.get<vTelevisitHistory[]>(this.apiHistory,null,false);

    }
    private get apiHistorywithFilter(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/CustomTelevisit/${this.practiceId}/${this.startDate}/${this.endDate}/${this.filterById}/${this.isDateSelected}`,
            applicationModuleId: 4067,
            keyName:'televisitMasterId'
	
        }
        return authorizeApi;
    }
    gettelevisitHistorywithFilter(PracticeId : number,startDate: any,endDate: any,filterById: any,isDateSelected: any ): Observable<vTelevisitHistory[]> {
        this.practiceId = PracticeId; 
        this.startDate = startDate; 
        this.endDate = endDate; 
        this.filterById = filterById; 
        this.isDateSelected = isDateSelected; 
        return this.http.get<vTelevisitHistory[]>(this.apiHistorywithFilter);
    }

}
