import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { CallDb } from './call-db'; @table('CallRecordHistory')
export class CallRecordHistory {
    constructor(callRecordHistory?: CallRecordHistory )  {
        let properties = [ "callRecordHistoryId", "callSentTo", "callSentVia", "createdBy", "createdDate", "forwardCallRefId", "isAlternateCallRecord", "modifiedBy", "modifiedDate", "onCallType", "operationType", "remarks", "sId", "skey",];
        for (let property of properties)
            if (callRecordHistory && (callRecordHistory[property] || callRecordHistory[property] == 0))
                this[property] = callRecordHistory[property];
    }
 
	@property('CallRecordHistoryId')
	callRecordHistoryId : number =   0 ;
 
    @maxLength(50)
	@property('CallSentTo')
	callSentTo : string = undefined ;
 
    @maxLength(50)
	@property('CallSentVia')
	callSentVia : string = undefined ;
 
	@property('CreatedBy')
	createdBy : number =   undefined;
 
	@property('CreatedDate')
	createdDate : Date =   undefined;
 
	@property('ForwardCallRefId')
	forwardCallRefId : number =   undefined;
 
	@property('IsAlternateCallRecord')
	isAlternateCallRecord : boolean = false ;
 
	@property('ModifiedBy')
	modifiedBy : number =   undefined;
 
	@property('ModifiedDate')
	modifiedDate : Date =   undefined;
 
	@property('OnCallType')
	onCallType : number =   undefined;
 
	@property('OperationType')
	operationType : number =   undefined;
 
    @maxLength(500)
	@property('Remarks')
	remarks : string = undefined ;
 
    @maxLength(100)
	@property('SId')
	sId : string = undefined ;
 
    @range(0,2147483647)
	@property('Skey')
	skey : number =   undefined;
	callDb : CallDb  ;



}
