import { Injectable } from "@angular/core";
import { Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';


@Injectable()
export class SideBarService {
  private username: string;
  private apiaccess: string = 'api/UserAuthorization/access'


  constructor(
    private http: RxHttp
  ) { }

  postAccess(): Observable<any> {
    return this.http.post<any>(this.apiaccess,null,false);
  }
}
