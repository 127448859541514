import { Component, OnInit, OnDestroy ,Input,ComponentFactoryResolver, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { RxMessageComponent } from '@rx/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { ComponentCanDeactivate, ApplicationConfiguration } from "@rx/core";
import {RxToast, RxDialog, DialogClick } from '@rx/view';
import { RxValidation } from '@rx/forms';
import {  User, } from 'src/app/database-models';

import { } from 'src/app/lookups';
import { UsersService } from '../users.service';
import { UserDomain } from '../domain/user.domain';
import { UserLookupGroup } from '../domain/user.models';
import { RxPopup } from '@rx';
import { user } from '@rx/security';
import { RecordStatusEnum } from '../../../../enums';
import { ManageUserAgentLookups } from '../../../../lookups';
import { vRoleType } from '../../../../database-models';


@Component({
    templateUrl: './user-add.component.html',
    entryComponents : [RxMessageComponent]
})
export class UserAddComponent extends UserDomain implements OnInit, OnDestroy, ComponentCanDeactivate  {
    isTemporaryImage: boolean;
    showComponent: boolean = false;
    userFormGroup: FormGroup;
    addSubscription: Subscription;
    userLookupGroup: UserLookupGroup;
    practiceName:any;
    roleTypes: any;
    PasswordPattern:string="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$^+=!*()@%&]).{8,16}";
    validationFailed: {
        [key: string]: any;
      }
    constructor(
        private validation: RxValidation,
        private router: Router,
        private toast: RxToast,
        private usersService: UsersService,  
        private popup: RxPopup,
    ) { super();
        this.validationFailed = {};  
        this.practiceName = user.data["practiceName"];      
}

    ngOnInit(): void {
         
        this.validationFailed = {};
        this.usersService.lookup([ManageUserAgentLookups.roleTypes]).then(t => {
             
        this.roleTypes = t['roleTypes'].where(x=>x.isAgent==true);
        // this.roleTypes = this.roleTypes.filter(x=> x.isAgent== true)
        
        this.userLookupGroup = new UserLookupGroup();
        this.userLookupGroup.user = new User();
        this.userLookupGroup.user.applicationTimeZoneId = 1;
        this.userLookupGroup.user.statusId = RecordStatusEnum.Active;
        this.userLookupGroup.user.isBackendUser = false;
        this.userLookupGroup.user.languageId = 1;
        this.userLookupGroup.user.isFirstTimeLogin = false;
        this.userLookupGroup.user.dateData = new Date();        
        this.userFormGroup = this.validation.getFormGroup(this.userLookupGroup.user);
        // this.userFormGroup.controls.statusId.setValue(RecordStatusEnum.InActive)
                this.showComponent = true;
        })
    }

    onFileChange(data: any): void {
        
        let target = data.target || data.srcElement;
        var file = target.files[0];
        // if (file.size >= (1000 * 1024)) {
        //   this.toast.show(ApplicationConfiguration.get("validation.message.custom.largeruploadfilethen1mb"), { status: 'error' });
        //   this.isTemporaryImage = false
        //   return;
        // }
        if (file) {
          this.isTemporaryImage = true;
          let filename = file.name;
          let extension = filename.substr((filename.lastIndexOf('.') + 1)).toLowerCase();
          this.userFormGroup.controls.fileExtension.patchValue(extension);
          this.userFormGroup.controls.fileType.patchValue(file.type);
          let split_str = "jpg,jpeg,bmp,png";
          if (split_str.indexOf(extension) !== -1) {
            let reader = new FileReader();
            reader.onload = (readerEvt: any) => {
              let documentPath = btoa(readerEvt.target.result);
              this.userFormGroup.controls.profilePicture.patchValue(documentPath);
            };
            reader.readAsBinaryString(file);
          }
          else {
            let error = [ApplicationConfiguration.get("validation.message.custom.imageuploadtypes")];
            this.isTemporaryImage = false
            //this.dialog.validation(error);
          }
        }
      }
      deleteImage(){
        
        this.isTemporaryImage = false;
        this.userFormGroup.controls.userProfilePhotoUrl.setValue("assets/images/user.jpg");
        this.userFormGroup.controls.profilePicture.setValue(null);
        this.fileInput.nativeElement.value = '';
    }
    @ViewChild('fileInput') fileInput: any;

    addUser(): void {
        this.addSubscription =  this.usersService.post(this.userFormGroup.value).subscribe(t => {
            this.router.navigateByUrl('/users')
        },
            error => {
                this.popup.validationFailed(error);
        })
    }

    setStatus():void{
         
        if(this.userFormGroup.value.statusId == RecordStatusEnum.InActive){
          this.userFormGroup.controls.statusId.setValue(RecordStatusEnum.Active)
        }
        else{
          this.userFormGroup.controls.statusId.setValue(RecordStatusEnum.InActive)
        }
      }
	canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        return !this.userFormGroup.dirty;
    }

    ngOnDestroy(): void {
        if (this.addSubscription)
            this.addSubscription.unsubscribe();
        super.destroy();
    }

}
