import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vPracticeOfficeHours')
export class vPracticeOfficeHour {
    constructor(vPracticeOfficeHour?: vPracticeOfficeHour )  {
        let properties = [ "addressId", "createdById", "createdDateTime", "dayOfWeekId", "dayWeekName", "lunchFrom", "lunchTo", "modifiedById", "modifiedDateTime", "officeClosed", "officeOpen", "practiceId", "practiceOfficeHoursId",];
        for (let property of properties)
            if (vPracticeOfficeHour && (vPracticeOfficeHour[property] || vPracticeOfficeHour[property] == 0))
                this[property] = vPracticeOfficeHour[property];
    }
 
    @range(1,2147483647)
	@property('AddressId')
	addressId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @range(1,2147483647)
	@property('DayOfWeekId')
	dayOfWeekId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('dayWeekName')
	dayWeekName : string = undefined ;
 
	@property('LunchFrom')
	lunchFrom : Date =   undefined;
 
	@property('LunchTo')
	lunchTo : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('OfficeClosed')
	officeClosed : Date =   undefined;
 
	@property('OfficeOpen')
	officeOpen : Date =   undefined;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @range(1,2147483647)
	@property('PracticeOfficeHoursId')
	practiceOfficeHoursId : number =   undefined;



}
