import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';
import { RxHttp, API_HOST_URI } from '@rx/http';
import { user } from '@rx/security';
import * as  firebase from 'firebase';
import { ScreenningFormSentToEnum } from 'src/app/enums/screenning-form-sent-to';
import { ScheduleTelevisitService } from '../schedule-televisit.service';
import { ScheduleTelevisitDomain } from '../domain/schedule-televisit.domain';
@Component({
  templateUrl: './schedule-televisit-view.component.html',
})
export class ScheduleTelevisitViewComponent extends ScheduleTelevisitDomain implements OnInit, OnDestroy {
  showComponent: boolean = false;
  dictationData: boolean = false;
  dictationForm: any;
  viewSubscription: Subscription;
  @Input() televisitMasterId: number;
  examlist: any;
  listSubscription: Subscription;
  cptlist: any;
  covidObj: any;
  icdlist: any;
  isEHR: boolean = false;
  covidQuestions: any;
  iscovid: boolean = false;
  PatientName: any;
  dob: any;
  visitId: any;
  uploadedFiles: Array<any> = [];
  uploadedFilesPatient: Array<any> = [];
  formList: any = [];
  sentToEnum: any;
  constructor(
    private dialog: RxDialog,
    private router: Router,
    private popup: RxPopup,
    private componentFactoryResolver: ComponentFactoryResolver,
    private readonly http: RxHttp,
    private toast: RxToast,
    private scheduleTelevisitService: ScheduleTelevisitService   

  ) {
    super();
    // this.popup.setComponent(componentFactoryResolver);
  }

  ngOnInit(): void {
    this.showComponent = true;
    this.sentToEnum = ScreenningFormSentToEnum;
    
    this.scheduleTelevisitService.getFormData(this.televisitMasterId).subscribe(list => {
      this.formList = list;
    });
  }
  hidePopup() {
    this.popup.hide(ScheduleTelevisitViewComponent);
  }
 
  showForm(form: any) {

    let URL = window.location.href;
    let host = URL.split("/")[0] || 0;
    let site = URL.split("/")[2] || 0;

    let formURL = host + '//' + site;
    window.open(formURL + "/#/screening-form/providerview/" + form.formNo, "_blank");

  }
  ngOnDestroy(): void {
    if (this.viewSubscription)
      this.viewSubscription.unsubscribe();
    super.destroy();
  }
}
