import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { vCallRecordHistory } from 'src/app/database-models';
import { CallRecordHistoryService } from '../call-record-history.service';
import { CallRecordHistoryDomain } from '../domain/call-record-history.domain';
import { user } from '@rx/security';
import { CallRecordHistoryViewComponent } from '../view/call-record-history-view.component';


@Component({
    templateUrl: './call-record-history-list.component.html',
    entryComponents: [CallRecordHistoryViewComponent]
})
export class CallRecordHistoryListComponent extends CallRecordHistoryDomain implements OnInit, OnDestroy {
    practiceName: any;
    showComponent: boolean = false;
    callRecordHistory: vCallRecordHistory[];
    listSubscription: Subscription;
    endDate: Date = undefined;
    startDate: Date = undefined;
    isOnlyCalltype: boolean = false;
    isNoDateSelected: boolean = false;
    callTypeId: any;
    callTypes: any = null;
    constructor(
        private callRecordHistoryService: CallRecordHistoryService,
        private dialog: RxDialog,
        private componentFactoryResolver: ComponentFactoryResolver,
        private router: Router,
        private popup: RxPopup,
    ) {
        super();
        this.popup.setComponent(componentFactoryResolver);
        this.practiceName = user.data['practiceName'];
    }

    ngOnInit(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        this.startDate = new Date();
        this.endDate = new Date();
        this.listSubscription = this.callRecordHistoryService.getBy([0, this.startDate.toISOString(), this.endDate.toISOString(), 0, true, this.isOnlyCalltype]).subscribe(callRecordHistory => {
            if (JSON.parse(callRecordHistory[0]['callDetails']) == null)
                this.callRecordHistory = [];
            else
                this.callRecordHistory = JSON.parse(callRecordHistory[0]['callDetails']);
            this.startDate = null;
            this.endDate = null
            this.callTypes = JSON.parse(callRecordHistory[0]['callTypes']).where(x => x.callTypeId < 7 || x.callTypeId > 10);
            this.showComponent = true;
        });
    }

    filterData(tableEvent: any, isNotReset :boolean): void {
        if ((this.startDate == null || this.startDate == undefined) && (this.endDate == null || this.endDate == undefined)) {
            this.isOnlyCalltype = true;
            this.isNoDateSelected = true;
            this.startDate = new Date();
            this.endDate = new Date();
        }
        this.callTypeId = this.callTypeId == undefined || this.callTypeId == "" || this.callTypeId == null ? 0 : this.callTypeId;
        if (this.callTypeId == 0 && this.isNoDateSelected == true) {
            isNotReset = false;
        }
        let startDate = this.convertDateString(this.startDate, true);
        let endDate = this.convertDateString(this.endDate, false);
        if (isNotReset) {
            this.callRecordHistoryService.getBy([0, startDate, endDate, this.callTypeId, false, this.isOnlyCalltype]).subscribe(
                (response: any) => {
                    if (JSON.parse(response[0]['callDetails']) == null)
                        this.callRecordHistory = [];
                    else
                        this.callRecordHistory = JSON.parse(response[0]['callDetails']);
                    this.callTypes = JSON.parse(response[0]['callTypes']).where(x => x.callTypeId < 7 || x.callTypeId > 10);

                    this.startDate = null;
                    this.endDate = null
                    this.isNoDateSelected = false;
                    this.isOnlyCalltype = false;
                    tableEvent.bindData(this.callRecordHistory, true)
                    this.callTypeId = 0;
                    // this.showComponent = true
                })
        }
        else {
            this.callRecordHistoryService.getBy([0, startDate, endDate, 0, true, this.isOnlyCalltype]).subscribe(
                (response: any) => {
                    if (JSON.parse(response[0]['callDetails']) == null)
                        this.callRecordHistory = [];
                    else
                        this.callRecordHistory = JSON.parse(response[0]['callDetails']);
                    this.callTypes = JSON.parse(response[0]['callTypes']).where(x => x.callTypeId < 7 || x.callTypeId > 10);

                    tableEvent.bindData(this.callRecordHistory, true)
                    this.startDate = null;
                    this.endDate = null;
                    this.callTypeId = 0
                    this.isNoDateSelected = false;
                    this.isOnlyCalltype = false;
                    this.callTypeId = 0;
                    // this.showComponent = true
                })
        }
        tableEvent.bindData(this.callRecordHistory, true)
    }

    convertDateString(dateString: Date, type: boolean) {
        // let dateString = String(date).replace("T00:00:00", "").split("-");
        if (type)
            return dateString.getFullYear() + '-' + (dateString.getMonth() + 1) + '-' + dateString.getDate() + ' ' + '00:00:00';
        else
            return dateString.getFullYear() + '-' + (dateString.getMonth() + 1) + '-' + dateString.getDate() + ' ' + '23:59:59';
    }

    showPopup(callDetil: any) {
        this.popup.show(CallRecordHistoryViewComponent, { skey: callDetil.skey }).then(t => {
            this.ngOnInit();
        })
        // this.popup.show(CallDbSearchComponent);
    }


    ngOnDestroy(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        super.destroy();
    }
}
