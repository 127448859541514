import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { RxMessageComponent } from '@rx/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';
import { RxValidation } from '@rx/forms';



import { ScheduleTelevisitService } from '../schedule-televisit.service';
import { ScheduleTelevisitDomain } from '../domain/schedule-televisit.domain';
import { ScheduleTelevisitLookupGroup } from '../domain/schedule-televisit.models';
import { user } from '@rx/security';
import * as  firebase from 'firebase';
import { DatePipe } from '@angular/common';
import { TimeSource } from 'src/app/const/time-source';
import { ScheduleTelevisit } from 'src/app/database-models/schedule-televisit';
import { CANCEL_VISIT_REASONS } from 'src/app/database-collections/cancel-visit-reason';
import { CancelVisitReasonEnum } from 'src/app/enums/cancel-visit-reason';
import { ScreenningFormSentToEnum } from 'src/app/enums/screenning-form-sent-to';


@Component({
    templateUrl: './schedule-televisit-edit.component.html',
    entryComponents: [RxMessageComponent]
})
export class ScheduleTelevisitEditComponent extends ScheduleTelevisitDomain implements OnInit, OnDestroy {
    showComponent: boolean = false;
    scheduleTelevisitFormGroup: FormGroup;
    editSubscription: Subscription;
    scheduleTelevisitLookupGroup: ScheduleTelevisitLookupGroup;;
    @Input() scheduleTelevisitId: number;
    @Input() data: any;
    timeSource = TimeSource;
    hours: any;
    minutes: any;
    seconds: any;
    Date: any;
    day: any;
    month: any;
    result: any;
    currtime: any;
    minDate: any;
    reasons: any[];
    totime: any;
    practiceId: number;
    validationFailed: {
        [key: string]: any;
    }
    providerId: number;
    selectedProviderUserId: number;
    isNow: boolean = false;
    isForcedschedule: boolean = false
    formList: any = [];
    sentToEnum: any;
    optedForCopayment: boolean = false;
    constructor(
        private validation: RxValidation,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private toast: RxToast,
        private scheduleTelevisitService: ScheduleTelevisitService,
        private dialog: RxDialog,
        private popup: RxPopup,
    ) {
        super();
        this.validationFailed = {};
        this.practiceId = user.data['practiceId'];
        this.selectedProviderUserId = user.data['userId'];
        this.optedForCopayment = user.data['optedForCopayment'];
        if (user.data["roleId"] == 1004 || user.data["roleId"] == 1005 || user.data["roleId"] == 1011) {
            var id = localStorage.getItem("selectedProviderUserId");
            var userId = parseInt(id);

            if (userId > 0) {
                this.selectedProviderUserId = userId;
            }
        }

    }

    ngOnInit(): void {
        this.sentToEnum = ScreenningFormSentToEnum;
        this.scheduleTelevisitLookupGroup = new ScheduleTelevisitLookupGroup();

        this.scheduleTelevisitLookupGroup.scheduleTelevisit = new ScheduleTelevisit();
        this.scheduleTelevisitFormGroup = this.validation.getFormGroup(this.scheduleTelevisitLookupGroup.scheduleTelevisit);

        if (this.data) {
            this.scheduleTelevisitFormGroup.controls['sms'].setValue(this.data['patientMobileNo']);
            this.scheduleTelevisitFormGroup.controls['coPayAmount'].setValue(this.data['coPayAmount']);
            this.scheduleTelevisitFormGroup.controls['visitDate'].setValue(new Date(this.data['timeZoneVisitDate']));
            this.scheduleTelevisitFormGroup.controls['patientFirstName'].setValue(this.data['patientFirstName']);
            this.scheduleTelevisitFormGroup.controls['patientLastName'].setValue(this.data['patientLastName']);
            this.scheduleTelevisitFormGroup.controls['fromTime'].setValue(this.data['timeZoneFromTime']);
            this.scheduleTelevisitFormGroup.controls['toTime'].setValue(this.data['timeZoneToTime']);
            this.scheduleTelevisitFormGroup.controls['duration'].setValue(this.data['totalMinutes']);
            this.scheduleTelevisitFormGroup.controls['comments'].setValue('web');
            this.scheduleTelevisitFormGroup.controls['providerId'].setValue(this.data['providerId']);
            this.scheduleTelevisitFormGroup.controls['practiceId'].setValue(this.data['practiceId']);
            this.scheduleTelevisitFormGroup.controls['createdById'].setValue(this.selectedProviderUserId);
            this.scheduleTelevisitFormGroup.controls['createdDateTime'].setValue(new Date());
            this.scheduleTelevisitFormGroup.controls['isWeb'].setValue(true);
            this.scheduleTelevisitFormGroup.controls['scheduleTelevisitId'].setValue(this.scheduleTelevisitId);
            this.scheduleTelevisitFormGroup.controls['televisitId'].setValue(this.data['televisitMasterId']);
            this.scheduleTelevisitFormGroup.controls['reasonId'].setValue(null);
            this.scheduleTelevisitFormGroup.controls['reasonId'].setValidators([Validators.required]);
            this.scheduleTelevisitFormGroup.controls['isGroupCall'].setValue(false);

        }
        this.showComponent = true;
        this.reasons = CANCEL_VISIT_REASONS;
        this.reasons.sort(function (a, b) {
            var textA = a.cancelVisitReasonName.toUpperCase();
            var textB = b.cancelVisitReasonName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });


        this.scheduleTelevisitService.getFormData(this.data['televisitMasterId']).subscribe(list => {
            this.formList = list;
        });



    }
    freeText: boolean = false;
    flag: boolean = false;
    isValid: boolean = false;
    changeReason() {
        let reasonid = this.scheduleTelevisitFormGroup.controls.reasonId.value;

        if (reasonid == CancelVisitReasonEnum.Other) {
            this.freeText = true;
            this.scheduleTelevisitFormGroup.controls['visitEditReson'].setValidators([Validators.required]);
        }
        else {
            this.scheduleTelevisitFormGroup.controls['visitEditReson'].setValidators([Validators.nullValidator]);
            this.scheduleTelevisitFormGroup.controls['visitEditReson'].setValue('');
            this.freeText = false;
            this.isValid = true;
        }

    }

    addComment() {
        this.isValid = true;
    }
    reScheduleTelevisit(rescheduleOrCancel: string): void {
        let mobile = this.scheduleTelevisitFormGroup.value.sms;
        mobile = mobile.replace(/\-/g, '');
        this.scheduleTelevisitFormGroup.controls['sms'].setValue(mobile);
        this.flag = rescheduleOrCancel == 'false' ? false : true;
        this.scheduleTelevisitFormGroup.controls['isDelete'].setValue(this.flag);

        let eventlabel = this.flag == true ? "cancel_televisit" : "reschedule_televisit";
        // get utc date of visitdate for firebase logevents ------------------------------
        var fromTime = this.timeConversionTo24Hour(this.scheduleTelevisitFormGroup.value.fromTime);
        var datePipe = new DatePipe('en-US');
        var changeformate = datePipe.transform(this.scheduleTelevisitFormGroup.value.visitDate, 'yyyy/MM/dd')
        changeformate = changeformate.replace("/", "-");

        var UtcTimefrom = new Date(changeformate + " " + fromTime + ":" + "00");
        var fromdateutc = this.Hours(UtcTimefrom.getUTCHours()) + ":" + this.Minutes(UtcTimefrom.getUTCMinutes()) + ":00";
        let dateutc = UtcTimefrom.getUTCFullYear() + "-" + this.Month(UtcTimefrom.getUTCMonth()) + "-" + this.Day(UtcTimefrom.getUTCDate()) + "T" + fromdateutc;
        //------------------------------------------------------------------------
        let data = this.scheduleTelevisitFormGroup.value;
        data.fromTime = this.timeConversionTo24Hour(this.scheduleTelevisitFormGroup.value.fromTime);
        data.toTime = this.timeConversionTo24Hour(this.scheduleTelevisitFormGroup.value.fromTime);

        this.editSubscription = this.scheduleTelevisitService.put(data).subscribe(t => {
            this.result = t;
            if (this.result.errormessage != null) {
                this.isForcedschedule = true
            }
            else {

                let userdata = user.data;
                firebase.analytics().setUserId('' + userdata.userId + '');

                firebase.analytics().setUserProperties({
                    userId: '' + userdata.userId + '',
                    practiceId: '' + userdata.practiceId + '',
                    roleId: '' + userdata.roleId + '',
                    userName: '' + userdata.userName + '',
                    practiceName: '' + userdata.practiceName + '',
                    mobilenumber: '' + userdata.mobileNumber + '',
                    providerId: '' + userdata.providerId + '',
                    isOptedForTelehealth: userdata.optedForTelehealth
                });
                firebase.analytics().logEvent(eventlabel, {
                    televisitId: '' + t["televisitId"] + '',
                    visitingDate: dateutc.toString(),
                    duration: '' + data.duration + '',
                    modifyBy: '' + user.data['userId'] + '',
                    isFromMessageDetail: false
                });
                if (this.flag) {
                    this.toast.show("Visit has cancelled", { status: 'success' });

                } else {
                    this.toast.show("Telehealth invitation has been sent for the rescheduled time. ", { status: 'success' });

                }
                this.popup.hide(ScheduleTelevisitEditComponent);
            }
        },
            error => {
                this.popup.hide(ScheduleTelevisitEditComponent);
                console.log(error);

            })
    }

    Forcedschedule() {

        let data = this.scheduleTelevisitFormGroup.value;
        data.isForcedSchedule = true;
        let eventlabel = this.flag == true ? "cancel_televisit" : "reschedule_televisit";

        // get utc date of visitdate for firebase logevents ------------------------------
        var fromTime = this.timeConversionTo24Hour(this.scheduleTelevisitFormGroup.value.fromTime);
        var datePipe = new DatePipe('en-US');
        var changeformate = datePipe.transform(this.scheduleTelevisitFormGroup.value.visitDate, 'yyyy/MM/dd')
        changeformate = changeformate.replace("/", "-");

        var UtcTimefrom = new Date(changeformate + " " + fromTime + ":" + "00");
        var fromdateutc = this.Hours(UtcTimefrom.getUTCHours()) + ":" + this.Minutes(UtcTimefrom.getUTCMinutes()) + ":00";
        let dateutc = UtcTimefrom.getUTCFullYear() + "-" + this.Month(UtcTimefrom.getUTCMonth()) + "-" + this.Day(UtcTimefrom.getUTCDate()) + "T" + fromdateutc;
        //------------------------------------------------------------------------

        this.editSubscription = this.scheduleTelevisitService.put(data).subscribe(t => {
            this.result = t;
            if (this.result.errormessage != null) {

                this.isForcedschedule = true;
            }
            else {
                let userdata = user.data;
                firebase.analytics().setUserId('' + userdata.userId + '');

                firebase.analytics().setUserProperties({
                    userId: '' + userdata.userId + '',
                    practiceId: '' + userdata.practiceId + '',
                    roleId: '' + userdata.roleId + '',
                    userName: '' + userdata.userName + '',
                    practiceName: '' + userdata.practiceName + '',
                    mobilenumber: '' + userdata.mobileNumber + '',
                    providerId: '' + userdata.providerId + '',
                    isOptedForTelehealth: userdata.optedForTelehealth
                });
                firebase.analytics().logEvent(eventlabel, {
                    televisitId: '' + t["televisitId"] + '',
                    visitingDate: dateutc.toString(),
                    duration: '' + data.duration + '',
                    modifyBy: '' + user.data['userId'] + '',
                    isFromMessageDetail: false
                });
                if (this.flag) {
                    this.toast.show("Visit has cancelled", { status: 'success' });

                } else {
                    this.toast.show("Telehealth invitation has been sent for the rescheduled time. ", { status: 'success' });

                }
                this.popup.hide(ScheduleTelevisitEditComponent);

            }
        },
            error => {
                this.popup.hide(ScheduleTelevisitEditComponent);
                console.log(error);
            })

    }
    hidePopup() {
        this.popup.hide(ScheduleTelevisitEditComponent);
    }

    duration(event) {
        var select = event.target.value
        if (select != null) {
            var fromhr = parseInt(select);
            var hours = Math.floor(fromhr / 60);
            var minutes = fromhr % 60;
            this.totime = this.Hours(hours) + ":" + this.Minutes(minutes);
            this.scheduleTelevisitFormGroup.controls['duration'].setValue(parseInt(select));
        }
    }
    Minutes(minutes) {
        if (minutes < 10) {
            return '0' + minutes;
        }
        else {
            return minutes + '';
        }

    }

    Hours(hours) {
        if (hours < 10) {
            return '0' + hours;
        }
        else {
            return hours + '';
        }

    }

    Day(day) {
        if (day < 10) {
            return '0' + day;
        }
        else {
            return day + '';
        }

    }
    Month(monthIndex) {
        if ((monthIndex + 1) < 10) {
            return '0' + (monthIndex + 1);
        }
        else {
            return (monthIndex + 1) + '';
        }

    }

    canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        return !this.scheduleTelevisitFormGroup.dirty;
    }

    ngOnDestroy(): void {
        if (this.editSubscription)
            this.editSubscription.unsubscribe();
        super.destroy();
    }
}
