import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";

import {RxFormsModule} from "@rx/forms";
import {RxViewModule} from "@rx/view";
import {    RxTableModule} from "@rx/table";

import { AlertAddComponent } from './alert-add.component'
import { ALERT_ADD_ROUTING } from './alert-add.routing'
import {AlertsService } from "../alerts.service";
import { EditorModule } from '../../../shared/editor/editor.module';

@NgModule({
    imports: [
        ALERT_ADD_ROUTING ,
        CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,EditorModule,
		    ],
    declarations: [AlertAddComponent ],
    exports: [RouterModule],
    providers: [AlertsService]
})
export class AlertAddModule { }