import { Injectable } from '@angular/core';
import {Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi} from "@rx/security";

import {  OnCallRoster, vOnCallRoster, } from 'src/app/database-models';
import { OnCallRosterLookupGroup, OnCallRosterModel } from './domain/on-call-roster.models';

@Injectable()
export class OnCallRostersService {
	private practiceId: number;

	private get api(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/Practices/${this.practiceId}/OnCallRosters`,
            //childModuleName: 'on-call-roster',
            applicationModuleId : 3,
            keyName:'onCallRosterId'
	
        }
        return authorizeApi;
    }
    constructor(
        private http: RxHttp
    ) { }

    lookup<OnCallRosterLookupGroup>(lookupActions: LookupAction[]): Promise<OnCallRosterLookupGroup> {
        return this.http.lookup<OnCallRosterLookupGroup>(lookupActions);
    }

    group<OnCallRosterLookupGroup>(practiceId : number, params: any[] | {
        [key: string]: any;
    } | RequestQueryParams, lookupActions: LookupAction[]): Promise<OnCallRosterLookupGroup> {
		this.practiceId = practiceId;
        return this.http.group<OnCallRosterLookupGroup>(this.api, {'dateFrom':params[0],'dateTo': params[1]}, 'OnCallRoster', lookupActions);
    }

	search(practiceId : number, search: any): Observable<vOnCallRoster[]> {
		this.practiceId = practiceId;
        return this.http.search<vOnCallRoster[]>(this.api, search);
    }

    // get(practiceId : number  ): Observable<any> {
	// 	this.practiceId = practiceId;
    //     return this.http.get<any>(this.api);
    // }
    get(practiceId : number , params?: any[] ): Observable<any> {
		this.practiceId = practiceId;
        return this.http.get<any>(this.api,{'dateFrom':params[0],'dateTo': params[1]});
    }
    
    getBy(practiceId : number, params?: any[] | {
        [key: string]: any;
    } | RequestQueryParams): Observable<OnCallRoster> {
		this.practiceId = practiceId;
        return this.http.get<OnCallRoster>(this.api, params);
    }

    post(practiceId : number, onCallRoster: OnCallRosterModel): Observable<OnCallRosterModel> {
		this.practiceId = practiceId;
        return this.http.post<OnCallRosterModel>(this.api, onCallRoster);
    } 

    put(practiceId : number, onCallRoster: OnCallRoster): Observable<OnCallRoster> {
		this.practiceId = practiceId;
        return this.http.put<OnCallRoster>(this.api, onCallRoster);
    }

    delete(practiceId : number, id : number): Observable<OnCallRoster> {
		this.practiceId = practiceId;
        return this.http.delete<OnCallRoster>(this.api,id);
    }

	filterLookup<T>(lookup:LookupAction,filterParameters:any[],queryParams?: {[key:string]:any}):Observable<T> {
        return this.http.filterLookup<T>(lookup,filterParameters,queryParams);
    }

}
