import { NgModule } from '@angular/core';
import {RouterModule } from '@angular/router';


import { APPLICATION_ISSUES_ROUTING } from './application-issues.routing';
import { ApplicationIssuesService } from './application-issues.service';

@NgModule({
    imports: [APPLICATION_ISSUES_ROUTING],
    exports: [RouterModule],
    providers: [ApplicationIssuesService]
})
export class ApplicationIssuesModule { }