import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick } from '@rx/view';

import { ApplicationIssue } from 'src/app/database-models';
import { ApplicationIssuesService } from '../application-issues.service';
import { ApplicationIssueDomain } from '../domain/application-issue.domain';
import { ApplicationConfiguration } from '@rx/core';


@Component({
    selector: 'app-application-issue-list',
    templateUrl: './application-issue-list.component.html',
})
export class ApplicationIssueListComponent extends ApplicationIssueDomain implements OnInit, OnDestroy {
    showComponent: boolean = false;
    applicationIssues: ApplicationIssue[];
    listSubscription: Subscription;

    constructor(
        private applicationIssuesService: ApplicationIssuesService,
        private dialog: RxDialog,
        private router: Router,
        private toast: RxToast,

    ) { super(); 
     
    }

    ngOnInit(): void {
         
 
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        this.listSubscription = this.applicationIssuesService.get().subscribe(applicationIssues => {
            this.applicationIssues = applicationIssues;
            this.showComponent = true;
        });
    }

    deleteApplicationIssue(applicationIssue: any) {
        let msgString = ApplicationConfiguration.get("dialog.confirmation.messageType.delete");
        this.dialog.confirmation([applicationIssue.title], "delete").then(dialogClick => {
            if (dialogClick == DialogClick.PrimaryOk) {
                this.listSubscription = this.applicationIssuesService.delete(applicationIssue.applicationIssueId).subscribe(t => {
                    this.ngOnInit();

                },
                    error => {
                        this.toast.show(error, { status: 'error' });
                    })
            }
        })
    }

    ngOnDestroy(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        super.destroy();
    }
}
