import { Component, OnInit, OnDestroy,  Input,ComponentFactoryResolver} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import {RxToast, RxDialog, DialogClick } from '@rx/view';

import { vAlert } from 'src/app/database-models';
import { AlertsService } from '../alerts.service';
import { AlertDomain } from '../domain/alert.domain';
import { AlertTypeEnum } from '../../../../enums';
import { user } from '@rx/security';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    templateUrl: './alert-search.component.html',
})
export class AlertSearchComponent extends AlertDomain implements OnInit, OnDestroy {
    practiceName: any;
    noResponcePlanMessage: any;
    showComponent: boolean = false;
    alerts: vAlert[];
    searchSubscription: Subscription;
	@Input()  practiceId :number;

    constructor(
        private alertsService: AlertsService,    
        private dialog: RxDialog,
        private router: Router,
        private sanitizer: DomSanitizer
    ) { super();
    this.practiceId = user.data['practiceId']
    this.practiceName = user.data['practiceName'];

}

       ngOnInit(): void {
        if (this.practiceId != undefined && this.practiceId != null && this.practiceId != 0) {
        if (this.searchSubscription)
            this.searchSubscription.unsubscribe();
        this.searchSubscription = this.alertsService.getBy(this.practiceId, [AlertTypeEnum.NoResponsePlan]).subscribe(alerts => {
          if(!alerts[0])
            this.noResponcePlanMessage = "No data found!"
          else
            this.noResponcePlanMessage = alerts[0].message  == undefined ?"": this.sanitizer.bypassSecurityTrustHtml(alerts[0].message);
            
            this.showComponent = true;
        });
    }
    else {
      
      this.noResponcePlanMessage = "No Practice Selected";
      this.showComponent = true;
    }
}



    ngOnDestroy(): void {
        if (this.searchSubscription)
            this.searchSubscription.unsubscribe();
        super.destroy();
    }
}
