import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('PasswordPolicies')
export class PasswordPolicy {
    constructor(passwordPolicy?: PasswordPolicy )  {
        let properties = [ "disableBrowserAutoComplete", "enforcePasswordReset", "forcePasswordAlphaNum", "minimumNumberOfCharacters", "numberOfAttempts", "passwordExpiryDuration", "passwordPolicyId", "securityQuestionRequired",];
        for (let property of properties)
            if (passwordPolicy && (passwordPolicy[property] || passwordPolicy[property] == 0))
                this[property] = passwordPolicy[property];
    }
 
	@property('DisableBrowserAutoComplete')
	disableBrowserAutoComplete : boolean = false ;
 
	@property('EnforcePasswordReset')
	enforcePasswordReset : boolean = false ;
 
	@property('ForcePasswordAlphaNum')
	forcePasswordAlphaNum : boolean = false ;
 
	@property('MinimumNumberOfCharacters')
	minimumNumberOfCharacters : number =   undefined;
 
	@property('NumberOfAttempts')
	numberOfAttempts : number =   undefined;
 
	@property('PasswordExpiryDuration')
	passwordExpiryDuration : number =   undefined;
 
	@property('PasswordPolicyId')
	passwordPolicyId : number =   0 ;
 
	@property('SecurityQuestionRequired')
	securityQuestionRequired : number =   undefined;



}
