import { LookupAction } from '@rx/http'

export class ProviderLookups {
    static pagerCarriers: LookupAction = {
        controllerName: "provider",
        actionName: "pagerCarriers",
		cacheOption: {
            isVersionBase: true,
            tableName:''
        }
    };

}
