import {ComponentContainer} from "@rx/core"
import { TechnicalTermListComponent } from './list/technical-term-list.component'

export const TECHNICAL_TERMS_SHARED_COMPONENT_CONTAINER: ComponentContainer[] = [
	{
    component: TechnicalTermListComponent,
    accessItem: 'list',
    applicationModuleId: 33,
	keyName: 'technicalTermId',
	childModuleName : undefined,
	pageName:'technical-terms',
	rootModuleId:33
	},
];

