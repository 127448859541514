import { NgModule } from '@angular/core';
import {RouterModule } from '@angular/router';


import { ALERTS_ROUTING } from './alerts.routing';
import { AlertsService } from './alerts.service';

@NgModule({
    imports: [ALERTS_ROUTING],
    exports: [RouterModule],
    providers: [AlertsService]
})
export class AlertsModule { }