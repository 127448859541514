import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";

import {RxFormsModule} from "@rx/forms";
import {RxViewModule} from "@rx/view";
import {    RxTableModule} from "@rx/table";

import { CallOutboundEditComponent } from './call-outbound-edit.component'
import { CALL_OUTBOUND_EDIT_ROUTING } from './call-outbound-edit.routing'
import {CallOutboundService } from "../call-outbound.service";

@NgModule({
    imports: [
        CALL_OUTBOUND_EDIT_ROUTING ,
        CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,
		    ],
    declarations: [CallOutboundEditComponent ],
    exports: [RouterModule],
    providers: [CallOutboundService]
})
export class CallOutboundEditModule { }