import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { vCallDb } from 'src/app/database-models';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { user } from '@rx/security';
import { CallTypesEnum } from '../../../../const/call-type';
import { ApplicationBroadcaster } from '@rx/core';


@Component({
    templateUrl: './call-db-list.component.html',
})
export class CallDbListComponent extends CallDbDomain implements OnInit, OnDestroy {
    showGrid: boolean;
    showComponent: boolean = false;
    callDb: vCallDb[];
    listSubscription: Subscription;
    isContinueClicked: boolean = true;
    orderByColumn: string;

    lastName: string = '';
    phoneNumber: string = '';
    birthdate: string = '';

    sortOrder: string;

    pageIndex: number;

    rowCount: number;
    @Input() practiceId: number;

    constructor(
        private callDbService: CallDbService,
        private route: ActivatedRoute,
        private dialog: RxDialog,
        private router: Router,
        private popup: RxPopup,
        private applicationBroadCast : ApplicationBroadcaster
    ) {
        super();
        this.practiceId = user.data['practiceId']
    }

    ngOnInit(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        this.showComponent = true;
    }

    isContinue() {
        if ((this.lastName || this.phoneNumber || this.birthdate) && this.isContinueClicked) {
            this.isContinueClicked = false;
            this.searchPatient();
        }
    }

    searchPatient() {
    
    this.showGrid = false;
        this.listSubscription = this.callDbService.get(this.practiceId, [this.lastName, this.phoneNumber, this.formatDate(this.birthdate), 0]).subscribe(callDb => {
    

            
            this.callDb = callDb;
            // if (callDb.length > 0)
                this.showGrid = true;
                this.isContinueClicked = true;
        });
    }

    redirectToPatient(rowDetails: any) {
        // let urlString = this.route['_routerState'].snapshot.url;
        // let parentString = urlString.split('/');
        // let currentString = parentString[1] + '/' + parentString[2];
        // this.router.navigate([currentString, this.practiceId], { queryParams: { "patientId": rowDetails.skey } });
        
        this.callDbService.getBy(this.practiceId, [CallTypesEnum.PatientToDoctor, rowDetails.skey,0]).subscribe((response: any) => {
            
            this.applicationBroadCast.patientIdBroadCast(rowDetails.skey);
            this.popup.hide(CallDbListComponent, { "patientId": rowDetails.skey , 'callDbData' : response[0]})
        });
    }


    HidePracticeSelectionPopup(): void {
        document.body.className = "";
        this.popup.hide(CallDbListComponent);
    }

    ngOnDestroy(): void {
        if (this.listSubscription)
            this.listSubscription.unsubscribe();
        super.destroy();
    }
}
