 	import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { ProviderMessage } from './provider-message';import { VacationRoster } from './vacation-roster';import { NotificationPrefAlternet } from './notification-pref-alternet';import { CallDb } from './call-db';import { HospitalRoundingRoster } from './hospital-rounding-roster';import { NotificationPlusTransaction } from './notification-plus-transaction';import { NotificationPreference } from './notification-preference';import { OnCallRoster } from './on-call-roster';import { vProviderRecord } from './v-provider-record'; @table('Providers')
export class Provider {
    constructor(provider?: Provider  | vProviderRecord )  {
        let properties = [ "additionalTextCount", "additionalTextInterval", "backline", "backlineExt", "createdById", "createdDateTime", "email", "enableAlternateNotification", "enableNotificationPlus", "fax", "firstName", "homePhone", "isAutomateCalls", "isNewBornCallDelivery", "lastName", "mi", "mobile", "modifiedById", "modifiedDateTime", "officePhone", "officePhoneExt", "optedForTelehealth", "pagerCoId", "pagerNumber", "phonetic", "practiceId", "providerId", "responseWaitTime", "statusId", "tital", "userId", "providerMessages", "telehealthContent", "vacationRosters", "notificationPrefAlternet", "extendedNewbornRosters", "callDb", "scheduleTelevisit", "hospitalRoundingRosters", "notificationPlusTransactions", "notificationPreferences", "onCallRosters", "userName", "password", "confirmPassword", "fileExtension", "fileType", "profilePicture", "userProfilePhotoUrl", "isPracticeManager",];
        for (let property of properties)
            if (provider && (provider[property] || provider[property] == 0))
                this[property] = provider[property];
    }
 
	@property('AdditionalTextCount')
	additionalTextCount : number =   undefined;
 
	@property('AdditionalTextInterval')
	additionalTextInterval : number =   undefined;
 
    @maxLength(50)
	@property('Backline')
	backline : string = undefined ;
 
    @maxLength(10)
@numeric()
@pattern('[0-9]')
	@property('BacklineExt')
	backlineExt : string = undefined ;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @maxLength(100)
@required()
@email()
	@property('Email')
	email : string = undefined ;
 
	@property('EnableAlternateNotification')
	enableAlternateNotification : boolean = false ;
 
	@property('EnableNotificationPlus')
	enableNotificationPlus : boolean = false ;
 
    @maxLength(50)
	@property('Fax')
	fax : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('FirstName')
	firstName : string = undefined ;
 
    @maxLength(50)
	@property('HomePhone')
	homePhone : string = undefined ;
 
	@property('IsAutomateCalls')
	isAutomateCalls : boolean = false ;
 
	@property('IsNewBornCallDelivery')
	isNewBornCallDelivery : boolean = false ;
 
    @required()
    @maxLength(50)
	@property('LastName')
	lastName : string = undefined ;
 
    @maxLength(10)
	@property('Mi')
	mi : string = undefined ;
 
    @maxLength(12)
@required()
	@property('Mobile')
	mobile : string = undefined ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @maxLength(50)
	@property('OfficePhone')
	officePhone : string = undefined ;
 
    @maxLength(10)
@numeric()
@pattern('[0-9]')
	@property('OfficePhoneExt')
	officePhoneExt : string = undefined ;
 
	@property('OptedForTelehealth')
	optedForTelehealth : boolean = false ;
 
	@property('PagerCoId')
	pagerCoId : number =   undefined;
 
    @maxLength(12)
	@property('PagerNumber')
	pagerNumber : string = undefined ;
 
    @maxLength(50)
	@property('Phonetic')
	phonetic : string = undefined ;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
	@property('ProviderId')
	providerId : number =   0 ;
 
	@property('ResponseWaitTime')
	responseWaitTime : number =   undefined;
 
    @range(1,2147483647)
	@property('StatusId')
	statusId : number =   undefined;
 
    @maxLength(50)
@required()
	@property('Tital')
	tital : string = undefined ;
 
	@property('UserId')
	userId : number =   undefined;
	@nested(ProviderMessage)
	providerMessages: ProviderMessage[];

	@nested(VacationRoster)
	vacationRosters: VacationRoster[];

	@nested(NotificationPrefAlternet)
	notificationPrefAlternet: NotificationPrefAlternet[];

	@nested(CallDb)
	callDb: CallDb[];

	@nested(HospitalRoundingRoster)
	hospitalRoundingRosters: HospitalRoundingRoster[];

	@nested(NotificationPlusTransaction)
	notificationPlusTransactions: NotificationPlusTransaction[];

	@nested(NotificationPreference)
	notificationPreferences: NotificationPreference[];

	@nested(OnCallRoster)
	onCallRosters: OnCallRoster[];



	@required()
	@maxLength(30)
	@pattern('^[a-zA-Z][A-Za-z0-9. ]+$')
	userName : string =   undefined;
	@required()
	@pattern('[A-Z]','capital')
	@pattern('[0-9]','number')
	@pattern('[a-z]','small')
	@pattern('[#$^+=!*()@%&]+','specialcharacter')
	password : string =   undefined;
	@required()
	confirmPassword : string =   undefined;
	fileExtension : string =   undefined;
	fileType : string =   undefined;
	profilePicture : string =   undefined;
	userProfilePhotoUrl : string =   undefined;
	isPracticeManager : boolean = false ;

}
