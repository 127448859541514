import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi } from "@rx/security";

import { OnCallOverride, vOnCallOverride, } from 'src/app/database-models';
import { OnCallOverrideLookupGroup } from './domain/on-call-override.models';

@Injectable()
export class OnCallOverrideService {
  private practiceId: number;

  private get api(): AuthorizeApi {
    var authorizeApi: AuthorizeApi = {
      api: `api/Practices/${this.practiceId}/OnCallOverride`,
      //childModuleName: 'on-call-override',
      applicationModuleId: 14,
      keyName: 'onCallOverrideId'

    }
    return authorizeApi;
  }
  constructor(
    private http: RxHttp
  ) { }

  lookup<OnCallOverrideLookupGroup>(lookupActions: LookupAction[]): Promise<OnCallOverrideLookupGroup> {
    return this.http.lookup<OnCallOverrideLookupGroup>(lookupActions);
  }

  group<OnCallOverrideLookupGroup>(practiceId: number, params: any[] | {
    [key: string]: any;
  } | RequestQueryParams, lookupActions: LookupAction[]): Promise<OnCallOverrideLookupGroup> {
    this.practiceId = practiceId;
    return this.http.group<OnCallOverrideLookupGroup>(this.api, params, 'OnCallOverride', lookupActions);
  }

  search(practiceId: number, search: any): Observable<vOnCallOverride[]> {
    this.practiceId = practiceId;
    return this.http.search<vOnCallOverride[]>(this.api, search);
  }

  get(practiceId: number): Observable<vOnCallOverride[]> {
    this.practiceId = practiceId;
    return this.http.get<vOnCallOverride[]>(this.api);
  }

  getBy(practiceId: number, params?: any[] | {
    [key: string]: any;
  } | RequestQueryParams): Observable<OnCallOverride> {
    this.practiceId = practiceId;
    return this.http.get<OnCallOverride>(this.api, params);
  }

  post(practiceId: number, onCallOverride: OnCallOverride): Observable<OnCallOverride> {
    this.practiceId = practiceId;
    return this.http.post<OnCallOverride>(this.api, onCallOverride);
  }

  put(practiceId: number, onCallOverride: OnCallOverride): Observable<OnCallOverride> {
    this.practiceId = practiceId;
    return this.http.put<OnCallOverride>(this.api, onCallOverride);
  }

  delete(practiceId: number, id: number): Observable<OnCallOverride> {
    this.practiceId = practiceId;
    return this.http.delete<OnCallOverride>(this.api, id);
  }

  filterLookup<T>(lookup: LookupAction, filterParameters: any[], queryParams?: { [key: string]: any }): Observable<T> {
    return this.http.filterLookup<T>(lookup, filterParameters, queryParams);
  }

}
