import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";
import {RxViewModule } from '@rx/view';
import {RxFormsModule } from '@rx/forms';
import {RxTableModule } from "@rx/table";
import {DynamicComponentContainer } from '@rx/core';

import { CallRecordHistoryViewComponent } from './view/call-record-history-view.component'
import {CallRecordHistoryService } from "./call-record-history.service";
import {CALL_RECORD_HISTORY_SHARED_COMPONENT_CONTAINER } from './call-record-history-shared-component.container';
@NgModule({
    imports: [
        RxViewModule, RxFormsModule,
        CommonModule, FormsModule, ReactiveFormsModule, RxTableModule
    ],
    declarations: [ CallRecordHistoryViewComponent, ],
    providers: [CallRecordHistoryService ],
    exports: [RouterModule, CallRecordHistoryViewComponent, ]
})
export class CallRecordHistorySharedComponentModule { }
DynamicComponentContainer.register(CALL_RECORD_HISTORY_SHARED_COMPONENT_CONTAINER );