export const ADDRESS_TYPES = [
	{
        "addressTypeId": 74,
        "addressTypeName": "Hospital"
    },
	{
        "addressTypeId": 75,
        "addressTypeName": "Practice"
    },
	{
        "addressTypeId": 76,
        "addressTypeName": "AhtProvider"
    },
	{
        "addressTypeId": 77,
        "addressTypeName": "Provider"
    },
]