export enum StateEnum {
        CO = 62,
        AK = 63,
        AL = 3152,
        AR = 3153,
        AZ = 3154,
        CA = 3155,
        CT = 3156,
        DC = 3157,
        DE = 3158,
        FL = 3159,
        GA = 3160,
        HI = 3161,
        IA = 3162,
        ID = 3163,
        IL = 3164,
        IN = 3165,
        KS = 3166,
        KY = 3167,
        LA = 3168,
        MA = 3169,
        MD = 3170,
        ME = 3171,
        MI = 3172,
        MN = 3173,
        MO = 3174,
        MS = 3175,
        MT = 3176,
        NC = 3177,
        ND = 3178,
        NE = 3179,
        NH = 3180,
        NJ = 3181,
        NM = 3182,
        NV = 3183,
        NY = 3184,
        OH = 3185,
        OK = 3186,
        OR = 3187,
        PA = 3188,
        RI = 3189,
        SC = 3190,
        SD = 3191,
        TN = 3192,
        TX = 3193,
        UT = 3194,
        VA = 3195,
        VT = 3196,
        WA = 3197,
        WI = 3198,
        WV = 3199,
        WY = 3200,
        VI = 3201,
}
