import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vAhtProviderMaster')
export class vAhtProviderMaster {
    constructor(vAhtProviderMaster?: vAhtProviderMaster )  {
        let properties = [ "addressId", "addressRefId", "addressTypeId", "afterHourTriageContact", "afterHourTriageName", "ahtProviderMasterId", "createdById", "email", "fax", "homePhone", "instructions", "isActive", "outboundCallsOnly",];
        for (let property of properties)
            if (vAhtProviderMaster && (vAhtProviderMaster[property] || vAhtProviderMaster[property] == 0))
                this[property] = vAhtProviderMaster[property];
    }
 
    @range(1,2147483647)
	@property('AddressId')
	addressId : number =   undefined;
 
    @range(1,2147483647)
	@property('AddressRefId')
	addressRefId : number =   undefined;
 
    @range(1,2147483647)
	@property('AddressTypeId')
	addressTypeId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('AfterHourTriageContact')
	afterHourTriageContact : string = undefined ;
 
    @required()
    @maxLength(100)
	@property('AfterHourTriageName')
	afterHourTriageName : string = undefined ;
 
    @range(1,2147483647)
	@property('AhtProviderMasterId')
	ahtProviderMasterId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @maxLength(100)
	@property('Email')
	email : string = undefined ;
 
    @maxLength(50)
	@property('Fax')
	fax : string = undefined ;
 
    @maxLength(50)
	@property('HomePhone')
	homePhone : string = undefined ;
 
    @maxLength(500)
	@property('Instructions')
	instructions : string = undefined ;
 
	@property('IsActive')
	isActive : boolean = false ;
 
	@property('OutboundCallsOnly')
	outboundCallsOnly : boolean = false ;



}
