import { required, minLength, maxLength, range, nested, table, property, alpha, alphaNumeric, numeric, uppercase, pattern, minDate, maxNumber, maxDate, lowercase, hexColor, email, contains, compare } from '@rx/annotations';
import { Provider } from './provider'; @table('ScheduleTelevisit')
export class ScheduleTelevisit {
	date: any = new Date();
	constructor(scheduleTelevisit?: ScheduleTelevisit) {
		let properties = ["comments", "createdById", "createdDateTime", "fromTime", "isDelete", "modifiedById", "modifiedDateTime", "reminderMsgSent", "scheduleTelevisitId", "televisitId", "toTime", "visitDate", "providerId", "sms","patientFirstName","patientLastName","visitEditReson","reasonId","childMobileNo","parentFormIds","childFormIds","parentMobileNo","coPayAmount","isGroupCall"];
		for (let property of properties)
			if (scheduleTelevisit && (scheduleTelevisit[property] || scheduleTelevisit[property] == 0))
				this[property] = scheduleTelevisit[property];

	}
	//@required()
	@maxLength(100)
	@property('Comments')
	comments: string = undefined;

	@range(1, 2147483647)
	@property('CreatedById')
	createdById: number = undefined;

	@required()
	@property('CreatedDateTime')
	createdDateTime: Date = undefined;

	@required()
	@property('FromTime')
	fromTime: Date = undefined;

	@property('IsDelete')
	isDelete : boolean = false ;

	@property('IsGroupCall')
	isGroupCall: boolean = false;

	@property('ReminderMsgSent')
	reminderMsgSent: boolean = false;

	@property('ScheduleTelevisitId')
	scheduleTelevisitId: number = 0;

	@property('TelevisitId')
	televisitId : number =   0;

	//@required()
	@property('ToTime')
	toTime: Date = undefined;

	@required()
    @minDate((new Date().getMonth() + 1).toString() + "/" + new Date().getDate().toString() + "/" + new Date().getFullYear().toString())
	@property('VisitDate')
	visitDate: Date = undefined;

	@range(0, 2147483647)
	@property('ProviderId')
	providerId: number = undefined;
	provider: Provider;

	@required()
	@property('SMS')
	sms: string = undefined;

	@property('isForcedSchedule')
	isForcedSchedule: boolean = false;

	@property('isWeb')
	isWeb: boolean = false;

	@property('isNow')
	isNow: boolean = false;

	@property('PracticeID')
	practiceId: number = undefined;

	@required()
	@property('Duration')
	duration: number = undefined;

	@maxLength(200)
	@property('PatientFirstName')
	patientFirstName: string = undefined;

	@maxLength(200)
	@property('PatientLastName')
	patientLastName: string = undefined;

	@property('VisitEditReson')
	visitEditReson: string = undefined;

	@property('ReasonId')
	reasonId: number = undefined;
	
	@property('ParentFormIds')
	parentFormIds: string = undefined;

	@property('ChildFormIds')
	childFormIds: string = undefined;

	@property('ChildMobileNo')
	childMobileNo: string = undefined;
	@property('ParentMobileNo')
	parentMobileNo: string = undefined;
	
	@property('CoPayAmount')
	coPayAmount: number = undefined;
	
}
