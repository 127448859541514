import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageAccess } from 'src/app/domain/authorization';

const ON_CALL_ROSTERS_ROUTES: Routes = [
	// {
	// path: 'add', 
	// loadChildren: './add/on-call-roster-add.module#OnCallRosterAddModule',
	// canActivate: [PageAccess],
	// data: { rootModuleId: 2,pageName:'on-call-roster', applicationModuleId: 3, accessItem: 'add',  keyName: 'onCallRosterId', compositeKeys:[] }
	// },
	{
		path: 'add',
		loadChildren: () => import('./add/on-call-roster-add.module').then(m => m.OnCallRosterAddModule),
		canActivate: [PageAccess],
		data: { rootModuleId: 2, pageName: 'on-call-roster', applicationModuleId: 3, accessItem: 'add', keyName: 'onCallRosterId', compositeKeys: [] }
	},
];

export const ON_CALL_ROSTERS_ROUTING: ModuleWithProviders<any> = RouterModule.forChild(ON_CALL_ROSTERS_ROUTES);
