import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vPractices')
export class vPractice {
    constructor(vPractice?: vPractice )  {
        let properties = [ "isActive", "practiceId", "practiceName", "subscriptionId",];
        for (let property of properties)
            if (vPractice && (vPractice[property] || vPractice[property] == 0))
                this[property] = vPractice[property];
    }
 
	@property('IsActive')
	isActive : boolean = false ;
 
	@property('PracticeId')
	practiceId : number =   0 ;
 
    @required()
    @maxLength(100)
	@property('PracticeName')
	practiceName : string = undefined ;
 
	@property('SubscriptionId')
	subscriptionId : string = undefined ;



}
