import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vSearchPatients')
export class vSearchPatient {
    constructor(vSearchPatient?: vSearchPatient )  {
        let properties = [ "age", "birthDate", "callerName", "callTypeId", "callTypeName", "cBNumberP", "cRelationship2Ch", "patientName", "practiceId", "primaryPhysicianId", "primaryPhysicianName", "relationship", "skey",];
        for (let property of properties)
            if (vSearchPatient && (vSearchPatient[property] || vSearchPatient[property] == 0))
                this[property] = vSearchPatient[property];
    }
 
    @maxLength(50)
	@property('age')
	age : string = undefined ;
 
	@property('BirthDate')
	birthDate : Date =   undefined;
 
    @maxLength(101)
	@property('callerName')
	callerName : string = undefined ;
 
    @range(1,2147483647)
	@property('CallTypeId')
	callTypeId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('callTypeName')
	callTypeName : string = undefined ;
 
    @maxLength(50)
	@property('CBNumberP')
	cBNumberP : string = undefined ;
 
	@property('cRelationship2Ch')
	cRelationship2Ch : number =   undefined;
 
    @maxLength(101)
	@property('patientName')
	patientName : string = undefined ;
 
	@property('PracticeId')
	practiceId : number =   undefined;
 
    @range(1,2147483647)
	@property('primaryPhysicianId')
	primaryPhysicianId : number =   undefined;
 
    @maxLength(101)
	@property('primaryPhysicianName')
	primaryPhysicianName : string = undefined ;
 
    @required()
    @maxLength(100)
	@property('relationship')
	relationship : string = undefined ;
 
    @range(1,2147483647)
	@property('skey')
	skey : number =   undefined;



}
