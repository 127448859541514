import { required, maxLength, range, nested, table, property, alpha, alphaNumeric, numeric, uppercase, pattern, minDate, maxNumber, maxDate, lowercase, hexColor, email, contains, compare } from '@rx/annotations';
import { PracticeType } from './practice-type'; import { ApplicationTimeZone } from './application-time-zone'; import { Provider } from './provider'; import { Alert } from './alert'; import { VacationRoster } from './vacation-roster'; import { AhtProvider } from './aht-provider'; import { OnCallRoster } from './on-call-roster'; import { PracticeOfficeHour } from './practice-office-hour'; import { HospitalRoundingRoster } from './hospital-rounding-roster'; import { HospitalAffiliation } from './hospital-affiliation'; import { UserMessage } from './user-message'; import { CallDb } from './call-db'; import { Address } from './address'; import { Contact } from './contact'; @table('Practices')
export class Practice {
  constructor(practice?: Practice) {
    let properties = ["aHTDivert", "aHTDIvertTime", "callCenterId", "callGroupId", "createdById", "createdDateTime", "cutOfTime", "dnis", "isActive", "modifiedById", "modifiedDateTime", "outBoundEmail", "practiceId", "practiceName", "respondTimeRoutine", "respondTimeUrgent", "showPMD", "subDomain", "websiteString", "practiceTypeId", "timeZoneId", "providers", "alerts", "vacationRosters", "ahtProviders", "onCallRosters", "practiceOfficeHours", "hospitalRoundingRosters", "hospitalAffiliations", "userMessages", "callDb", "contactList", "practiceTimeZoneDate", "practiceAddresses","package","medicalArticleCount","ehrLink"];
    for (let property of properties)
      if (practice && (practice[property] || practice[property] == 0))
        this[property] = practice[property];
  }

  @property('AHTDivert')
  aHTDivert: boolean = false;

  @property('SubscriptionId')
  subscriptionId: string = undefined;

  @property('AHTDIvertTime')
  aHTDIvertTime: Date = undefined;

  @range(1, 2147483647)
  @property('CallCenterId')
  callCenterId: number = undefined;

  @property('CallGroupId')
  callGroupId: number = undefined;

  @range(1, 2147483647)
  @property('CreatedById')
  createdById: number = undefined;

  @required()
  @property('CreatedDateTime')
  createdDateTime: Date = undefined;

  @property('CutOfTime')
  cutOfTime: Date = undefined;

  @maxLength(12)
  @property('Dnis')
  dnis: string = undefined;

  @property('IsActive')
  isActive: boolean = false;

  @property('ModifiedById')
  modifiedById: number = undefined;

  @property('ModifiedDateTime')
  modifiedDateTime: Date = undefined;

  @maxLength(100)
  @email()
  @property('OutBoundEmail')
  outBoundEmail: string = undefined;

  @property('PracticeId')
  practiceId: number = 0;

  @required()
  @maxLength(100)
  @property('PracticeName')
  practiceName: string = undefined;

  @numeric()
  @maxLength(2)
  @pattern('[0-9]')
  @property('RespondTimeRoutine')
  respondTimeRoutine: number = undefined;

  @numeric()
  @maxLength(2)
  @pattern('[0-9]')
  @property('RespondTimeUrgent')
  respondTimeUrgent: number = undefined;

  @property('ShowPMD')
  showPMD: boolean = false;

  @maxLength(200)
  @property('SubDomain')
  subDomain: string = undefined;

  @maxLength(100)
  @property('websiteString')
  websiteString: string = undefined;

  @maxLength(300)
  @property('EHRLink')
  ehrLink: string = undefined;

  @range(0, 2147483647)
  @property('PracticeTypeId')
  practiceTypeId: number = undefined;
  practiceType: PracticeType;

@numeric()
  @property('LinkPackage')
  linkPackage: number = undefined;

@numeric()
@range(0, 2147483647)
  @property('MedicalArticleCount')
  medicalArticleCount: number = undefined;

  @range(0, 2147483647)
  @property('TimeZoneId')
  timeZoneId: number = undefined;
  applicationTimeZone: ApplicationTimeZone;
  @nested(Provider)
  providers: Provider[];

  @nested(Alert)
  alerts: Alert[];

  @nested(VacationRoster)
  vacationRosters: VacationRoster[];

  @nested(AhtProvider)
  ahtProviders: AhtProvider[];

  @nested(OnCallRoster)
  onCallRosters: OnCallRoster[];

  @nested(PracticeOfficeHour)
  practiceOfficeHours: PracticeOfficeHour[];

  @nested(HospitalRoundingRoster)
  hospitalRoundingRosters: HospitalRoundingRoster[];

  @nested(HospitalAffiliation)
  hospitalAffiliations: HospitalAffiliation[];

  @nested(UserMessage)
  userMessages: UserMessage[];

  @nested(CallDb)
  callDb: CallDb[];


  @nested(Address)
  practiceAddresses: Address[];


  @nested(Contact)
  contactList: Contact[] = undefined;
  practiceTimeZoneDate: Date = undefined;

}
