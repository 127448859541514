import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vNotificationPlusRecords')
export class vNotificationPlusRecord {
    constructor(vNotificationPlusRecord?: vNotificationPlusRecord )  {
        let properties = [ "additinalTextInterval", "additionalTexts", "firstName", "lastMsgSentTime", "lastName", "messageSentCount", "messageSentTime", "mobile", "notificationPlusTranId", "providerId", "responseWaitTime", "skey",];
        for (let property of properties)
            if (vNotificationPlusRecord && (vNotificationPlusRecord[property] || vNotificationPlusRecord[property] == 0))
                this[property] = vNotificationPlusRecord[property];
    }
 
    @maxLength(100)
	@property('AdditinalTextInterval')
	additinalTextInterval : string = undefined ;
 
    @required()
    @maxLength(100)
	@property('AdditionalTexts')
	additionalTexts : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('FirstName')
	firstName : string = undefined ;
 
	@property('LastMsgSentTime')
	lastMsgSentTime : Date =   undefined;
 
    @required()
    @maxLength(50)
	@property('LastName')
	lastName : string = undefined ;
 
    @range(1,2147483647)
	@property('MessageSentCount')
	messageSentCount : number =   undefined;
 
    @required()
	@property('MessageSentTime')
	messageSentTime : Date =   undefined;
 
    @maxLength(12)
	@property('Mobile')
	mobile : string = undefined ;
 
    @range(1,2147483647)
	@property('NotificationPlusTranId')
	notificationPlusTranId : number =   undefined;
 
    @range(1,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
 
    @maxLength(100)
	@property('ResponseWaitTime')
	responseWaitTime : string = undefined ;
 
    @range(1,2147483647)
	@property('Skey')
	skey : number =   undefined;



}
