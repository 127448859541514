import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxFormsModule } from "@rx/forms";
import { RxViewModule } from "@rx/view";
import { RxTableModule } from "@rx/table";

import { EditorComponent } from './editor.component';

@NgModule({
  imports: [
    CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,
  ],
  declarations: [EditorComponent],
  exports: [RouterModule, EditorComponent],
  providers: []
})
export class EditorModule { }
