import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { CallDb } from 'src/app/database-models';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { CallManagerLookups } from 'src/app/lookups';
import { CustomValidation, RxValidation, RxMessageComponent } from '@rx';
import { CallDbLookupGroup } from 'src/app/components/call-manager/call-db/domain/call-db.models';
import { NOTIFICATION_TYPES, NOTIFICATION_PREFERENCES } from '../../../../database-collections';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApplicationBroadcaster } from '@rx/core';
import { CallDbListComponent } from '../list/call-db-list.component';
import { CallDbViewComponent } from '../view/call-db-view.component';
import { user } from '@rx/security';
import { CallTypesEnum } from '../../../../const/call-type';
import { OperationTypesEnum } from '../../../../const/operation-type';
import { textConstants } from '../../../../const/text-const';
import { parse } from 'url';
import { FormState } from '../../../../domain/form-state';
import { RecordStatusEnum, NotificationMinutesEnum, NotificationPreferencesEnum } from '../../../../enums';


@Component({
    selector: 'app-call-db-ansservice',
    templateUrl: './call-db-ansservice.component.html',
    entryComponents: [CallDbListComponent, CallDbViewComponent, RxMessageComponent]
})
export class CallDbAnsserviceComponent extends CallDbDomain implements OnInit, OnDestroy, OnChanges {
    formControls: string[];
    focusElementFormControlName: string;
    toBeAsked: any;
    showComponent: boolean = false;
    callDb: CallDb[];
    ansserviceSubscription: Subscription;
    referenceNumer: string;
    patientId: any;
    callDbAnsServiceFormGroup: FormGroup;
    callDbAnsServiceLookupGroup: CallDbLookupGroup;
    @Input() practiceId: number;
    private sub: any;
    validationFailed: {
        [key: string]: any;
    }
    notificationTypes: { "notificationPreferencesId": number; "notificationPreferencesName": string; }[];
    isEdit: boolean;
    ansservice: any;
    callEndDate: Date;
    isContinueClicked: boolean = true;
    constructor(
        private route: ActivatedRoute,
        private callDbService: CallDbService,
        private dialog: RxDialog,
        private applicationBroadcaster: ApplicationBroadcaster,
        private validation: RxValidation,
        private router: Router,
        private popup: RxPopup,
        private componentResolver: ComponentFactoryResolver
    ) {
        super();
        this.validationFailed = {};
        this.popup.setComponent(componentResolver);
        this.formControls = ["referenceNumber", "cBNumberP", "currentCallProviderId", "currentCallNotPref", "textMessage",];
    }

    ngOnInit(): void {
        this.showComponent = false
        this.callEndDate = new Date();
        if (localStorage.getItem('callId')) {
            this.patientId = parseInt(localStorage.getItem('callId'));
            this.applicationBroadcaster.patientIdBroadCast(this.patientId);
            this.isEdit = true;
        }
        else
            this.callDbService.node$.subscribe(n => this.patientId = n);
        this.sub = this.route.params.subscribe(params => {
            this.practiceId = params['practiceId'];
        });
        if (this.ansserviceSubscription)
            this.ansserviceSubscription.unsubscribe();
        // let referenceNumer = new FormControl("", Validators.required);
        // referenceNumer.valueChanges.subscribe(x => {
        //     this.referenceNumer = x;
        // });
        if (this.patientId && this.patientId != 0) {
            this.callDbService.group(this.practiceId, [CallTypesEnum.AnsweringSrtoDr, this.patientId, 0], [CallManagerLookups.providers]).then((response: any) => {
                this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=> x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage && x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM);
                this.callDbAnsServiceLookupGroup = new CallDbLookupGroup();
                this.callDbAnsServiceLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                this.callDbAnsServiceLookupGroup.callDb = new CallDb(JSON.parse(response.CallDb[0].callDb)[0]);
                let data = FormState.getState(this.patientId);
                if (data) {
                    this.callDbAnsServiceLookupGroup.callDb = data
                }
                // if(this.callDbAnsServiceLookupGroup.callDb.referenceNumber)
                //     this.callDbAnsServiceLookupGroup.callDb.referenceNumber = parseInt(this.callDbAnsServiceLookupGroup.callDb.referenceNumber.toString());
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                let jsonData = JSON.parse(response.CallDb[0].annotationProps);
                jsonData['referenceNumber'] = { 'required': true, 'numeric': true };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: jsonData,
                });
                if (this.callDbAnsServiceFormGroup) {
                    this.callDbAnsServiceFormGroup = undefined;
                }
                // if(this.callDbAnsServiceLookupGroup.callDb.referenceNumber !=null && this.callDbAnsServiceLookupGroup.callDb.referenceNumber != "" && this.callDbAnsServiceLookupGroup.callDb.referenceNumber != undefined){
                //     this.callDbAnsServiceLookupGroup.callDb.referenceNumber = parseInt(this.callDbAnsServiceLookupGroup.callDb.referenceNumber);
                // }
                this.callDbAnsServiceFormGroup = this.validation.getFormGroup(this.callDbAnsServiceLookupGroup.callDb, customValidation);
                this.callDbAnsServiceFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbAnsServiceFormGroup.controls.currentCallNotPref.setValue(null);
                // this.callDbAnsServiceFormGroup.addControl('referenceNumber', referenceNumer);
                // if (this.callDbAnsServiceFormGroup.value.userName) {
                //     if (this.callDbAnsServiceFormGroup.value.userName.includes(':')) {
                //         this.callDbAnsServiceFormGroup.controls.referenceNumber.setValue(parseInt(localStorage.getItem('referenceNumber')));
                //         localStorage.removeItem('referenceNumber');
                //     }
                // }
                for (let i = 0; i < this.formControls.length; i++) {
                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.AnsweringSrtoDr);
                this.showComponent = true;
                this.ngOnChanges();
            });
        }
        else {
            this.callDbService.group(this.practiceId, [CallTypesEnum.AnsweringSrtoDr, 0, 0], [CallManagerLookups.providers]).then((response: any) => {
                this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=> x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage && x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM);;
                this.callDbAnsServiceLookupGroup = new CallDbLookupGroup();
                this.callDbAnsServiceLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
                let data = FormState.getState(0);
                if (data) {
                    this.callDbAnsServiceLookupGroup.callDb = data;
                } else {
                    this.callDbAnsServiceLookupGroup.callDb = new CallDb();
                    this.callDbAnsServiceLookupGroup.callDb.practiceId = this.practiceId
                    this.callDbAnsServiceLookupGroup.callDb.callTypeId = CallTypesEnum.AnsweringSrtoDr;
                }
                // if(this.callDbAnsServiceLookupGroup.callDb.referenceNumber)
                //     this.callDbAnsServiceLookupGroup.callDb.referenceNumber = parseInt(this.callDbAnsServiceLookupGroup.callDb.referenceNumber.toString());
                this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
                let jsonData = JSON.parse(response.CallDb[0].annotationProps);
                jsonData['referenceNumber'] = { 'required': true, 'numeric': true };
                var customValidation: CustomValidation[] = [];
                customValidation.push({
                    annotationProps: jsonData,
                });
                if (this.callDbAnsServiceFormGroup) {
                    this.callDbAnsServiceFormGroup = undefined;
                }
                this.callDbAnsServiceFormGroup = this.validation.getFormGroup(this.callDbAnsServiceLookupGroup.callDb, customValidation);
                this.callDbAnsServiceFormGroup.controls.currentCallProviderId.setValue(null);
                this.callDbAnsServiceFormGroup.controls.currentCallNotPref.setValue(null);
                // this.callDbAnsServiceFormGroup.addControl('referenceNumber', referenceNumer);
                for (let i = 0; i < this.formControls.length; i++) {
                    if (this.toBeAsked[this.formControls[i]]) {
                        this.focusElementFormControlName = this.formControls[i];
                        break;
                    }
                }
                this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.AnsweringSrtoDr);
                this.showComponent = true;
                this.ngOnChanges();
            });
        }

    }

    ngOnChanges(): void {
        this.callDbAnsServiceFormGroup.valueChanges.subscribe(val => {
            FormState.setState(this.callDbAnsServiceFormGroup.value.sKey, this.callDbAnsServiceFormGroup);
        });
    }

    showPopup(value) {
        this.popup.show(CallDbListComponent).then(t => {
            if (t['patientId'])
                this.patientId = t['patientId'];
            if (t['callDbData']) {
                this.ansservice = t['callDbData'];
                let data = new CallDb(JSON.parse(this.ansservice.callDb)[0])
                this.callDbAnsServiceFormGroup.setValue(JSON.parse(this.ansservice.callDb)[0]);
            }
        })
    }

    isContinue() {
        if (this.callDbAnsServiceFormGroup.valid && this.isContinueClicked){
            this.isContinueClicked= false;
            this.continue();
        }
    }

    continue() {
        
        let message = textConstants.answeringMessage;
        localStorage.setItem('referenceNumber', this.callDbAnsServiceFormGroup.value.referenceNumber);
        message = message.replace('{0}', this.callDbAnsServiceFormGroup.value.referenceNumber);
        message = message.replace('{1}', user.data['userName']);

        let callDbObj = new CallDb(this.callDbAnsServiceFormGroup.value);

        callDbObj.userName=message;
        callDbObj.agentId=user.data['userId'];
        callDbObj.cStartTimeDate=new Date();
        callDbObj.cStartDate=this.callEndDate.toISOString();
        callDbObj.callTypeId=CallTypesEnum.AnsweringSrtoDr;
        callDbObj.admissionTime=null;
        callDbObj.admissionDate=null;
        callDbObj.holdTillDt=null;
        callDbObj.holdUntillTime=null;
        if (this.isEdit) {

            callDbObj.sKey=this.patientId;
            callDbObj.operationType=OperationTypesEnum.EditData.toString();
            callDbObj.callTypeId=CallTypesEnum.AnsweringSrtoDr;

            // this.callDbAnsServiceFormGroup.controls.sKey.setValue(this.patientId);
            // this.callDbAnsServiceFormGroup.controls.operationType.setValue(OperationTypesEnum.EditData);
            // this.callDbAnsServiceFormGroup.controls.callTypeId.setValue(CallTypesEnum.AnsweringSrtoDr);
            this.ansserviceSubscription = this.callDbService.put(this.practiceId, callDbObj).subscribe(t => {
                this.isEdit = false;
                this.patientId = t.sKey;
                this.callDbAnsServiceFormGroup.controls.sKey.setValue(t.sKey);
                FormState.remove(this.patientId);
                localStorage.removeItem('callId');
                this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.AnsweringSrtoDr, spResult: t.spResult })
                    .then(t => {
                        if (t.isEdit) {
                            this.isContinueClicked= true;
                            this.isEdit = t.isEdit;
                            FormState.setState(this.callDbAnsServiceFormGroup.value.sKey, this.callDbAnsServiceFormGroup);
                        }
                        else {
                            FormState.remove(this.patientId);
                            this.callDbAnsServiceFormGroup.reset();
                            this.callDbService.addNode(0);
                            this.callDbAnsServiceFormGroup = this.setDefaultValue(this.callDbAnsServiceFormGroup, CallTypesEnum.AnsweringSrtoDr);
                            let userData = user.data;
                            userData.practiceId = null;
                            userData.practiceName = null;
                            user.data = userData;
                            this.router.navigate(['dashboard']);
                            this.applicationBroadcaster.practiceSelectionBroadCast(false);
                        }
                    });
            },
                error => {
                    this.popup.validationFailed(error);
                })
        } else {
            callDbObj.sKey = 0;
            // this.callDbAnsServiceFormGroup.controls.sKey.setValue(0);
            this.ansserviceSubscription = this.callDbService.post(this.practiceId, callDbObj).subscribe(t => {
                this.patientId = t.sKey;
                this.callDbAnsServiceFormGroup.controls.sKey.setValue(t.sKey);
                FormState.remove(this.patientId);
                localStorage.removeItem('callId');
                this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.AnsweringSrtoDr, spResult: t.spResult }).then(t => {
                    if (t.isEdit) {
                        this.isContinueClicked= true;
                        this.isEdit = t.isEdit;
                        FormState.setState(this.callDbAnsServiceFormGroup.value.sKey, this.callDbAnsServiceFormGroup);
                    }
                    else {
                        FormState.remove(this.patientId);
                        this.callDbAnsServiceFormGroup.reset();
                        this.callDbService.addNode(0);
                        this.callDbAnsServiceFormGroup = this.setDefaultValue(this.callDbAnsServiceFormGroup, CallTypesEnum.AnsweringSrtoDr);
                        let userData = user.data;
                        userData.practiceId = null;
                        userData.practiceName = null;
                        user.data = userData;
                        this.router.navigate(['dashboard']);
                        this.applicationBroadcaster.practiceSelectionBroadCast(false);
                    }
                });
            },
                error => {
                    this.popup.validationFailed(error);
                })
        }
    }

    clearFormGroup(): void {

        this.isEdit = false;
        this.callDbService.addNode(0);
        this.callDbAnsServiceFormGroup.reset();
        this.callDbAnsServiceFormGroup = this.setDefaultValue(this.callDbAnsServiceFormGroup, CallTypesEnum.AnsweringSrtoDr);
        if (this.patientId)
            FormState.remove(this.patientId);
        else
            FormState.remove(0);
        this.applicationBroadcaster.patientIdBroadCast(0);
        localStorage.removeItem('callId');

        let userData = user.data;
        userData.practiceId = null;
        userData.practiceName = null;
        user.data = userData;
        this.router.navigate(['dashboard']);
        this.applicationBroadcaster.practiceSelectionBroadCast(false);
    }

    ngOnDestroy(): void {
        if (this.ansserviceSubscription)
            this.ansserviceSubscription.unsubscribe();
        super.destroy();
    }
}
