import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';

import { CallDb } from 'src/app/database-models';
import { CallDbService } from '../call-db.service';
import { CallDbDomain } from '../domain/call-db.domain';
import { CallManagerLookups } from 'src/app/lookups';
import { PHONE_TYPES, ADDMISSION_TYPES, NOTIFICATION_PREFERENCES } from 'src/app/database-collections';
import { CallDbLookupGroup } from '../domain/call-db.models';
import { CustomValidation, RxValidation, RxMessageComponent } from '@rx';
import { FormGroup } from '@angular/forms';
import { ApplicationBroadcaster, ApplicationConfiguration } from '@rx/core';
import { CallDbListComponent } from '../list/call-db-list.component';
import { CallDbViewComponent } from '../view/call-db-view.component';
import { user } from '@rx/security';
import { CallTypesEnum } from '../../../../const/call-type';
import { OperationTypesEnum } from '../../../../const/operation-type';
import { FormState } from '../../../../domain/form-state';
import { RecordStatusEnum, NotificationPreferencesEnum } from '../../../../enums';
import { TimeSource } from '../../../../const/time-source';


@Component({
  selector: 'app-call-db-admission',
  templateUrl: './call-db-admission.component.html',
  entryComponents: [CallDbListComponent, CallDbViewComponent, RxMessageComponent]
})
export class CallDbAdmissionComponent extends CallDbDomain implements OnInit, OnDestroy, OnChanges {
  formControls: string[];
  focusElementFormControlName: string;
  toBeAsked: any;
  phoneTypeList: { "phoneTypesId": number; "phoneTypesName": string; }[];
  showComponent: boolean = false;
  callDb: CallDb[];
  timeSource = TimeSource;
  admissionSubscription: Subscription;
  callDbAdmissionLookupGroup: CallDbLookupGroup;
  callDbAdmissionFormGroup: FormGroup;
  @Input() practiceId: number;
  validationFailed: {
    [key: string]: any;
  }
  private sub: any;
  admissionTypes: { "addmissionTypesId": number; "addmissionTypesName": string; }[];
  notificationTypes: { "notificationPreferencesId": number; "notificationPreferencesName": string; }[];
  patientId: any;
  isEdit: boolean;
  admissionData: any;
  callEndDate: Date;
  isContinueClicked: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private callDbService: CallDbService,
    private dialog: RxDialog,
    private router: Router,
    private applicationBroadcaster: ApplicationBroadcaster,
    private validation: RxValidation,
    private popup: RxPopup,
    private toast: RxToast,
    private componentResolver: ComponentFactoryResolver
  ) {
    super();
    this.validationFailed = {};
    this.popup.setComponent(componentResolver)
    this.formControls = ["cFirstName", "cLastName", "facilityName", "cBNumberP", "chFirstName", "chLastName", "callBack", "birthDate", "age", "primaryPhysicianId", "admissionType", "room", "admissionDate", "textMessage"];
  }

  ngOnInit(): void {
    this.showComponent = false
    this.callEndDate = new Date();
    if (localStorage.getItem('callId')) {
      this.patientId = parseInt(localStorage.getItem('callId'));
      this.applicationBroadcaster.patientIdBroadCast(this.patientId);
      this.isEdit = true;
    }
    else
      this.callDbService.node$.subscribe(n => this.patientId = n);
    this.sub = this.route.params.subscribe(params => {
      this.practiceId = params['practiceId'];
    });
    if (this.admissionSubscription)
      this.admissionSubscription.unsubscribe();

    this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM && x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage);
    if (this.patientId && this.patientId != 0) {
      this.callDbService.group(this.practiceId, [CallTypesEnum.HospitalAdmission, this.patientId, 0], [CallManagerLookups.providers, CallManagerLookups.hospitals]).then((response: any) => {
        this.phoneTypeList = PHONE_TYPES;
        this.admissionTypes = ADDMISSION_TYPES;
        // this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM && x.notificationPreferencesId != NotificationPreferencesEnum.AfterHourTriage);
        this.callDbAdmissionLookupGroup = new CallDbLookupGroup();
        this.callDbAdmissionLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
        this.callDbAdmissionLookupGroup.hospitals = response.hospitals.where(x => x.practiceId == this.practiceId);
        this.callDbAdmissionLookupGroup.callDb = new CallDb(JSON.parse(response.CallDb[0].callDb)[0]);
        let data = FormState.getState(this.patientId);
        if (data) {
          // data.admissionTime = this.timeConversionTo24Hour(data.admissionTime);
          this.callDbAdmissionLookupGroup.callDb = data
        }
        let jsonData = JSON.parse(response.CallDb[0].annotationProps);
        jsonData['admissionDate'] = { 'required': jsonData['admissionDate']['required'] };
        jsonData['admissionTime'] = { 'required': jsonData['admissionDate']['required'] };
        this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
        var customValidation: CustomValidation[] = [];
        customValidation.push({
          annotationProps: jsonData,
        });
        if (this.callDbAdmissionFormGroup) {
          this.callDbAdmissionFormGroup = undefined;
        }
        this.callDbAdmissionFormGroup = this.validation.getFormGroup(this.callDbAdmissionLookupGroup.callDb, customValidation);
        this.callDbAdmissionFormGroup.controls.currentCallProviderId.setValue(null);
        this.callDbAdmissionFormGroup.controls.currentCallNotPref.setValue(null);
        for (let i = 0; i < this.formControls.length; i++) {
          if (this.toBeAsked[this.formControls[i]]) {
            this.focusElementFormControlName = this.formControls[i];
            break;
          }
        }
        this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.HospitalAdmission);
        this.showComponent = true;
        this.ngOnChanges();
      });
    }
    else {
      this.callDbService.group(this.practiceId, [CallTypesEnum.HospitalAdmission, 0, 0], [CallManagerLookups.providers, CallManagerLookups.hospitals]).then((response: any) => {
      this.phoneTypeList = PHONE_TYPES;
        this.admissionTypes = ADDMISSION_TYPES;
        // this.notificationTypes = NOTIFICATION_PREFERENCES.filter(x=>x.notificationPreferencesId != NotificationPreferencesEnum.HoldForAM);
        this.callDbAdmissionLookupGroup = new CallDbLookupGroup();
        this.callDbAdmissionLookupGroup.providers = response.providers.where(x => x.practiceId == this.practiceId && x.statusId == RecordStatusEnum.Active);
        this.callDbAdmissionLookupGroup.hospitals = response.hospitals.where(x => x.practiceId == this.practiceId);
        let data = FormState.getState(0);
        if (data) {
          // data.admissionTime = this.timeConversionTo24Hour(data.admissionTime);
          this.callDbAdmissionLookupGroup.callDb = data;
        } else {
          this.callDbAdmissionLookupGroup.callDb = new CallDb();
          this.callDbAdmissionLookupGroup.callDb.practiceId = this.practiceId
          this.callDbAdmissionLookupGroup.callDb.callTypeId = CallTypesEnum.HospitalAdmission;
        }
        let jsonData = JSON.parse(response.CallDb[0].annotationProps);
        jsonData['admissionDate'] = { 'required': jsonData['admissionDate']['required'] };
        jsonData['admissionTime'] = { 'required': jsonData['admissionDate']['required'] };
        this.toBeAsked = JSON.parse(response.CallDb[0].toBeAsked);
        var customValidation: CustomValidation[] = [];
        customValidation.push({
          annotationProps: jsonData,
        });
        if (this.callDbAdmissionFormGroup) {
          this.callDbAdmissionFormGroup = undefined;
        }
        this.callDbAdmissionFormGroup = this.validation.getFormGroup(this.callDbAdmissionLookupGroup.callDb, customValidation);
        this.callDbAdmissionFormGroup.controls.currentCallProviderId.setValue(null);
        this.callDbAdmissionFormGroup.controls.currentCallNotPref.setValue(null);
        this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.HospitalAdmission);
        for (let i = 0; i < this.formControls.length; i++) {
          if (this.toBeAsked[this.formControls[i]]) {
            this.focusElementFormControlName = this.formControls[i];
            break;
          }
        }
        this.showComponent = true;
        this.ngOnChanges();
      });
    }
  }

  ngOnChanges(): void {
    this.callDbAdmissionFormGroup.valueChanges.subscribe(val => {
      FormState.setState(this.callDbAdmissionFormGroup.value.sKey, this.callDbAdmissionFormGroup);
    });
  }

  showPopup(value) {
    this.popup.show(CallDbListComponent)
      .then(t => {
        if (t['patientId'])
          this.patientId = t['patientId'];
        if (t['callDbData']) {
          this.admissionData = t['callDbData'];
          let data = new CallDb(JSON.parse(this.admissionData.callDb)[0])
          // let time = this.timeConversionTo12Hour(JSON.parse(this.admissionData.callDb)[0].admissionTime);
          this.callDbAdmissionFormGroup.setValue(JSON.parse(this.admissionData.callDb)[0]);
          // this.callDbAdmissionFormGroup.controls.admissionTime.setValue(time);
          // this.applicationBroadcaster.callTypeIdBroadCast(CallTypesEnum.PatientToDoctor);
        }
      })
  }

  isContinue() {
    if (this.callDbAdmissionFormGroup.valid && this.isContinueClicked) {
      this.isContinueClicked = false;
      this.continue();
    }
  }

  continue() {
    let dataCompare = new Date();
    let isDateTrue = false;


    // if (this.callDbAdmissionFormGroup.value.admissionDate) {
    //   dataCompare = this.mergeDateTime(this.callDbAdmissionFormGroup.value.admissionDate, this.callDbAdmissionFormGroup.value.admissionTime);
    //   isDateTrue = dataCompare < new Date();
    // }

    if (this.callDbAdmissionFormGroup.value.birthDate > new Date()) {
      this.toast.show(ApplicationConfiguration.get("toastValidationMessages.birthDate"), { status: 'error' });
    }
    // else if (isDateTrue) {
    //   this.toast.show('past Date/Time for admission can not be selected', { status: 'error' });
    // }
    else {
      let callDbObje = new CallDb();
      callDbObje.admissionDate = this.callDbAdmissionFormGroup.value.admissionDate;
      callDbObje.admissionTime = this.timeConversionTo24Hour(this.callDbAdmissionFormGroup.value.admissionTime);
      callDbObje.admissionType = this.callDbAdmissionFormGroup.value.admissionType;
      callDbObje.birthDate = this.callDbAdmissionFormGroup.value.birthDate;
      callDbObje.cBNumberP = this.callDbAdmissionFormGroup.value.cBNumberP;
      callDbObje.cFirstName = this.callDbAdmissionFormGroup.value.cFirstName;
      callDbObje.cLastName = this.callDbAdmissionFormGroup.value.cLastName;
      callDbObje.callBack = this.callDbAdmissionFormGroup.value.callBack;
      callDbObje.callTypeId = CallTypesEnum.HospitalAdmission;
      callDbObje.chFirstName = this.callDbAdmissionFormGroup.value.chFirstName;
      callDbObje.chLastName = this.callDbAdmissionFormGroup.value.chLastName;
      callDbObje.facilityName = this.callDbAdmissionFormGroup.value.facilityName;
      callDbObje.primaryPhysicianId = this.callDbAdmissionFormGroup.value.primaryPhysicianId;
      callDbObje.room = this.callDbAdmissionFormGroup.value.room;
      callDbObje.textMessage = this.callDbAdmissionFormGroup.value.textMessage;
      callDbObje.urgent = this.callDbAdmissionFormGroup.value.urgent;
      callDbObje.age = this.callDbAdmissionFormGroup.value.age;
      callDbObje.practiceId = this.callDbAdmissionFormGroup.value.practiceId;
      callDbObje.notPhysId = this.callDbAdmissionFormGroup.value.notPhysId;
      callDbObje.notPrefId = this.callDbAdmissionFormGroup.value.notPrefId;
      callDbObje.userName = user.data['userName'];
      callDbObje.agentId = user.data['userId'];
      callDbObje.cStartTimeDate = new Date();
      callDbObje.cStartDate = this.callEndDate.toISOString();
      callDbObje.currentCallNotPref = this.callDbAdmissionFormGroup.value.currentCallNotPref;
      callDbObje.currentCallProviderId = this.callDbAdmissionFormGroup.value.currentCallProviderId;
      // this.callDbAdmissionFormGroup.controls.userName.setValue(user.data['userName']);
      // this.callDbAdmissionFormGroup.controls.agentId.setValue(user.data['userId']);
      // this.callDbAdmissionFormGroup.controls.cStartTimeDate.setValue(this.dateToUTC(new Date()));

      if (this.isEdit) {
        callDbObje.operationType = OperationTypesEnum.EditData.toString();
        callDbObje.sKey = this.callDbAdmissionFormGroup.value.sKey;
        this.admissionSubscription = this.callDbService.put(this.practiceId, callDbObje).subscribe(t => {
          // this.callDbAdmissionFormGroup.controls.birthDate.setValue(null);
          // this.callDbAdmissionFormGroup.controls.admissionDate.setValue(null);
          this.isEdit = false;
          this.patientId = t.sKey;
          this.callDbAdmissionFormGroup.controls.sKey.setValue(t.sKey);
          FormState.remove(this.patientId);
          // localStorage.removeItem('isEdit');
          localStorage.removeItem('callId');
          this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.HospitalAdmission, spResult: t.spResult })
            // .then(t => this.ngOnInit());
            .then(t => {
              if (t.isEdit) {
                this.isContinueClicked = true;
                this.isEdit = t.isEdit;
                // FormState.remove(this.patientId);
                FormState.setState(this.callDbAdmissionFormGroup.value.sKey, this.callDbAdmissionFormGroup);
              }
              else {
                FormState.remove(this.patientId);
                this.callDbAdmissionFormGroup.reset();
                this.callDbService.addNode(0);
                this.callDbAdmissionFormGroup = this.setDefaultValue(this.callDbAdmissionFormGroup, CallTypesEnum.HospitalAdmission);
                let userData = user.data;
                userData.practiceId = null;
                userData.practiceName = null;
                user.data = userData;
                this.router.navigate(['dashboard']);
                this.applicationBroadcaster.practiceSelectionBroadCast(false);
              }
            });
        },
          error => {
            this.popup.validationFailed(error);
          })
      } else {
        callDbObje.sKey = 0;
        this.admissionSubscription = this.callDbService.post(this.practiceId, callDbObje).subscribe(t => {
          // this.callDbAdmissionFormGroup.controls.birthDate.setValue(null);
          // this.callDbAdmissionFormGroup.controls.admissionDate.setValue(null);
          this.patientId = t.sKey;
          this.callDbAdmissionFormGroup.controls.sKey.setValue(t.sKey);
          FormState.remove(this.patientId);
          localStorage.removeItem('callId');
          this.popup.show(CallDbViewComponent, { sKey: t.sKey, newBornSkeyList: [], callTypeId: CallTypesEnum.HospitalAdmission, spResult: t.spResult })
            // .then(t => this.ngOnInit());
            .then(t => {
              if (t.isEdit) {
                this.isContinueClicked = true;
                this.isEdit = t.isEdit;
                // FormState.remove(this.patientId);
                FormState.setState(this.callDbAdmissionFormGroup.value.sKey, this.callDbAdmissionFormGroup);
              }
              else {
                FormState.remove(this.patientId);
                this.callDbAdmissionFormGroup.reset();
                this.callDbService.addNode(0);
                this.callDbAdmissionFormGroup = this.setDefaultValue(this.callDbAdmissionFormGroup, CallTypesEnum.HospitalAdmission);
                let userData = user.data;
                userData.practiceId = null;
                userData.practiceName = null;
                user.data = userData;
                this.router.navigate(['dashboard']);
                this.applicationBroadcaster.practiceSelectionBroadCast(false);
              }
            });
        },
          error => {
            this.popup.validationFailed(error);
          })
      }
    }
  }

  checkDate(value) {
    let date = new Date();
    if (value < date) {
        let age = this.calculateAge(value);
        this.callDbAdmissionFormGroup.controls.age.setValue(age);
    }
    else {
        this.callDbAdmissionFormGroup.controls.age.setValue(0);
    }
}

  clearFormGroup(): void {

    this.callDbService.addNode(0);
    this.isEdit = false;

    this.callDbAdmissionFormGroup.reset();
    this.callDbAdmissionFormGroup = this.setDefaultValue(this.callDbAdmissionFormGroup, CallTypesEnum.HospitalAdmission);
    if (this.patientId)
      FormState.remove(this.patientId);
    else
      FormState.remove(0);
    this.applicationBroadcaster.patientIdBroadCast(0);
    localStorage.removeItem('callId');

    let userData = user.data;
    userData.practiceId = null;
    userData.practiceName = null;
    user.data = userData;
    this.router.navigate(['dashboard']);
    this.applicationBroadcaster.practiceSelectionBroadCast(false);
  }

  ngOnDestroy(): void {
    if (this.admissionSubscription)
      this.admissionSubscription.unsubscribe();
    super.destroy();
  }
}
