import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vNotificationPreferences')
export class vNotificationPreference {
    constructor(vNotificationPreference?: vNotificationPreference )  {
        let properties = [ "callTypeId", "dayOfWeekId", "dayOfWeekName", "description", "endMinutes", "fromTime", "notificationPrefId", "notificationType", "notificationTypeId", "providerId", "startMinutes", "toTime",];
        for (let property of properties)
            if (vNotificationPreference && (vNotificationPreference[property] || vNotificationPreference[property] == 0))
                this[property] = vNotificationPreference[property];
    }
 
    @range(1,2147483647)
	@property('CallTypeId')
	callTypeId : number =   undefined;
 
	@property('DayOfWeekId')
	dayOfWeekId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('DayOfWeekName')
	dayOfWeekName : string = undefined ;
 
    @required()
    @maxLength(100)
	@property('Description')
	description : string = undefined ;
 
	@property('EndMinutes')
	endMinutes : number =   undefined;
 
    @maxLength(4000)
	@property('FromTime')
	fromTime : string = undefined ;
 
    @range(1,2147483647)
	@property('NotificationPrefId')
	notificationPrefId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('NotificationType')
	notificationType : string = undefined ;
 
    @range(1,2147483647)
	@property('NotificationTypeId')
	notificationTypeId : number =   undefined;
 
    @range(1,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
 
	@property('StartMinutes')
	startMinutes : number =   undefined;
 
    @maxLength(4000)
	@property('ToTime')
	toTime : string = undefined ;



}
