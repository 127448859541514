export const DAYS_OF_WEEKS = [
	{
        "daysOfWeekId": 52,
        "daysOfWeekName": "Monday"
    },
	{
        "daysOfWeekId": 53,
        "daysOfWeekName": "Tuesday"
    },
	{
        "daysOfWeekId": 54,
        "daysOfWeekName": "Wednesday"
    },
	{
        "daysOfWeekId": 55,
        "daysOfWeekName": "Thursday"
    },
	{
        "daysOfWeekId": 56,
        "daysOfWeekName": "Friday"
    },
	{
        "daysOfWeekId": 57,
        "daysOfWeekName": "Saturday"
    },
	{
        "daysOfWeekId": 58,
        "daysOfWeekName": "Sunday"
    },
	{
        "daysOfWeekId": 59,
        "daysOfWeekName": "Mon thru Fri"
    },
	{
        "daysOfWeekId": 60,
        "daysOfWeekName": "Sat and Sun"
    },
	{
        "daysOfWeekId": 61,
        "daysOfWeekName": "All Days"
    },
]