import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('CallOutbound')
export class CallOutbound {
    constructor(callOutbound?: CallOutbound )  {
        let properties = [ "agentId", "agentProcessed", "callContent", "callDestination", "callOutboundId", "comment", "emailedCallToAlphaPage", "lastUpdate", "numberToCall", "personToCall", "processed", "refNo", "timeProcessed", "timetoPlaceCall", "userId",];
        for (let property of properties)
            if (callOutbound && (callOutbound[property] || callOutbound[property] == 0))
                this[property] = callOutbound[property];
    }
 
	@property('AgentId')
	agentId : number =   undefined;
 
	@property('AgentProcessed')
	agentProcessed : number =   undefined;
 
    @maxLength(4000)
	@property('CallContent')
	callContent : string = undefined ;
 
    @maxLength(25)
	@property('CallDestination')
	callDestination : string = undefined ;
 
	@property('CallOutboundId')
	callOutboundId : number =   0 ;
 
    @maxLength(1000)
	@property('Comment')
	comment : string = undefined ;
 
	@property('EmailedCallToAlphaPage')
	emailedCallToAlphaPage : boolean = false ;
 
	@property('LastUpdate')
	lastUpdate : Date =   undefined;
 
    @maxLength(25)
	@property('NumberToCall')
	numberToCall : string = undefined ;
 
	@property('PersonToCall')
	personToCall : number =   undefined;
 
	@property('Processed')
	processed : boolean = false ;
 
    @range(1,2147483647)
	@property('RefNo')
	refNo : number =   undefined;
 
	@property('TimeProcessed')
	timeProcessed : Date =   undefined;
 
	@property('TimetoPlaceCall')
	timetoPlaceCall : Date =   undefined;
 
	@property('UserId')
	userId : number =   undefined;



}
