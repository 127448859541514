export const RECORD_STATUS = [
	{
        "recordStatusId": 12,
        "recordStatusName": "Active"
    },
	{
        "recordStatusId": 13,
        "recordStatusName": "InActive"
    },
	{
        "recordStatusId": 14,
        "recordStatusName": "Deleted"
    },
]