import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('ServerMessages')
export class ServerMessage {
    constructor(serverMessage?: ServerMessage )  {
        let properties = [ "serverMessageId", "serverMessageName",];
        for (let property of properties)
            if (serverMessage && (serverMessage[property] || serverMessage[property] == 0))
                this[property] = serverMessage[property];
    }
 
	@property('ServerMessageId')
	serverMessageId : number =   0 ;
 
    @required()
    @maxLength(50)
	@property('ServerMessageName')
	serverMessageName : string = undefined ;



}
