export const CALL_SENT_METHODS = [
	{
        "callSentMethodId": 5165,
        "callSentMethodName": "GlobalOverride"
    },
	{
        "callSentMethodId": 5166,
        "callSentMethodName": "Primary Care Provider"
    },
	{
        "callSentMethodId": 5167,
        "callSentMethodName": "Rounding Provider"
    },
	{
        "callSentMethodId": 5159,
        "callSentMethodName": "AHT"
    },
	{
        "callSentMethodId": 5160,
        "callSentMethodName": "OnCallProvider"
    },
	{
        "callSentMethodId": 5161,
        "callSentMethodName": "LocalOverride"
    },
]