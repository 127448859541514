import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi } from "@rx/security";

import { Practice, vPractice, } from 'src/app/database-models';
import { PracticeLookupGroup } from './domain/practice.models';

@Injectable()
export class PracticesService {
    private clock: Observable<Date>;


    private get api(): AuthorizeApi {
        var authorizeApi: AuthorizeApi = {
            api: `api/Practices`,
            applicationModuleId: 33,
            keyName: 'practiceId'

        }
        return authorizeApi;
    }

    constructor(
        private http: RxHttp
    ) {
        // this.clock = Observable.interval(1000).map(tick => new Date()).share();
    }

    getClock(offset : number): Observable<Date> {

        this.clock = Observable.interval(1000).map((tick): Date => {
            let dateObject = new Date() /////////////// create Date object for current location
            let localTime = dateObject.getTime(); ////////// convert to msec since Jan 1 1970
            let localOffset = dateObject.getTimezoneOffset() * 60000; ////// obtain local UTC offset and convert to msec
            let utc = localTime + localOffset; /////////////// obtain UTC time in msec
            // let offset = offset; ////////////////// obtain and add destination's UTC time offset
            let mountain = utc + (3600000 * offset); //////////////// convert msec value to date string
            let nd = new Date(mountain);
            // this.clock = Observable.interval(1000).map(tick => new Date(mountain)).share();
            return new Date(mountain)
        }).share();
        return this.clock;
    }
    lookup<PracticeLookupGroup>(lookupActions: LookupAction[]): Promise<PracticeLookupGroup> {
        return this.http.lookup<PracticeLookupGroup>(lookupActions);
    }

    group<PracticeLookupGroup>(params: any[] | {
        [key: string]: any;
    } | RequestQueryParams, lookupActions: LookupAction[]): Promise<PracticeLookupGroup> {
        return this.http.group<PracticeLookupGroup>(this.api, params, 'practice', lookupActions);
    }

    search(search: any): Observable<any[]> {
        return this.http.search<any[]>(this.api, search,false);
    }

    get(): Observable<vPractice[]> {
        return this.http.get<vPractice[]>(this.api);
    }

    getBy(params?: any[] | {
        [key: string]: any;
    } | RequestQueryParams): Observable<Practice> {
        return this.http.get<Practice>(this.api, params);
    }

    post(practice: Practice): Observable<Practice> {
        return this.http.post<Practice>(this.api, practice);
    }

    put(practice: Practice): Observable<Practice> {
        return this.http.put<Practice>(this.api, practice);
    }

    delete(id: number): Observable<Practice> {
        return this.http.delete<Practice>(this.api, id);
    }

    filterLookup<T>(lookup: LookupAction, filterParameters: any[], queryParams?: { [key: string]: any }): Observable<T> {
        return this.http.filterLookup(lookup, filterParameters, queryParams);
    }

}
