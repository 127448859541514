import { required } from "@rx/annotations";

export class UserCredentialModel {
    @required()
    username: string = undefined;
    @required()
    password: string = undefined;
    failedCount: number = 0;
}

export class UserAuthenticationViewModel {
    token: string;
    modules: any;
    roleId: number;
    fullName: string;
    userName: string;
    failedCount: number;
    failedLogin: boolean;
    validationMessage: string;
    practiceId: number;
    userId: number;
    isFirstTimeLogin: boolean;
  profilePicture : string;
  isUnlockedUser : any;
}
