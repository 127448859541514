export const NOTIFICATION_MINUTES = [
	{
        "notificationMinutesId": 2113,
        "notificationMinutesName": "1 Minute"
    },
	{
        "notificationMinutesId": 2114,
        "notificationMinutesName": "5 Minute"
    },
	{
        "notificationMinutesId": 2115,
        "notificationMinutesName": "10 Minute"
    },
	{
        "notificationMinutesId": 2116,
        "notificationMinutesName": "15 Minute"
    },
	{
        "notificationMinutesId": 2117,
        "notificationMinutesName": "20 Minute"
    },
	{
        "notificationMinutesId": 2118,
        "notificationMinutesName": "30 Minute"
    },
	{
        "notificationMinutesId": 2119,
        "notificationMinutesName": "60 Minute"
    },
]