import { NgModule } from '@angular/core';
import {RouterModule } from '@angular/router';


import { ON_CALL_ROSTERS_ROUTING } from './on-call-rosters.routing';
import { OnCallRostersService } from './on-call-rosters.service';

@NgModule({
    imports: [ON_CALL_ROSTERS_ROUTING],
    exports: [RouterModule],
    providers: [OnCallRostersService]
})
export class OnCallRostersModule { }