import { NgModule } from '@angular/core';
import {RouterModule } from '@angular/router';


import { PROVIDERS_ROUTING } from './providers.routing';
import { ProvidersService } from './providers.service';

@NgModule({
    imports: [PROVIDERS_ROUTING],
    exports: [RouterModule],
    providers: [ProvidersService]
})
export class ProvidersModule { }