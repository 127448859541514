import { ComponentContainer } from "@rx/core"
import { CallDbPatientComponent } from './patient/call-db-patient.component'
import { CallDbNewbornComponent } from './newborn/call-db-newborn.component'
import { CallDbDoctorComponent } from './doctor/call-db-doctor.component'
import { CallDbAdmissionComponent } from './admission/call-db-admission.component'
import { CallDbAppointmentComponent } from './appointment/call-db-appointment.component'
import { CallDbAnsserviceComponent } from './ansservice/call-db-ansservice.component'
import { CallDbTriageComponent } from './triage/call-db-triage.component'
import { CallDbListComponent } from "./list/call-db-list.component";
import { CallDbSearchComponent } from "./search/call-db-search.component";
import { CallDbCallhistoryComponent } from "./callhistory/call-db-callhistory.component";

export const CALL_DB_SHARED_COMPONENT_CONTAINER: ComponentContainer[] = [
	{
		component: CallDbPatientComponent,
		accessItem: 'patient',
		applicationModuleId: 2045,
		keyName: 'sKey',
		childModuleName: undefined,
		pageName: 'call-d-b',
		rootModuleId: 33
	},
	{
		component: CallDbNewbornComponent,
		accessItem: 'newborn',
		applicationModuleId: 2045,
		keyName: 'sKey',
		childModuleName: undefined,
		pageName: 'call-d-b',
		rootModuleId: 33
	},
	{
		component: CallDbDoctorComponent,
		accessItem: 'doctor',
		applicationModuleId: 2045,
		keyName: 'sKey',
		childModuleName: undefined,
		pageName: 'call-d-b',
		rootModuleId: 33
	},
	{
		component: CallDbAdmissionComponent,
		accessItem: 'admission',
		applicationModuleId: 2045,
		keyName: 'sKey',
		childModuleName: undefined,
		pageName: 'call-d-b',
		rootModuleId: 33
	},
	{
		component: CallDbAppointmentComponent,
		accessItem: 'appointment',
		applicationModuleId: 2045,
		keyName: 'sKey',
		childModuleName: undefined,
		pageName: 'call-d-b',
		rootModuleId: 33
	},
	{
		component: CallDbAnsserviceComponent,
		accessItem: 'ansservice',
		applicationModuleId: 2045,
		keyName: 'sKey',
		childModuleName: undefined,
		pageName: 'call-d-b',
		rootModuleId: 33
	},
	{
		component: CallDbTriageComponent,
		accessItem: 'triage',
		applicationModuleId: 2045,
		keyName: 'sKey',
		childModuleName: undefined,
		pageName: 'call-d-b',
		rootModuleId: 33
	},
	{
		component: CallDbListComponent,
		accessItem: 'list',
		applicationModuleId: 2045,
		keyName: 'sKey',
		childModuleName: undefined,
		pageName: 'call-d-b',
		rootModuleId: 33
	},
	{
		component: CallDbSearchComponent,
		accessItem: 'list',
		applicationModuleId: 2045,
		keyName: 'sKey',
		childModuleName: undefined,
		pageName: 'call-d-b',
		rootModuleId: 33
	},
	{
		component: CallDbCallhistoryComponent,
		accessItem: 'list',
		applicationModuleId: 2045,
		keyName: 'sKey',
		childModuleName: undefined,
		pageName: 'call-d-b',
		rootModuleId: 33
	},
	
];

