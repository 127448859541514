import { Injectable } from '@angular/core';

@Injectable()
export class OnCallRosterDomain {
    constructor() { }

    destroy(): void {

    }
    timeConversionTo12Hour(time: any): any {
        if (time != null && time != undefined && time != "") {
            var timeArray = time.split(":");
            var hours = parseInt(timeArray[0]);
            var isMr = hours >= 12 ? "PM" : "AM"
            var newhour = hours >= 12 ? (hours == 12 ? 12 : hours - 12) : (hours == 0 ? 12 : hours);
            var paddedNum = newhour.toString().length == 1 ? String("00"+newhour).slice(-2) : newhour;
            time = paddedNum + ":" + timeArray[1] + " " + isMr;
            return time;
        }
        else {
            return time;
        }
    }

    timeConversionTo24Hour(time: any): any {
        if (time != null && time != undefined && time != "") {
            var splitText = time.split(" ");
            var splitTime = splitText[0].split(":");
            var finalTime = "";
            if (splitText[1] == "PM" || splitText[1] == "pm") {
                switch (splitTime[0]) {
                    case "00":
                        finalTime = "".concat("12", ":", splitTime[1])
                        break;
                    case "01":
                        finalTime = "".concat("13", ":", splitTime[1])
                        break;
                    case "02":
                        finalTime = "".concat("14", ":", splitTime[1])
                        break;
                    case "03":
                        finalTime = "".concat("15", ":", splitTime[1])
                        break;
                    case "04":
                        finalTime = "".concat("16", ":", splitTime[1])
                        break;
                    case "05":
                        finalTime = "".concat("17", ":", splitTime[1])
                        break;
                    case "06":
                        finalTime = "".concat("18", ":", splitTime[1])
                        break;
                    case "07":
                        finalTime = "".concat("19", ":", splitTime[1])
                        break;
                    case "08":
                        finalTime = "".concat("20", ":", splitTime[1])
                        break;
                    case "09":
                        finalTime = "".concat("21", ":", splitTime[1])
                        break;
                    case "10":
                        finalTime = "".concat("22", ":", splitTime[1])
                        break;
                    case "11":
                        finalTime = "".concat("23", ":", splitTime[1])
                        break;
                    case "12":
                        finalTime = "".concat("12", ":", splitTime[1])
                        break;
                }
            }
            else if (splitText[1] == "AM" || splitText[1] == "am") {
                switch (splitTime[0]) {
                    case "00":
                        finalTime = "".concat("00", ":", splitTime[1])
                        break;
                    case "01":
                        finalTime = "".concat("01", ":", splitTime[1])
                        break;
                    case "02":
                        finalTime = "".concat("02", ":", splitTime[1])
                        break;
                    case "03":
                        finalTime = "".concat("03", ":", splitTime[1])
                        break;
                    case "04":
                        finalTime = "".concat("04", ":", splitTime[1])
                        break;
                    case "05":
                        finalTime = "".concat("05", ":", splitTime[1])
                        break;
                    case "06":
                        finalTime = "".concat("06", ":", splitTime[1])
                        break;
                    case "07":
                        finalTime = "".concat("07", ":", splitTime[1])
                        break;
                    case "08":
                        finalTime = "".concat("08", ":", splitTime[1])
                        break;
                    case "09":
                        finalTime = "".concat("09", ":", splitTime[1])
                        break;
                    case "10":
                        finalTime = "".concat("10", ":", splitTime[1])
                        break;
                    case "11":
                        finalTime = "".concat("11", ":", splitTime[1])
                        break;
                    case "12":
                        finalTime = "".concat("00", ":", splitTime[1])
                        break;
                }
            }
            return finalTime == "" ? time : finalTime;
        }
        return null;
    }

    formatDate(d): any {
        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        let stringDate = [year, month, day].join('-') + "T00:00:00";
        return stringDate;
    }

    dateToUTC(date) {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }
}
