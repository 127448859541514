import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vCallTypes')
export class vCallType {
    constructor(vCallType?: vCallType )  {
        let properties = [ "callTypeId", "description",];
        for (let property of properties)
            if (vCallType && (vCallType[property] || vCallType[property] == 0))
                this[property] = vCallType[property];
    }
 
	@property('CallTypeId')
	callTypeId : number =   0 ;
 
    @required()
    @maxLength(100)
	@property('Description')
	description : string = undefined ;



}
