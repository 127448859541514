import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vHospitalAddresses')
export class vHospitalAddress {
    constructor(vHospitalAddress?: vHospitalAddress )  {
        let properties = [ "addressLine1", "addressLine2", "addressRefId", "addressTypeId", "city", "contact", "hospitalId", "hospitalName", "officePhone", "officePhoneExt", "postalCode", "practiceId", "stateId", "statusId",];
        for (let property of properties)
            if (vHospitalAddress && (vHospitalAddress[property] || vHospitalAddress[property] == 0))
                this[property] = vHospitalAddress[property];
    }
 
    @maxLength(200)
@required()
	@property('AddressLine1')
	addressLine1 : string = undefined ;
 
    @maxLength(200)
	@property('AddressLine2')
	addressLine2 : string = undefined ;
 
    @range(1,2147483647)
	@property('AddressRefId')
	addressRefId : number =   undefined;
 
    @range(1,2147483647)
	@property('AddressTypeId')
	addressTypeId : number =   undefined;
 
    @maxLength(60)
	@property('City')
	city : string = undefined ;
 
    @maxLength(50)
	@property('Contact')
	contact : string = undefined ;
 
    @range(1,2147483647)
	@property('HospitalId')
	hospitalId : number =   undefined;
 
    @required()
    @maxLength(100)
	@property('HospitalName')
	hospitalName : string = undefined ;
 
    @maxLength(50)
	@property('OfficePhone')
	officePhone : string = undefined ;
 
    @maxLength(10)
	@property('OfficePhoneExt')
	officePhoneExt : string = undefined ;
 
    @maxLength(5)
	@property('PostalCode')
	postalCode : string = undefined ;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
	@property('StateId')
	stateId : number =   undefined;
 
    @range(1,2147483647)
	@property('StatusId')
	statusId : number =   undefined;



}
