import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('OnCallOverride')
export class OnCallOverride {
    constructor(onCallOverride?: OnCallOverride )  {
        let properties = [ "callTypeId", "createdById", "createdDateTime", "fromDate", "fromTime", "modifiedById", "modifiedDateTime", "notificationPrefId", "onCallOverrideId", "practiceId", "providerId", "toDate", "toTime",];
        for (let property of properties)
            if (onCallOverride && (onCallOverride[property] || onCallOverride[property] == 0))
                this[property] = onCallOverride[property];
    }
 
	@property('CallTypeId')
	callTypeId : number =   undefined;
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
    @required()
	@property('FromDate')
	fromDate : Date =   undefined;
 
    @required()
	@property('FromTime')
	fromTime : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('NotificationPrefId')
	notificationPrefId : number =   undefined;
 
	@property('OnCallOverrideId')
	onCallOverrideId : number =   0 ;
 
    @range(1,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
 
	@property('ProviderId')
	providerId : number =   undefined;
 
    @required()
	@property('ToDate')
	toDate : Date =   undefined;
 
    @required()
	@property('ToTime')
	toTime : Date =   undefined;



}
