import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick } from '@rx/view';

import { vPractice } from 'src/app/database-models';
import { PracticesService } from '../practices.service';
import { PracticeDomain } from '../domain/practice.domain';
import { PracticeLookupGroup } from '../domain/practice.models';
import { Practice, Contact } from '../../../../database-models';
import { FormGroup } from '@angular/forms';
import { RxValidation, RxPopup } from '@rx';
import { user } from '@rx/security';
import { ApplicationBroadcaster } from '@rx/core';
import { AgentOfficeLookups } from '../../../../lookups';
import { DAYS_OF_WEEKS } from '../../../../database-collections';
import { BranchTypeEnum } from '../../../../enums';


@Component({
    templateUrl: './practice-search.component.html',
})
export class PracticeSearchComponent extends PracticeDomain implements OnInit, OnDestroy {
    selectedAddress: any;
    onCallRosterProvider: any;
    dayofweek: { "daysOfWeekId": number; "daysOfWeekName": string; }[];
    practiceAddresses: any;
    practiceOfficeHours: any;
    practiceId: any;
    showComponent: boolean = false;
    practices: any;
    searchSubscription: Subscription;
    practiceLookupGroup: PracticeLookupGroup;
    practiceFormGroup: FormGroup;
    AddressId: number;
    practiceName: string;

    constructor(
        private practicesService: PracticesService,
        private validation: RxValidation,
        private router: Router,
        private toast: RxToast,

        private popup: RxPopup,
        private applicationBroadcaster: ApplicationBroadcaster,
    ) {
        super();
        this.practiceName = user.data['practiceName']
        this.practiceId = user.data['practiceId'];
    }

    ngOnInit(): void {


        if (this.searchSubscription)
            this.searchSubscription.unsubscribe();
        this.searchSubscription = this.practicesService.search([{ practiceId: this.practiceId }]).subscribe(practices => {

            this.practices = practices;

            this.practiceAddresses = practices[0].PracticeAddressses;
            this.onCallRosterProvider = practices[0].OnCallRosterProvider;
            this.selectedAddress = this.practiceAddresses.where(t => t.AddressTypeRefId == BranchTypeEnum.Main)[0];
            this.selectedAddress.Location = 'Main';
            if (this.selectedAddress.AddressContacts == undefined || this.selectedAddress.AddressContacts == null) {
                this.selectedAddress['AddressContacts'] = [];
                this.selectedAddress['AddressContacts'][0] = new Contact();
            }
            this.practiceOfficeHours = this.selectedAddress.PracticeOfficeHours;

            this.showComponent = true
        })
    }

    setValues(practiceAddressId: any) {
        this.selectedAddress = this.practiceAddresses.where(t => t.AddressId == practiceAddressId)[0];
        if (this.selectedAddress.AddressContacts == undefined || this.selectedAddress.AddressContacts == null) {
            this.selectedAddress['AddressContacts'] = [];
            this.selectedAddress['AddressContacts'][0] = new Contact();
        }
        this.practiceOfficeHours = this.selectedAddress.PracticeOfficeHours;

    }


    HidePracticeSelectionPopup(): void {
        document.body.className = "";
        this.popup.hide(PracticeSearchComponent);
    }




    ngOnDestroy(): void {
        if (this.searchSubscription)
            this.searchSubscription.unsubscribe();
        super.destroy();
    }
}
