import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick } from '@rx/view';

import { vPractice } from 'src/app/database-models';
import { PracticesService } from '../practices.service';
import { PracticeDomain } from '../domain/practice.domain';
import { PracticeLookupGroup } from '../domain/practice.models';
import { Practice } from '../../../../database-models';
import { FormGroup } from '@angular/forms';
import { RxValidation, RxPopup } from '@rx';
import { user } from '@rx/security';
import { ApplicationBroadcaster } from '@rx/core';
import { AgentOfficeLookups } from '../../../../lookups';


@Component({
  templateUrl: './practice-list.component.html',
})
export class PracticeListComponent extends PracticeDomain implements OnInit, OnDestroy {
  showComponent: boolean = false;
  practices: vPractice[];
  listSubscription: Subscription;
  practiceLookupGroup: PracticeLookupGroup;
  practiceFormGroup: FormGroup;

  constructor(
    private practicesService: PracticesService,
    private validation: RxValidation,
    private router: Router,
    private toast: RxToast,

    private popup: RxPopup,
    private applicationBroadcaster: ApplicationBroadcaster,
  ) { super(); }

  ngOnInit(): void {

    if (this.listSubscription)
      this.listSubscription.unsubscribe();
    
    this.practicesService.lookup([AgentOfficeLookups.practices]).then(
      (response: PracticeLookupGroup) => {
        

        this.practiceLookupGroup = response;
        let sortedArray = this.practiceLookupGroup.practices.sort((obj1, obj2) => {
          if (obj1.practiceName.toLowerCase() > obj2.practiceName.toLowerCase()) {
            return 1;
          }

          if (obj1.practiceName.toLowerCase() < obj2.practiceName.toLowerCase()) {
            return -1;
          }

          return 0;
        });
        this.practiceLookupGroup.practices = sortedArray;
        this.practiceLookupGroup.practice = new Practice();
        this.practiceFormGroup = this.validation.getFormGroup(this.practiceLookupGroup.practice);
        this.showComponent = true;
      });
  }


  HidePracticeSelectionPopup(): void {
    document.body.className = "";
    this.popup.hide(PracticeListComponent);
  }

  UpdatePracticeId(): void {
    
    let userData = user.data;
    let practiceId = this.practiceFormGroup.controls.practiceId.value;
    if (practiceId != 0) {
      userData["practiceId"] = practiceId;
      user.data = userData;
      // this.applicationBroadcaster.practiceSelectionBroadCast(true);
      this.popup.hide(PracticeListComponent);
      this.router.navigate(["call-db/patient/", practiceId]);
    }
    else {
      this.toast.show("Please enter valid Practice name.", { 'status': "error" })
    }
  }


  ngOnDestroy(): void {
    if (this.listSubscription)
      this.listSubscription.unsubscribe();
    super.destroy();
  }
}
