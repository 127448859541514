import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { CallType } from './call-type';import { Provider } from './provider'; @table('NotificationPreferences')
export class NotificationPreference {
    constructor(notificationPreference?: NotificationPreference )  {
        let properties = [ "createdById", "createdDateTime", "dayOfWeekId", "fromTime", "modifiedById", "modifiedDateTime", "notificationPrefId", "notificationTypeId", "toTime", "callTypeId", "providerId",];
        for (let property of properties)
            if (notificationPreference && (notificationPreference[property] || notificationPreference[property] == 0))
                this[property] = notificationPreference[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('DayOfWeekId')
	dayOfWeekId : number =   undefined;
 
	@property('FromTime')
	fromTime : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
	@property('NotificationPrefId')
	notificationPrefId : number =   0 ;
 
    @range(1,2147483647)
	@property('NotificationTypeId')
	notificationTypeId : number =   undefined;
 
	@property('ToTime')
	toTime : Date =   undefined;
 
    @range(0,2147483647)
	@property('CallTypeId')
	callTypeId : number =   undefined;
	callType : CallType  ;
 
    @range(0,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
	provider : Provider  ;



}
