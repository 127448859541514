import {ComponentContainer} from "@rx/core"
import { AlertListComponent } from './list/alert-list.component'
import { AlertViewComponent } from './view/alert-view.component'

export const ALERTS_SHARED_COMPONENT_CONTAINER: ComponentContainer[] = [
	{
    component: AlertListComponent,
    accessItem: 'list',
    applicationModuleId: 12,
	keyName: 'alertId',
	childModuleName :  'alerts'  ,
	pageName:'alerts',
	rootModuleId:33
	},
	{
    component: AlertViewComponent,
    accessItem: 'view',
    applicationModuleId: 12,
	keyName: 'alertId',
	childModuleName :  'alerts'  ,
	pageName:'alerts',
	rootModuleId:33
	},
];

