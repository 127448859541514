import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Hospital } from './hospital';import { Practice } from './practice'; @table('HospitalAffiliations')
export class HospitalAffiliation {
    constructor(hospitalAffiliation?: HospitalAffiliation )  {
        let properties = [ "createdById", "createdDateTime", "hospitalAffiliationId", "modifiedById", "modifiedDateTime", "hospitalId", "practiceId",];
        for (let property of properties)
            if (hospitalAffiliation && (hospitalAffiliation[property] || hospitalAffiliation[property] == 0))
                this[property] = hospitalAffiliation[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('HospitalAffiliationId')
	hospitalAffiliationId : number =   0 ;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @range(0,2147483647)
	@property('HospitalId')
	hospitalId : number =   undefined;
	hospital : Hospital  ;
 
    @range(0,2147483647)
	@property('PracticeId')
	practiceId : number =   undefined;
	practice : Practice  ;



}
