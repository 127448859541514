import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { RxMessageComponent, CustomValidation } from '@rx/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import { RxToast, RxDialog, DialogClick, RxPopup } from '@rx/view';
import { RxValidation } from '@rx/forms';
import { User, vUser, } from 'src/app/database-models';

import { } from 'src/app/lookups';
import { UsersService } from '../users.service';
import { UserDomain } from '../domain/user.domain';

import { UserLookupGroup } from '../domain/user.models';
import { ApplicationBroadcaster, ApplicationConfiguration } from '@rx/core';
import { user } from '@rx/security';
import { RecordStatusEnum } from '../../../../enums';
import { ManageUserAgentLookups } from '../../../../lookups';
import { DEFAULT_USERPROFILE_PHOTO_URL } from 'src/app/const/default.const';
//import { User } from '@rx/security/security.models';



@Component({
    templateUrl: './user-edit.component.html',
    entryComponents: [RxMessageComponent]
})
export class UserEditComponent extends UserDomain implements OnInit, OnDestroy {
    isTemporaryImage: boolean;
    roleTypes: UserLookupGroup;
    practiceName: any;
    showComponent: boolean = false;
    userFormGroup: FormGroup;
    editSubscription: Subscription;
    userLookupGroup: UserLookupGroup;;
    validationFailed: {
        [key: string]: any;
    }
    PasswordPattern: string = "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$^+=!*()@%&]).{8,16}";
    isPassword: boolean;
    userId: number;
    constructor(
        private validation: RxValidation,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private toast: RxToast,
        private usersService: UsersService,
        private dialog: RxDialog,
        private popup: RxPopup,
        private applicationBroadcaster: ApplicationBroadcaster,
        //private applicationBroadcaster: ApplicationBroadcaster,
    ) {
        super();
        this.validationFailed = {};
        this.practiceName = user.data["practiceName"];

        activatedRoute.params.subscribe((param: any) => this.userId = param['userId']);

    }

    ngOnInit(): void {
        this.usersService.group([this.userId], [ManageUserAgentLookups.roleTypes]).then(
            (response: UserLookupGroup) => {
                this.userLookupGroup = new UserLookupGroup();
                this.roleTypes = response;
                this.userLookupGroup.user = new User(response.user);
                this.userFormGroup = this.validation.getFormGroup(this.userLookupGroup.user);
                // this.userFormGroup.controls['confirmPassword'].disable();
                // this.userFormGroup.controls['userPassword'].disable();
                this.userFormGroup.controls['password'].setValue(null);
                this.userFormGroup.controls['salt'].setValue(null);
                this.userFormGroup.controls['userPassword'].setValidators(null);
                this.userFormGroup.controls['confirmPassword'].setValidators(null);
               
                this.showComponent = true;
            });
    }

    

    editUser(): void {
        this.editSubscription = this.usersService.put(this.userFormGroup.value).subscribe(t => {
            if (this.userFormGroup.value.userId == user.data['userId']) {
                 this.applicationBroadcaster.updateEmailIdBroadCast({'email':t.email,'profilepic':t.userProfilePhotoUrl});
            }
            this.router.navigateByUrl('/users')
        },
            error => {
                this.popup.validationFailed(error);
            })
    }
    setStatus(): void {

        if (this.userFormGroup.value.statusId == RecordStatusEnum.InActive) {
            this.userFormGroup.controls.statusId.setValue(RecordStatusEnum.Active)
        }
        else {
            this.userFormGroup.controls.statusId.setValue(RecordStatusEnum.InActive)
        }
    }

    onFileChange(data: any): void {
        
        let target = data.target || data.srcElement;
        var file = target.files[0];
        // if (file.size >= (1000 * 1024)) {
        //   this.toast.show(ApplicationConfiguration.get("validation.message.custom.largeruploadfilethen1mb"), { status: 'error' });
        //   this.isTemporaryImage = false
        //   return;
        // }
        if (file) {
          this.isTemporaryImage = true;
          let filename = file.name;
          let extension = filename.substr((filename.lastIndexOf('.') + 1)).toLowerCase();
          this.userFormGroup.controls.fileExtension.patchValue(extension);
          this.userFormGroup.controls.fileType.patchValue(file.type);
          let split_str = "jpg,jpeg,bmp,png";
          if (split_str.indexOf(extension) !== -1) {
            let reader = new FileReader();
            reader.onload = (readerEvt: any) => {
              let documentPath = btoa(readerEvt.target.result);
              this.userFormGroup.controls.profilePicture.patchValue(documentPath);
            };
            reader.readAsBinaryString(file);
          }
          else {
            let error = [ApplicationConfiguration.get("validation.message.custom.imageuploadtypes")];
            this.isTemporaryImage = false
            //this.dialog.validation(error);
          }
        }
      }

    canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        return !this.userFormGroup.dirty;
    }
    deleteImage(){
        
        this.isTemporaryImage = false;
        this.userFormGroup.controls.userProfilePhotoUrl.setValue(DEFAULT_USERPROFILE_PHOTO_URL);
        this.userFormGroup.controls.profilePicture.setValue(null);
        this.fileInput.nativeElement.value = '';
    }
    @ViewChild('fileInput') fileInput: any;

    ngOnDestroy(): void {
        if (this.editSubscription)
            this.editSubscription.unsubscribe();
        super.destroy();
    }

    focusPassword() {
        if (!this.userFormGroup.value.userPassword) {
            this.userFormGroup.controls['userPassword'].setValue(null);
            this.userFormGroup.controls['confirmPassword'].setValue(null);
            this.userFormGroup.controls['userPassword'].setErrors(null);
            this.userFormGroup.controls['confirmPassword'].setErrors(null);
            this.userFormGroup.controls['userPassword'].setValidators(null);
            this.userFormGroup.controls['confirmPassword'].setValidators(null);
        }
        else {
            let customValidation: CustomValidation[] = [];
            customValidation.push({
                annotationProps: {
                    'userPassword': {
                        required: true,
                    },
                    'confirmPassword': {
                        required: true,
                    },
                },
            });

            this.userFormGroup = this.validation.getFormGroup(new User(this.userFormGroup.value), customValidation);
        }
        if (this.userFormGroup.controls['userPassword'].value)
            this.isPassword = true;
        else
            this.isPassword = false;
    }

    changePassword() {
        if (this.userFormGroup.controls['userPassword'].value)
            this.isPassword = true;
        else
            this.isPassword = false;
    }
}
