import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { UserMessage } from './user-message';import { MessageTransactionLog } from './message-transaction-log'; @table('UserMessageMapping')
export class UserMessageMapping {
    constructor(userMessageMapping?: UserMessageMapping )  {
        let properties = [ "isForward", "isRead", "messageType", "notificationCount", "notificationSendTime", "senderMessageTransactionLogId", "userId", "userMessageMappingId", "messageId", "messageTransactionLogId",];
        for (let property of properties)
            if (userMessageMapping && (userMessageMapping[property] || userMessageMapping[property] == 0))
                this[property] = userMessageMapping[property];
    }
 
	@property('IsForward')
	isForward : boolean = false ;
 
	@property('IsRead')
	isRead : boolean = false ;
 
    @range(1,2147483647)
	@property('MessageType')
	messageType : number =   undefined;
 
    @range(1,2147483647)
	@property('NotificationCount')
	notificationCount : number =   undefined;
 
    @required()
	@property('NotificationSendTime')
	notificationSendTime : Date =   undefined;
 
	@property('SenderMessageTransactionLogId')
	senderMessageTransactionLogId : number =   undefined;
 
    @range(1,2147483647)
	@property('UserId')
	userId : number =   undefined;
 
	@property('UserMessageMappingId')
	userMessageMappingId : number =   0 ;
 
    @range(0,2147483647)
	@property('MessageId')
	messageId : number =   undefined;
	userMessage : UserMessage  ;
 
	@property('MessageTransactionLogId')
	messageTransactionLogId : number =   undefined;
	messageTransactionLog : MessageTransactionLog  ;



}
