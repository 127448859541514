import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Provider } from './provider';import { CallDb } from './call-db'; @table('NotificationPlusTransactions')
export class NotificationPlusTransaction {
    constructor(notificationPlusTransaction?: NotificationPlusTransaction )  {
        let properties = [ "lastMsgSentTime", "messageSentCount", "messageSentTime", "messageSid", "notificationPlusTranId", "responseReceived", "providerId", "skey",];
        for (let property of properties)
            if (notificationPlusTransaction && (notificationPlusTransaction[property] || notificationPlusTransaction[property] == 0))
                this[property] = notificationPlusTransaction[property];
    }
 
	@property('LastMsgSentTime')
	lastMsgSentTime : Date =   undefined;
 
	@property('MessageSentCount')
	messageSentCount : number =   undefined;
 
    @required()
	@property('MessageSentTime')
	messageSentTime : Date =   undefined;
 
    @required()
    @maxLength(100)
	@property('MessageSid')
	messageSid : string = undefined ;
 
	@property('NotificationPlusTranId')
	notificationPlusTranId : number =   0 ;
 
	@property('ResponseReceived')
	responseReceived : any =   undefined;
 
    @range(0,2147483647)
	@property('ProviderId')
	providerId : number =   undefined;
	provider : Provider  ;
 
    @range(0,2147483647)
	@property('Skey')
	skey : number =   undefined;
	callDb : CallDb  ;



}
