import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";
import {RxViewModule } from '@rx/view';
import {RxFormsModule } from '@rx/forms';
import {RxTableModule } from "@rx/table";
import {DynamicComponentContainer } from '@rx/core';

import { AlertListComponent } from './list/alert-list.component'
import { AlertViewComponent } from './view/alert-view.component'
import {AlertsService } from "./alerts.service";
import {ALERTS_SHARED_COMPONENT_CONTAINER } from './alerts-shared-component.container';
@NgModule({
    imports: [
        RxViewModule, RxFormsModule,
        CommonModule, FormsModule, ReactiveFormsModule, RxTableModule
    ],
    declarations: [ AlertListComponent,  AlertViewComponent, ],
    providers: [AlertsService ],
    exports: [RouterModule, AlertListComponent,  AlertViewComponent, ]
})
export class AlertsSharedComponentModule { }
DynamicComponentContainer.register(ALERTS_SHARED_COMPONENT_CONTAINER );