import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('TwilioSession')
export class TwilioSession {
    constructor(twilioSession?: TwilioSession )  {
        let properties = [ "createdById", "createdDateTime", "modifiedById", "modifiedDateTime", "participantId", "patientPhoneNo", "sessionId", "twilioSessionId", "userId",];
        for (let property of properties)
            if (twilioSession && (twilioSession[property] || twilioSession[property] == 0))
                this[property] = twilioSession[property];
    }
 
    @range(1,2147483647)
	@property('CreatedById')
	createdById : number =   undefined;
 
    @required()
	@property('CreatedDateTime')
	createdDateTime : Date =   undefined;
 
	@property('ModifiedById')
	modifiedById : number =   undefined;
 
	@property('ModifiedDateTime')
	modifiedDateTime : Date =   undefined;
 
    @required()
    @maxLength(50)
	@property('ParticipantId')
	participantId : string = undefined ;
 
    @required()
    @maxLength(12)
	@property('PatientPhoneNo')
	patientPhoneNo : string = undefined ;
 
    @required()
    @maxLength(50)
	@property('SessionId')
	sessionId : string = undefined ;
 
	@property('TwilioSessionId')
	twilioSessionId : number =   0 ;
 
    @range(1,2147483647)
	@property('UserId')
	userId : number =   undefined;



}
