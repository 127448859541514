import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { user } from '@rx/security';
import { RecordStatusEnum } from '../../../../enums';

@Injectable()
export class UserDomain {
    constructor() { }

    setDefaultValue(userFormGroup: FormGroup) {
        if (userFormGroup.value["userId"] == undefined) {
            userFormGroup.controls['practiceId'].setValue(user.data["practiceId"]);
            userFormGroup.controls['applicationTimeZoneId'].setValue(1);
            userFormGroup.controls['statusId'].setValue(RecordStatusEnum.Active);
            userFormGroup.controls['isBackendUser'].setValue(false);
            userFormGroup.controls['languageId'].setValue(1);
            userFormGroup.controls['isFirstTimeLogin'].setValue(false);
            userFormGroup.controls['dateData'].setValue(new Date());
           
            
        }
        else {
            userFormGroup.controls['practiceId'].setValue(user.data["practiceId"]);
            userFormGroup.controls['applicationTimeZoneId'].setValue(1);
            
            userFormGroup.controls['isBackendUser'].setValue(false);
            userFormGroup.controls['languageId'].setValue(1);
            userFormGroup.controls['isFirstTimeLogin'].setValue(false);
            userFormGroup.controls['dateData'].setValue(new Date());
          
        }
      }
    destroy(): void {

    }
}
