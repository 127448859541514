import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule } from "@angular/forms";

import {RxFormsModule} from "@rx/forms";
import {RxViewModule} from "@rx/view";
import {    RxTableModule} from "@rx/table";

import { ScheduleTelevisitListComponent } from './schedule-televisit-list.component'
import { SCHEDULE_TELEVISIT_LIST_ROUTING } from './schedule-televisit-list.routing'
import {ScheduleTelevisitService } from "../schedule-televisit.service";

@NgModule({
    imports: [
        SCHEDULE_TELEVISIT_LIST_ROUTING ,
        CommonModule, RxViewModule, RxTableModule, RxFormsModule, FormsModule, ReactiveFormsModule,
		    ],
    declarations: [ScheduleTelevisitListComponent ],
    exports: [RouterModule],
    providers: [ScheduleTelevisitService]
})
export class ScheduleTelevisitListModule { }