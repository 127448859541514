import {ComponentContainer} from "@rx/core"
import { OnCallRosterListComponent } from './list/on-call-roster-list.component'

export const ON_CALL_ROSTERS_SHARED_COMPONENT_CONTAINER: ComponentContainer[] = [
	{
    component: OnCallRosterListComponent,
    accessItem: 'list',
    applicationModuleId: 3,
	keyName: 'onCallRosterId',
	childModuleName :  'oncallroster'  ,
	pageName:'on-call-roster',
	rootModuleId:2
	},
];

