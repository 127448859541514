export const ENTITIES = [
	{
        "entityId": 67,
        "entityName": "Practice"
    },
	{
        "entityId": 69,
        "entityName": "Aht"
    },
	{
        "entityId": 70,
        "entityName": "Hospital"
    },
]