import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
import { Hospital } from './hospital'; @table('RosterHospitalMaping')
export class RosterHospitalMaping {
    constructor(rosterHospitalMaping?: RosterHospitalMaping )  {
        let properties = [ "rosterHospitalMapingId", "rosterId", "hospitalId",];
        for (let property of properties)
            if (rosterHospitalMaping && (rosterHospitalMaping[property] || rosterHospitalMaping[property] == 0))
                this[property] = rosterHospitalMaping[property];
    }
 
	@property('RosterHospitalMapingId')
	rosterHospitalMapingId : number =   0 ;
 
    @range(1,2147483647)
	@property('RosterId')
	rosterId : number =   undefined;
 
    @range(0,2147483647)
	@property('HospitalId')
	hospitalId : number =   undefined;
	hospital : Hospital  ;



}
