import { NgModule } from '@angular/core';
import {RouterModule } from '@angular/router';
import { ScheduleTelevisitAddComponent } from './add/schedule-televisit-add.component';


import { SCHEDULE_TELEVISIT_ROUTING } from './schedule-televisit.routing';
import { ScheduleTelevisitService } from './schedule-televisit.service';

@NgModule({
    declarations:[],
    imports: [SCHEDULE_TELEVISIT_ROUTING],
    exports: [RouterModule,],
    providers: [ScheduleTelevisitService],
    
})
export class ScheduleTelevisitModule { }