import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';

import { RxHttp, RequestQueryParams, LookupAction } from '@rx/http';
import { AuthorizeApi } from "@rx/security";

import { AhtProviderMaster, } from 'src/app/database-models';

@Injectable()
export class AhtProviderMasterService {
private ahtProviderId : number;
private methodName : string;
  private get api(): AuthorizeApi {
    var authorizeApi: AuthorizeApi = {
      api: `api/AhtProviderMaster`,
      applicationModuleId: 1032,
      //childModuleName: 'after-hours-triages',
      keyName: 'ahtProviderMasterId'

    }
    return authorizeApi;
  }

  constructor(
    private http: RxHttp
  ) { }

  lookup<AhtProviderMasterLookupGroup>(lookupActions: LookupAction[]): Promise<AhtProviderMasterLookupGroup> {
    return this.http.lookup<AhtProviderMasterLookupGroup>(lookupActions);
  }

  group<AhtProviderMasterLookupGroup>(params: any[] | {
    [key: string]: any;
  } | RequestQueryParams, lookupActions: LookupAction[]): Promise<AhtProviderMasterLookupGroup> {
    return this.http.group<AhtProviderMasterLookupGroup>(this.api, params, 'ahtProviderMaster', lookupActions);
  }

  search(search: any): Observable<AhtProviderMaster[]> {
    return this.http.search<AhtProviderMaster[]>(this.api, search);
  }

  get(): Observable<AhtProviderMaster[]> {
    return this.http.get<AhtProviderMaster[]>(this.api);
  }

  getBy(params?: any[] | {
    [key: string]: any;
  } | RequestQueryParams): Observable<AhtProviderMaster> {
    return this.http.get<AhtProviderMaster>(this.api, params);
  }

  post(ahtProviderMaster: AhtProviderMaster): Observable<AhtProviderMaster> {
    return this.http.post<AhtProviderMaster>(this.api, ahtProviderMaster);
  }

  put(ahtProviderMaster: AhtProviderMaster): Observable<AhtProviderMaster> {
    return this.http.put<AhtProviderMaster>(this.api, ahtProviderMaster);
  }

  delete(id: number): Observable<AhtProviderMaster> {
    return this.http.delete<AhtProviderMaster>(this.api, id);
  }

  private get ahtproviders_search(): AuthorizeApi {
    var authorizeApi: AuthorizeApi = {
        api: `api/ahtproviders/search/${this.methodName}/${this.ahtProviderId}`,
        applicationModuleId: 1032,
        keyName:'ahtProviderMasterId'

    }
    return authorizeApi;
}       
GetAhtPracticeList(ahtProviderId : number):Observable<any[]> {
    this.ahtProviderId = ahtProviderId;
    this.methodName = "GetAhtPracticeList";
    return this.http.get<any[]>(this.ahtproviders_search);
}

DeleteAhtDivertCallsForPractice(ahtProviderId : number):Observable<any[]> {
  this.ahtProviderId = ahtProviderId;
  this.methodName = "DeleteAhtDivertCallsForPractice";
  return this.http.get<any[]>(this.ahtproviders_search);
}

GetCurrentNotificationMethod(ProviderId : number):Observable<any[]> {
  this.ahtProviderId = ProviderId;
  this.methodName = "GetCurrentNotificationMethod";
  return this.http.get<any[]>(this.ahtproviders_search);
}

}
