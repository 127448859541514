import { Component, OnInit, OnDestroy , Input,ComponentFactoryResolver} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { RxMessageComponent } from '@rx/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/Rx';

import {RxToast, RxDialog, DialogClick,RxPopup } from '@rx/view';
import { RxValidation } from '@rx/forms';
import {  CallOutbound, } from 'src/app/database-models';

import { } from 'src/app/lookups';
import { CallOutboundService } from '../call-outbound.service';
import { CallOutboundDomain } from '../domain/call-outbound.domain';
import { CallOutboundLookupGroup } from '../domain/call-outbound.models';



@Component({
    templateUrl: './call-outbound-edit.component.html',
    entryComponents : [RxMessageComponent]
})
export class CallOutboundEditComponent extends CallOutboundDomain implements OnInit, OnDestroy {
    showComponent:boolean = false;
    callOutboundFormGroup: FormGroup;
    editSubscription: Subscription;
    callOutboundLookupGroup: CallOutboundLookupGroup;;
callOutboundId: number;
	@Input()  practiceId :number;
    constructor(
        private validation: RxValidation,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private toast: RxToast,
        private callOutboundService: CallOutboundService,    
        private dialog: RxDialog,
		private popup:RxPopup
    ) { 
        super();
        activatedRoute.params.subscribe((param: any) => this.callOutboundId = param['callOutboundId']);

    }

    ngOnInit(): void {

		this.callOutboundService.getBy(this.practiceId,[this.callOutboundId]).subscribe(
            (response: CallOutbound) => {
                this.callOutboundLookupGroup = new CallOutboundLookupGroup();
                this.callOutboundLookupGroup.callOutbound = new CallOutbound(response);
                this.callOutboundFormGroup = this.validation.getFormGroup(this.callOutboundLookupGroup.callOutbound);
                this.showComponent = true;
            });


    }


    editCallOutbound(): void {
        this.editSubscription =  this.callOutboundService.put(this.practiceId,this.callOutboundFormGroup.value).subscribe(t => {
            
        },
            error => {
                    this.dialog.validation(error);
        })
    }

	canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        return !this.callOutboundFormGroup.dirty;
    }

    ngOnDestroy(): void {
        if (this.editSubscription)
            this.editSubscription.unsubscribe();
        super.destroy();
    }
}
