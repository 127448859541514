import { required, maxLength, range, nested,table, property, alpha, alphaNumeric, numeric, uppercase,pattern,minDate,maxNumber,maxDate,lowercase,hexColor,email,contains,compare } from '@rx/annotations';
@table('vAgentCallHistory')
export class vAgentCallHistory {
    constructor(vAgentCallHistory?: vAgentCallHistory )  {
        let properties = [ "agentId", "callName", "callTypeId", "callTypeName", "comments", "cStartTimeDate", "notPrefId", "patientName", "practiceId", "processed", "skey", "textMessage", "userName",];
        for (let property of properties)
            if (vAgentCallHistory && (vAgentCallHistory[property] || vAgentCallHistory[property] == 0))
                this[property] = vAgentCallHistory[property];
    }
 
	@property('AgentId')
	agentId : number =   undefined;
 
    @maxLength(101)
	@property('CallName')
	callName : string = undefined ;
 
    @range(1,2147483647)
	@property('CallTypeId')
	callTypeId : number =   undefined;
 
    @required()
    @maxLength(20)
	@property('callTypeName')
	callTypeName : string = undefined ;
 
    @maxLength(1000)
	@property('Comments')
	comments : string = undefined ;
 
	@property('CStartTimeDate')
	cStartTimeDate : Date =   undefined;
 
	@property('NotPrefId')
	notPrefId : number =   undefined;
 
    @required()
    @maxLength(101)
	@property('PatientName')
	patientName : string = undefined ;
 
	@property('practiceId')
	practiceId : number =   undefined;
 
	@property('Processed')
	processed : boolean = false ;
 
    @range(1,2147483647)
	@property('skey')
	skey : number =   undefined;
 
    @maxLength(5000)
	@property('TextMessage')
	textMessage : string = undefined ;
 
    @maxLength(50)
	@property('UserName')
	userName : string = undefined ;



}
