import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { user } from '@rx/security';

@Injectable()
export class AlertDomain {
  constructor() { }

  timeConversionTo12Hour(time: any): any {
    if (time != null && time != undefined && time != "") {
        var timeArray = time.split(":");
        var hours = parseInt(timeArray[0]);
        var isMr = hours >= 12 ? "PM" : "AM"
        var newhour = hours >= 12 ? (hours == 12 ? 12 : hours - 12) : (hours == 0 ? 12 : hours);
        var paddedNum = newhour.toString().length == 1 ?String(newhour).padStart(2,'0') : newhour;
        time = paddedNum  + ":" + timeArray[1] + " " + isMr;
        return time;
    }
    else {
        return time;
    }
}

timeConversionTo24Hour(time: any): any {
    if (time != null && time != undefined && time != "") {
        var splitText = time.split(" ");
        var splitTime = splitText[0].split(":");
        var finalTime = "";
        if (splitText[1] == "PM" || splitText[1] == "pm") {
            switch (splitTime[0]) {
                case "00":
                    finalTime = "".concat("12", ":", splitTime[1])
                    break;
                case "01":
                    finalTime = "".concat("13", ":", splitTime[1])
                    break;
                case "02":
                    finalTime = "".concat("14", ":", splitTime[1])
                    break;
                case "03":
                    finalTime = "".concat("15", ":", splitTime[1])
                    break;
                case "04":
                    finalTime = "".concat("16", ":", splitTime[1])
                    break;
                case "05":
                    finalTime = "".concat("17", ":", splitTime[1])
                    break;
                case "06":
                    finalTime = "".concat("18", ":", splitTime[1])
                    break;
                case "07":
                    finalTime = "".concat("19", ":", splitTime[1])
                    break;
                case "08":
                    finalTime = "".concat("20", ":", splitTime[1])
                    break;
                case "09":
                    finalTime = "".concat("21", ":", splitTime[1])
                    break;
                case "10":
                    finalTime = "".concat("22", ":", splitTime[1])
                    break;
                case "11":
                    finalTime = "".concat("23", ":", splitTime[1])
                    break;
                case "12":
                    finalTime = "".concat("12", ":", splitTime[1])
                    break;
            }
        }
        else if (splitText[1] == "AM" || splitText[1] == "am") {
            switch (splitTime[0]) {
                case "00":
                    finalTime = "".concat("00", ":", splitTime[1])
                    break;
                case "01":
                    finalTime = "".concat("01", ":", splitTime[1])
                    break;
                case "02":
                    finalTime = "".concat("02", ":", splitTime[1])
                    break;
                case "03":
                    finalTime = "".concat("03", ":", splitTime[1])
                    break;
                case "04":
                    finalTime = "".concat("04", ":", splitTime[1])
                    break;
                case "05":
                    finalTime = "".concat("05", ":", splitTime[1])
                    break;
                case "06":
                    finalTime = "".concat("06", ":", splitTime[1])
                    break;
                case "07":
                    finalTime = "".concat("07", ":", splitTime[1])
                    break;
                case "08":
                    finalTime = "".concat("08", ":", splitTime[1])
                    break;
                case "09":
                    finalTime = "".concat("09", ":", splitTime[1])
                    break;
                case "10":
                    finalTime = "".concat("10", ":", splitTime[1])
                    break;
                case "11":
                    finalTime = "".concat("11", ":", splitTime[1])
                    break;
                case "12":
                    finalTime = "".concat("00", ":", splitTime[1])
                    break;
            }
        }
        return finalTime == "" ? time : finalTime;
    }
    return null;
}

  setDefaultValue(alertFormGroup: FormGroup, alertTypeId: number) {
    if (alertFormGroup.value["alertId"] == null || alertFormGroup.value["alertId"] == 0) {
      alertFormGroup.controls['createdById'].setValue(user.data["userId"]);
      alertFormGroup.controls['createdDateTime'].setValue(new Date());
      alertFormGroup.controls['practiceId'].setValue(user.data["practiceId"]);
      alertFormGroup.controls['alertTypeId'].setValue(alertTypeId);
    }
    else {
      alertFormGroup.controls['modifiedById'].setValue(user.data["userId"]);
      alertFormGroup.controls['modifiedDateTime'].setValue(new Date());
    }

  }

  dateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }
  
  destroy(): void {

  }
}
